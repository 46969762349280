import React from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/ReactPhoneNumber.css';
import {ProductRouter} from "./product/ProductRouter";

const App = ({surelyncProps}) => {
    return (
            <div>
                <Router basename={surelyncProps ? `/surelync/sureapp` : "/sureapp"}>
                    <Switch>
                        <Route path="/:id">
                            <ProductRouter surelyncProps={surelyncProps}/>
                        </Route>
                        <Route path="/">
                            <ProductRouter surelyncProps={surelyncProps}/>
                        </Route>
                    </Switch>
                </Router>
            </div>
    );
};

App.propTypes = {
    surelyncProps: PropTypes.object,
};

export default App;
