import React from 'react';

const Button = (allProps) => {
    const {
        pending,
        disabled,
        children,
        ...props
    } = allProps;
    return (
        <button disabled={pending || disabled} className="btn" {...props}>{pending ? <i className="fa fa-hourglass fa-spin"/> : children}</button>
    );
};

export default Button;
