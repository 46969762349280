export const sortAddressesNumerically = (token, addresses) =>
        addresses.sort(({[token]: a}, {[token]: b}) => {
            const matchesA = a && a.match && (a.match(/\d+$/) || a.match(/^(\d+)/));
            const matchesB = b && a.match && (b.match(/\d+$/) || b.match(/^(\d+)/));
            if (matchesA && matchesB) {
                a = parseFloat(matchesA[0]);
                b = parseFloat(matchesB[0]);
            }
            return +a === +b ? 0 : +a < +b ? -1 : 1;
        });

export const getItemForSort = (addresses, fields) => {
    return addresses.find(item => item[fields[0]] || item[fields[1]] || item[fields[2]] || item[fields[3]]);
}

export const getSortedKey = (sortedItem, fields) => {
    return Object.keys(sortedItem).find(item => {
            if (item === fields[0] && sortedItem[fields[0]]) {
                return true;
            }
            if (item === fields[1] && sortedItem[fields[1]]) {
                return true;
            }
            if (item === fields[2] && sortedItem[fields[2]]) {
                return true;
            }
            if (item === fields[3] && sortedItem[fields[3]]) {
                return true;
            }
            return false;
        });
}

// Remove double Flat 24 from this array
// ['Flat 24', 'Flat 24, Michaelson House', 'Kingswood Estate', '', '', undefined]
// For fixing this result:
// 'Flat 24, Flat 24, Michaelson House, Kingswood Estate'
export const filterDoubles = (item, index, array) => !(array[index + 1] || '').startsWith(`${item},`);
