import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {Login} from '@blocksure/blocksure-core/dist/src/components/pages';
import queryString from 'query-string';
import {ResetPasswordComponent, TriggerPasswordResetComponent} from '@blocksure/blocksure-core/dist/src/components';
import {base, convertToNewPath, productBase} from '../base';
import {QuoteFlow} from '../quote/QuoteFlow';
import {LocalStorageContext, TradingGroupContext} from '../common/context';
import HomeNoAuth from '../home/HomeNoAuth';
import Header from '../widgets/Header';
import {useDefaultTranslation} from '../common/Translation';

export const NotAuthenticatedRoutes = ({login, redirectToLogin}) => {
    const location = useLocation();
    const history = useHistory();
    const {
        t,
        i18n
    } = useDefaultTranslation();

    const [error, setError] = useState(null);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    useEffect(() => {
        history.listen((location) => {
            setError(null);
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageview');
        });
        // eslint-disable-next-line
    }, [history]);

    const redirectPath = redirectToLogin ? `${newPath}/login` : `${newPath}/`;

    const showHeader = !global.embedded &&
            location.pathname !== `${newPath}/login` &&
            location.pathname !== `${newPath}/reset/callback` &&
            location.pathname !== `${newPath}/reset`;
    return (
            <LocalStorageContext.Consumer>{({localStorage}) => (
                    <TradingGroupContext.Consumer>{({
                        group,
                        logo
                    }) => {
                        return (
                                <div>
                                    {showHeader && <Header/>}
                                    <Switch>
                                        {/**** Menu ****/}
                                        <Route path={`${newPath}/`} component={(props) => <HomeNoAuth {...props} />} exact/>

                                        {/**** Quick Quote ****/}
                                        <Route path={`${newPath}/quote/:productOrSequenceId/:step?`} component={QuoteFlow}/>

                                        {/**** Login ****/}
                                        <Route path={`${newPath}/login`} component={props =>
                                                <Login {...props} {...{
                                                    history,
                                                    t,
                                                    logo,
                                                    title: group.tradingName,
                                                    productPath: newPath
                                                }}
                                                    lng={i18n.language}
                                                       error={error}
                                                       login={login}/>}
                                        />

                                        {/**** Reset Password ****/}
                                        <Route path={`${newPath}/reset`} exact render={props =>
                                                <TriggerPasswordResetComponent
                                                        {...props}
                                                        t={t}
                                                        logo={logo}
                                                        forwardUrl={`${window.location.origin.toString()}${base}/reset/callback/`}
                                                        appName={localStorage.getItem('app-name')}
                                                        lng={i18n.language}
                                                        productId={new Map(Object.entries(group.productIdByAlias)).get(productBase)}
                                                        productPath={newPath}
                                                />
                                        }/>

                                        {/**** Reset Password Callback ****/}
                                        <Route path={`${newPath}/reset/callback`} render={props =>
                                                <ResetPasswordComponent
                                                        t={t}
                                                        loginPath={`${newPath}/login`}
                                                        jwt={queryString.parse(props.location.search).jwt}
                                                        appName={queryString.parse(props.location.search).appName}
                                                        jwtError={queryString.parse(props.location.search).jwtError}
                                                        primaryEmailAddress={queryString.parse(props.location.search).email}
                                                />
                                        }/>

                                        {/**** This is required to make legacy embedded products work. ****/}
                                        <Route path={`${newPath}/:productId`} component={params => {
                                            if (params.match.params.productId.indexOf('prd_') !== 0) return <Redirect to={`${newPath}/`}/>;
                                            return <Redirect to={`${newPath}/quote/${params.match.params.productId}`}/>;
                                        }} exact/>

                                        {/**** Otherwise go home ****/}
                                        <Redirect to={redirectPath}/>
                                    </Switch>
                                </div>
                        );
                    }}</TradingGroupContext.Consumer>
            )}</LocalStorageContext.Consumer>
    );
};
