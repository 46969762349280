import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { PaymentApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { CurrencyUtils, IDrawdown, ITableData, localeService, MessageBox, PlaceHolder, RecordsNotFound, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataCsv, setDataDrawdownDetails } from "./helper";
import GlobalContext from "../context/global-context";
import { BackButton, BannerClientLogo, PageContainer } from "../components";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { getHeaderColumnsI18 } from "./columns.config";

const DrawdownDetailsPage: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const params = useParams<{ id: string }>();
    const { t } = useTranslation();
    const theme = useTheme();

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [batch, setBatch] = useState<IDrawdown>();
    const [csvData, setСsvData] = useState<any>([]);
    const [error, setError] = useState<string>(null);

    const paymentApiClient = new PaymentApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const headerTitles = [
        [
            `${t("policyId")}`,
            `${t("transactionReference")}`,
            `${t("dateCollected")}`,
            `${t("dateAvailable")}`,
            `${t("status")}`,
            `${t("currency")}`,
            `${t("amountCollected")}`,
            `${t("paymentFee")}`,
            `${t("netAmount")}`,
        ],
    ];

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);
        try {
            const batches = await paymentApiClient.getSettlementBatch(params.id);
            const headerColumnsI18 = getHeaderColumnsI18();

            if (!isMountRef.current) {
                return;
            }

            setTotal(batches.total);
            setHeaderColumns(headerColumnsI18);
            const dataSettlementStatement = setDataDrawdownDetails(batches.settlements);
            setData(dataSettlementStatement);
            setBatch(batches);

            const dataCsv = setDataCsv(headerTitles, batches.settlements);
            setСsvData(dataCsv);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    return (
        <PageContainer isBreadcrumb={true} title={t("drawdownsDetails")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Box mb={1}>
                                <BackButton />
                            </Box>
                            <Typography variant="h5">{t("drawdownsDetails")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} justify="space-between">
                        <Grid item xs={12} lg={6}>
                            <Typography variant="body1">
                                {t("dateSettled")}: {batch ? FormatUtils.renderDate(batch.settled) : "-"}
                            </Typography>
                            <Typography variant="body1">
                                {t("reference")}: {batch ? batch.id : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <CSVLink
                                    data={csvData}
                                    filename={batch ? `settled_with_ref-${batch.id}.csv` : "settled"}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button color="primary" variant="contained">
                                        {t("exportCsv")}
                                    </Button>
                                </CSVLink>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} loading={loading} />
                </Grid>
                {data && data.length === 0 && (
                    <Grid item xs={12}>
                        <RecordsNotFound theme={theme} />
                    </Grid>
                )}
                {error ? (
                    <Grid item xs={12} md={8}>
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item xs={12} lg={3}>
                            <Grid container spacing={1} justify="flex-end">
                                <Grid item xs={8}>
                                    <Typography variant="body1">{t("totalAmount")}:</Typography>
                                    <Typography variant="body1">{t("stripeFee")}:</Typography>
                                    <Typography variant="body1">{t("totalLessGateway")}:</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right">
                                        {batch ? CurrencyUtils._renderCurrency(batch.amount, localeService.locale, batch.currency) : PlaceHolder}
                                    </Typography>
                                    <Typography variant="body2" align="right">
                                        {batch
                                            ? CurrencyUtils._renderCurrency(CurrencyUtils.getStripeFee(batch), localeService.locale, batch.currency)
                                            : PlaceHolder}
                                    </Typography>
                                    <Typography variant="body2" align="right">
                                        {batch
                                            ? CurrencyUtils._renderCurrency(
                                                batch.amount - CurrencyUtils.getStripeFee(batch),
                                                localeService.locale,
                                                batch.currency
                                            )
                                            : PlaceHolder}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" component={Link} to={"/statements"}>
                                {t("statements")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default DrawdownDetailsPage;
