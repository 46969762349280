import {AuthContext, PolicyContext} from '../common/context';
import React from 'react';
import './Screen.css';
import {ProductProvider} from '../product/ProductProvider';
import {PolicyProvider} from '../policy/PolicyProvider';

export const ProductScreen = ({
    children,
    productId
}) => (
        <AuthContext.Consumer>{({auth, hasPassword}) => (
                <ProductProvider productId={productId} email={auth && auth.username !== 'anonymous' ? auth.username : null} hasPassword={hasPassword}>
                    <Screen>
                        {children}
                    </Screen>
                </ProductProvider>
        )}</AuthContext.Consumer>
);

export const PolicyScreen = ({
    children,
    sequenceId,
}) => (
        <PolicyProvider sequenceId={sequenceId}>
            <PolicyContext.Consumer>{({policy}) => (
                    <ProductScreen productId={policy && policy.productId}>
                        {children}
                    </ProductScreen>
            )}</PolicyContext.Consumer>
        </PolicyProvider>
);

export const Screen = ({children}) => (
        <div className="screen container">
            {children}
        </div>
);
