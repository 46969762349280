import { IAction } from "@surelync/common";

export const SET_EARLIST_CANELLATION_DATE = "SET_EARLIST_CANELLATION_DATE";
export const POLICY_CANCEL_START = "POLICY_CANCEL_START";
export const POLICY_CANCEL_DONE = "POLICY_CANCEL_DONE";
export const POLICY_CANCEL_FAILED = "POLICY_CANCEL_FAILED";
export const POLICY_PROCEED_START = "POLICY_PROCEED_START";
export const POLICY_PROCEED_DONE = "POLICY_PROCEED_DONE";
export const POLICY_PROCEED_FAILED = "POLICY_PROCEED_FAILED";
export const SET_ERROR = "SET_ERROR";
export const RESET = "RESET";

export interface PolicyState {
    earliestCancellationDate: string;
    error: string;
    pending: boolean;

    // step 2
    cancellationFee: number;
    refundAmount: number;
    refundable: boolean;
}

export const initialState = {
    earliestCancellationDate: "",
    error: "",

    pending: false,

    // step 2
    cancellationFee: null,
    refundAmount: null,
    refundable: false,
};

export function reducer(state: PolicyState, action: IAction) {
    // console.log(action)
    switch (action.type) {
        case POLICY_CANCEL_START:
            return { ...state, error: null, refundAmount: null, cancellationFee: null, pending: true };
        case POLICY_CANCEL_DONE:
            return { ...state, pending: false, refundable: true, ...action.payload };
        case POLICY_CANCEL_FAILED:
            return { ...state, pending: false, error: action.payload };

        case POLICY_PROCEED_START:
            return { ...state, pending: true };
        case POLICY_PROCEED_FAILED:
            return { ...state, pending: false, error: action.payload };

        case SET_EARLIST_CANELLATION_DATE:
            return { ...state, earliestCancellationDate: action.payload };
        case SET_ERROR:
            return { ...state, error: action.payload };

        case POLICY_PROCEED_DONE:
        case RESET:
            return { ...initialState };

        default:
            return state;
    }
}
