import * as React from "react";
import { useContext, useMemo } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { ContactContext, SET_TAB } from "../../../../models";
import { Line } from "../../../../../components";
import { Link } from "react-router-dom";
import { CurrencyUtils, localeService, RecordsNotFound } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import LinkButton from "@material-ui/core/Link";
import { ETab } from "../../../../models/tabs.model";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        ellipsContent: {
            overflow: "hidden",
            maxWidth: "100%",
        },
    })
);

type IProps = Record<string, unknown>;

const RecentClaims: React.FC<IProps> = () => {
    const [{ claims, policies }, dispatch] = useContext(ContactContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const recentClaim = claims ? FormatUtils.sortByProperty([...claims], "creationDate").pop() : null;
    const policy = recentClaim ? policies.find((p) => p.id === recentClaim.policyId) : null;
    const amount = useMemo(
        () => (recentClaim ? CurrencyUtils._renderCurrency(recentClaim.claimAmount, localeService.locale, recentClaim.currency) : ""),
        [recentClaim]
    );
    const dateOfIncident = useMemo(() => (recentClaim ? FormatUtils.renderDate(recentClaim.dateOfIncident) : ""), [recentClaim]);

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("recentClaims")}
            </Typography>
            <Paper className={classes.root}>
                {recentClaim ? (
                    <Grid container direction="column" spacing={3} classes={{ root: classes.content }} data-testid="recent-claim">
                        <Grid item xs>
                            <LinkButton component={Link} to={`/claims/${recentClaim.id}`} variant="body1">
                                {recentClaim.category} - {recentClaim.id}
                            </LinkButton>
                            <Line divider label={`${t("policy")} ${t("id")}`} value={policy.reportingRef} />
                            <Line divider label={t("status")} value={t(recentClaim?.status.toLowerCase())} />
                            <Line divider label={t("dateOfLoss")} value={dateOfIncident} />
                            <Line label={t("claimAmount")} value={amount} />
                        </Grid>
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end">
                                <LinkButton
                                    href="#"
                                    color="primary"
                                    variant="body2"
                                    onClick={() => dispatch({ type: SET_TAB, payload: ETab.Claims })}
                                >
                                    {t("viewAll")}
                                </LinkButton>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    );
};

export default RecentClaims;
