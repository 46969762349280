import {merge} from 'lodash';
import {translate} from './Translate';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isObjectLike from 'lodash/isObjectLike';

export function loadSubmissionSchema(submissionSchema, def, universalPolicyholderSchema, t, type) {
    const rawSubmissionSchema = JSON.parse(JSON.stringify(submissionSchema)
            .replace(/"def.json#\//g, '"#/definitions/'));
    const rawDef = JSON.parse(JSON.stringify(def)
            .replace(/"#\//g, '"#/definitions/'));

    // Here we manually 'import' the external definitions and update the JSON pointers to local ones.
    rawSubmissionSchema.definitions = merge(rawSubmissionSchema.definitions, rawDef);

    // There are a few steps common to all submission schemas, so we add them here.
    if (type === 'submission') {
        insertStepIfMissing(rawSubmissionSchema, 0, 'amend', 'Amend', {});
        insertStepIfMissing(rawSubmissionSchema, rawSubmissionSchema['ui:order'].indexOf('payment') + 1, 'password', 'Password', {});
    }
    // There are a few steps common to all claim schemas, so we add them here.
    if (type === 'claim') {
        insertStepIfMissing(rawSubmissionSchema, rawSubmissionSchema['ui:order'].length, 'summary', 'Summary', {});
    }

    // include 'fm:ref:universalPolicyholderSchema' property from the Universal Policyholder Schema
    const universalPolicyholderSchemaProps = processUniversalPolicyholderSchema(rawSubmissionSchema, universalPolicyholderSchema);

    // Add 'fm:path' annotations - these contain the absolute path to the property. These can be used for lookups such as to translate.
    const withPaths = addPaths(rawSubmissionSchema);

    // universalPolicyholderSchema do not include
    // "title": (translated),
    // add ref to the "#/definitions/"
    if (universalPolicyholderSchemaProps.length) {
        addDefinitionsToUniversalProps(universalPolicyholderSchemaProps);
    }

    // Now split out uiSchema from schema.
    const translated = translate(t, withPaths);

    // Finally apply any translations.
    return processSchema(translated, []);
}

function insertStepIfMissing(schema, index, name, title, options) {
    if (!schema['ui:order'].includes(name)) {
        schema['ui:order'].splice(index, 0, name);
    }
    if (!schema.properties[name]) {
        schema.properties[name] = {
            title,
            type: 'object',
            'ui:widget': `${name}-screen`,
            'ui:options': options
        };
    }
}

function addDefinitionsToUniversalProps(arr) {
    arr.forEach(({
        key,
        obj: {properties}
    }) => {
        Object.keys(properties || {})
                .forEach(key => properties[key]['$ref'] = `#/definitions/${properties[key]['fm:path']}`);
    });
}

function addPaths(schema, path = []) {
    // If the penultimate entry in the path is 'properties', ie. we are a property - add the 'fm:path' property.
    if (path[path.length - 2] === 'properties') {
        schema['fm:path'] = path.filter(it => it !== 'properties')
                .join('.');
    }

    // Recurse through objects and arrays in the schema.
    if (schema) Object.entries(schema)
            .forEach(([key, value]) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    addPaths(value, path.concat([key]));

                } else if (Array.isArray(value)) {
                    value.forEach((it, i) => {
                        if (it && (typeof it === 'object' || Array.isArray(it))) {
                            addPaths(it, path.concat([`${key}[${i}]`]));
                        }
                    });
                }
            });

    return schema;
}

function getUniversalPolicyholderProps(obj, prop) {
    return reduce(obj, function (result, value, key) {
        if (typeof value === 'string' && value.startsWith(prop)) {
            result.push({
                key: value.replace(prop, ''),
                obj
            });
            delete obj[key];
        } else if (isObjectLike(value)) {
            return result.concat(getUniversalPolicyholderProps(value, prop));
        }
        return result;
    }, []);
}

function processSchema(raw, path = []) {
    const schema = {};
    const ui = {};

    // Split the 'UI schema' out from the vanilla 'JSON schema'.
    if (raw) Object.keys(raw)
            .forEach(key => {
                let value = raw[key];
                // Whitelist of properties to move over to the UI.
                if (key === 'classNames' || key.startsWith('ui:') || key.startsWith('fm:')) {
                    ui[key] = value;
                    // If the value is an object, then we recurse.
                } else if (value && typeof value === 'object' && !Array.isArray(value)) {
                    const {
                        submissionSchema,
                        submissionUiSchema
                    } = processSchema(value, path.concat([key]));
                    if (Object.keys(submissionSchema).length > 0) schema[key] = submissionSchema;
                    if (submissionUiSchema && Object.keys(submissionUiSchema).length > 0) ui[key] = submissionUiSchema;
                    // For all other values, we just set it into the new schema.
                } else {
                    schema[key] = value;
                }


            });

    // The UI schema inexplicably does away with the 'properties' object in its schema, so we move all of its properties up to the level above and remove it.
    if (ui.properties) {
        Object.keys(ui.properties)
                .forEach(key => {
                    ui[key] = ui.properties[key];
                });
        delete ui.properties;
    }
    return {
        submissionSchema: schema,
        submissionUiSchema: ui
    };
}

function processUniversalPolicyholderSchema(raw, universalPolicyholderSchema) {
    var results = getUniversalPolicyholderProps(raw, 'ups.json#/');
    results.forEach(item => {
        const obj = get(universalPolicyholderSchema, item.key);
        Object.keys(obj)
                .forEach(key => {
                    // do not override these props from submissionSchema
                    // "ui:widget": "hidden",
                    // "default": "日本"
                    if (key === 'properties') {
                        const props = obj.properties;
                        Object.keys(props)
                                .forEach(property => {
                                    const newPropery = item.obj.properties[property];
                                    const uniProperty = obj.properties[property];
                                    if (newPropery) {
                                        Object.keys(uniProperty)
                                                .forEach(it => newPropery[it] = uniProperty[it]);
                                    } else {
                                        item.obj.properties[property] = uniProperty;
                                    }
                                });
                    } else {
                        item.obj[key] = obj[key];
                    }
                });
    });
    return results;
}

export function addAutoFocus(fields, uiSchema) {
    return {[fields[0]]: {'ui:autofocus': true}, ...uiSchema};
}

export const changeRequiredMessage = ({target}, t) => {
    const message = t("fillOutThisField") || "Please fill in this field";
    target.setCustomValidity(message);
}

export const resetRequiredMessage = ({target}) => target.setCustomValidity("");
