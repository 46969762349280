import React, {useContext, useState} from 'react';
import {get} from 'lodash';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {ClaimContext, ClaimListContext, MetadataContext, PendingContext, PolicyContext, ProductContext, SubmissionContext} from '../common/context';
import {SummaryPanel} from '../common/SummaryPanel';
import {createClaim} from './claimActions';
import {useDefaultTranslation} from '../common/Translation';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {convertToNewPath} from "../base";

const ClaimConfirmation = ({children}) => {
    const {t} = useDefaultTranslation();
    const {policy} = useContext(PolicyContext);
    const [error, setError] = useState(false);
    const {reloadClaims} = useContext(ClaimListContext);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {
        claim,
        incidentDate,
        updateClaim
    } = useContext(ClaimContext);
    const {
        product,
        claimUiSchema
    } = useContext(ProductContext);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {metadata} = useContext(MetadataContext);
    const {
        submission,
        attachments,
        updateSubmission
    } = useContext(SubmissionContext);
    const {items} = get(claimUiSchema, 'summary[\'ui:options\']') || {};

    const onSubmit = () => {
        pendingAction(async () => {
            setError(null);
            try {
                const created = await createClaim(t, policy.sequenceId, submission, metadata, incidentDate, attachments);
                console.info(created);
                updateClaim(created.id);
                updateSubmission({});
                reloadClaims();
            } catch (e) {
                console.error('Error submitting form', e);
                setError(generateErrorMessage(e));
            }
        });
    };

    const thankYou = t('thankYou');
    const confirmation = t('confirmation');
    const claimSubmitted = t('claimSubmitted');
    const claimRef = t('claimRef', {claimRef: claim && claim.reportingRef});
    const pleaseReviewClaim = t('pleaseReviewClaim');
    
    
    if (claim) {
        return (
                <div className="container">
                    <div className="row">
                        <div className="section offset-md-3 col-md-6">
                            <legend><span>{thankYou}</span></legend>
                            <p>{claimSubmitted}</p>
                            <p><b>{claimRef}</b></p>
                            <p>&nbsp;</p>
                            <div>
                                <hr/>
                                <Link to={`${newPath}/`} pending={pending} disabled={pending} className="btn btn-primary float-right">{t('home')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }

    return (
            <div className="container">
                <div className="row">
                    {error && <Error>{error}</Error>}
                    <div className="section offset-md-3 col-md-6">
                        <legend><span>{confirmation}</span></legend>
                        <p>{pleaseReviewClaim}</p>
                        <hr/>
                        <div>
                            <SummaryPanel submission={submission} items={items} product={product}/>
                        </div>
                        <div>
                            <hr/>
                            <Button pending={pending} disabled={pending} className="btn btn-primary float-right"
                                    onClick={onSubmit}>{t('submit')}</Button>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ClaimConfirmation;
