import React, {useContext} from 'react';
import {QuoteFlow} from '../quote/QuoteFlow';
import {AuthContext, CurrentStepContext, PolicyContext} from '../common/context';
import {Redirect} from 'react-router-dom';
import {formatDateWithHours} from '../shared/util';
import {useDefaultTranslation} from '../common/Translation';

export const AmendFlow = (props) => {
    const {t} = useDefaultTranslation();
    const {surelyncProps} = useContext(AuthContext);
    return (
            <QuoteFlow {...props} amend={true}>
                <PolicyContext.Consumer>{({
                    policy,
                    amendDate
                }) => {
                    const policyId = policy && policy.reportingRef;
                    amendDate = (formatDateWithHours(amendDate) || "").replace(' 00:00', '');
                    const amendHeader = t('amendHeader', {
                        policyId,
                        amendDate
                    });
                    return (
                            <CurrentStepContext.Consumer>{({currentStep}) => currentStep !== 'amend' && (
                                    <div className="text-center">
                                        {!amendDate && !surelyncProps && <Redirect to="./amend"/>}
                                        {amendDate && <h3><label className="badge badge-warning badge-wrap">{amendHeader}</label></h3>}
                                    </div>
                            )}</CurrentStepContext.Consumer>
                    );
                }}</PolicyContext.Consumer>
            </QuoteFlow>
    );
};
