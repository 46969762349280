import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {get} from 'lodash';
import UserApiClient from '../services/api-clients/UserApiClient';
import AuthApiClient from '../services/api-clients/AuthApiClient';
import PasswordInput from './PasswordInput';
import Error from './widgets/Error';
import {Button} from './widgets';

class ResetPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.checkedPassword = this.checkedPassword.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.setPasswordText = this.setPasswordText.bind(this);
        this.setNonValidTokenText = this.setNonValidTokenText.bind(this);
        this.state = {
            values: {},
            isValid: false,
            error: null
        };
    }

    checkedPassword(value) {
        this.setState({isValid: value});
    }

    onPasswordChange(name, value) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            }
        });
        this.props.onPasswordChange && this.props.onPasswordChange(value);
    }

    async onSubmit(e) {
        const t = this.translatorOrDefault();
        e.preventDefault();
        this.setState({
            error: null,
            success: false,
            pending: true
        });
        const {password} = this.state.values;
        const error = this.state.isValid ? null : t('invalidPassword', {defaultValue: 'Invalid password'});
        if (error) {
            this.setState({
                error,
                pending: false
            });
        } else {
            this.performReset(password);
        }
    }

    async performReset(password) {
        const t = this.translatorOrDefault();
        try {
            if (this.props.jwt) {
                const config = {headers: {Authorization: `Bearer ${this.props.jwt}`}};
                const authApiClient = new AuthApiClient(this.props.appName);
                await authApiClient.getToken(config);
            }
            const userApiClient = new UserApiClient(this.props.appName);
            await userApiClient.updateCurrentUserPassword(password);
            if (this.props.onComplete) this.props.onComplete();
            if (this.props.jwt) {
                await userApiClient.clearJwtToken();
            }
            this.setState({
                error: null,
                success: true,
                pending: false
            });
        } catch (e) {
            console.info(e);
            this.setState({
                error: t('unableToResetPassword', {defaultValue: 'Unable to reset password'}),
                pending: false
            });
        }
    }

    setPasswordText() {
        const t = this.translatorOrDefault();
        let title;
        if (this.props.onComplete && !this.props.onEmailChange)
            title = t('accountPassword', {defaultValue: 'Account Password'});
        else if (this.props.onComplete && this.props.onEmailChange)
            title = t('setPassword', {defaultValue: 'Set Account Password'});
        else {
            title = t('resetPassword', {defaultValue: 'Reset Password'});
        }
        const passwordOrSkip = this.props.onEmailChange ? t('passwordNoSkip', {defaultValue: 'You must add a password to your account right now in order to change your email.'}) :
            t('passwordOrSkip', {defaultValue: 'You may add a password to your account right now &mdash; or press Skip if you\'d rather set one later.'});
        return {
            title,
            passwordOrSkip
        };
    }

    setNonValidTokenText() {
        const t = this.translatorOrDefault();
        let nonValidTokenText;
        if (this.props.jwtError)
            nonValidTokenText = t(this.props.jwtError, {defaultValue: 'Non valid token'});
        return nonValidTokenText;
    }

    render() {
        const t = this.translatorOrDefault();
        const {
            product,
            logo,
            loginPath,
            inline,
            primaryEmailAddress
        } = this.props;
        const productName = get(product, 'name');
        const {
            state: {
                success,
                error,
                values,
                pending
            }
        } = this;
        const {
            title,
            passwordOrSkip
        } = this.setPasswordText();
        const passwordRequirements = t('passwordRequirements', {defaultValue: 'Password must be at least 10 characters, contain numbers, uppercase and lowercase letters.'});
        const yourPasswordWasReset = t('yourPasswordWasReset', {defaultValue: 'Your password was reset.'});
        const clickHereToLogIn = t('clickHereToLogIn', {defaultValue: 'Click here to log in.'});
        const continueText = t('continue', {defaultValue: 'Continue'});
        const skip = t('skip', {defaultValue: 'Skip'});
        const nonValidToken = this.setNonValidTokenText();
        return (
            <div className="section no-border">
                <div className={inline ? 'login-inline' : 'login'}>
                    {logo && <Link to="/"><img alt={productName} src={logo} style={{maxHeight: 100}}/></Link>}
                    <h2 className="text-center">{title}</h2>
                    {this.props.children}
                    {this.props.onComplete && <p className="field">{passwordOrSkip}</p>}
                    {!nonValidToken && <p className="field">{passwordRequirements}</p>}
                    <div className="field">
                        <form name="register" onSubmit={this.onSubmit} noValidate>
                            {/* add hidden 'username' input for the Chrome Password Manager */}
                            {!nonValidToken && primaryEmailAddress &&
                                <input hidden id="username" required={false} name="username" autoComplete="username" type="email" value={primaryEmailAddress}/>}
                            {!nonValidToken && this.renderPasswordInput(pending, success, values)}
                            {error && <Error>{error}</Error>}
                            {nonValidToken &&
                                <>
                                    <p className="text-center">{nonValidToken}</p>
                                    <p className="text-center">{t('pwdResetGenerate', {defaultValue: 'Please generate a new link.'})}</p>
                                </>}
                            {success &&
                                <div className="notification is-info is-large">{yourPasswordWasReset} <Link to={loginPath}>{clickHereToLogIn}</Link>
                                </div>}
                            <p className="gap">
                                {!nonValidToken && <Button
                                    pending={pending}
                                    disabled={success || pending}
                                    className="btn btn-primary float-right"
                                    type="submit">{continueText}
                                </Button>}
                                {(this.props.onComplete && !this.props.onEmailChange && !nonValidToken)
                                    && <Button type="button float-right" className="btn btn-secondary float-right"
                                        onClick={this.props.onComplete}>{skip}</Button>}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    translatorOrDefault() {
        return this.props.t || ((it, {defaultValue}) => defaultValue || it);
    }

    renderPasswordInput(pending, success, values) {
        return (
            <div className="form-group field field-undefined">
                <PasswordInput
                    t={this.props.t}
                    disabled={pending || success}
                    onChange={this.onPasswordChange}
                    password={values.password}
                    checkedPassword={this.checkedPassword}
                    autoComplete={false}
                    required={null} style={{
                        div: 'contaier',
                        input: 'form-control'
                    }}/>
            </div>
        );
    }
}

export default ResetPasswordComponent;
