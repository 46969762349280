import * as React from "react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTheme } from "@material-ui/core/styles";
import { LoadingBtn } from "@surelync/common";
import { availableColumns, move, reorder } from "./helpers";
import { useStyles } from "./styles";
import { IColumn } from "./models";
import GlobalContext from "../../../context/global-context";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IMove {
    droppable?: IColumn[];
    droppable2?: IColumn[];
}

interface IProps {
    tableName: string;
    defaultColumns: IColumn[];
}

const CustomiseFlyout: React.FC<IProps> = ({ tableName, defaultColumns }) => {
    const { t } = useTranslation();
    const { openCustomiseFlyout, namespacedLocalStorage, displayedTableColumns, setOpenCustomiseFlyout, setDisplayedTableColumns } =
        useContext(GlobalContext);
    const [items, setItems] = useState<IColumn[]>([]);
    const [selected, setSelected] = useState<IColumn[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const theme = useTheme();
    const classes = useStyles(theme)();

    const blobApiClient = new BlobApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        setItems(availableColumns(defaultColumns, displayedTableColumns));
        setSelected(displayedTableColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedTableColumns, openCustomiseFlyout]);

    const handleClose = () => {
        setOpenCustomiseFlyout(false);
    };

    const getList = (id) => {
        if (id === "droppable") {
            return items;
        }

        return selected;
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            if (source.droppableId === "droppable2") {
                setSelected(items);
            }
        } else {
            const result: IMove = move(getList(source.droppableId), getList(destination.droppableId), source, destination);

            setItems(result.droppable);
            setSelected(result.droppable2);
        }
    };

    const handleReset = () => {
        const selected = defaultColumns.filter((c) => !c.isAdditional);
        setSelected(selected);
        saveBlob(selected);
    };

    const handleSaveBlob = async () => {
        await saveBlob(selected);
        handleClose();
    };

    const saveBlob = async (selected: IColumn[]) => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        const columns = selected.map((item) => {
            return { [item.id]: true };
        });
        const hash = await blobApiClient.setBlob({ [tableName]: columns });
        await blobApiClient.assignTag(hash.hash, `${tableName}-column-preferences`);
        const blobs = await blobApiClient.retrievedTag(`${tableName}-column-preferences`);
        const result = [];
        blobs[tableName].forEach((blob) => {
            const key = Object.keys(blob)[0];
            const column = defaultColumns.find((defaultColumn) => defaultColumn.id === key);
            if (column) {
                result.push(column);
            }
        });

        if (!isMountRef.current) {
            return;
        }

        setDisplayedTableColumns(result);
        setLoading(false);
    };

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
            open={openCustomiseFlyout}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">{t("customisedColumnDisplay")}</DialogTitle>

            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    <Typography component="span" variant="body2" gutterBottom className={classes.contentBodyText}>
                        {t("columnsCustomisationSelection")}
                    </Typography>
                </DialogContentText>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t("displayedColumns")}
                            </Typography>
                            <Droppable droppableId="droppable2" isDropDisabled={loading}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                        <Paper className={classes.paperList}>
                                            <List>
                                                {selected.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <ListItem button>
                                                                    {item.header.map((header, idx) => (
                                                                        <Fragment key={`${index}-${idx}`}>{t(header)}</Fragment>
                                                                    ))}
                                                                </ListItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </List>
                                        </Paper>
                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t("availableColumns")}
                            </Typography>
                            <Droppable droppableId="droppable" isDropDisabled={loading}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                        <Paper className={classes.paperList} data-testid="available-columns">
                                            <List>
                                                {items.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <ListItem button>
                                                                    {item.header.map((header) => (
                                                                        <>{t(header)} </>
                                                                    ))}
                                                                </ListItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </List>
                                        </Paper>
                                    </div>
                                )}
                            </Droppable>
                        </Grid>
                    </Grid>
                </DragDropContext>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button color="secondary" disabled={loading} onClick={handleReset}>
                            {t("reset")}
                        </Button>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Button color="secondary" disabled={loading} onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingBtn theme={theme} label={t("save")} onClick={handleSaveBlob} fetching={loading} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default CustomiseFlyout;
