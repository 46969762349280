import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LinkButton from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import GlobalContext from "../../context/global-context";
import { CurrencyUtils, EIdentityType, IPolicyholder, IProduct, IReferral, localeService, PolicyholderUtils, ProductUtils } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
    })
);

interface IProps {
    referral: IReferral;
    product: IProduct;
    policyholder: IPolicyholder;
}

const Overview: React.FC<IProps> = ({ referral, product, policyholder }) => {
    const classes = useStyles();
    const { currentUser } = useContext(GlobalContext);
    const { t } = useTranslation();
    const { identityType } = currentUser.authDetails;

    const address = useMemo(() => {
        const address = referral && referral.submission && (referral.submission.yourDetails || referral.submission.aboutYou);
        return address ? PolicyholderUtils.renderAddress(policyholder, address) : "";
    }, [policyholder, referral]);
    const effectiveFromDate = useMemo(() => (referral ? FormatUtils.renderDate(referral.effectiveFromDate) : ""), [referral]);
    const effectiveToDate = useMemo(() => (referral ? FormatUtils.renderDate(referral.effectiveToDate) : ""), [referral]);
    const fullName = useMemo(() => (policyholder ? PolicyholderUtils._renderFullName(policyholder) : ""), [policyholder]);

    const renderPartiesAndRoles = () => {
        const mga = ProductUtils._formatParty(product.partiesAndRoles.MGA);

        switch (identityType) {
            // Broker or MGA
            case EIdentityType.Insurer:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{mga ? t("mga") : t("brokerCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{mga || ProductUtils._formatParty(product.partiesAndRoles.Broker)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                );
            case EIdentityType.MGA:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("brokerCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{ProductUtils._formatParty(product.partiesAndRoles.Broker)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("insuranceCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{ProductUtils._formatParty(product.partiesAndRoles.Insurer)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                );
            default:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("insuranceCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{ProductUtils._formatParty(product.partiesAndRoles.Insurer)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                );
        }
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("overview")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("product")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap title={product?.name}>
                            {product?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("policyholder")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <LinkButton component={Link} to={`/contacts/${policyholder.id}`} variant="body1">
                            {fullName}
                        </LinkButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body1">{t("descriptionOfRisk")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap title={address}>
                            {address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body1">{t("policyDates")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {effectiveFromDate} - {effectiveToDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {product && renderPartiesAndRoles()}

                    <Grid item xs={4}>
                        <Typography variant="body1">{t("sumInsured")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap>
                            {referral && CurrencyUtils._renderCurrency(referral.sumInsured, localeService.locale, referral.currency)}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Overview;
