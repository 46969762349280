import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { ITableData, TableContainer } from "@surelync/common";
import { setDataFees } from "./helper";
import GlobalContext from "../../../context/global-context";
import { ContactContext } from "../../models";
import { getHeaderColumnsI18 } from "./columns.config";

const TabFees: React.FC = () => {
    const theme = useTheme();
    const { currentUser } = useContext(GlobalContext);
    const [{ claims, policies }] = useContext(ContactContext);
    // const params = useParams<{ id: string }>();
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const { identityType } = currentUser.authDetails;

    const location = useLocation();
    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname } };
    };

    const fetchData = () => {
        const dataPolicies = setDataFees(claims, policies);
        const headerColumnsI18 = getHeaderColumnsI18(handleRedirect).filter((column) => !column.roles || column.roles.includes(identityType));
        setHeaderColumns(headerColumnsI18);
        setData(dataPolicies);
    };

    return <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} />;
};

export default TabFees;
