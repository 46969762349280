import React, { useEffect, useState } from 'react';
import '../../styles/AutoSuggest.css';

const MoneyWidget = ({
    disabled,
    formData,
    id,
    required,
    schema,
    value,
    onChange,
}) => {
    const [isFocused, setFocus] = useState(false);
    const { type, minimum, maximum } = schema;

    const handleBlur = (e) => {
        const {value} = e.target;
        const valueWithCondition = value < minimum ? minimum : value > maximum ? maximum : value;
        setFocus(false);
        onChange(+valueWithCondition);
    }

    // init form with MIN value if exists
    useEffect(() => {
        if (formData === undefined && value === undefined && minimum) {
            onChange(minimum);
        }
        // eslint-disable-next-line
    }, []);

    const formatValue = (value) => {
        const isDecimal = value % 1 !== 0;
        return value.toFixed(isDecimal ? 2 : 0);
    }

    return (
            <div id={id}>
                <input
                    className="form-control"
                    disabled={disabled}
                    max={maximum}
                    min={minimum}
                    pattern="[1-9]\d*"
                    required={required}
                    step="0.01"
                    type={type}
                    value={value === undefined ? '' : isFocused ? value : formatValue(+value)}
                    onBlur={handleBlur}
                    onChange={event => onChange(event.target.value)}
                    onFocus={_ => setFocus(true)}
                />
            </div>
    );
};

export default MoneyWidget;
