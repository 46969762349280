import { i18n } from "@surelync/common";

export const validate = (values) => {
    const errors: any = {};

    if (!values.name) {
        errors.name = i18n.t("fillField");
    }

    if (!values.description) {
        errors.description = i18n.t("fillField");
    }

    return errors;
};
