import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from './common/LanguageDetector';
import {initReactI18next} from 'react-i18next';
import buildJson from './build.json';

const DEFAULT_LANG = 'en';
// For MTA try to load the language selected in the SureLync
const SURELYNC_LANG = window.location.pathname.startsWith('/surelync') && localStorage.getItem('com.blocksure.global.locale');

i18n
    .use(Backend /* https://github.com/i18next/i18next-http-backend */)
    .use(LanguageDetector /* https://github.com/i18next/i18next-browser-languageDetector */)
    .use(initReactI18next)
    .init({
        // https://www.i18next.com/overview/configuration-options
        lng: SURELYNC_LANG ? SURELYNC_LANG.split('-')[0] : null,
        fallbackLng: false, // do not always load 'en.json'. it will be loaded only in the error handler
        react: {
            nsMode: 'fallback',
            useSuspense: false
        },
        loadPath: '',
        debug: false,
        interpolation: {escapeValue: false},
        backend: {
            // We have various different apps with various different paths, so we configure to load via the constant '/f' path.
            loadPath: `/sureapp/locales/{{lng}}/{{ns}}.json?v=${buildJson.version}`
        },
    }, (err) => {
        if (err) {
            const lng = shorterLng(err) || DEFAULT_LANG;
            i18n.loadLanguages(lng).then(val => i18n.changeLanguage(lng));
        }
    });

export default i18n;

// Parses the error message for a specific translation file not existing.
// e.g. if a specific file for en-AU (English for Australia) does not exist, then return 'en' string
// const err = 'locales/en-AU/translation.json not found';
// then return value would be 'en'
export function shorterLng(err) {
    if (err && err[0]) {
        const values = err[0].split('/');

        if (values.length < 2) {
            console.error(err);
            return;
        }

        const lng = values[values.length - 2].split('-');
        if (lng.length > 1) {
            return lng[0];
        }
    }
}
