import React, {useEffect, useState} from 'react';
import {PaymentRequestButtonElement} from 'react-stripe-elements';

export const PayNowButton = ({
    stripe,
    currency,
    amount,
    pay,
    disabled
}) => {
    const [paymentRequest, setPaymentRequest] = useState(null);

    useEffect(() => {
        const paymentRequest = stripe.paymentRequest({
            country: 'GB',
            currency: currency.toLowerCase(),
            // Total amount in pence.
            total: {
                label: 'Policy payment',
                amount: Math.floor(amount * 100)
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        paymentRequest.canMakePayment()
                .then(result => {
                    paymentRequest.on('paymentmethod', async e => pay(e, e.paymentMethod.id));
                    if (result) setPaymentRequest(paymentRequest);
                });
        // eslint-disable-next-line
    }, []);

    return paymentRequest && (
            <div className="col-xs-12 offset-sm-2 col-sm-8 clearfix" style={{
                paddingTop: 30,
                paddingBottom: 30
            }}>
                <PaymentRequestButtonElement paymentRequest={paymentRequest} disabled={disabled}/>
            </div>
    );
};
