import React, {useContext} from 'react';
import {useDefaultTranslation} from '../common/Translation';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {PolicyListContext} from '../common/context';
import { RenewalList } from '../shared/RenewalList';
import {convertToNewPath} from "../base";

export const RenewalListScreen = () => {
    const {t} = useDefaultTranslation();
    const {renewals} = useContext(PolicyListContext);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    
    return (
            <div className="screen container">
                <div className="clearfix">&nbsp;</div>
                <div className="section rjsf offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                    <legend><span>{t('renewals')}</span></legend>
                        {renewals?.length ? <RenewalList renewals={renewals} /> : null}
                    <hr/>
                    <Link data-menukey="back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary gap">{t('back')}</Link>
                </div>
            </div>
    );
};
