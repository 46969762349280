import React, {useContext, useEffect, useState} from 'react';
import {Button} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import {getPaymentSources, regsiterPaymentSource} from './paymentActions';
import {PendingContext} from '../common/context';
import {PayNowButton} from './PayNowButton';
import {useDefaultTranslation} from '../common/Translation';

const classes = {base: 'stripe-control form-control'};

const PayFormNoStripe = ({
    currency,
    amount,
    paymentGateway,
    policyholderId,
    back,
    stripe,
    pay
}) => {
    const {t} = useDefaultTranslation();
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const [paymentSources, setPaymentSources] = useState(undefined);
    const [selectedPaymentSourceId, setSelectedPaymentSourceId] = useState([]);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        if (paymentGateway && policyholderId) {
            getPaymentSources(paymentGateway, policyholderId)
                    .then(it => setPaymentSources(it));
        }
    }, [paymentGateway, policyholderId]);

    const selectPaymentSource = (e, id) => {
        e.preventDefault();
        setValid(true);
        setSelectedPaymentSourceId(id);
    };

    const cardDetailChange = ({complete}) => {
        setValid(complete);
        setSelectedPaymentSourceId(null);
    };

    const payAndContinue = async e => pendingAction(async () => {
        e.preventDefault();
        await pay(selectedPaymentSourceId || await regsiterPaymentSource(stripe, paymentGateway, policyholderId));
    });

    const hasPaymentSources = paymentSources && paymentSources.length > 0;

    return (
            <div>
                {/* Magic button for apple pay etc. */}
                {currency && amount > 0 && <PayNowButton stripe={stripe} currency={currency} amount={amount} pay={payAndContinue}/>}

                <form onSubmit={payAndContinue}>
                    {hasPaymentSources && <div className="col-xs-12 offset-sm-2 col-sm-8 clearfix">
                        <label>{t('selectFromYourCards')}:</label>
                        <div className="list-group">
                            {paymentSources.map(it => {
                                const className = `list-group-item ${it.id === selectedPaymentSourceId ? 'active' : ''}`;
                                return <button key={it.id} disabled={pending} onClick={e => selectPaymentSource(e, it.id)}
                                               className={className}>{t('cardEnding', it)}</button>;
                            })}
                        </div>
                        <hr/>
                    </div>}


                    <div className="col-xs-12 offset-sm-1 col-sm-10 offset-md-1 col-md-10 offset-lg-2 col-lg-8 clearfix">
                        <label>{t('enterYourCardDetails')}:</label>
                    </div>

                    <div className="col-xs-12 offset-sm-1 col-sm-10 offset-md-1 col-md-10 offset-lg-2 col-lg-8 credit-card clearfix">
                        <div className="row gap">
                            <div className="col-xs-12 offset-sm-1 col-sm-10">
                                <label htmlFor="card-number">{t('cardNumber')}:</label>
                                <CardNumberElement onChange={cardDetailChange} disabled={pending} classes={classes}
                                                   placeholder="•••• •••• •••• ••••"/>
                            </div>
                        </div>
                        <div className="row gap">
                            <div className="col-6 offset-sm-1 col-sm-5">
                                <label htmlFor="card-expiry">{t('expiryDate')}:</label>
                                <CardExpiryElement disabled={pending} classes={classes} placeholder="mm/yy"/>
                            </div>
                            <div className="col-6 col-sm-5">
                                <label htmlFor="card-cvc">{t('cvv')}:</label>
                                <CardCVCElement disabled={pending} classes={classes} name="card-cvc" placeholder="•••"/>
                            </div>
                        </div>
                    </div>

                    <div className="clearfix"/>
                    <div className="col-xs-12 gap">
                        <Button pending={pending} className="btn btn-primary float-right" type="submit"
                                disabled={!valid && !!policyholderId}>{t('pay')}</Button>
                        <Button disabled={pending} className="btn btn-secondary float-right" type="button" onClick={back}>{t('back')}</Button>
                    </div>
                    <div className="clearfix"/>
                </form>
            </div>
    );
};

export const PayForm = injectStripe(PayFormNoStripe);
