import React, {Component} from 'react';
import './progress.css';

class Progress extends Component {
    constructor(props) { // constructor for progress bar
        super(props);
        this.state = {};
    }

    render() {
        return ( // html component
            <div className="ProgressBar">
                <div
                    className="Progress"
                    style={{width: `${this.props.progress}%`}}
                />
            </div>
        );
    }
}

export default Progress;
