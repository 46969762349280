import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core/";
import { useTheme } from "@material-ui/core/styles";

import { PolicyApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { TableContainer, ITableData } from "@surelync/common";
import { setDataPaymentAttempts } from "./helper";
import GlobalContext from "../../context/global-context";
import { getHeaderColumnsI18 } from "./columns.config";

const PaymentAttemptsFlyout: React.FC = () => {
    const { t } = useTranslation();
    const { openPaymentAttemptsFlyout, namespacedLocalStorage, setOpenPaymentAttemptsFlyoutOpen } = useContext(GlobalContext);

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const theme = useTheme();
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const handleClose = () => {
        setOpenPaymentAttemptsFlyoutOpen(false);
    };

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        try {
            const headerColumnsI18 = getHeaderColumnsI18();
            const policy = await policyApiClient.getTransaction(openPaymentAttemptsFlyout.transactionId);
            const dataSettlementStatement = setDataPaymentAttempts(policy.attempts);

            if (!isMountRef.current) {
                return;
            }

            setHeaderColumns(headerColumnsI18);
            setData(dataSettlementStatement);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            console.warn("error", error.message);
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openPaymentAttemptsFlyout.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("collectedAttemptDetails")}</DialogTitle>
            <DialogContent>
                <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentAttemptsFlyout;
