import React, {Component} from 'react';
import './upload.css';
import imageCompression from 'browser-image-compression';
import { ImportApiClient } from '@blocksure/blocksure-core/dist/src/services/api-clients';
import checkCircle from './assets/check_circle-24px.svg';
import Dropzone from './dropzone';
import Progress from './progress';
import config from '../configuration';
import { namespacedLocalStorage } from '../helper';

const compressionOptions = {
    maxWidthOrHeight: config.imageSize
};

class Upload extends Component { // creates component

    constructor(props) { // component constructor
        super(props);
        this.state = { // sets the state of the upload component. a files array, uploading status as false,
            // successfull Upload as false, as well as creates a sub object of upload progress, keeps track of if component is uploading
            files: [],
            compressing: false,
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false
        };
        this.onFilesAdded = this.onFilesAdded.bind(this); // creates methods fo what to do when files added
        this.uploadFiles = this.uploadFiles.bind(this); // creates method for when files are uploaded
        this.sendRequest = this.sendRequest.bind(this); // creates method for when requests are sent
        this.renderActions = this.renderActions.bind(this);
    }

    async onFilesAdded(files) { // files added listener
        this.setState(prevState => ({
            files: prevState.files.concat(files) // sets property of files to the files that have been added
        }), () => {
            this.uploadFiles();
        });
    }

    sendRequest(file) {
        const productApiClient = new ImportApiClient(namespacedLocalStorage);
        return new Promise((resolve, reject) => {
            imageCompression(file, compressionOptions)
                .then(async compressedFile => {
                    const fileName = compressedFile.name;

                    const imageFormData = new FormData();
                    imageFormData.append('files', compressedFile, fileName); // create form data and append image to it
                    const {currentUser} = this.props;
                    const userName = `${currentUser.userDetails.firstName}_${currentUser.userDetails.lastName}`;

                    const onProgress = (progress) => {
                        const copy = {...this.state.uploadProgress};
                        copy[compressedFile.name] = { // copies file name and state
                            state: 'pending',
                            percentage: progress // works out the fraction of parts loaded compared with total
                        };
                        this.setState({uploadProgress: copy}); // sets upload progress to match
                    };
                    try {
                        await productApiClient.importImages(userName, imageFormData, onProgress);

                        const copy = {...this.state.uploadProgress};
                        copy[compressedFile.name] = {
                            state: 'done',
                            percentage: 100
                        }; // sets state to done
                        this.setState({uploadProgress: copy});
                    } catch (error) {
                        const copy = {...this.state.uploadProgress};
                        copy[compressedFile.name] = {
                            state: 'error',
                            percentage: 0
                        };
                        this.setState({uploadProgress: copy});
                    }
                });
        });
    }


    async uploadFiles() { // for when files are uploaded
        this.setState({
            uploadProgress: {},
            uploading: true
        }); // sets uploading to true and creates an object for the upload progress to clear upload progress
        const promises = []; // creates an array for the list of pormices
        this.state.files.forEach(file => { // creates a function with arrow notation that for each files, push the request onto promises array
            promises.push(this.sendRequest(file));
        });
        try {
            await Promise.all(promises);
            this.setState({
                successfullUploaded: true,
                uploading: false
            }); // says when all promises returned to set uploading to false and successful uploads to full
        } catch (e) {
            console.log(`upload error: \n${e}`);
            this.setState({
                successfullUploaded: true,
                uploading: false
            });
        }
    }

    renderProgress(file) {
        // shows upload state of file name
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0}/>
                    <img
                        className="CheckIcon"
                        width="16"
                        alt="done"
                        src={checkCircle}
                        style={{
                            opacity: uploadProgress && uploadProgress.state === 'done' ? 0.5 : 0
                        }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    isAllUploadDone() {
        const { uploadProgress } = this.state;
        const allNotDone = Object.keys(uploadProgress).find(key => {
            return uploadProgress[key].percentage !== 100;
        });
        return allNotDone;
    }

    renderActions() { // renders the correct views depending of on the state for the actions html item
        if (this.isAllUploadDone()) {
            return (
                <button disabled>Next</button>
            );
        } else {
            return (this.props.nextStep);
        }
    }


    render() {
        return (
            <div className="Upload">
                <div className="Content">
                    <Dropzone
                        onFilesAdded={this.onFilesAdded}
                        disabled={this.isAllUploadDone()} // disables when uploaded or uploading
                    />
                    <div/>
                    <div className="Files">
                        {this.state.files.map(file => { // html for the file list
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="Actions">{this.renderActions()}</div>
            </div>
        );
    }
}

export default Upload;
