import React from 'react';
import {PolicyScreen} from '../shared/Screen';
import {PolicyContext, ProductContext, TradingGroupContext} from '../common/context';
import {Menu} from '../shared/Menu';
import DocLink from '../common/DocLink';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {formatDateWithHours} from '../shared/util';
import {SinglePrice} from '../quote/QuoteArray';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const KnowMyPolicy = ({match}) => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <PolicyScreen sequenceId={match.params.sequenceId}>
                <ProductContext.Consumer>{({product}) => (
                        <PolicyContext.Consumer>{({policy}) => (
                                <TradingGroupContext.Consumer>{({products = {}}) => {
                                    if (!product) return '';

                                    const {logo} = products[product.id];
                                    const version = `v${policy.version}.${policy.sequence}`;

                                    const items = [];
                                    const className = 'btn btn-lg btn-block btn-primary text-center gap';
                                    if (policy.status !== 'Cancelled' && product.staticAttachments.find(it => it.name === 'PolicyWording.pdf')) items.push(
                                            <DocLink data-menukey="wording" href="attachment:PolicyWording.pdf" sequenceId={policy.sequenceId}
                                                     product={product} className={className}>{t('policyWording')}</DocLink>
                                    );

                                    if (product.staticAttachments.find(it => it.name === 'IPID.pdf')) items.push(
                                            <DocLink data-menukey="ipid" href="attachment:IPID.pdf" sequenceId={policy.sequenceId} product={product}
                                                     className={className}>{t('productDetails')}</DocLink>
                                    );

                                    if (product.staticAttachments.find(it => it.name === 'Important Notice to Policyholders 1 May 2021.pdf')) items.push(
                                            <DocLink data-menukey="importantNoticeToPolicyholders"
                                                     href="attachment:Important Notice to Policyholders 1 May 2021.pdf" sequenceId={policy.sequenceId}
                                                     product={product} className={className}>{t('importantNoticeToPolicyholders')}</DocLink>
                                    );

                                    items.push(
                                            <DocLink data-menukey="template" href="doc:PolicyBoundTemplate" sequenceId={policy.sequenceId}
                                                     product={product} className={className}>
                                                {policy.status === 'Quoted' ? t('quoteSchedule', {version}) :
                                                    policy.status === 'Cancelled' ? t('cancellationConfirmation', {version}) : t('policySchedule', {version})
                                                }
                                            </DocLink>
                                    );

                                    if (product.dynamicDocumentTemplates.find(it => it.name === 'StatementOfFactTemplate')) items.push(
                                            <DocLink data-menukey="sof" href="doc:StatementOfFactTemplate" sequenceId={policy.sequenceId}
                                                     product={product} className={className}>{t('statementOfFact', {version})}</DocLink>
                                    );

                                    if (policy.status !== 'Quoted') items.push(
                                            <Link data-menukey="schedule" to={`${newPath}/payment/${policy.sequenceId}`}
                                                  className={className}>{t('paymentSchedule', {version: version})}</Link>
                                    );

                                    items.push(<Link data-menukey="Back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary gap">{t('home')}</Link>);
                                    items.push(<hr data-menukey="separator2" className="d-sm-block d-md-none"/>);

                                    const policySection = <PolicySection product={product} logo={logo} data-menukey="status" policy={policy}/>;
                                    items.push(policySection);
                                    return <Menu items={items} breakout={[policySection]}/>
                                }}</TradingGroupContext.Consumer>
                        )}</PolicyContext.Consumer>
                )}</ProductContext.Consumer>
            </PolicyScreen>
    );
};

const PolicySection = ({
    product,
    policy,
    logo
}) => {
    const {t} = useDefaultTranslation();
    return (
            <div className="section gap">
                <div className="row">
                    <div className="col-md-12 text-center">
                        {logo && <img className="d-sm-none d-md-block" style={{
                            margin: 'auto',
                            backgroundColor: 'white',
                            padding: 10,
                            height: 100,
                            borderRadius: 4
                        }} src={logo} alt="logo"/>}
                        <h2>{policy.reportingRef}</h2>
                        <hr className="d-md-none .d-lg-block"/>
                    </div>
                    <div className="col-md-8">
                        <h5><label>{t('inception')}:</label> {formatDateWithHours(policy.inceptionDate)}</h5>
                        <h5><label>{t('expiry')}:</label> {formatDateWithHours(policy.expiryDate)}</h5>
                    </div>
                    <div className="col-md-4 text-right-md">
                        <h5><label>{t('status')}:</label> {t(policy.status.toLowerCase(), {defaultValue: policy.status})}</h5>
                    </div>
                    <div className="col-md-12">
                        <h5 className="mt-0">
                            <label>{t('effective')}:</label> {formatDateWithHours(policy.effectiveFromDate)} - {formatDateWithHours(policy.effectiveToDate)}
                        </h5>
                    </div>
                    <div className="col-md-12">
                        <hr className="d-md-none .d-lg-block"/>
                        <h3>
                            <SinglePrice
                                    product={product}
                                    totalPayable={getFullValue(policy.totalPayable)}
                                    premium={getFullValue(policy.grossPremium)}
                                    ipt={getFullValue(policy.totals.IPT)}
                            />
                        </h3>
                    </div>
                </div>
            </div>
    );
};

const getFullValue = (total) => {
    return total ? (total.prorata === 0 ? total.fullTerm : total.prorata) : 0;
};
