import { IClaim, IPolicy, IPolicyholder, PolicyholderUtils } from "@surelync/common";

export const setDataClaims = (claims: IClaim[], policyholders: { [key: string]: IPolicyholder }, policies: IPolicy[]) =>
    claims.map((item) => {
        const policy = policies.find((policyItem) => policyItem.id === item.policyId);
        const policyholder = policyholders[policy.policyholderId];
        return {
            policyholder: PolicyholderUtils._renderFullName(policyholder),
            policyNo: policy.reportingRef,
            claimNo: item.reportingRef,
            status: item.status,
            category: item.category,
            dateOfLoss: item.dateOfIncident,
            dateReceived: item.creationDate,
            paidUpToDate: item.claimAmount,
            lossReserve: item.claimFee,
            totalIncurred: item.claimAmount + item.claimFee,
            description: item.lossDescription,
            policyholderId: policyholder.id,
            currency: item.currency,
            id: item.id,
            policyId: policy.id,
        };
    });
