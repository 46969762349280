import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DatePickerWidget(props) {

    if (!props.id) {
        console.log('No id for', props);
        throw new Error(`no id for props ${JSON.stringify(props)}`);
    }

    const {
        value,
        readonly,
        disabled,
        autofocus,
        onBlur,
        onFocus,
        options,
        schema,
        formContext,
        registry,
        rawErrors,
        ...inputProps
    } = props;

    // if (schema.multipleOf) inputProps.step = schema.multipleOf;
    // if (typeof schema.minimum !== 'undefined') inputProps.min = schema.minimum;
    // if (typeof schema.maximum !== 'undefined') inputProps.max = schema.maximum;

    let date = Date.parse(value);
    if (isNaN(date) !== false) date = null;

    const _onChange = (value) => {
        let date = value.toISOString()
                .split('T')[0];
        return props.onChange(date);
    };

    return (
            <div style={{fontSize: '20px'}}>
                <DatePicker
                        className="form-control"
                        readOnly={readonly}
                        disabled={disabled}
                        autoFocus={autofocus}
                        dateFormat="dd/MM/yyyy"
                        selected={date}
                        {...inputProps}
                        onChange={_onChange}
                />
            </div>
    );
}

if (process.env.NODE_ENV !== 'production') {
    DatePickerWidget.propTypes = {
        value: PropTypes.object,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default DatePickerWidget;
