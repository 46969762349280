import { i18n } from "@surelync/common";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("dateCreated"),
        accessor: "dateCreated",
        disableSortBy: true,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        size: "medium",
    },
    {
        Header: () => i18n.t("eventType"),
        accessor: "eventType",
        disableSortBy: true,
        Cell: (row: CellInfo) => i18n.t(`a${row.value}`),
        size: "medium",
    },
    {
        Header: () => i18n.t("email"),
        accessor: "email",
        disableSortBy: true,
        size: "medium",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        disableSortBy: true,
        size: "large",
    },
    {
        Header: () => i18n.t("ip"),
        accessor: "ip",
        disableSortBy: true,
        size: "small",
    },
    {
        Header: () => i18n.t("userId"),
        accessor: "userId",
        disableSortBy: true,
        size: "medium",
    },
];
