import React, {Component} from 'react';
import quintInOut from 'eases/quint-in-out';

export default class NumberEase extends Component {
    timeout = null;
    startAnimationTime = null;

    constructor(props) {
        super(props);
        const value = parseInt(props.value, 10);
        this.state = {
            value,
            previousValue: value
        };
        this.update = this.update.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps({value}) {
        if (parseInt(value, 10) === parseInt(this.props.value, 10)) return;
        this.setState({previousValue: this.state.value});
        this.startAnimationTime = (new Date()).getTime();
        this.update();
    }

    update() {
        const elapsedTime = Math.min(this.props.speed, ((new Date()).getTime() - this.startAnimationTime));
        const value = parseInt(this.props.value, 10);
        const progress = quintInOut(elapsedTime / this.props.speed);
        this.setState({value: Math.round((value - this.state.previousValue) * progress + this.state.previousValue)});

        elapsedTime < this.props.speed ?
            this.timeout = setTimeout(this.update, 16) :
            this.setState({previousValue: value});
    }

    render() {
        return <span>{this.state.value === 0 ? '-' : this.state.value.toLocaleString()}</span>;
    }
}

NumberEase.defaultProps = {speed: 500};
