export function flatten(ob) {
    if (!ob) return ob;
    const toReturn = {};
    Object.keys(ob).forEach(i => {
        if (ob[i] && Object.prototype.toString.call(ob[i]) === '[object Object]' || Array.isArray(ob[i])) {
            const flatObject = flatten(ob[i]);
            Object.keys(flatObject).forEach(x => {
                const newKey =
                    (Array.isArray(ob) ? `[${i}]` : `${i}`) +
                    (Array.isArray(ob[i]) ? `${x}` : `.${x}`);
                toReturn[newKey] = flatObject[x];
            });
        } else {
            toReturn[i] = ob[i];
        }
    });
    return toReturn;
}

export function unflatten(target) {
    const result = {};

    if (Object.prototype.toString.call(target) !== '[object Object]')
        return target;

    function getkey(key) {
        key = key && key.replace(/]$/, '');
        const parsedKey = Number(key);
        return (isNaN(parsedKey) || key.indexOf('.') !== -1) ? key : parsedKey;
    }

    const sortedKeys = Object.keys(target)
        .sort((keyA, keyB) => keyA.length - keyB.length);

    sortedKeys.forEach((key) => {
        const split = key.split(/[.[]/);
        let key1 = getkey(split.shift());
        let key2 = getkey(split[0]);
        let recipient = result;

        while (key2 !== undefined) {
            const type = Object.prototype.toString.call(recipient && recipient[key1]);
            const isobject = (
                type === '[object Object]' ||
                type === '[object Array]'
            );

            if (!isobject && typeof recipient[key1] !== 'undefined')
                return;

            if ((!isobject) || (recipient[key1] == null))
                recipient[key1] = typeof key2 === 'number' ? [] : {};

            recipient = recipient[key1];
            if (split.length > 0) {
                key1 = getkey(split.shift());
                key2 = getkey(split[0]);
            }
        }

        recipient[key1] = unflatten(target[key]);
    });

    return result;
}
