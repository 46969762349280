import * as React from "react";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalContext from "../../../context/global-context";
import { ClaimApiClient, PolicyApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { Container, useTheme } from "@material-ui/core";
import { ContactContext } from "../../models";
import { ITableData, PolicyUtils, TableContainer } from "@surelync/common";
import { getHeaderColumnsI18 } from "./columns.config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: 0,
            padding: 0,
        },
    })
);

type IProps = Record<string, unknown>;

const TabFiles: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ claims, policies }] = useContext(ContactContext);
    const { locale, namespacedLocalStorage, products } = useContext(GlobalContext);
    const theme = useTheme();
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);

    const claimApiClient = new ClaimApiClient(namespacedLocalStorage);
    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const productApiClient = new ProductApiClient(namespacedLocalStorage);

    const location = useLocation();

    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname } };
    };

    const fetchData = () => {
        const headerColumnsI18 = getHeaderColumnsI18(handleRedirect);
        const appName = namespacedLocalStorage.getItem("app-name");
        const attacments = PolicyUtils.getDocuments(
            { items: policies, basepath: policyApiClient.basepath },
            { items: claims, basepath: claimApiClient.basepath },
            { items: Object.values(products), basepath: productApiClient.basepath },
            appName,
            locale
        );

        setHeaderColumns(headerColumnsI18);
        setData(attacments);
    };

    return (
        <Container maxWidth="md" classes={{ root: classes.wrapper }}>
            <TableContainer columns={headerColumns} data={data} fetchData={fetchData} theme={theme} />
        </Container>
    );
};

export default TabFiles;
