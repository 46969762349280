export enum ETab {
    Overview,
    IdentityAndAddress,
    // Rfqs,
    Policies,
    Claims,
    Fees,
    Notes,
    Enquiries,
    Files,
}
