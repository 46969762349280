import config from './configuration.js';


export default (inputObj) => {
    for (let i = 0; i < inputObj.length; i++) {
        for (let j = 0; j < config.listOfStdFields.length; j++) {
            if (inputObj[i][config.listOfStdFields[j]] === '') {
                delete inputObj[i][config.listOfStdFields[j]];
            }
        }
    }
    return inputObj;
};


/* function deleteIfBlank(inputObj){
    let outputArray = []
    for (let i = 0;  i < inputObj.length ; i++){
        //console.log("inputObj[optionalPolicyholderAttributeNames[i]] is " + inputObj[optionalPolicyholderAttributeNames[i]])
        if (inputObj[[i]] != null){
          console.log("if triggered")
          outputArray.push(optionalArray[i])
        }
      }
      return outputArray
} */
