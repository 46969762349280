import React from "react";
import { Link } from "react-router-dom";
import { CurrencyUtils, i18n, localeService } from "@surelync/common";
import Typography from "@material-ui/core/Typography";
import { CellInfo } from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const getHeaderColumnsI18 = (isMobile: boolean, onRedirect: (url: string) => any) => [
    {
        Header: () => i18n.t("policyholder"),
        accessor: "policyholder",
        Cell: (row: CellInfo) => {
            return (
                <LinkButton component={Link} to={onRedirect(`/contacts/${row.row?.original.policyholderId}`)} variant="body1">
                    {row.value}
                </LinkButton>
            );
        },
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("policyholder")],
        },
    },
    {
        Header: () => i18n.t("claimNo"),
        accessor: "claimNo",
        Cell: (row: CellInfo) => {
            return (
                <LinkButton component={Link} to={onRedirect(`/claims/${row.row?.original.id}`)} variant="caption">
                    {row.row?.original.claimNo}
                </LinkButton>
            );
        },
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("claimNo")],
            values: ["claimNo"],
        },
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => <Typography variant="button">{row.value}</Typography>,
        size: "small",
        toCSV: {
            headers: [i18n.t("status")],
            values: ["status"],
        },
    },
    {
        Header: () => i18n.t("category"),
        accessor: "category",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("category")],
        },
    },
    {
        Header: () => i18n.t("dateOfLoss"),
        accessor: "dateOfLoss",
        size: isMobile ? "small" : "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        toCSV: {
            headers: [i18n.t("dateOfLoss")],
            format: (row) => FormatUtils.renderDate(row["dateOfLoss"]),
        },
    },
    {
        Header: () => i18n.t("dateReceived"),
        accessor: "dateReceived",
        size: isMobile ? "small" : "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        toCSV: {
            headers: [i18n.t("dateReceived")],
            format: (row) => FormatUtils.renderDate(row["dateReceived"]),
        },
    },
    {
        Header: () => i18n.t("paidUpToDate"),
        accessor: "paidUpToDate",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("paidUpToDate")],
        },
    },
    {
        Header: () => i18n.t("lossReserve"),
        accessor: "lossReserve",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row?.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("lossReserve")],
            format: (row) => CurrencyUtils._renderCurrency(row["lossReserve"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("totalIncurred"),
        accessor: "totalIncurred",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row?.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("totalIncurred")],
            format: (row) => CurrencyUtils._renderCurrency(row["totalIncurred"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("lossDescription"),
        accessor: "lossDescription",
        Cell: (row: CellInfo) => {
            return (
                <div>
                    {row.value ? (
                        <div>
                            {row.value.length < 60 ? (
                                <div>{row.value}</div>
                            ) : (
                                <Tooltip title={row.value} placement="bottom-start">
                                    <div>{`${row.value.splice(0, 60)}...`}</div>
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        <div>-</div>
                    )}
                </div>
            );
        },
        size: "medium",
        toCSV: {
            headers: [i18n.t("lossDescription")],
        },
    },

    // Additional columns
    {
        Header: () => i18n.t("productName"),
        accessor: "productName",
        toCSV: {
            headers: [i18n.t("productName")],
        },
    },
    {
        Header: () => i18n.t("subCategory"),
        accessor: "subCategory",
        toCSV: {
            headers: [i18n.t("subCategory")],
        },
    },
    {
        Header: () => i18n.t("creationDate"),
        accessor: "creationDate",
        size: isMobile ? "small" : "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        toCSV: {
            headers: [i18n.t("creationDate")],
            format: (row) => FormatUtils.renderDateTime(row["creationDate"]),
        },
    },
    {
        Header: () => i18n.t("claimFee"),
        accessor: "claimFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row?.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("claimFee")],
            format: (row) => CurrencyUtils._renderCurrency(row["claimFee"], localeService.locale, row.currency),
        },
    },
];
