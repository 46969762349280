import React, { useMemo } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ETabs } from "../../tabs.model";

interface IProps {
    disabled: boolean;
    value: ETabs;
    handleChange: (event: React.ChangeEvent<unknown>, newValue: ETabs) => void;
}

const applyProps = (index: string) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
});

const TabsTable: React.FC<IProps> = ({ disabled, value, handleChange }) => {
    const { t } = useTranslation();
    const tabsName = useMemo(() => Object.keys(ETabs).filter((value) => typeof value === "string") as string[], []);

    return (
        <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="tabs" variant="scrollable">
            {tabsName && tabsName.map((name, index) => <Tab disabled={disabled} label={t(name)} key={name} value={ETabs[name]} {...applyProps(name)} />)}
        </Tabs>
    );
};

export default TabsTable;
