import React, {useContext, useEffect, useState} from 'react';
import {PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';
import {PolicyListContext, TradingGroupContext} from '../common/context';

export const QuoteStatuses = ['New', 'RFQ', 'DTQ', 'UTQ', 'Referral', 'Quoted', 'NTU'];
export const PolicyStatuses = ['Bound', 'RFR', 'Lapsed', 'Cancelled'];

export const PolicyListProvider = ({
    policyholder,
    children
}) => {
    const [policies, setPolicies] = useState(null);
    const [renewals, setRenewals] = useState(null);

    const {products} = useContext(TradingGroupContext);

    const reloadPolicies = () => {
        if (!policyholder || !policyholder.primaryEmailAddress) {
            setPolicies([]);
            return;
        }
        const client = new PolicyApiClient(SureAppName);
        client.search({
            policyholderIds: policyholder.id,
            productIds: Object.keys(products || {})
        }, 1000, 0, ['load.transactions', 'load.attachments', 'load.breakdown'])
                .then(({items}) => items.map(it => {
                    it.product = products[it.productId];
                    return it;
                }))
                .then(setPolicies);
    };

    const getRenewals = async (policies) => {
        const client = new PolicyApiClient(SureAppName);
        const generatePolicyIds = policies.map((pol) => pol.id); 
        client.getRenewalPolicy(generatePolicyIds, []).then(renewals => setRenewals(renewals?.items));
    }

    useEffect(() => {
        reloadPolicies();
        // eslint-disable-next-line
    }, [policyholder]);

    useEffect(() => {
        if (policies?.length) {
            getRenewals(policies);
        }
    }, [policies]);

    return (
            <PolicyListContext.Provider value={{
                getRenewals,
                reloadPolicies,
                all: policies,
                policies: policies && policies.filter(it => PolicyStatuses.indexOf(it.status) > -1),
                quotes: policies && policies.filter(it => QuoteStatuses.indexOf(it.status) > -1),
                renewals
            }}>
                <div>{children}</div>
            </PolicyListContext.Provider>
    );
};
