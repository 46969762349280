import BaseApiClient from './BaseApiClient';

export default class NoteApiClient extends BaseApiClient {
    constructor(...args) {
        super('/note/api/v1/', ...args);
    }

    async addNote(data) {
        const response = await super.handlePost(this.basepath, data);
        super.handleResponse(response);
        return response;
    }

    async getNotesByOwnerId(id) {
        const response = await super.handleGet(`${this.basepath}owned/${id}`);
        super.handleResponse(response);
        return response.data;
    }
}
