import React, {useContext, useEffect, useState} from 'react';
import {get} from 'lodash';
import {QuoteApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import './Banner.css';
import './Quote.css';
import ReactMarkdown from 'react-markdown';
import {SureAppName} from '../base';
import {CurrentStepContext, ProductContext, SubmissionContext} from '../common/context';
import {InProductCurrency, inProductCurrencyFunc} from '../widgets/InProductCurrency';
import Loading from '../widgets/Loading';
import {Dtq} from './Dtq';
import {useDefaultTranslation} from '../common/Translation';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const quoteApiClient = new QuoteApiClient(SureAppName);

const QuoteArray = ({children}) => {
    const {t} = useDefaultTranslation();
    const [quotes, setQuotes] = useState(null);
    const [error, setError] = useState(null);
    const {product} = useContext(ProductContext);
    const {stepUiSchema} = useContext(CurrentStepContext);
    const {
        submission,
        getAssumptionsObject
    } = useContext(SubmissionContext);
    const {
        header = [],
        footer = [],
        count = 3
    } = get(stepUiSchema, 'ui:options') || {};

    useEffect(() => {
        if (submission) {
            getQuotes(count, product, getAssumptionsObject)
                    .then(setQuotes)
                    .catch(e => {
                        console.error('Error loading quotes', e);
                        setError(generateErrorMessage(e, t('unableToLoadQuotes')));
                    });
        }
        // eslint-disable-next-line
    }, [submission]);

    if (!quotes && !error) return <div className="section text-center offset-2 col-xs-8"><Loading/></div>;
    const declines = quotes && quotes.filter(it => isDecline(it));

    if (error) {
        return <div className="section text-center"><Error>{error}</Error></div>;
    }
    if (declines && declines.length === count) {
        return (
                <div className="section text-center">
                    <div className="row">
                        <Dtq productId={product.id}/>
                    </div>
                </div>
        );
    }
    return (
            <div className={`quote${count}`}>
                <QuoteHeader t={t} header={header}/>
                {quotes &&
                <div className="row">{[...Array(count)].map((_, i) => <SingleQuote key={i} quotes={quotes[i]} count={count - declines.length}
                                                                                   i={i}/>)}</div>}
                <hr/>
                <QuoteFooter t={t} footer={footer}/>
                {children}
            </div>
    );
};

const getQuotes = async (count, product, getAssumptionsObject) => {
    const rfqs = {};
    [...Array(count)].forEach((_, i) => {
        rfqs[`${i}`] = {
            productId: product.id,
            productVersion: product.version,
            submission: getAssumptionsObject(i)
        };
    });
    const quotesByIndex = await quoteApiClient.requestQuotes(rfqs);
    // Unwrap the quotes into an array.
    return Object.values(quotesByIndex)
            .map(({
                status,
                quote
            }) => Object.values(quote)
                    .map(it => ({
                        status, ...it.quote,
                        totalPayable: it.totalPayable,
                        totals: it.breakdown
                    })));
};

const QuoteHeader = ({
    t,
    header
}) => {
    const headers = Array.isArray(header) ? header : [header];
    return headers.map((it, i) => {
        const translationKey = Array.isArray(header) ? `quote.tx-header.${i}` : 'quote.tx-header';
        return <div key={i} className="text-center"><ReactMarkdown>{t(translationKey, {defaultValue: it})}</ReactMarkdown></div>;
    });
};

const QuoteFooter = ({
    t,
    footer
}) => {
    const footers = Array.isArray(footer) ? footer : [footer];
    return footers.map((it, i) => {
        const translationKey = Array.isArray(footer) ? `quote.tx-footer.${i}` : 'quote.footer';
        return <div key={i} className="text-center"><ReactMarkdown>{t(translationKey, {defaultValue: it})}</ReactMarkdown></div>;
    });
};

function isDecline(quotes) {
    return quotes && (quotes.find(it => it.status === 'DTQ' || it.status === 'Referral'));
}

function getStyle(border, background, color) {
    const style = {};
    if (border) style.border = border;
    if (background) style.background = background;
    if (color) style.color = color;
    return style;
}

const SingleQuote = ({
    i,
    quotes,
    count
}) => {
    const {t} = useDefaultTranslation();
    const {product} = useContext(ProductContext) || {};
    const {
        stepUiSchema,
        nextStep
    } = useContext(CurrentStepContext);
    const {
        updateSubmission,
        getAssumptionsObject
    } = useContext(SubmissionContext);
    const {
        titles,
        colors,
        backgrounds,
        borders,
        banners,
        buttonTexts,
        quoteOptions
    } = get(stepUiSchema, 'ui:options') || {};
    if (isDecline(quotes)) return <span/>;
    const style = getStyle(borders && borders[i], backgrounds && backgrounds[i], colors && colors[i]);
    const banner = t(`quote.tx-banners.${i}`, {defaultValue: banners && banners[i]});
    const chooseItem = () => {
        nextStep();
        updateSubmission(getAssumptionsObject(i, false));
    };
    const title = t(`quote.tx-titles.${i}`, {defaultValue: titles && titles[i]});
    const buttonText = t(`quote.tx-buttonTexts.${i}`, {defaultValue: buttonTexts && buttonTexts[i]});
    return (
            <div className={'gap offset-xs-1 col-xs-10 offset-sm-0 col-sm-' + ((count <= 3 ? 12 : 24) / (count || 1))}>
                <div className="section section-column quote" style={{
                    ...style,
                    position: 'relative'
                }}>
                    {banner && <div className="banner-container">
                        <div className="before btn-primary"/>
                        <div className="banner btn-primary">{banner}</div>
                        <div className="after btn-primary"/>
                    </div>}

                    <div className="form-group field field-object">
                        {quotes && <YourQuote t={t} style={style} quoteOptions={quoteOptions} title={title} i={i}/>}
                    </div>

                    <div className="text-center">
                        {quotes && <Price quotes={quotes} product={product}/>}
                        {quotes && <Button className={`btn btn-primary`} onClick={chooseItem}>{buttonText || 'Proceed'}</Button>}
                    </div>
                </div>
            </div>
    );
};

export const Heading = ({
    style,
    children
}) => (
        <div className="col-md-12 text-center">
            <h3 style={style} className="title ">{children}</h3>
            <hr/>
        </div>
);

export const YourQuote = ({
    t,
    quoteOptions = [],
    style,
    title,
    i
}) => {
    return (
            <div>
                <Heading style={{
                    color: style.color,
                    background: style.background
                }}>{title || 'Your Quote'}</Heading>
                <div className="gap">
                    {quoteOptions.filter(it => it.title && (it.valueStrings && it.valueStrings[i]))
                            .map(({
                                path,
                                title,
                                valueStrings
                            }) => {
                                const titleTranslated = t(`quote.quoteOptions.${path}.tx-title`, {defaultValue: title});
                                const valueString = t(`quote.quoteOptions.${path}.tx-valueStrings.${i}`, {defaultValue: valueStrings[i]});
                                return (
                                        <div key={path} className="row" id={path}>
                                            <div className="col-xs-8 text-left">
                                                <p className={'key'}>{titleTranslated}</p>
                                            </div>
                                            <div className="col-xs-4 text-right">
                                                <p className={'row-item'}>{valueString}</p>
                                            </div>
                                        </div>
                                );
                            })}
                </div>
            </div>
    );
};

export const SinglePrice = ({
    totalPayable,
    premium,
    ipt,
    product
}) => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const renderPrice = () => {
        switch (product.country) {
            case 'GB':
                return t('priceWithTaxes', {
                    premium: inProductCurrencyFunc(product, premium, i18n.language),
                    ipt: inProductCurrencyFunc(product, ipt)
                });
            default:
                return null;
        }
    };
    return (
            <div>
                <h1 className="text-center d-none d-sm-block"><InProductCurrency product={product} amount={totalPayable}/></h1>
                <h3 className="text-center d-block d-sm-none"><InProductCurrency product={product} amount={totalPayable}/></h3>
                <p className="text-center">
                    <small>{renderPrice()}</small>
                </p>
            </div>
    );
};

export const Price = ({
    quotes,
    product
}) => {
    const premium = Math.round(quotes.reduce((a, b) => (a + (get(b, 'premium') || 0) * 100), 0)) / 100;
    const ipt = Math.round(quotes.reduce((a, b) => (a + (get(b, 'totals.IPT') || 0) * 100), 0)) / 100;
    const totalPayable = Math.round(quotes.reduce((a, b) => (a + (get(b, 'totalPayable') || 0) * 100), 0)) / 100;

    return <SinglePrice {...{
        totalPayable,
        premium,
        ipt,
        product
    }} />;
};

export function getPrice(quote) {
    const ipt = (quote.totals.IPT && quote.totals.IPT.cumulative) || 0;
    const premium = (quote.grossPremium && quote.grossPremium.cumulative) || 0;
    const totalPayable = (quote.totalPayable && quote.totalPayable.cumulative) || 0;

    return {
        ipt,
        premium,
        totalPayable
    };
}

export default QuoteArray;
