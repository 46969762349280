import React, {useState} from 'react';
import {PendingContext} from './context';

export const PendingProvider = ({children}) => {
    const [pending, setPending] = useState(false);

    // Simple thinger to prevent needing try/catch setPending all over the place to disable controls.
    const pendingAction = async body => {
        try {
            setPending(true);
            return await body();
        } finally {
            setPending(false);
        }
    };

    return (
            <PendingContext.Provider value={{
                pending,
                pendingAction
            }}>
                {children}
            </PendingContext.Provider>
    );
};
