import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { PolicyContext } from "../../models/context";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import LinkButton from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
    CurrencyUtils,
    EPolicyStatus,
    ISettlementStatement,
    ITableData,
    ITransaction,
    localeService,
    PolicyUtils,
    RecordsNotFound,
    signInService,
    TableContainer,
} from "@surelync/common";
import { Hidden } from "@material-ui/core";
import GlobalContext from "../../../context/global-context";
import { setDataSettlementStatement } from "../AmountsDue/helper";
import { headerColumnsI18 } from "../AmountsDue/columns.config";
import * as BlocksurePolicyUtils from "@blocksure/blocksure-core/dist/src/utilities/PolicyUtils";
import { filterCanceled } from "./helper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

interface IProps {
    isSectionLevel?: boolean; // When user select the section do not show modal. Only at the Overview tab need modal.
}

const PremiumSummary: React.FC<IProps> = ({ isSectionLevel }) => {
    const classes = useStyles();
    const { currentUser } = useContext(GlobalContext);
    const [{ policy, product, selectedSection, sequences }] = useContext(PolicyContext);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const theme = useTheme();
    const { party } = currentUser.authDetails;
    const partyLoggedInToSurelync = party;

    useEffect(() => {
        if (!policy || !signInService.hasPermission(["SETTLEMENT_READ"])) {
            return;
        }

        const settlements = [];
        sequences.forEach((sequence) =>
            sequence.transactions.forEach((transaction: ITransaction & { settlements: Record<string, ISettlementStatement> }) => {
                const settlement: ISettlementStatement = transaction.settlements[partyLoggedInToSurelync];
                // /Payment API do not return 'Pending' and 'Failed' settlements
                // Lets use settlements from transactions by Policy API
                // but set 'reportingRef' from sequence and 'status' from transaction
                if (settlement) {
                    settlement.reportingRef = sequence.reportingRef;
                    settlement.status = transaction.status;
                    settlements.push(settlement);
                }
            })
        );

        setHeaderColumns(headerColumnsI18);
        setData(setDataSettlementStatement(filterCanceled(settlements)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const handlePaymentDetails = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderLine = ({ index, isDivider, title, value }) => {
        return (
            <Fragment key={index}>
                <Grid item xs>
                    <Typography variant="body1">{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="right">
                        {value}
                    </Typography>
                </Grid>
                <Hidden xsUp={!isDivider}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Hidden>
            </Fragment>
        );
    };

    const renderLines = () => {
        const lines = [];
        const grossPremium = isSectionLevel ? selectedSection.grossPremium : policy.grossPremium;
        const totals = isSectionLevel ? selectedSection.breakdown : policy.totals;
        // const totalPayable = isSectionLevel ? selectedSection.totalPayable : policy.totalPayable;

        // Insurer View
        // The values are derived from the policy breakdown for the insurer as follows:
        // Gross premium -> grossPremium
        // Deductions -> brokerage
        // Net Premium -> Net Premium
        // IPT -> IPT
        // Platform Fee -> Insurer platform Fee
        // Total Payable -> totalPayable/Cumulative
        // Paid to Date -> cashMovements/cumulative
        // Amount Outstanding -> cashMovements/future

        grossPremium &&
            lines.push({
                title: t("grossPremium"),
                value: CurrencyUtils._renderCurrency(grossPremium.cumulative, localeService.locale, policy.currency),
            });

        totals["Net Premium"] &&
            lines.push({
                title: t("netPremium"),
                value: CurrencyUtils._renderCurrency(totals["Net Premium"].cumulative, localeService.locale, policy.currency),
            });

        totals["Net Premium"] &&
            lines.push({
                title: t("deductions"),
                value: CurrencyUtils._renderCurrency(
                    grossPremium.cumulative - PolicyUtils.getValueByKey(totals, "Net Premium", "cumulative"),
                    localeService.locale,
                    policy.currency
                ),
            });

        totals["IPT"] &&
            lines.push({
                title: t("ipt"),
                value: CurrencyUtils._renderCurrency(totals["IPT"].cumulative, localeService.locale, policy.currency),
            });

        if (BlocksurePolicyUtils.shouldSkipPayment(currentUser.authDetails, product)) {
            totals["Tax"] &&
                lines.push({
                    title: t("taxWithheld"),
                    value: CurrencyUtils._renderCurrency(totals["Tax"].cumulative, localeService.locale, policy.currency),
                });
        }

        // Any platform fees are at Policy level and will not be shown at Section level
        if (!isSectionLevel) {
            const totalPayable = sequences.reduce(
                (total, sequence) =>
                    total + sequence.partyCashMovements[partyLoggedInToSurelync].toDate + sequence.partyCashMovements[partyLoggedInToSurelync].future,
                0
            );

            const paidToDate = sequences.reduce((total, sequence) => total + sequence.partyCashMovements[partyLoggedInToSurelync].toDate, 0);

            const amountOutstanding = sequences.reduce((total, sequence) => total + sequence.partyCashMovements[partyLoggedInToSurelync].future, 0);

            totals["Insurer Platform Fee"] &&
                lines.push({
                    title: `${t("insurerPlatformFee")}`,
                    value: CurrencyUtils._renderCurrency(totals["Insurer Platform Fee"].cumulative, localeService.locale, policy.currency),
                });

            lines.push({
                title: t("totalPayable"),
                value: CurrencyUtils._renderCurrency(totalPayable, localeService.locale, policy.currency),
            });

            lines.push({
                title: t("paidToDate"),
                value: CurrencyUtils._renderCurrency(paidToDate, localeService.locale, policy.currency),
            });

            lines.push({
                title: t("outstandingBalance"),
                value: CurrencyUtils._renderCurrency(amountOutstanding, localeService.locale, policy.currency),
            });
        }

        return lines.map((line, index) => renderLine({ ...line, index, isDivider: index !== lines.length - 1 }));
    };

    const displayData = policy.status === EPolicyStatus.Quoted ? [] : data;

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("premiumSummary")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container direction="column" classes={{ root: classes.content }}>
                    <Grid item xs>
                        <Grid container spacing={1}>
                            {renderLines()}
                        </Grid>
                    </Grid>

                    {/* Insurer: the modal “Payment Details” are displayed in the “Premium Summary” box at the Overview level
              (or on the Section tab if there is only one Section in the view.) */}
                    <Hidden xsUp={isSectionLevel}>
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <LinkButton href="" color="primary" variant="body2" onClick={handlePaymentDetails}>
                                    {t("paymentDetails")}
                                </LinkButton>
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
            <Dialog fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="amount-due-dialog" open={Boolean(anchorEl)}>
                <DialogTitle>
                    <Typography component="span" variant="h6">
                        {t("realtimeSettlementForPolicy")} {policy?.reportingRef}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TableContainer columns={headerColumns} data={displayData} fetchData={() => undefined} theme={theme} />

                    {!displayData?.length ? (
                        <Box my={10}>
                            <RecordsNotFound theme={theme} />
                        </Box>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={1}>
                        <Grid item>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {t("close")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PremiumSummary;
