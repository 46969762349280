import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

import { SelectCustom } from "@surelync/common";

interface IProps {
    endorsements: any;
    sectionName: string;
    issue: any;
    productSection: any;
    createReferralData: (sectionName: string, issueName: string, value: string) => void;
}

const Issues: React.FC<IProps> = ({ endorsements, sectionName, issue, productSection, createReferralData }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [selectedValue, setSelecetedValue] = useState<string>("");
    const endorsementName = endorsements[sectionName][issue.name];
    const productIssues = productSection.issues[issue.name];

    let noEndoresment = true;

    const optionEndorsements = Object.keys(productIssues.endorsements).map((item) => {
        if (item === endorsementName) noEndoresment = false;
        return {
            label: item,
            value: item,
            disabled: false,
        };
    });

    if (noEndoresment) {
        optionEndorsements.unshift({
            label: t("noEndorsement"),
            value: t("noEndorsement"),
            disabled: true,
        });
    }

    useEffect(() => {
        setSelecetedValue(optionEndorsements[0].value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment key={issue.name}>
            <Grid item xs={2}>
                <Typography variant="body1">
                    {sectionName.toUpperCase()[0]}
                    {sectionName.substring(1)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body1">{issue.name}</Typography>
            </Grid>
            <Grid item xs={7}>
                <SelectCustom
                    name={issue.name}
                    options={optionEndorsements}
                    theme={theme}
                    value={selectedValue}
                    onChange={(event) => {
                        setSelecetedValue(event.target.value);
                        createReferralData(sectionName, issue.name, event.target.value);
                    }}
                />
            </Grid>
        </React.Fragment>
    );
};

export default Issues;
