import React, {useContext, useEffect, useMemo} from 'react';
import {Menu} from '../shared/Menu';
import {get} from 'lodash';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {AuthContext, PolicyListContext, TradingGroupContext} from '../common/context';
import {Screen} from '../shared/Screen';
import {useDefaultTranslation} from '../common/Translation';
import {Markdown} from '../schema/Markdown';
import Loading from '../widgets/Loading';
import {convertToNewPath} from "../base";


const findClaimSchema = (products) => Object.keys(products)
    .find(productId => products[productId].hiddenAttachments.find(a => a.name === 'claimSchema.json'));

const HomeWithAuth = () => {
    const {formulator} = useContext(TradingGroupContext) || {};
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <TradingGroupContext.Consumer>{({
                formulator: {
                    claim,
                    mainMenu: {footer} = {}
                } = {},
                products
            } = {}) => (
                    <Screen>
                        <PolicyListContext.Consumer>{({policies, reloadPolicies, renewals}) => (
                                <AuthContext.Consumer>{({signOut}) => (
                                   <MenuItems
                                        claim={claim}
                                        footer={footer}
                                        formulator={formulator}
                                        policies={policies}
                                        products={products}
                                        reloadPolicies={reloadPolicies}
                                        renewals={renewals}
                                        signOut={signOut}
                                        path={newPath}
                                   />
                                )}</AuthContext.Consumer>
                        )}</PolicyListContext.Consumer>
                    </Screen>
            )}</TradingGroupContext.Consumer>
    );
};

const MenuItems = ({claim, footer, formulator, policies, products, reloadPolicies, renewals, signOut, path}) => {
    const {t} = useDefaultTranslation();
    useEffect(() => {
        reloadPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = useMemo(() => {
        const items = [];
        const renewalPath = renewals?.length === 1 ? `${path}/renewal/${renewals[0].sequenceId}` : `${path}/renewal`;
        items.push(<Link data-menukey="quote" to={`${path}/quote`}
                         className="btn btn-lg btn-block btn-primary gap">{get(formulator, 'mainMenu.newQuote') || t('newQuote')}</Link>);
    
        // Only show if they have a policy (even if its expired).
        if (policies) {
            if (policies.length > 0) {
                // Only some apps will include claim functionality.
                if (claim || findClaimSchema(products)) {
                    items.push(<Link data-menukey="claim" to={`${path}/claim`}
                                     className="btn btn-lg btn-block btn-primary gap">{t('claims')}</Link>);
                }
                if (renewals?.length) {
                    items.push(<Link data-menukey="renewal" to={renewalPath}
                                        className="btn btn-lg btn-block btn-primary gap">{t('renewal')}</Link>);
                }
                items.push(<Link data-menukey="policy"
                                 to={policies.length > 1 ? `${path}/policy` : `${path}/policy/${policies[0].sequenceId}`}
                                 className="btn btn-lg btn-block btn-primary gap">{t('knowMyPolicy')}</Link>);
                items.push(<Link data-menukey="payment"
                                 to={policies.length > 1 ? `${path}/payment` : `${path}/payment/${policies[0].sequenceId}`}
                                 className="btn btn-lg btn-block btn-primary gap">{t('paymentSchedule')}</Link>);
            }
        } else {
            items.push(<Loading data-menukey="loading" inline/>);
        }
    
        // Only show if they have an active (bound) policy - must be the latest sequence.
        const finalSequencePolicies = policies && policies.filter(it => it.sequence === it.version);
        if (finalSequencePolicies && finalSequencePolicies.length > 0) {
            items.push(<Link data-menukey="amend" to={`${path}/amend`}
                             className="btn btn-lg btn-block btn-primary gap">{t('amend')}</Link>);
        }
    
        items.push(<hr data-menukey="separator"/>);
        items.push(<Link data-menukey="logout" to={`${path}/`} onClick={signOut}
                         className="btn btn-lg btn-block btn-danger gap">{t('signOut')}</Link>);
        if (footer) {
            items.push(<hr data-menukey="separator2"/>);
            items.push(<p data-menukey="footer" className="alert alert-info gap text-center"><Markdown
                    value={footer}/></p>);
        }
        return items;
    }, [claim, footer, formulator, policies, products, renewals, signOut, t, path])
     
    return <Menu items={items}/>;
}

export default HomeWithAuth;
