import * as React from "react";
import { useContext, useEffect, useReducer } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import NoteApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/NoteApiClient";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import { ADD_ENQUIRY_DONE, FETCH_DONE, FETCH_ERROR, FETCH_START, initialState, OPEN_NEW_DIALOG, reducer } from "./reducer";
import { IPolicyholder, Preloader } from "@surelync/common";
import { INote } from "./note.models";
import DialogEditNote from "../../components/DialogEditNote/DialogEditNote";
import GlobalContext from "../../context/global-context";
import { SET_CACHE_USER_START } from "../../context/reducers";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const MAX_ENQUIRY_LENGTH = 120;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

interface IProps {
    newEnquiryId?: string; // [Claim Details] page Enquiry could be added by modal
    policyholder: IPolicyholder;
    onLoad?: (payload: INote[]) => void; // [Contact Details] page tab name must include Badge with count
    onViewAll: () => void;
}

export const OpenEnquiries: React.FC<IProps> = ({ newEnquiryId, policyholder, onLoad, onViewAll }) => {
    const classes = useStyles();
    const [{ fetching, newEnquiry, enquiries, open }, dispatch] = useReducer(reducer, initialState);
    const { cacheUsers, namespacedLocalStorage, dispatchGlobal } = useContext(GlobalContext);
    const { t } = useTranslation();

    const noteApiClient = new NoteApiClient(namespacedLocalStorage);
    const ownerId = `${policyholder.id}-enquiries`;
    const theme = useTheme();

    useEffect(() => {
        let isMount = true;
        const fetchData = async () => {
            try {
                dispatch({ type: FETCH_START });
                const response: INote[] = await noteApiClient.getNotesByOwnerId(ownerId);
                // cleanup func
                if (!isMount) return;
                dispatch({ type: FETCH_DONE });

                // how much we can show? 2 big notes or 3 smaller
                const sortData = FormatUtils.sortByProperty(response, "ts").reverse();
                onLoad && onLoad(sortData);

                if (sortData && sortData.length) {
                    const maxLength = sortData.slice(0, 3).reduce((total, item) => {
                        return total + item.description.substring(0, MAX_ENQUIRY_LENGTH).length;
                    }, 0);
                    const count = maxLength > MAX_ENQUIRY_LENGTH * 2 ? 2 : 3;
                    dispatch({ type: FETCH_DONE, payload: sortData.slice(0, count) });
                } else {
                    dispatch({ type: FETCH_DONE, payload: null });
                }
            } catch (error) {
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerId, newEnquiry, newEnquiryId]);

    useEffect(() => {
        if (!enquiries || !enquiries.length) {
            return;
        }

        const emails = {};
        enquiries.forEach((note: INote) => {
            if (!cacheUsers[note.email]) emails[note.email] = null;
        });

        dispatchGlobal({ type: SET_CACHE_USER_START, payload: emails });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enquiries]);

    const handleClose = (newEnquiry?: INote) => {
        if (newEnquiry) {
            dispatch({ type: ADD_ENQUIRY_DONE, payload: newEnquiry });
        } else {
            dispatch({ type: OPEN_NEW_DIALOG, payload: false });
        }
    };

    const handleViewAll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        // dispatchContact({ type: SET_TAB, payload: ETab.Enquiries });
        onViewAll();
    };

    const renderNote = (description: string) => {
        if (description.length > MAX_ENQUIRY_LENGTH) {
            return `${description.substring(0, MAX_ENQUIRY_LENGTH)} ...`;
        }

        return description;
    };

    const renderAuthor = (note: INote) => {
        if (cacheUsers[note.email]?.firstName) {
            return `${cacheUsers[note.email]?.firstName} ${cacheUsers[note.email]?.lastName}`;
        } else {
            return note.email;
        }
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("openEnquiries")}
            </Typography>
            <Paper className={classes.root}>
                {fetching ? (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Preloader theme={theme} />
                        </Grid>
                    </Grid>
                ) : enquiries ? (
                    <Grid container direction="column" spacing={3} classes={{ root: classes.content }}>
                        <Grid item xs>
                            {enquiries.map((enquiry: INote, index: number) => (
                                <Grid container key={index} spacing={3}>
                                    <Grid item xs={1}>
                                        <DescriptionIcon color="primary" style={{ verticalAlign: "middle" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container wrap="nowrap" spacing={2}>
                                            <Grid item>
                                                <Typography variant="body2" noWrap>
                                                    {FormatUtils.renderDateTime(enquiry.ts)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs style={{ overflow: "hidden" }}>
                                                <Typography align="right" variant="body2" noWrap title={renderAuthor(enquiry)}>
                                                    {renderAuthor(enquiry)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body1">{renderNote(enquiry.description)}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            aria-label="add-enquiry"
                                            color="primary"
                                            style={{ visibility: index === 0 ? "visible" : "hidden" }}
                                            onClick={() => dispatch({ type: OPEN_NEW_DIALOG, payload: true })}
                                        >
                                            <AddIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item>
                            <Box display="flex" justifyContent="flex-end">
                                <Link href="#" color="primary" variant="body2" onClick={handleViewAll}>
                                    {t("viewAll")}
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <IconButton
                                        aria-label="add-note"
                                        color="primary"
                                        onClick={() => dispatch({ type: OPEN_NEW_DIALOG, payload: true })}
                                    >
                                        <AddIcon fontSize="large" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{t("newEnquiry")}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <DialogEditNote open={open} ownerId={ownerId} placeholder={`${t("newEnquiry")}...`} onClose={handleClose} />
        </>
    );
};
