import React, {useState} from 'react';
import {MetadataContext} from '../common/context';

export const MetadataProvider = ({children}) => {
    const [metadata, setMetadata] = useState({});

    const updateMetadata = (key, value) => {
        setMetadata({
            ...metadata,
            [key]: value
        });
    };
    return (
            <MetadataContext.Provider value={{
                metadata,
                updateMetadata
            }}>
                {children}
            </MetadataContext.Provider>
    );
};
