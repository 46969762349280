import React, {useContext, useEffect, useState} from 'react';
import {AuthContext, PolicyContext, PolicyholderContext, PolicyListContext, TradingGroupContext} from '../common/context';
import {PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';
import {QuoteStatuses} from './PolicyListProvider';

const policyApiClient = new PolicyApiClient(SureAppName);

export const PolicyProvider = ({
    children,
    sequenceId,
}) => {
    const {products} = useContext(TradingGroupContext);
    const [policy, setPolicy] = useState(undefined);
    const [amendDate, setAmendDate] = useState(sessionStorage.getItem(`com.blocksure.${sequenceId}.amendDate`));
    const quote = policy && QuoteStatuses.includes(policy.status) && policy;
    const {reloadPolicies} = useContext(PolicyListContext) || {};
    const {policyholder} = useContext(PolicyholderContext);
    const {surelyncProps} = useContext(AuthContext);

    const updatePolicyBySequenceId = async sequenceId => {
        await policyApiClient.getPolicyBySequenceId(sequenceId)
                .then(loaded => {
                    // if email was updated submission could have old in the Quote
                    const primaryEmailAddress = loaded.submission?.aboutYou?.primaryEmailAddress;
                    if (!surelyncProps && primaryEmailAddress && primaryEmailAddress !== policyholder.primaryEmailAddress) {
                        loaded.submission.aboutYou.primaryEmailAddress = policyholder.primaryEmailAddress;
                    }
                    sessionStorage.setItem(`com.blocksure.${loaded.productId}.sequenceId`, sequenceId);
                    loaded.product = products && products[loaded.id];
                    setPolicy(loaded);
                });
        reloadPolicies && reloadPolicies();
    };

    const updatePolicy = async policy => {
        sessionStorage.setItem(`com.blocksure.${policy.productId}.sequenceId`, policy.sequenceId);
        policy.product = products && products[policy.id];
        setPolicy(policy);
        reloadPolicies && reloadPolicies();
    };

    useEffect(() => {
        if (!sequenceId) {
            setPolicy(null);
        } else {
            updatePolicyBySequenceId(sequenceId);
        }
        // eslint-disable-next-line
    }, [products && Object.keys(products)
            .toString(), sequenceId]);

    const updateAmendDate = amendDate => {
        sessionStorage.setItem(`com.blocksure.${sequenceId}.amendDate`, amendDate);
        setAmendDate(amendDate);
    };

    return (
            <PolicyContext.Provider value={{
                policy,
                quote,
                amendDate,
                updateAmendDate,
                updatePolicy,
            }}>
                <div>{children}</div>
            </PolicyContext.Provider>
    );
};
