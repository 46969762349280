import React from "react";
import { useField } from "formik";
import { SelectCustom } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";

export const FSelectField: React.FC<any> = ({ ...props }) => {
    const [field, meta] = useField<unknown>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    const theme = useTheme();
    return <SelectCustom {...props} {...field} theme={theme} helperText={errorText} error={errorText ?? ""} />;
};
