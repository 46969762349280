import { JSONSchema7 } from "json-schema";
import { IAttachment, IClaim, IPolicyholder, IAction, IPolicy, IProduct } from "@surelync/common";

export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const OPEN_ADD_ENQUIRY_DIALOG = "OPEN_ADD_ENQUIRY_DIALOG";
export const OPEN_ACTIONS = "OPEN_ACTIONS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_NEW_ENQUIRY_ID = "SET_NEW_ENQUIRY_ID";

export interface ClaimState {
    anchorEl: HTMLElement;
    claim: IClaim;
    documents: IAttachment[];
    error: string;
    fetching: boolean;
    message: string;
    newEnquiryId: string;
    openEnquiry: boolean;
    policy: IPolicy;
    policyholder: IPolicyholder;
    product: IProduct;
    sequences: IPolicy[];
    universalPolicyholderSchema: JSONSchema7;
}

export const initialState: ClaimState = {
    anchorEl: null,
    claim: null,
    documents: null,
    error: "",
    fetching: false,
    message: null,
    newEnquiryId: null,
    openEnquiry: false,
    policy: null,
    policyholder: null,
    product: null,
    sequences: null,
    universalPolicyholderSchema: null,
};

export function reducer(state: ClaimState, action: IAction): ClaimState {
    switch (action.type) {
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false, ...action.payload };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case OPEN_ADD_ENQUIRY_DIALOG:
            return { ...state, openEnquiry: action.payload };
        case OPEN_ACTIONS:
            return { ...state, anchorEl: action.payload };
        case SET_MESSAGE:
            return { ...state, message: action.payload };
        case SET_NEW_ENQUIRY_ID:
            return { ...state, newEnquiryId: action.payload };

        default:
            return state;
    }
}
