import {base,  SureAppName} from '../base';
import {ClaimApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const claimApiClient = new ClaimApiClient(SureAppName);

const constants = {
    claimAmount: 0.0,
    claimFee: 0.0,
    status: 'New'
};

/**
 * Updates the current quote we have and logs/throws a human friendly error if something goes wrong.
 */
export const createClaim = async (t, sequenceId, _submission, meta, dateOfIncident, attachments) => {
    try {
        // These top level parameters come from the 'claim' section of schema.
        const {
            claim: {
                category = '',
                subCategory = '',
                lossDescription = '',
                ...claim
            }
        } = _submission;
        const combinedMeta = {
            ...meta,
            appPath: base
        };
        const attachmentArray = Object.entries(attachments)
                .map(([name, file]) => ({
                    name,
                    file
                }));
        const attachmentObjects = Object.entries(attachments)
                .map(([name, file]) => ({
                    name: name,
                    modifiedDate: FormatUtils.parseDate(file.lastModifiedDate).toUTC().toISO(),
                    description: file.name,
                    mediaType: file.type
                }));
        const claimData = {
            sequenceId,
            category,
            dateOfIncident,
            lossDescription,
            subCategory,
            submission: {
                ..._submission,
                claim
            },
            attachments: attachmentObjects,
            meta: combinedMeta, ...constants
        };
        return await claimApiClient.createClaim(claimData, attachmentArray);
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, t('unableToCreateClaim', {defaultValue: 'Unable to create claim'})));
    }
};
