import React, { useContext, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import GlobalContext from "../../../context/global-context";
import { ESearchType, HomeContext, ISearchClaimParams, RESET_SEARCH_PARAMS, SET_SEARCH_PARAMS, SET_SEARCH_TYPE } from "../../models";
import { AutocompleteCustom, DatePickerCustom, DateRangeCustom, EContactType, LoadingBtn } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { FTextField } from "../../../components/Formik/FTextField";
import { SearchItemsSelect } from "../SearchItems/SearchItemsSelect";
import { FSelectField } from "../../../components/Formik";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

type ISearchProps = Record<string, unknown>;

export const SearchClaim: React.FC<ISearchProps> = () => {
    const { locale, products } = useContext(GlobalContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState(null);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [state, dispatch] = useContext(HomeContext);

    const { fetching } = state;

    const handleClear = () => {
        setDateRange(null);
        setValue(null);
        setInputValue("");
        dispatch({ type: RESET_SEARCH_PARAMS });
    };

    const handleSubmit = (values: ISearchClaimParams) => {
        const searchParams: ISearchClaimParams = {};
        const [from, to] = dateRange || [];

        if (values.claimId) {
            searchParams.claimId = values.claimId.trim();
        }

        if (values.dateOfIncident) {
            searchParams.dateOfIncident = FormatUtils.renderFormatDate(values.dateOfIncident, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (values.creationDate) {
            searchParams.creationDate = FormatUtils.renderFormatDate(values.creationDate, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (from) {
            searchParams.from = FormatUtils.renderFormatDate(from, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (to) {
            searchParams.to = FormatUtils.renderFormatDate(to, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (inputValue) {
            searchParams.productName = inputValue.trim();
        }

        if (values.insurer) {
            searchParams.insurer = values.insurer.trim();
        }

        if (values.contactType) {
            searchParams.contactType = values.contactType;
        }

        if (values.contactType === EContactType.Company && values.companyRegistrationNumber) {
            searchParams.companyRegistrationNumber = values.companyRegistrationNumber.trim();
        }

        if (values.postcode) {
            searchParams.postcode = values.postcode.trim();
        }

        dispatch({ type: SET_SEARCH_PARAMS, payload: searchParams });
    };

    const formatDateRange = () => {
        const [from, to] = dateRange || [];
        if (from && to) {
            return `${FormatUtils.renderFormatDate(from, "D")} - ${FormatUtils.renderFormatDate(to, "D")}`;
        } else {
            return "";
        }
    };

    const contactTypeOptions = useMemo(() => {
        return [EContactType.Individual, EContactType.Company].map((item) => ({ label: t(item.toLowerCase()), value: item }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const optionsProduct = useMemo(() => Object.values(products).map((product) => ({ label: product.name, value: product.id })), [products]);

    return (
        <Formik
            initialValues={{
                // claimId: '',
                companyRegistrationNumber: "",
                contactType: "",
                creationDate: null,
                dateOfIncident: null,
                dateOfLoss: null,
                ts: null,
                insurer: "",
                postcode: "",
                productName: "",
            }}
            onSubmit={handleSubmit}
        >
            {({ resetForm, values, setFieldValue }) => (
                <Form>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6">{t("advancedSearch")}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        data-testid="close-btn"
                                        onClick={() => dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.Global })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SearchItemsSelect />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={6}>
                            <DatePickerCustom
                                displayValue={values.creationDate ? FormatUtils.renderFormatDate(values.creationDate, "D") : ""}
                                label={t("fnolDate")}
                                locale={locale}
                                theme={theme}
                                value={values.creationDate ? FormatUtils.parseDate(values.creationDate).toJSDate() : null}
                                onChange={(v) => setFieldValue("creationDate", v)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DatePickerCustom
                                alignRight={true}
                                displayValue={values.dateOfIncident ? FormatUtils.renderFormatDate(values.dateOfIncident, "D") : ""}
                                label={t("dateOfLoss")}
                                locale={locale}
                                theme={theme}
                                value={values.dateOfIncident ? FormatUtils.parseDate(values.dateOfIncident).toJSDate() : null}
                                onChange={(v) => setFieldValue("dateOfIncident", v)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateRangeCustom
                                displayValue={formatDateRange()}
                                label={t("lastActionDate")}
                                locale={locale}
                                theme={theme}
                                value={dateRange}
                                onChange={setDateRange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AutocompleteCustom
                                inputValue={inputValue}
                                label={t("product")}
                                name="productName"
                                options={optionsProduct}
                                theme={theme}
                                value={value}
                                freeSolo
                                fullWidth
                                onChange={(event, newValue: string) => {
                                    setValue(newValue);
                                    setInputValue(newValue);
                                }}
                                onInput={(event) => {
                                    setInputValue(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FSelectField data-testid="contact-type" label={t("contactType")} name="contactType" options={contactTypeOptions} />
                        </Grid>

                        <Hidden xsUp={values.contactType !== EContactType.Company}>
                            <Grid item xs={12}>
                                <FTextField label={t("companyRegNo")} name="companyRegistrationNumber" />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <FTextField label={t("insurer")} name="insurer" />
                        </Grid>

                        <Grid item xs={12}>
                            <FTextField label={t("postcode")} name="postcode" />
                        </Grid>

                        <Grid item xs={12}>
                            <Box my={2} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={fetching}
                                        onClick={() => {
                                            resetForm();
                                            handleClear();
                                        }}
                                    >
                                        {t("clear")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingBtn fetching={fetching} label={t("applyFilters")} theme={theme} type="submit" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
