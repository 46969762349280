import React, {useContext} from 'react';
import {AuthContext, CurrentStepContext, MetadataContext, ProductContext, SubmissionContext} from '../common/context';
import './DebugView.css';
import {merge} from 'lodash';

export const ClaimDebugView = () => {
    const {metadata} = useContext(MetadataContext);
    return (
            global.notProd && (
                    <ProductContext.Consumer key="params">{({
                        defaultClaim,
                        product = {}
                    }) => [
                        <SubmissionContext.Consumer key="submission">{({
                            submission,
                            updateSubmission
                        }) => (
                                <pre className="debug-view">
                        <div><label><button className="btn btn-secondary" onClick={() => updateSubmission(merge({}, defaultClaim, submission))}>Set Default Claim</button></label></div>
                        <div><label>Metadata: </label>{JSON.stringify(metadata, null, 4)}</div>
                        <div><label>Submission: </label>{JSON.stringify(submission, null, 4)}</div>
                    </pre>
                        )}</SubmissionContext.Consumer>,
                        <CurrentStepContext.Consumer key="params">{({
                            stepNames,
                            firstStep,
                            previousStep,
                            previousStepName,
                            currentStep,
                            nextStep,
                            nextStepName,
                            shouldRegister,
                            shouldQuote,
                            stepSchema,
                            stepUiSchema
                        }) => (
                                <AuthContext.Consumer key="params">{({
                                    auth,
                                    hasPassword
                                }) => (
                                        <pre className="debug-view">
                            <div><label>StepNames: </label>{`${stepNames}`}</div>
                            <div><label>FirstStep: </label>{`${!!firstStep}`}</div>
                            <div><label>PreviousStep: </label><button className="btn btn-sm btn-secondary"
                                                                      onClick={previousStep}>{`${previousStepName}`}</button></div>
                            <div><label>CurrentStep: </label>{`${currentStep}`}</div>
                            <div><label>NextStepName: </label><button className="btn btn-sm btn-secondary"
                                                                      onClick={nextStep}>{`${nextStepName}`}</button></div>
                            <div><label>ShouldRegister: </label>{`${shouldRegister}`}</div>
                            <div><label>ShouldQuote: </label>{`${shouldQuote}`}</div>
                            <div><label>StepSchema: </label>{`${JSON.stringify(stepSchema)}`}</div>
                            <div><label>StepUiSchema: </label>{`${JSON.stringify(stepUiSchema)}`}</div>
                            <br/>
                            <div><label>Product ID: </label>{`${product.id}`}</div>
                            <div><label>Product Name: </label>{`${product.name}`}</div>
                            <br/>
                            <div><label>Auth: </label>{`${auth && JSON.stringify(auth)}`}</div>
                            <div><label>Set password: </label>{`${hasPassword}`}</div>
                        </pre>
                                )}</AuthContext.Consumer>
                        )}</CurrentStepContext.Consumer>
                    ]}</ProductContext.Consumer>
            )
    );
};
