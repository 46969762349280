import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { SearchItems } from "../SearchItems/SearchItemsLinks";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SET_SEARCH_PARAMS, SET_SEARCH_TYPE } from "../../models/action";
import { HomeContext } from "../../models/context";
import { InputCustom, signInService } from "@surelync/common";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import GlobalContext from "../../../context/global-context";
import { ESearchType } from "../../models";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            [theme.breakpoints.up("xs")]: {
                width: 300,
            },
            [theme.breakpoints.up("sm")]: {
                width: 350,
            },
            [theme.breakpoints.up("md")]: {
                width: 400,
            },
            [theme.breakpoints.up("lg")]: {
                width: 450,
            },
        },
    })
);

type ISearchProps = Record<string, unknown>;

const showSearch = () => {
    return signInService.hasPermission(["CLAIM_READ", "POLICY_HOLDER_READ", "POLICY_READ"], "all");
}

export const SearchGlobal: React.FC<ISearchProps> = () => {
    const { clientLogo } = useContext(GlobalContext);
    const [, dispatch] = useContext(HomeContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const classes = useStyles();

    const handleSubmit = () => {
        const value = search.trim();

        if (!value) {
            return;
        }

        dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.All });
        dispatch({ type: SET_SEARCH_PARAMS, payload: { search: value } });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit();
        }
    };

    return (
        <Box pt={12}>
            <Grid container justify="center">
                <Grid item>
                    <Grid container direction="column" spacing={1} classes={{ root: classes.wrapper }}>
                        {clientLogo ? (
                            <Grid item>
                                <Box textAlign="center" mb={8}>
                                    <img src={clientLogo} alt="client-logo" style={{ maxWidth: 250, maxHeight: 250 }} />
                                </Box>
                            </Grid>
                        ) : null}
                        {showSearch() ? <>
                            <Grid item>
                                <InputCustom
                                    autoComplete="off"
                                    data-testid="input-search"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="search button" data-testid="submit" size="small" onClick={handleSubmit}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t("search")}
                                    theme={theme}
                                    onChange={(event) => setSearch(event.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                            </Grid>
                            <Grid item>
                                <Box mt={8}>
                                    <Typography paragraph variant="body2">
                                        {t("advancedSearch")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <SearchItems />
                            </Grid>
                        </> : null}
                       
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
