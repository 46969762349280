import React from 'react';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';
import { useDefaultTranslation } from '../../common/Translation';

function EmailWidget(props) {
    const {
        disabled,
        id,
        name,
        readonly,
        required,
        schema,
        value,
        onBlur,
        onChange,
        onFocus,
    } = props;
    const {t} = useDefaultTranslation();

    const placeholder = schema['ui:placeholder'] || '';
    
    return (
        <div>
            <input
                className="form-control" 
                disabled={disabled || readonly}
                id={id}
                name={name}
                type="email"
                placeholder={placeholder} 
                required={required}
                value={value}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onChange={event => {
                    resetRequiredMessage(event);
                    onChange(event.target.value);
                }}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
                onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}
            />
        </div>
    );
}

export default EmailWidget;
