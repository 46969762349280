import React from 'react';
import { useDefaultTranslation } from '../../common/Translation';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';
import '../../styles/AutoSuggest.css';

function validate(value) {
    const str = value.trim();
    // could remove all characters
    return value.length === 0 || !!str;
}

const NotEmptyStringField = ({
    schema,
    uiSchema,
    formData,
    onChange,
    name,
    required,
    disabled
}) => {
    const helperText = uiSchema['ui:helperText'] || '';
    const {t} = useDefaultTranslation();
    const placeholder = uiSchema['ui:placeholder'] || '';
    const onChangeLocal = (e) => {
        resetRequiredMessage(e);
        validate(e.target.value) && onChange(e.target.value)
    };
    return (
            <div>
                <label className="col-form-label" htmlFor="string-field">{schema.title || name}{required ? '*' : ''}</label>
                <input disabled={disabled} className="form-control" placeholder={placeholder} required={required}
                       value={formData === undefined ? '' : formData} onChange={onChangeLocal} onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}/>
                {helperText}
            </div>
    );
};

export default NotEmptyStringField;
