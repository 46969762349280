import * as React from "react";
import { useContext, useEffect, useReducer } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/global-context";
import NoteApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/NoteApiClient";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import { Button, Container, Divider, useTheme } from "@material-ui/core";
import { ADD_NOTE_DONE, FETCH_DONE, FETCH_ERROR, FETCH_START, initialState, OPEN_DIALOG, reducer, SELECT_NOTE } from "./reducer";
import { ContactContext, SET_NOTES } from "../../models";
import { INote, Preloader, RecordsNotFound } from "@surelync/common";
import EditIcon from "@material-ui/icons/Edit";
import { SET_CACHE_USER_START } from "../../../context/reducers";
import DialogEditNote from "../../../components/DialogEditNote/DialogEditNote";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: 0,
            padding: 0,
        },
        content: {
            padding: theme.spacing(3),
        },
    })
);

type IProps = Record<string, unknown>;

const TabNotes: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ fetching, newNote, open, selectedNote }, dispatch] = useReducer(reducer, initialState);
    const [{ notes, policyholder }, dispatchContact] = useContext(ContactContext);
    const { cacheUsers, currentUser, namespacedLocalStorage, dispatchGlobal } = useContext(GlobalContext);
    const { t } = useTranslation();

    const noteApiClient = new NoteApiClient(namespacedLocalStorage);
    const ownerId = `${policyholder.id}-notes`;
    const theme = useTheme();

    useEffect(() => {
        if (!newNote) {
            return;
        }

        let isMount = true;
        const fetchData = async () => {
            dispatch({ type: FETCH_START });

            try {
                const response: INote[] = await noteApiClient.getNotesByOwnerId(ownerId);
                // cleanup func
                if (!isMount) return;

                const sortData = FormatUtils.sortByProperty(response, "ts").reverse();
                dispatchContact({ type: SET_NOTES, payload: sortData });
            } catch (error) {
                dispatchContact({ type: FETCH_ERROR, payload: generateErrorMessage(error)});
            }

            dispatch({ type: FETCH_DONE });
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newNote]);

    useEffect(() => {
        if (!notes || !notes.length) {
            return;
        }

        const emails = {};
        notes.forEach((note: INote) => {
            if (!cacheUsers[note.email]) emails[note.email] = null;
        });

        dispatchGlobal({ type: SET_CACHE_USER_START, payload: emails });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes]);

    const handleClose = (newNote?: INote) => {
        if (newNote) {
            dispatch({ type: ADD_NOTE_DONE, payload: newNote });
        } else {
            dispatch({ type: SELECT_NOTE, payload: null });
            dispatch({ type: OPEN_DIALOG, payload: false });
        }
    };

    const renderAuthor = (note: INote) => {
        if (cacheUsers[note.email]?.firstName) {
            return `${cacheUsers[note.email]?.firstName} ${cacheUsers[note.email]?.lastName}`;
        } else {
            return note.email;
        }
    };

    const renderDate = (value: string) => FormatUtils.renderDateTime(value);

    if (fetching) {
        return (
            <Grid container justify="center">
                <Grid item>
                    <Box mt={25}>
                        <Preloader theme={theme} />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Container maxWidth="md" classes={{ root: classes.wrapper }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={1} justify="flex-end" alignItems="center">
                        <Grid item>
                            <IconButton
                                aria-label="add-note"
                                color="primary"
                                data-testid="add-note"
                                onClick={() => dispatch({ type: OPEN_DIALOG, payload: true })}
                            >
                                <AddIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {t("addNotes")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {notes &&
                    notes.map((note: INote, index: number) => (
                        <Grid item key={index} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={5} sm={2}>
                                    <Typography variant="body2">{renderDate(note.ts)}</Typography>
                                </Grid>
                                <Grid item xs={7} sm={4}>
                                    <Typography variant="body2" noWrap title={renderAuthor(note)}>
                                        {renderAuthor(note)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <DescriptionIcon color="primary" />
                                        </Grid>
                                        <Grid item xs>
                                            {/* <Typography variant="body1">
                        {note.description}
                      </Typography> */}
                                            {note.description.split("\n").map((line, key) => {
                                                return (
                                                    <Typography key={key} variant="body1" style={{ height: !line ? 20 : "auto" }}>
                                                        {line}
                                                    </Typography>
                                                );
                                            })}
                                            <Box display="flex" justifyContent="flex-end">
                                                {note.authorId === currentUser.userDetails.id ? (
                                                    <Button
                                                        color="primary"
                                                        endIcon={<EditIcon />}
                                                        variant="text"
                                                        onClick={() => dispatch({ type: SELECT_NOTE, payload: note })}
                                                    >
                                                        {t("edit")}
                                                    </Button>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
            </Grid>

            <DialogEditNote note={selectedNote} open={open} ownerId={ownerId} placeholder={`${t("addNotes")}...`} onClose={handleClose} />

            {!notes || !notes.length ? (
                <Grid item xs={12}>
                    <Box mt={25}>
                        <RecordsNotFound theme={theme} />
                    </Box>
                </Grid>
            ) : null}
        </Container>
    );
};

export default TabNotes;
