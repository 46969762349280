import React, {useContext, useEffect, useState} from 'react';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {mtaPolicy, pay, previewMta} from './paymentActions';
import {bind} from './../quote/quoteActions';
import './Pay.css';
import {
    AuthContext,
    CurrentStepContext,
    MetadataContext,
    PendingContext,
    PolicyContext,
    PolicyholderContext,
    ProductContext,
    SubmissionContext
} from '../common/context';
import {PayForm} from './PayForm';
import {YourQuote} from './YourQuote';
import DocLink from '../common/DocLink';
import {SummaryPanel} from '../common/SummaryPanel';
import {Dtq} from '../quote/Dtq';
import {LoadingScreen} from '../common/LoadingScreen';
import {useDefaultTranslation} from '../common/Translation';
import * as PolicyUtils from '@blocksure/blocksure-core/dist/src/utilities/PolicyUtils';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const Pay = ({amend}) => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const {metadata} = useContext(MetadataContext);
    const {
        policy,
        amendDate,
        updatePolicyBySequenceId
    } = useContext(PolicyContext);
    const {product} = useContext(ProductContext);
    const {nextStep} = useContext(CurrentStepContext);
    const {
        submission,
        updateSubmission,
        attachments
    } = useContext(SubmissionContext);
    const {auth} = useContext(AuthContext);
    const {pendingAction} = useContext(PendingContext);
    const {policyholder} = useContext(PolicyholderContext);
    const [quote, setQuote] = useState(undefined);
    const [accept, setAccept] = useState(false);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (policy && submission) {
            if (amend) {
                previewMta(policy, submission, amendDate)
                        .then(sequences => setQuote(sequences[sequences.length - 1]));
            } else {
                setQuote(policy);
            }
        }
        // eslint-disable-next-line
    }, [submission, policy && policy.sequenceId]);

    const decline = quote && (quote.status === 'DTQ' || quote.status === 'Referral');
    if (quote === undefined) return <LoadingScreen/>;

    const {
        currency,
        totalAmount,
        id: transactionId
    } = quote.transactions.length > 0 && quote.transactions[0];
    const skipPayment = PolicyUtils.shouldSkipPayment(auth, product) || !(totalAmount > 0);

    const bindAndContinue = async (paymentSourceId) => {
        try {
            // Either mta or bind the policy (payment will be skipped if the product is set up to do so).
            const sequenceId = await (amend ?
                    mtaPolicy(policy, product, submission, amendDate, paymentSourceId, metadata, i18n.language) :
                    (skipPayment ?
                            bind(t, quote, policyholder.id, product, submission, null, null, metadata, i18n.language, attachments, paymentSourceId) :
                            pay(transactionId, paymentSourceId)));
            nextStep(sequenceId);
            await updatePolicyBySequenceId(sequenceId);
            updateSubmission({});
        } catch (e) {
            setError(generateErrorMessage(e));
        }
    };

    // Mini state-machine here for DTQ, quote preview and payment details.
    let content;
    if (decline) {
        // Always render declines.
        content = <Dtq productId={product.id}/>;
    } else if (!accept) {
        let proceed = () => setAccept(true);

        // Payment is skipped if product has been set up to do so
        if (skipPayment) {
            proceed = () => pendingAction(async () => {
                await bindAndContinue();
            });
        }

        // If the user has not accepted, render a summary of the quote.
        content = <Quote quote={quote} amend={amend} proceed={() => proceed()}/>;
    } else {
        // If they have accepted, render the payment form.
        content = <PayForm
                policyholderId={quote.policyholderId}
                paymentGateway={product.paymentGateway}
                currency={currency}
                amount={totalAmount}
                back={() => {
                    setAccept(false);
                    setError(undefined);
                }}
                pay={bindAndContinue}/>;
    }
    return (
            <div className="section pay offset-md-2 col-md-8">
                {error && <Error>{error}</Error>}
                <div className="form-group field field-object">{content}</div>
            </div>
    );
};

const Quote = ({
    quote,
    amend,
    proceed
}) => {
    const {t} = useDefaultTranslation();
    const {
        previousStep,
        stepUiSchema = {}
    } = useContext(CurrentStepContext);
    const {pending} = useContext(PendingContext);
    const {product} = useContext(ProductContext);

    let {
        paySummaryItems = {},
        disclaimer = '',
        breakdownDisclaimer = '',
        links = []
    } = stepUiSchema['ui:options'] || {};
    const validPayFrequencies = product.validPayFrequencies || {};
    if (amend) links = links.filter(it => !it.url.startsWith('doc:'));

    const breakdownDisclaimerTranslated = t('payment.tx-breakdownDisclaimer', {defaultValue: breakdownDisclaimer})
    const disclaimerTranslated = t('payment.tx-disclaimer', {defaultValue: disclaimer});
    return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <YourQuote {...{
                            product,
                            quote,
                            amend,
                            validPayFrequencies
                        }} />
                    </div>
                </div>

                <div className="row">
                    {Object.keys(paySummaryItems).length > 0 && <div className="col-xs-12 offset-sm-3 col-sm-6">
                        <SummaryPanel submission={quote.submission} items={paySummaryItems} product={product}/>
                    </div>}
                    <div className="col-xs-12">
                        {breakdownDisclaimerTranslated && (
                            <div className="row">
                            <div className="col-xs-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8 disclaimer">
                                <small>{breakdownDisclaimerTranslated}</small>
                            </div>
                        </div>
                        )}
                        <hr/>
                    </div>
                </div>

                {disclaimerTranslated && (
                        <div className="row">
                            <div className="col-xs-12 offset-sm-2 col-sm-8 offset-md-2 col-md-8 disclaimer text-center">
                                <small>{disclaimerTranslated}</small>
                            </div>
                            <div className="col-xs-12">
                                <hr/>
                            </div>
                        </div>
                )}

                {links && links.length > 0 && <PayLinks {...{
                    links,
                    quote,
                    pending
                }} />}

                <div className="row gap">
                    <div className="col-xs-12 gap">
                        <Button pending={pending} className="btn btn-primary float-right" type="button" onClick={proceed}>{t('proceed')}</Button>
                        <Button disabled={pending} className="btn btn-secondary float-right" type="button" onClick={previousStep}>{t('amend')}</Button>
                    </div>
                </div>
            </div>
    );
};

const PayLinks = ({
    links,
    quote,
    pending
}) => {
    const {t} = useDefaultTranslation();
    return (
            <div className="row">
                <div className="offset-md-1 col-md-10 text-center">
                    {links.map(({
                        name,
                        text,
                        url
                    }) => {
                        const textTranslated = t(`payment.links.${name}.tx-text`, {defaultValue: text});
                        return <DocLink key={url} href={url} sequenceId={quote.sequenceId} {...{pending}} className="pay-doc-link btn btn-primary"
                                        defaultValue="">{textTranslated}</DocLink>;
                    })}
                </div>
                <div className="col-xs-12">
                    <hr/>
                </div>
            </div>
    );
};

export default Pay;
