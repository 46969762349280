import React, {useContext, useEffect, useState} from 'react';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {LocalStorageContext} from '../common/context';
import {renewal, renewalBind} from './renewalActions';
import './Renewal.css';
import {
    AuthContext,
    PendingContext,
    PolicyContext,
    ProductContext,
} from '../common/context';
import {YourQuote} from './YourQuote';
import DocLink from '../common/DocLink';
import {SummaryPanel} from '../common/SummaryPanel';
import {Dtq} from '../quote/Dtq';
import {LoadingScreen} from '../common/LoadingScreen';
import {useDefaultTranslation} from '../common/Translation';
import { PayForm } from '../pay/PayForm';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {convertToNewPath} from "../base";

const STATUSES = {
    accepted: 'ACCEPTED',
    declined: 'DECLINED',
}

const RenewalPay = () => {
    const {
        policy,
    } = useContext(PolicyContext);
    const {
        surelyncProps
    } = useContext(AuthContext);
    const {product} = useContext(ProductContext);
    const {localStorage} = useContext(LocalStorageContext)
    const history = useHistory();
    const [quote, setQuote] = useState(undefined);
    const [accept, setAccept] = useState(false);
    const [error, setError] = useState(undefined);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    useEffect(() => {
        setQuote(policy);
    }, [policy]);
    
    const decline = quote && (quote.status === 'DTQ' || quote.status === 'Referral');
    if (quote === undefined) return <LoadingScreen/>;

    const {
        currency,
        totalAmount,
    } = quote.transactions.length > 0 && quote.transactions[0];

    const generateRenewalData = (policy) => {
        const policySequenceId = policy.sequenceId;
        const previousPolicyId = policy.renewal.previousPolicyId;
        const execute = new Date().getTime();

        return {
            execute,
            policySequenceId,
            previousPolicyId,
        }
    };
    const goBack = () => {
        if (surelyncProps) {
            history.goBack();
        } else {
            history.push('/');
        }
    }
    const bindAndContinue = async (paymentSourceId) => {
        const {
            policySequenceId,
        } = generateRenewalData(policy);

        try {
            const data = await renewalBind(policy.id || policySequenceId, paymentSourceId);
            localStorage.setItem('redirect', `${newPath}/policies/${data.id}`);
            goBack();
            return data;
        } catch (e) {
            setError(generateErrorMessage(e));
        }
    };

    const declineAndContinue = async () => {
        const status = STATUSES.declined;
        const {
            execute,
            policySequenceId,
            previousPolicyId,
        } = generateRenewalData(policy);

        try {
            const sequenceId = await renewal(policySequenceId, previousPolicyId, status, execute);
            goBack();
            return sequenceId;
        } catch (e) {
            setError(generateErrorMessage(e));
        }
    }
    // Mini state-machine here for DTQ, quote preview and payment details.
    let content;
    if (decline) {
        // Always render declines.
        content = <Dtq productId={product.id}/>;
    } else if (!accept) {
        let proceed = () => setAccept(true);
        let declined = () => {declineAndContinue()};
        // If the user has not accepted, render a summary of the quote.
        content = <Quote declined={declined} quote={quote} proceed={() => proceed()}/>;
    } else {
        // If they have accepted, render the payment form.
        content = <PayForm
                policyholderId={quote.policyholderId}
                paymentGateway={product.paymentGateway}
                currency={currency}
                amount={totalAmount}
                back={() => {
                    setAccept(false);
                    setError(undefined);
                }}
                pay={bindAndContinue}/>;
    }
    return (
            <div className="section pay offset-md-3 col-md-6">
                {error && <Error>{error}</Error>}
                <div className="form-group field field-object">{content}</div>
            </div>
    );
};

const Quote = ({
    declined,
    quote,
    proceed
}) => {
    const {t} = useDefaultTranslation();
    const {pending} = useContext(PendingContext);
    const {product, submissionUiSchema} = useContext(ProductContext);

    if (!submissionUiSchema && !product) return null;

    let {
        paySummaryItems = {},
        disclaimer = '',
        links = []
    } = submissionUiSchema?.renewal['ui:options'] || {};

    const disclaimerTranslated = t('payment.tx-disclaimer', {defaultValue: disclaimer});

    return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        {
                            product && <YourQuote {...{
                                product,
                                quote,
                            }} />
                        }
                    </div>
                </div>

                <div className="row">
                    {Object.keys(paySummaryItems).length > 0 && <div className="col-10 offset-1">
                        <SummaryPanel submission={quote.submission} items={paySummaryItems} product={product}/>
                    </div>}
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                </div>

                {disclaimerTranslated && (
                        <div>
                            <div className="text-center">
                                <small>{disclaimerTranslated}</small>
                            </div>
                            <div className="col-xs-12">
                                <hr/>
                            </div>
                        </div>
                )}

                {links && links.length > 0 && <PayLinks {...{
                    links,
                    quote,
                    pending
                }} />}

                <div className="row gap">
                    <div className="col-xs-12 gap">
                        <Button pending={pending} className="btn btn-primary float-right" type="button" onClick={proceed}>{t('proceed')}</Button>
                        <Button pending={pending} className="btn btn-secondary float-right" type="button" onClick={declined}>{t('decline')}</Button>
                    </div>
                </div>
            </div>
    );
};

const PayLinks = ({
    links,
    quote,
    pending
}) => {
    const {t} = useDefaultTranslation();
    return (
            <div className="row">
                <div className="col-md-12 text-center gap">
                    {links.map(({
                        name,
                        text,
                        url
                    }) => {
                        const textTranslated = t(`payment.links.${name}.tx-text`, {defaultValue: text});
                        return <DocLink key={url} href={url} sequenceId={quote.sequenceId} {...{pending}} className="pay-doc-link btn btn-primary"
                                        defaultValue="">{textTranslated}</DocLink>;
                    })}
                </div>
                <div className="col-xs-12">
                    <hr/>
                </div>
            </div>
    );
};

export default RenewalPay;
