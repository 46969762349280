import React from "react";
import { CurrencyUtils, i18n, localeService } from "@surelync/common";
import { CellInfo } from "react-table";
import Typography from "@material-ui/core/Typography";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const headerColumnsI18 = [
    {
        Header: () => i18n.t("dateDue"),
        accessor: "due",
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => i18n.t("dateCollected"),
        accessor: "paid",
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDateTime(row.value) : null),
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => i18n.t(row.value.toLowerCase()),
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        Cell: (row: CellInfo) => row.value,
    },
    {
        Header: () => i18n.t("totalAmount"),
        accessor: "totalAmount",
        Cell: (row: CellInfo) => (
            <Typography color={row.value < 0 ? "error" : "textSecondary"}>
                {CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}
            </Typography>
        ),
    },
    {
        Header: () => i18n.t("creationDate"),
        accessor: "creation",
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
];
