import React, {Component} from 'react';
import './dropzone.css';
import uploadLogo from './assets/upload.svg';

class Dropzone extends Component { // class for dropzone
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this); // reference to the dialogue
        this.onFilesAdded = this.onFilesAdded.bind(this); // means that on files being added, the function is called with the object as the parameter
        // it sets the onFiles added property of the object to the result fo that function
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    onFilesAdded(evt) { // when files are added
        if (this.props.disabled) return; // do nothing if disabled
        const files = evt.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files); // adds files to the array
            this.props.onFilesAdded(array); // adds the array of files to the properties
        }
    }

    fileListToArray(list) { // turns the files list to a regular array
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    onDragOver(evt) { // does nothing if disabled, if dragged over it will highlight the component
        evt.preventDefault();

        if (this.props.disabled) return;

        this.setState({hightlight: true});
    }

    onDragLeave() { // unhighlights when dragging over leaves
        this.setState({hightlight: false});
    }


    onDrop(event) { // when files are dropped
        event.preventDefault();
        if (this.props.disabled) return; // do nothing if disabled

        const files = event.dataTransfer.files; // sets the files variable as files being transferred
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array); // adds the array to the properties of this event if files are added
        }
        this.setState({hightlight: false});
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click(); // clicks the invisable element that opens the file selection input
    }

    render() { // rendering for the component
        return (<div
            className={'Dropzone'}// ${this.state.hightlight ? 'highlight' : ''}`} //dropzone http component
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
            style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
        >
            <img
                alt="upload"
                className="Icon"
                src={uploadLogo}
            />
            <input
                ref={this.fileInputRef}
                className="FileInput"
                type="file"
                multiple
                onChange={this.onFilesAdded}
            />
            <span>Upload Files</span>
        </div>
        );
    }
}

export default Dropzone;
