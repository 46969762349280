import { IPermission, isNotHiddenPermissions, TranslateUtils } from "@surelync/common";
import sortBy from "lodash/sortBy";

export const setDataPermissions = (permissions: IPermission[]) => {
    const result = [];

    permissions.filter(isNotHiddenPermissions).forEach((permission) => {
        result.push({
            name: permission.name,
            id: permission.id,
            description: TranslateUtils.translateAltValue(permission.name, permission.description),
            addButton: true,
        });
    });

    return sortBy(result, "name");
};
