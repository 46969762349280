import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { BackButton, BannerClientLogo, PageContainer } from "../components";
import { useTranslation } from "react-i18next";
import SureApp from "@blocksure/sureapp";
import { MessageBox, namespacedLocalStorage, PolicyholderUtils } from "@surelync/common";
import { PolicyApiClient, PolicyholderApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./styles.css";
import { useTheme } from "@material-ui/core";

const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
const envJSON = (window as any).env;

interface IProps {
    titleKey: string;
}

const PolicyMTA: React.FC<IProps> = ({titleKey}) => {
    const [error, setError] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [policyholder, setPolicyholder] = useState(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const { policyId } = useParams<{ policyId: string }>();

    useEffect(() => {
        const fetchData = async () => {
            setFetching(true);

            try {
                const sequence = await policyApiClient.getPolicyBySequenceId(policyId);
                const policyholder = await policyholderApiClient.getPolicyholder(sequence.policyholderId);

                setPolicyholder(policyholder);
            } catch (error) {
                setError(generateErrorMessage(error));
            }

            setFetching(false);
        };

        fetchData();
    }, [policyId]);

    const renderTitle = useMemo(
        () => (policyholder ? `${PolicyholderUtils._renderFullName(policyholder)} - ${t(titleKey)}` : ""),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [policyholder]
    );

    /*
    FIXME @surelync/main Error: application '@surelync/main' died in status LOADING_SOURCE_CODE: Module parse failed
    If @blocksure/sureapp/dist/app.css include Bootstrap

    Maybe this is solution: https://medium.com/@harith.sankalpa/how-to-load-css-or-sass-styles-into-single-spa-framework-using-style-loader-7d5937fc1c53
  */
    useEffect(() => {
        const head = document.head;
        const linkBootstrap = document.createElement("link");
        const linkFontawesome = document.createElement("link");

        linkBootstrap.type = "text/css";
        linkBootstrap.rel = "stylesheet";
        linkBootstrap.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
        linkBootstrap.integrity = "sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l";
        linkBootstrap.crossOrigin = "anonymous";

        linkFontawesome.type = "text/css";
        linkFontawesome.rel = "stylesheet";
        linkFontawesome.href = "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
        linkFontawesome.integrity = "sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p";
        linkFontawesome.crossOrigin = "anonymous";

        head.appendChild(linkBootstrap);
        head.appendChild(linkFontawesome);

        return () => {
            try {
                head.removeChild(linkBootstrap);
                head.removeChild(linkFontawesome);
            } catch (error) {
                console.warn(error);
            }
        };
    }, []);

    return (
        <>
            <PageContainer title={renderTitle} fetching={fetching}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={8} md={5}>
                                <Box mb={1}>
                                    <BackButton />
                                </Box>

                                {policyholder ? (
                                    <>
                                        <Typography variant="h5">{renderTitle}</Typography>
                                        <Typography color="textPrimary" variant="body2">
                                            {`${t("id")} - ${policyholder.id}`}
                                        </Typography>
                                    </>
                                ) : null}
                            </Grid>

                            <Grid item xs={6} sm={4} md={2}>
                                <Box textAlign={{ xs: "right", md: "center" }}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} />
                        </Grid>
                    </Grid>

                    {error ? (
                        <Grid item xs={12} md={6}>
                            <MessageBox message={error} theme={theme} variant="error" />
                        </Grid>
                    ) : null}

                    {!error && policyholder ? (
                        <Grid item xs={12}>
                            <SureApp surelyncProps={{ appName: "Surelync", env: envJSON, policyholder }} />
                        </Grid>
                    ) : null}
                </Grid>
            </PageContainer>
        </>
    );
};

export default PolicyMTA;
