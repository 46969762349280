import React, {useEffect, useState} from 'react';
import {ProductApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {TradingGroupContext} from '../common/context';
import ThemeRoute from '../widgets/ThemeRoute';
import {SureAppName} from '../base';
import {loadAttachments} from '../common/Attachments';
import i18next from 'i18next';
import Loading from '../widgets/Loading';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';

const GENERATED_DESCRIPTIONS = [
    'Generated Schema',
    'JSON schema for the product\'s submission'
];
const productApiClient = new ProductApiClient(SureAppName);

const defaultTradingGroup = (products, skin, productIdByAlias) => ({
    skin,
    tradingId: 'sureapp',
    tradingName: 'All Products',
    productIds: Object.keys(products),
    productIdByAlias: productIdByAlias
});

const attachments = {
    logo: {
        name: 'logo.png',
        urlOnly: true
    }
};

async function getAttachment(group, name: string) {
    const attachment = group.attachments.find(it => it.name === name);
    return attachment && await productApiClient.getProductGroupAttachment(group.id, attachment.hash);
}

async function getAttachmentUrl(group, name: string) {
    const attachment = group.attachments.find(it => it.name === name);
    return attachment && await productApiClient.getProductGroupAttachmentUrl(group.id, attachment.hash);
}

function getProductsSet(items) {
    return items.reduce((cache, product) => {
        cache[product.id] = product;
        return cache;
    }, {});
}

export const TradingGroupProvider = ({
    children,
    tradingId,
    surelyncProps
}) => {
    const [group, setGroup] = useState(null);
    const [{
        logo,
        formulator
    }, setAttachments] = useState({});
    const [products, setProducts] = useState(null);

    const currentLanguages = i18next.languages;

    async function refreshGroup() {
        let name = tradingId.indexOf('/') === 0 ? tradingId.substring(1) : tradingId;
        let products;
        const productGroups = await productApiClient.getProductGroups(true).then(({productGroups}) => productGroups.map(it => it.tradingId))
        if (!productGroups.includes(name)) {
            const response = await productApiClient.search({
                'load.attachments': true,
                'load.payment': true
            });
            products = getProductsSet(response.items);
            Object.values(products)
                    .filter(it => {
                        const threshold = FormatUtils.minusToday(1, 'hours');
                        const hasGeneratedAttachment = it.hiddenAttachments.filter(att => GENERATED_DESCRIPTIONS.includes(att.description)).length > 0;
                        const hasNoSchema = it.hiddenAttachments.filter(att => att.name === 'submissionSchema.json').length === 0;

                        return hasNoSchema || (hasGeneratedAttachment && FormatUtils.parseDate(it.ts) < threshold);
                    })
                    .forEach(it => delete products[it.id]);
            setGroup(defaultTradingGroup(products, name, new Map()));
            setProducts(products);
        } else {
            const group = await productApiClient.getProductGroup(name);

            const [logo, favIcon, formulator, ...languageBundles] = await Promise.all([
                getAttachmentUrl(group, 'logo.png'),
                getAttachmentUrl(group, 'favicon.png'),
                getAttachment(group, 'formulator.json')
            ].concat(!currentLanguages ? [] : currentLanguages.map(async language => {
                const bundle = await getAttachment(group, `translation-${language}.json`);
                return {
                    language,
                    bundle
                };
            })));

            languageBundles.forEach(({
                language,
                bundle
            }) => {
                i18next.addResourceBundle(language, 'group', {...bundle});
            });

            setGroup(group);
            if (formulator.title) document.title = formulator.title;
            setAttachments({
                logo,
                formulator
            });
            setFavIcon(favIcon);
            const response = await productApiClient.search({
                'load.attachments': true,
                'load.payment': true,
                productIds: Object.values(group.productIdByAlias)
            });
            products = getProductsSet(response.items);
        }
        // Attach various attachments to the product.
        Promise.all(Object.values(products)
                .map(async product => {
                    let promise = loadAttachments(product, attachments);
                    const {logo} = await promise;
                    product.logo = logo;
                }));
        setProducts(products);
    }

    useEffect(() => {
        refreshGroup();
        // eslint-disable-next-line
    }, [tradingId, currentLanguages]);

    if (!group) return <Loading/>;
    return (
            <TradingGroupContext.Provider value={{
                group,
                products,
                logo,
                formulator
            }}>
                <ThemeRoute setTheme={group.skin} surelyncProps={surelyncProps}>
                    {children}
                </ThemeRoute>
            </TradingGroupContext.Provider>
    );
};

function setFavIcon(url) {
    let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
    if (!link) {
        link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    }
    link.href = url;
}
