import React from "react";
import { Link } from "react-router-dom";
import { CurrencyUtils, EIdentityType, i18n, localeService, PlaceHolder } from "@surelync/common";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const getHeaderColumnsI18 = (onRedirect: (url: string) => any) => [
    {
        Header: () => {
            return <div>{i18n.t("typeOfFee")}</div>;
        },
        accessor: "typeOfFee",
    },
    {
        Header: () => {
            return <div>{i18n.t("policy")}</div>;
        },
        accessor: "policy",
        Cell: (row: CellInfo) => {
            return (
                <div>
                    {row.value === "-" ? (
                        <div>{row.value}</div>
                    ) : (
                        <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.policyId}`)} variant="body1">
                            {row.value}
                        </LinkButton>
                    )}
                </div>
            );
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("claim")}</div>;
        },
        accessor: "claim",
        Cell: (row: CellInfo) => {
            return (
                <div>
                    {row.value === "-" ? (
                        <div>{row.value}</div>
                    ) : (
                        <LinkButton component={Link} to={onRedirect(`/claims/${row.row.original.claimId}`)} variant="body1">
                            {row.value}
                        </LinkButton>
                    )}
                </div>
            );
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("dateFeeCharged")}</div>;
        },
        accessor: "dateFeeCharged",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDate(row.value) : PlaceHolder),
    },
    {
        roles: [EIdentityType.Blocksure, EIdentityType.Broker],
        Header: () => {
            return <div>{i18n.t("adminFeeAmount")}</div>;
        },
        accessor: "adminFeeAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        roles: [EIdentityType.Insurer, EIdentityType.MGA],
        Header: () => {
            return <div>{i18n.t("totalDeductions")}</div>;
        },
        accessor: "totalDeductions",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        roles: [EIdentityType.MGA],
        Header: () => {
            return <div>{i18n.t("mgaCommission")}</div>;
        },
        accessor: "mgaCommission",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        roles: [EIdentityType.MGA],
        Header: () => {
            return <div>{i18n.t("grossBrokerage")}</div>;
        },
        accessor: "grossBrokerage",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("currency")}</div>;
        },
        accessor: "currency",
    },
];
