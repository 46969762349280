import React, {useContext} from 'react';
import {ProductApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';
import {ProductContext} from './context';
import {useDefaultTranslation} from './Translation';

const productApiClient = new ProductApiClient(SureAppName);

// Renders a link to product attachments and documentation.
const DocLink = ({
    href,
    children,
    product,
    sequenceId,
    pending,
    className,
    defaultValue
}) => {
    const {i18n} = useDefaultTranslation();
    const {product: productOrDefault} = useContext(ProductContext) || {product};

    // Link to one of the templated documentation items.
    if (href.startsWith('doc:')) {
        if (sequenceId == null) return defaultValue === undefined ? children : defaultValue;
        href = `/policy/api/v1/${sequenceId}?format=Pdf&docType=${href.slice(4)}&app-name=${SureAppName}&lng=${i18n.language}&product=${productApiClient.product}`;
    }

    // Link to something attached to the product.
    if (href.startsWith('attachment:')) {
        const {staticAttachments = [], dynamicDocumentTemplates = [], hiddenAttachments = []} = productOrDefault || {};
        const localAttachments = [...dynamicDocumentTemplates, ...hiddenAttachments, ...staticAttachments];
        if (!localAttachments.length) return defaultValue === undefined ? children : defaultValue;
        const matching = localAttachments.filter(it => it.name === href.slice(11));
        if (matching.length === 0) return defaultValue === undefined ? children : defaultValue;
        href = productApiClient.getAttachmentUrl(productOrDefault.id, matching[0].location, SureAppName);
    }

    return (
            <a href={href}
               className={`${className || ''} ${(!href || pending) ? 'disabled' : ''} doc-link`}
               rel="noopener noreferrer"
               target="_blank">{children}</a>
    );
};

export default DocLink;
