import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, Grid, Hidden, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { PaymentApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { CurrencyUtils, history, ISettlementStatement, ITableData, localeService, MessageBox, RecordsNotFound, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataSettlementStatement } from "./helper";
import { useStyles } from "./styles";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, ConfirmDialog, PageContainer } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";

const rowsPerPageOptions = [50, 100, 250, 500];

const SupportPage: React.FC = () => {
    const { currentUser, namespacedLocalStorage } = useContext(GlobalContext);
    const { party } = currentUser.authDetails;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const settlementsRef = useRef<ISettlementStatement[] | null>(null);
    const [totalSum, setTotalSum] = useState<number>(0);
    const [totalCollectedSum, setTotalCollectedSum] = useState<number>(0);
    const [totalCollectedNumber, setTotalCollectedNumber] = useState<number>(0);
    const [showPending, setShowPending] = useState<boolean>(false);
    const [stripeFee, setStripeFee] = useState<number>(0);
    const [selectedNumber, setSelectedNumber] = useState<number>(0);
    const [totalSumSelected, setTotalSumSelected] = useState<number>(0.0);
    const [, setTotalNumber] = useState<number>(0);
    const [settlementsSelected, setSettlementsSelected] = useState([]);
    const selectedRowsRef = useRef([]);
    const [message, setMessage] = useState<string>();
    const [messageType, setMessageType] = useState<"warning" | "error">();
    const [drawdownButton, setDrawdownButton] = useState<boolean>();
    const selectAllRef = useRef(false);
    const [error, setError] = useState<string>(null);
    const [isConfirm, setIsConfirm] = useState(false);

    const paymentApiClient = new PaymentApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const calculateTotals = () => {
        const totalNumber = selectedRowsRef.current.length;
        let selectedNumber = 0;
        let totalSumSelected = 0.0;
        const settlementsSelected = [];
        selectedRowsRef.current.forEach((checkbox) => {
            if (new Date(checkbox.available) < new Date()) {
                totalSumSelected += +checkbox.netAmount;
                selectedNumber += 1;
                settlementsSelected.push(checkbox.id);
            }
        });

        setSettlementsSelected(settlementsSelected);
        setTotalSumSelected(totalSumSelected);
        setTotalNumber(totalNumber);
        setSelectedNumber(selectedNumber);

        if (totalNumber) {
            setStripeFee(CurrencyUtils.getStripeFee(selectedRowsRef.current[0]));
        }
    };

    const handleConfirm = (enable: boolean) => {
        setIsConfirm(false);

        if (enable) {
            drawDown();
        }
    };

    const handleSelectedRow = (row) => {
        if (selectedRowsRef.current.find((item) => item.id === row.id)) {
            const filteredRow = selectedRowsRef.current.filter((item) => item.id !== row.id);
            selectedRowsRef.current = filteredRow;
        } else {
            const newSelected = settlementsRef.current.find((settlement) => settlement.id === row.id);
            selectedRowsRef.current = [...selectedRowsRef.current, newSelected];
        }

        selectAllRef.current = selectedRowsRef.current.length === settlementsRef.current.length;
        calculateTotals();
    };

    const gridReady = (rows) => {
        const totalCollectedNumber = rows.length;
        let totalSum = 0.0;
        let totalCollectedSum = 0.0;
        rows.forEach((checkbox) => {
            if (new Date(checkbox.available) < new Date()) {
                totalCollectedSum += +checkbox.netAmount;
            }
            totalSum += checkbox.netAmount;
        });

        setTotalSum(totalSum);
        setTotalCollectedNumber(totalCollectedNumber);
        setTotalCollectedSum(totalCollectedSum);
    };

    const handleSelectedAllRows = () => {
        selectedRowsRef.current = selectAllRef.current ? [] : settlementsRef.current;
        selectAllRef.current = !selectAllRef.current;
        calculateTotals();
    };

    const fetchSettlements = async (showPending: boolean) => {
        setLoading(true);
        const searchCriteria = Object.assign({ statuses: "Paid", parties: party });
        if (!showPending) searchCriteria.availableMax = Date.now();
        try {
            const settlements = await paymentApiClient.search(searchCriteria);

            settlementsRef.current = settlements.items;
            setTotal(settlements.total);
            gridReady(settlements.items);
        } catch (error) {
            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    const fetchData = (rowsPerPage: number, page: number) => {
        if (!settlementsRef.current || !settlementsRef.current.length) {
            return;
        }
        const offset = page * rowsPerPage;
        const dataSettlementStatement = setDataSettlementStatement(settlementsRef.current.slice(offset, offset + rowsPerPage));

        if (!isMountRef.current) {
            return;
        }

        setData(dataSettlementStatement);
    };

    useLayoutEffect(() => {
        if (settlementsRef.current) {
            const headerColumnsI18 = getHeaderColumnsI18(selectAllRef, selectedRowsRef, handleSelectedRow, handleSelectedAllRows);
            calculateTotals();
            setHeaderColumns(headerColumnsI18);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settlementsRef.current]);

    useLayoutEffect(() => {
        const updateData = async () => {
            await fetchSettlements(showPending);
            fetchData(rowsPerPageOptions[0], 0);
        };

        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPending]);

    const drawDown = async () => {
        setDrawdownButton(true);

        try {
            const batch = await paymentApiClient.settle(settlementsSelected);
            history.push(`/statement-details/${batch.id}`);
        } catch (error) {
            console.error(error);
            setMessageType("error");
            setMessage(generateErrorMessage(error));
            setDrawdownButton(false);
        }
    };

    const showConfirm = () => {
        let message = "";
        if (settlementsSelected.length > 0) {
            if (totalSumSelected < 1) {
                setMessageType("warning");
                message = t("ensureTotalGt1");
            } else {
                setIsConfirm(true);
            }
        } else {
            setMessageType("warning");
            message = t("noSettlementSelected");
        }

        setMessage(message);
    };

    const totalAccountBalance = totalCollectedNumber !== 0 ? parseFloat(totalSum.toString()) : 0;

    const fee = Number(parseFloat(stripeFee.toString()).toFixed(2));
    const totalLessSettlementFee = selectedNumber !== 0 ? Number(parseFloat(totalSumSelected.toString())) - fee : -fee;

    const paymentsNotYetAvailableToSettle = parseFloat(totalSum.toString()) - parseFloat(totalCollectedSum.toString());

    return (
        <PageContainer title={t("settlements")} fetching={!settlementsRef.current}>
            {settlementsRef.current ? <div data-testid="settlements" /> : null}
            {totalCollectedSum ? <div data-testid="totalCollectedSum" /> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("settlements")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                {!error && (
                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <Grid item sm={12} md={10} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{t("paymentsNotAvailableToSettle")}</Typography>
                                                <Typography variant="body2">{t("paymentsAvailableToSettle")}</Typography>
                                                <Typography variant="body2">{t("accountBalanceTotal")}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" align="right">
                                                    {CurrencyUtils._renderCurrency(paymentsNotYetAvailableToSettle, localeService.locale)}
                                                </Typography>
                                                <Typography variant="body1" align="right">
                                                    {CurrencyUtils._renderCurrency(totalCollectedSum, localeService.locale)}
                                                </Typography>
                                                <Typography variant="body1" align="right">
                                                    {CurrencyUtils._renderCurrency(totalAccountBalance, localeService.locale)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                                <Typography variant="body2">{t("showPaymentsNotYetAvailable")}</Typography>
                                                <Typography variant="body2">{t("numberOfItemsShown")}</Typography>
                                                <Typography variant="body2">{t("totalValueOfItemsShown")}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Checkbox
                                                        color="default"
                                                        size="small"
                                                        checked={showPending}
                                                        onChange={() => setShowPending(!showPending)}
                                                        className={classes.checkboxCustom}
                                                        disableRipple
                                                    />
                                                </Box>
                                                <Typography variant="body1" align="right">
                                                    {totalCollectedNumber}
                                                </Typography>
                                                <Typography variant="body1" align="right">
                                                    {CurrencyUtils._renderCurrency(totalSum, localeService.locale)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {settlementsRef.current && (
                        <TableContainer
                            columns={headerColumns}
                            data={data}
                            loading={loading}
                            rowsPerPageOptions={rowsPerPageOptions}
                            theme={theme}
                            total={total}
                            fetchData={fetchData}
                        />
                    )}
                </Grid>
                {!loading && data && data.length === 0 && (
                    <Grid item xs={12}>
                        <RecordsNotFound theme={theme} />
                    </Grid>
                )}
                {error ? (
                    <Grid item xs={12} md={8}>
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                    </Grid>
                ) : null}
                {!error && (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={12} lg={6}>
                                {message ? <MessageBox message={message} theme={theme} variant={messageType} onClose={() => setMessage(null)} /> : null}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <Typography variant="body2">{t("totalSelected")}</Typography>
                                        <Typography variant="body2">{t("numberOfItemsSelected")}</Typography>
                                        <Hidden xsUp={!selectedNumber}>
                                            <Typography variant="body2">{t("settlementFee")}</Typography>
                                            <Typography variant="body2">{t("totalLessSettlementFee")}</Typography>
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" align="right">
                                            {CurrencyUtils._renderCurrency(
                                                totalSumSelected,
                                                localeService.locale,
                                                getSettlementCurrency(selectedRowsRef.current[0])
                                            )}
                                        </Typography>
                                        <Typography variant="body1" align="right">
                                            {selectedNumber}
                                        </Typography>
                                        <Hidden xsUp={!selectedNumber}>
                                            <Typography variant="body1" align="right">
                                                {CurrencyUtils._renderCurrency(
                                                    stripeFee,
                                                    localeService.locale,
                                                    getSettlementCurrency(selectedRowsRef.current[0])
                                                )}
                                            </Typography>
                                            <Typography variant="body1" align="right">
                                                {CurrencyUtils._renderCurrency(
                                                    totalLessSettlementFee,
                                                    localeService.locale,
                                                    getSettlementCurrency(selectedRowsRef.current[0])
                                                )}
                                            </Typography>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={showConfirm} disabled={drawdownButton}>
                                    {t("settleToBank")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <ConfirmDialog
                open={isConfirm}
                title={t("settleToBank")}
                message={`${t("confirmMove2Bank", {
                    amount: CurrencyUtils._renderCurrency(totalSumSelected, localeService.locale, getSettlementCurrency(selectedRowsRef.current[0])),
                })}?`}
                onClose={handleConfirm}
            />
        </PageContainer>
    );
};

export default SupportPage;

function getSettlementCurrency(settlement: ISettlementStatement) {
    return settlement && settlement.currency;
}
