import React, {useEffect} from 'react';
import {PolicyholderContext} from '../common/context';
import {PolicyListProvider} from '../policy/PolicyListProvider';
import Header from '../widgets/Header';
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import HomeWithAuth from '../home/HomeWithAuth';
import {CoverMenu} from '../policy/CoverMenu';
import {QuoteFlow} from '../quote/QuoteFlow';
import {PolicyMenu} from '../policy/PolicyMenu';
import {KnowMyPolicy} from '../policy/KnowMyPolicy';
import {Payments} from '../pay/Payments';
import {Transaction} from '../transaction/Transaction';
import {AmendMenu} from '../policy/AmendMenu';
import {AmendFlow} from '../amend/AmendFlow';
import ClaimMenu from '../claim/ClaimMenu';
import {ClaimFlow} from '../claim/ClaimFlow';
import {ClaimInfo} from '../claim/ClaimInfo';
import {ClaimHistory} from '../claim/ClaimHistory';
import {ClaimListProvider} from '../claim/ClaimListProvider';
import {ClaimTerms} from '../claim/ClaimTerms';
import {ClaimDate} from '../claim/ClaimDate';
import ClaimSummary from '../claim/ClaimSummary';
import {AmendEmail} from '../amend/AmendEmail';
import {AmendUpdateEmail} from '../amend/AmendUpdateEmail';
import {AmendConfirmEmail} from '../amend/AmendConfirmEmail';
import {RenewalScreen} from '../renewal/RenewalScreen';
import { RenewalListScreen } from '../renewal/RenewalListScreen';
import {convertToNewPath} from "../base";

export const AuthenticatedRoutes = (props) => {
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);
    const history = useHistory();
    useEffect(() => {
        history.listen((location) => {
            window.ga('set', 'page', location.pathname + location.search);
            window.ga('send', 'pageview');
        });
    }, [history]);

    return (
            <PolicyholderContext.Consumer>{({policyholder}) => (
                    <PolicyListProvider policyholder={policyholder}>
                        <ClaimListProvider policyholder={policyholder}>
                            <div>
                                {!global.embedded && <Header/>}
                                <Switch>
                                    <Route path={`${newPath}/`} component={HomeWithAuth} exact/>
                                    <Route path={`${newPath}/claim`} component={ClaimMenu} exact/>
                                    <Route path={`${newPath}/claim/info`} component={ClaimInfo} exact/>
                                    <Route path={`${newPath}/claim/history`} component={ClaimHistory} exact/>
                                    <Route path={`${newPath}/claim/terms`} component={ClaimTerms} exact/>
                                    <Route path={`${newPath}/claim/date`} component={ClaimDate} exact/>
                                    <Route path={`${newPath}/claim/summary/:claimId`} component={ClaimSummary}/>
                                    <Route path={`${newPath}/claim/:sequenceId?/:step?`} component={ClaimFlow}/>
                                    <Route path={`${newPath}/quote`} component={CoverMenu} exact/>
                                    <Route path={`${newPath}/quote/:productOrSequenceId/:step?`} component={QuoteFlow}/>
                                    <Route path={`${newPath}/policy`} component={PolicyMenu} exact/>
                                    <Route path={`${newPath}/policy/:sequenceId`} component={KnowMyPolicy} exact/>
                                    <Route path={`${newPath}/email`} component={AmendEmail} exact/>
                                    <Route path={`${newPath}/email/:policyholderId`} component={AmendUpdateEmail} exact/>
                                    <Route path={`${newPath}/email/:policyholderId/confirm`} component={AmendConfirmEmail} exact/>
                                    <Route path={`${newPath}/payment`} component={Payments} exact/>
                                    <Route path={`${newPath}/payment/:sequenceId(seq_[A-Za-z0-9]+)`} component={Payments} exact/>
                                    <Route path={`${newPath}/payment/:transactionId(tra_[A-Za-z0-9]+)`} component={Transaction} exact/>
                                    <Route path={`${newPath}/amend`} component={AmendMenu} exact/>
                                    <Route path={`${newPath}/amend/policy`} component={PolicyMenu} exact/>
                                    <Route path={`${newPath}/amend/:productOrSequenceId/:step?`} component={AmendFlow}/>
                                    <Route path={`${newPath}/renewal`} component={RenewalListScreen} exact/>
                                    <Route path={`${newPath}/renewal/:sequenceId(seq_[A-Za-z0-9]+)`} component={RenewalScreen} exact/>
                                    <Redirect to={`${newPath}/`}/>
                                </Switch>
                            </div>
                        </ClaimListProvider>
                    </PolicyListProvider>
            )}</PolicyholderContext.Consumer>
    );
};
