import "regenerator-runtime/runtime";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { RoleApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { generateLanguageOptions, ITableData, localeService, MessageBox, SelectCustom, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataProfiles } from "./helper";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, PageContainer } from "../components";
import { Box, Divider } from "@material-ui/core";
import { getHeaderColumnsI18 } from "./columns.config";

const ProfilePage: React.FC = () => {
    const { currentUser, locale, namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const roleApiClient = new RoleApiClient(namespacedLocalStorage);

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);
        try {
            const { roles } = await roleApiClient.listRoles();
            const headerColumnsI18 = getHeaderColumnsI18();
            const dataProfiles = setDataProfiles(roles, [currentUser.userDetails]);

            if (!isMountRef.current) {
                return;
            }

            setData(dataProfiles);
            setHeaderColumns(headerColumnsI18);
        } catch (error) {
            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    const handleLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        localeService.setLocale(event.target.value);
    };
    
    const optionsLang = generateLanguageOptions(t);

    return (
        <PageContainer title={t("accountSettings")}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("accountSettings")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} xl={2}>
                            <Typography variant="body2">{t("email")}</Typography>
                            <Typography variant="body1">{currentUser.userDetails.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Typography variant="body2">{t("firstName")}</Typography>
                            <Typography variant="body1">{currentUser.userDetails.firstName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Typography variant="body2">{t("lastName")}</Typography>
                            <Typography variant="body1">{currentUser.userDetails.lastName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <SelectCustom
                        data-testid="user-lang"
                        label={t("language")}
                        options={optionsLang}
                        theme={theme}
                        value={locale}
                        onChange={handleLangChange}
                    />
                </Grid>

                {currentUser.userDetails.roles ? (
                    <>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{t("roles")}</Typography>
                        </Grid>

                        {error ? (
                            <Grid item xs={12} md={8} lg={6} xl={4}>
                                <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                            </Grid>
                        ) : null}

                        <Grid item xs={12} lg={5}>
                            <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </PageContainer>
    );
};

export default ProfilePage;
