import { i18n, IDrawdown } from "@surelync/common";

export const setDataDrawdowns = (data: IDrawdown[]) => {
    return data.map((item) => ({
        currency: item.currency,
        grossAmount: item.amount,
        drawdownId: item.id,
        stripeFee: item.paymentFee,
        state: i18n.t(item.state.toLowerCase()),
        externalRef: item.externalReference,
        dateSettled: item.settled,
    }));
};
