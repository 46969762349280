import React, {useContext, useState} from 'react';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {ResetPasswordComponent} from '@blocksure/blocksure-core/dist/src/components';
import {convertToNewPath, SureAppName} from '../base';
import {Screen} from '../shared/Screen';
import {AuthContext, PendingContext, PolicyholderContext} from '../common/context';
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

export const AmendUpdateEmail = (match) => (
        <Screen>
            <AuthContext.Consumer>{({
                login,
                updateAuthEmail,
                hasPassword
            }) => (
                    <ConfirmAmendEmailScreen match={match} login={login} updateAuthEmail={updateAuthEmail} hasPassword={hasPassword}/>
            )}</AuthContext.Consumer>
        </Screen>
);

const ConfirmAmendEmailScreen = ({
    match,
    login,
    updateAuthEmail,
    hasPassword
}) => {
    const history = useHistory();
    const {t} = useDefaultTranslation();
    const [updatedEmail] = useState(sessionStorage.getItem('com.blocksure.updatedEmail'));
    const {
        policyholder,
        updatePolicyholder
    } = useContext(PolicyholderContext);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const [error, setError] = useState(false);
    const [pwd, setPassword] = useState(null);
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);

    const saveChanges = async () => {
        pendingAction(async () => {
            const productId = policyholder.local['com.blocksure.initialProductId'];
            try {
                if (hasPassword) {
                    const email = policyholder.primaryEmailAddress;
                    await login(email, pwd, true);
                }
                await updatePolicyholder({}, {}, productId, updatedEmail);
                await updateAuthEmail(updatedEmail, pwd);
                sessionStorage.removeItem('com.blocksure.updatedEmail');
                history.push(`${newPath}/email/${policyholder.id}/confirm`);
            } catch (e) {
                console.error('Unable to authenticate.', e);
                setError(generateErrorMessage(e, t('invalidPassword')));
            }
        });
    };

    const onChange = (password) => {
        setError(null);
        setPassword(password);
    };

    const resetChange = () => {
        setPassword(null);
    };

    return (
            <div className="menu">
                {!hasPassword ?
                        <div>
                            <ResetPasswordComponent t={t} onComplete={saveChanges} onPasswordChange={onChange} appName={SureAppName}
                                                    loginPath={`${newPath}/`} onEmailChange/>
                        </div>
                        :
                        <div className="offset-md-2 col-md-8">
                            {error && <Error>{error}</Error>}
                            <div className="row">
                                <div className="col-xs-12 offset-sm-2 col-sm-8">
                                    <label data-menukey="Existing" htmlFor="email">{t('updatedEmail')}</label>
                                </div>
                                <div className="col-xs-12 offset-sm-2 col-sm-8">
                                    <input data-menukey="Email" className="form-control" type="email" id="email" name="email" disabled
                                           value={updatedEmail}/>
                                </div>
                            </div>
                            <div className="row gap">
                                <div className="col-xs-12 offset-sm-2 col-sm-8">
                                    <label data-menukey="Label" htmlFor="pwd">{t('enterPassword')}</label>
                                </div>
                                <div className="col-xs-12 offset-sm-2 col-sm-8">
                                    <input data-menukey="Input" className="form-control" type="password" id="pwd" name="password"
                                           placeholder="••••••••" onChange={e => onChange(e.target.value)} value={pwd ? pwd : ''}/>
                                </div>
                            </div>
                            <hr data-menukey="separator"/>
                            <div className="row">
                                <div className="col-xs-12 offset-sm-2 col-sm-8">
                                    <Button data-menukey="Save" className="btn btn-primary gap float-right" pending={pending}
                                            onClick={saveChanges}>{t('save')}</Button>
                                    <Link data-menukey="Cancel" role="button" className="btn btn-primary gap float-right" to={`${newPath}/amend`}
                                          disabled={pending} onClick={resetChange}>{t('cancel')}</Link>
                                </div>
                            </div>
                        </div>
                }
            </div>
    );
};
