import i18next from 'i18next';
import { generateError } from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {validatePhoneNumberLength} from 'libphonenumber-js';

// eslint-disable-next-line no-unused-vars
function ageIsAtLeast(dateOfBirth, minAge) {
    var today = new Date();
    var birthDate = new Date(Date.parse(dateOfBirth));
    var age = today.getFullYear() - birthDate.getFullYear();
    var monthDelta = today.getMonth() - birthDate.getMonth();
    if (monthDelta < 0 || (monthDelta === 0 && today.getDate() < birthDate.getDate())) age--;
    return age >= minAge;
}

const LIB_LENGTH_KEYS = {
    toShort: 'TOO_SHORT',
    toLong: 'TOO_LONG'
}
// eslint-disable-next-line no-unused-vars
function phoneNumberLength(value) {
    const isCorrect = validatePhoneNumberLength(value);
    const messageKey = {errorKey: null};
    if (isCorrect === LIB_LENGTH_KEYS.toShort) {
        messageKey.errorKey = 'ERR_TOO_SHORT_NSN';
    } else if (isCorrect === LIB_LENGTH_KEYS.toLong) {
        messageKey.errorKey = 'ERR_TOO_LONG';
    }
    return {
        result: false,
        message: generateError(i18next, messageKey, null)
    };
}

function replaceValues(results, javascript) {
    return Object.entries(results)
            .reduce((current, [name, result]) => current.replace(`$\{${name}}`, result), javascript);
}

/**
 * The following method is a validation method for react json schema form that will parse validation rules on the JSON schema such as:
 *
 * {
 *   "field": "dateOfBirth",
 *   "rule": "ageIsAtLeast(formData.dateOfBirth, 18)",
 *   "message": "You must be at least 18 years of age to buy a policy."
 * }
 *
 * @param uiSchema
 * @returns {function(*=, *): *}
 */
export const validate = (uiSchema, stepSchema, t) => (formData, errors) => {
    const validationRules = uiSchema['fm:validation'] || [];

    validationRules.forEach(({
        name,
        field,
        rule,
        message
    }) => {
        if (!name) throw Error(`Validation rule has no ${name} (required as a key to look up translations.)`);
        let results = {};
        try {
            const rules = typeof rule === 'string' ? [{
                name: 'result',
                javascript: rule
            }] : rule;
            rules.forEach(({
                name,
                javascript
            }) => {
                // Replace variables from previous entries.
                const javascriptWithVars = replaceValues(results, javascript);
                // eslint-disable-next-line no-eval
                results[name] = eval(javascriptWithVars);
            });
        } catch (e) {
            console.error(`Error processing validation rules ${e}`, e, results, rule, formData);
        } finally {
            if (!results.result) {
                const errorMessage = replaceValues(results, message);
                const fields = Array.isArray(field) ? field : [field];
                fields.forEach(next => {
                    const target = next === 'global' ? errors : errors[next];
                    if (target && target.addError) target.addError(errorMessage);
                });
            }
        }
    });

    return errors;
};
