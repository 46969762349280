import React, {useContext, useEffect, useState} from 'react';
import {ClaimApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';
import {ClaimListContext, TradingGroupContext} from '../common/context';

const client = new ClaimApiClient(SureAppName);

async function loadClaims(policyholder, products) {
    const {items} = await client.search({
        claimants: policyholder.id,
        productIds: Object.keys(products || {})
    }, 1000, 0);
    items.forEach(it => {
        it.product = products[it.productId];
    });
    return items;
}

export const ClaimListProvider = ({
    policyholder,
    children
}) => {
    const [claims, setClaims] = useState(null);
    const {products} = useContext(TradingGroupContext);

    const reloadClaims = () => {
        if (!policyholder || !policyholder.primaryEmailAddress) return;
        loadClaims(policyholder, products)
                .then(setClaims);
    };

    useEffect(() => {
        reloadClaims();
        // eslint-disable-next-line
    }, [policyholder]);

    return (
            <ClaimListContext.Provider value={{
                reloadClaims,
                claims
            }}>
                <div>{children}</div>
            </ClaimListContext.Provider>
    );
};
