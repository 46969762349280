import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { PermissionApiClient, RoleApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { history, InputCustom, IRolePermission, MessageBox, ITableData, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataPermissions } from "./helper";
import { validate } from "./validate";
import { BannerClientLogo, PageContainer } from "../components";
import GlobalContext from "../context/global-context";
import { getHeaderColumnsI18 } from "./columns.config";
import {isSeed} from "../@RoleAdministration/helper";

const RoleAdministrationEditPage: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const params = useParams<{ id: string }>();
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [mode, setMode] = useState("create");
    const [permissionsId, setPermissionsId] = useState<string[]>([]);
    const [allPermissions, setAllPermissions] = useState<IRolePermission[]>();
    const [role, setRole] = useState<IRolePermission>();
    const [errorMessage, setErrorMessage] = useState("");

    const permissionApiClient = new PermissionApiClient(namespacedLocalStorage);
    const roleApiClient = new RoleApiClient(namespacedLocalStorage);

    const handleAddAndRemovePermission = (id: string) => {
        if (permissionsId.includes(id)) {
            const newPermissionsId = permissionsId.filter((permissionId) => permissionId !== id);
            setPermissionsId(newPermissionsId);
            return;
        }
        setPermissionsId([...permissionsId, id]);
    };

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        try {
            const { permissions } = await permissionApiClient.listPermissions();
            const dataPermissions = setDataPermissions(permissions);

            if (!isMountRef.current) {
                return;
            }

            if (params.id) {
                const role = await roleApiClient.getRole(params.id);

                if (!isMountRef.current) {
                    return;
                }

                setRole(role);
                setMode("edit");
            }

            setAllPermissions(permissions);
            setData(dataPermissions);
        } catch (error) {
            console.warn("error", error.message);
        }
        setLoading(false);
    };
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        const headerColumnsI18 = getHeaderColumnsI18(permissionsId, handleAddAndRemovePermission);
        setHeaderColumns(headerColumnsI18);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionsId, role]);

    useEffect(() => {
        if (role && role.permissions.some((permission) => !permissionsId.includes(permission.id))) {
            const permissions = uniqBy(role.permissions, (element) => element.id).map((permission) => permission.id);
            setPermissionsId([...permissionsId, ...permissions]);
            formik.setFieldValue("name", role.name);
            formik.setFieldValue("description", role.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const formik = useFormik({
        initialValues: { name: "", description: "" },
        validate,
        onSubmit: async ({ name, description }) => {
            try {
                if (mode === "edit") {
                    const editRole = {
                        category: role.category,
                        name,
                        description,
                        permissions: permissionsId,
                    };
                    await roleApiClient.updateRole(role.id, editRole);
                } else {
                    const newRole = {
                        category: "Custom",
                        description,
                        name,
                        permissions: [...permissionsId, allPermissions.filter((permission) => permission.name === "SYSTEM_AUTH")[0].id],
                    };
                    await roleApiClient.createRole(newRole);
                }
                history.push("/role-administration");
            } catch (err) {
                console.error("Error persisting role", err);
                setErrorMessage(generateErrorMessage(err));
                // this.setState({formMessage: 'Unable to save role.'});
            } finally {
                // this.setState({busy: false});
            }
        },
    });

    const handleUpdateRole = () => undefined;

    const title = params.id ? t("updateRole") : t("createRole");

    return (
        <PageContainer title={title}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{title}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                    {errorMessage && (
                        <MessageBox message={errorMessage} theme={theme} variant="error" onClose={() => setErrorMessage(null)} />
                    )}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("details")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={formik.handleSubmit} id="role-form">
                                <InputCustom
                                    autoComplete="off"
                                    error={formik.errors.name as string}
                                    label={`${t("name")}*`}
                                    name="name"
                                    theme={theme}
                                    touched={formik.touched.name as boolean}
                                    value={formik.values.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                <InputCustom
                                    autoComplete="off"
                                    error={formik.errors.description as string}
                                    label={`${t("description")}*`}
                                    name="description"
                                    theme={theme}
                                    touched={formik.touched.description as boolean}
                                    value={formik.values.description}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h6">{t("permissions")}</Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ height: 400, overflowY: "scroll" }}>
                            <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} lg={5}>
                    <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                            <Button component={Link} color="secondary" variant="text" to="/role-administration">
                                {t("cancel")}
                            </Button>
                        </Grid>
                        {(isSeed(role)) ? null : (
                            <Grid item>
                                <Button color="primary" variant="contained" type="submit" form="role-form"
                                    onClick={handleUpdateRole}>
                                    {params.id ? t("updateRole") : t("createRole")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default RoleAdministrationEditPage;
