import { makeStyles, Theme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const useStyles = (theme: Theme) =>
    makeStyles(() => ({
        paperList: {
            height: 250,
            [breakpoints.up("sm")]: {
                height: "60vh",
            },
            overflow: "hidden",
            overflowY: "auto",
            minWidth: 200,
        },
        contentBodyText: {
            marginBottom: theme.spacing(3),
        },
    }));
