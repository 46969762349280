import {get} from 'lodash';
import React from 'react';
import {useDefaultTranslation} from '../common/Translation';

export const Instalments = ({transactions = []}) => {
    if (!transactions.length) {
        return null;
    }

    // eslint-disable-next-line
    const {t, i18n} = useDefaultTranslation();
    const condensed = transactions.reduce((condensed, transaction) => {
        const amount = transaction.totalAmount;
        if (condensed.length > 0 && condensed[condensed.length - 1].amount === amount) {
            condensed[condensed.length - 1].count++;
        } else {
            condensed.push({
                amount,
                count: 1
            });
        }
        return condensed;
    }, []);

    const joinInstalment = t('joinInstalment');
    const joinLastInstalment = t('joinLastInstalment');
    const formatCcy = Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: get(transactions, '[0].currency') || 'GBP'
    });
    let allItems = [];
    condensed.forEach(({
        amount,
        count
    }, i) => {
        const template = count === 1 ? 'instalmentsOf' : 'instalmentsOfPlural';
        const text = t(template, {
            count,
            amount: formatCcy.format(amount)
        });
        if (i === 0) allItems = allItems.concat([text]);
        else allItems = allItems.concat(i < condensed.length - 1 ? [joinInstalment, text] : [joinLastInstalment, text]);
    });
    const listOfItems = allItems.join("");
    const result = t('thisWillBePaidBy', {listOfItems});
    if(i18n.language.includes("ja"))
        return<span style={{whiteSpace: "pre-line"}}>{result}</span>;
    return <span>{result}</span>;
};
