import React from 'react';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';
import { useDefaultTranslation } from '../../common/Translation';

function TextWidget(props) {
    const {
        disabled,
        id,
        name,
        readonly,
        required,
        schema,
        type,
        value,
        onBlur,
        onChange,
        onFocus,
    } = props;
    const {t} = useDefaultTranslation();

    const placeholder = schema['ui:placeholder'] || '';
    const inputType = (type || schema.type) === 'string' ?  'text' : `${type || schema.type}`

    return (
        <div>
            <input
                   className="form-control" 
                   disabled={disabled || readonly}
                   id={id}
                   name={name}
                   type={inputType}
                   placeholder={placeholder} 
                   required={required}
                   value={value}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onChange={event => {
                    resetRequiredMessage(event);
                    onChange(event.target.value);
                }}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
                onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}
            />
        </div>
    );
}

export default TextWidget;
