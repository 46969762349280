import * as React from "react";
import { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Contact from "./components/Contact/Contact";
import RecentPolicies from "./components/RecentPolicies/RecentPolicies";
import ImportantNotes from "./components/ImportantNotes/ImportantNotes";
import UpcomingRenewals from "./components/UpcomingRenewals/UpcomingRenewals";
import RecentClaims from "./components/RecentClaims/RecentClaims";
import { ContactContext, SET_ENQUIRIES, SET_TAB } from "../../models";
import { OpenEnquiries } from "../../../components";
import { ETab } from "../../models/tabs.model";
import { INote } from "../../../components/OpenEnquiries/note.models";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            display: "flex",
            flexDirection: "column",
        },
    })
);

type IProps = Record<string, unknown>;

const TabOverview: React.FC<IProps> = () => {
    const [{ policyholder }, dispatchContact] = useContext(ContactContext);

    const classes = useStyles();

    return (
        <Grid container spacing={2} alignItems="stretch" wrap="wrap">
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <Contact />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <RecentPolicies />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <ImportantNotes />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <UpcomingRenewals />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <RecentClaims />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.card}>
                <OpenEnquiries
                    policyholder={policyholder}
                    onLoad={(payload: INote[]) => dispatchContact({ type: SET_ENQUIRIES, payload })}
                    onViewAll={() => dispatchContact({ type: SET_TAB, payload: ETab.Enquiries })}
                />
            </Grid>
        </Grid>
    );
};

export default TabOverview;
