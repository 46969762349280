// THE BELOW TO BE ABLE TO RAPIDLY PROTOTYPE PRODUCTS - IN PROD THEY ARE LOADED VIA THE PRODUCT.

let products = {};

if (process.env.NODE_ENV !== 'production' && !process.env.JEST_WORKER_ID) {
    products = {
        // prd_9FHc4idZw5uvUw6dBnoqTB: {
        //     logo: require('./dws/logo.png'),
        //     product: require('./dws/DogWalkers.product'),
        //     defaultSubmission: require('./dws/defaultSubmission'),
        //     submissionSchema: require('./dws/submissionSchema'),
        //     def: require('./dws/def')
        // },
        // prd_T6a36VBmVexy1ic9GjzNqu: {
        //     logo: require('./ccs/logo.png'),
        //     product: require('./ccs/CommercialCleaners.product'),
        //     defaultSubmission: require('./ccs/defaultSubmission'),
        //     submissionSchema: require('./ccs/submissionSchema'),
        //     def: require('./ccs/def')
        // },
        // prd_Q1MspyW594bX153QHRY7d: {
        //     logo: require('./ats/logo.png'),
        //     product: require('./ats/AnimalTrades.product.json'),
        //     defaultSubmission: require('./ats/defaultSubmission'),
        //     submissionSchema: require('./ats/submissionSchema'),
        //     def: require('./ats/def')
        // },
        // prd_Cv97WHuWdt75mGbnaqrnVC: {
        //     logo: require('./ino/logo.png'),
        //     product: require('./ino/product'),
        //     defaultSubmission: require('./ino/defaultSubmission'),
        //     submissionSchema: require('./ino/submissionSchema'),
        //     claimSchema: require('./ino/claimSchema'),
        //     def: require('./ino/def')
        // },
        // prd_hhiiVAtR4RzieuhrwY7Bs: {
        //     logo: require('./hhi/logo.png'),
        //     product: require('./hhi/product'),
        //     defaultSubmission: require('./hhi/defaultSubmission'),
        //     submissionSchema: require('./hhi/submissionSchema'),
        //     def: require('./hhi/def')
        // },
        // prd_sciQH6V6y5TaXDAiZjJ8x: {
        //     product: require('./sci/SatsiContents.product'),
        //     logo: require('./sci/logo.png'),
        //     defaultSubmission: require('./sci/defaultSubmission'),
        //     submissionSchema: require('./sci/submissionSchema'),
        //     'translation-ja': require('./sci/translation-ja.json'),
        //     def: require('./sci/def')
        // },
        // prd_stu76NVYYtYESPmzS2iGS: {
        //     product: require('./stu/IndonesiaStudent.product.json'),
        //     logo: require('./stu/logo.png'),
        //     defaultSubmission: require('./stu/defaultSubmission'),
        //     submissionSchema: require('./stu/submissionSchema'),
        //     def: require('./stu/def')
        // }
    };
    // Prevents issues where the products in the deployed environment's version doesn't match these.
    Object.values(products).forEach(it => {
        delete it.product.version;
    });
}

export default products;
