import React, {useContext} from 'react';
import {get} from 'lodash';
import {ClaimContext, ProductContext, SubmissionContext} from '../common/context';
import {SummaryPanel} from '../common/SummaryPanel';
import {useDefaultTranslation} from '../common/Translation';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {ClaimProvider} from './ClaimProvider';
import {ProductScreen} from '../shared/Screen';
import {convertToNewPath, SureAppName} from '../base';
import Loading from '../widgets/Loading';

const ClaimSummary = ({match}) => (
        <ClaimProvider claimId={match.params.claimId}>
            <ClaimContext.Consumer>{({claim}) => {
                if (!claim) return <Loading inline/>;
                return (
                        <ProductScreen productId={claim.productId}>
                            <Summary claim={claim}/>
                        </ProductScreen>
                );
            }}</ClaimContext.Consumer>
        </ClaimProvider>
);

const Summary = ({claim}) => {
    const {t} = useDefaultTranslation();
    const {
        product,
        claimUiSchema
    } = useContext(ProductContext);
    const {items} = get(claimUiSchema, 'summary[\'ui:options\']') || {};
    const claimDetails = t('claimDetails');
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    
    return (
            <ClaimSubmissionContext claim={claim}>
                <div className="container">
                    <div className="row">
                        <div className="section offset-md-3 col-md-6">
                            <legend><span>{claimDetails}</span></legend>
                            <div className="col-8 offset-2">
                                <SummaryPanel submission={{
                                    ...claim.submission,
                                    claim: {...claim.submission.claim, ...claim}
                                }} items={items} product={product}/>
                            </div>
                            <div>
                                <hr/>
                                <Link to={`${newPath}/`} className="btn btn-secondary float-right">{t('home')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </ClaimSubmissionContext>
    );
};

/**
 * Creates submission info using an existing claim object.
 */
const ClaimSubmissionContext = ({
    children,
    claim
}) => {
    const submission = claim && claim.submission;
    const attachments = {};
    if (claim) {
        claim.attachments.forEach(({
            name,
            location
        }) => {
            attachments[name] = ({
                name,
                location,
                url: `/claim/api/v1/${claim.id}/${location}?app-name=${SureAppName}`
            });
        });
    }

    return (
            <SubmissionContext.Provider value={{
                submission,
                attachments
            }}>
                {children}
            </SubmissionContext.Provider>
    );
};

export default ClaimSummary;
