import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { ITableData, setDataPolicies, signInService, TableContainer } from "@surelync/common";
import GlobalContext from "../../../context/global-context";
import { ContactContext } from "../../models";
import { getHeaderColumnsI18 } from "./columns.config";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";

const TabPolicies: React.FC = () => {
    const { currentUser, products } = useContext(GlobalContext);
    const [{ policies, policyholder }] = useContext(ContactContext);
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [total, setTotal] = useState<number | null>(null);

    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation();

    const { identityType } = currentUser.authDetails;

    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname } };
    };

    const fetchData = async (rowsPerPage: number, page: number) => {
        const headerColumnsI18 = columns();
        const offset = page * rowsPerPage;
        const dataPolicies = setDataPolicies(identityType, { items: policies.slice(offset, offset + rowsPerPage) }, products, {
            [policyholder.id]: policyholder,
        });

        setData(dataPolicies);
        setHeaderColumns(headerColumnsI18);
        setTotal(policies.length);
    };

    const columns = () => {
        const isMobile = window.innerWidth < theme.breakpoints.values.md;
        const headerColumnsI18 = getHeaderColumnsI18(isMobile, handleRedirect).filter(
            (column) => !column.roles || column.roles.includes(identityType)
        );

        if (signInService.hasPermission(["POLICY_EDIT"])) {
            headerColumnsI18.push({
                Header: () => t("actions") as string,
                accessor: "amendButton",
                Cell: (row: CellInfo) =>
                    row.value ? (
                        <LinkButton component={Link} to={row.value} variant="caption">
                            {t("amend")}
                        </LinkButton>
                    ) : null,
                size: "small",
            });
        }

        return headerColumnsI18;
    };

    return <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} />;
};

export default TabPolicies;
