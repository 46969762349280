import React from 'react';

// Exposes the submission being edited currently.
export const SubmissionContext = React.createContext();

// Exposes the quote currently being worked on or policy if bound.
export const PolicyContext = React.createContext();

// Exposes the claim currently being worked on.
export const ClaimContext = React.createContext();

// Exposes the current list of policies and quotes for this user.
export const PolicyListContext = React.createContext();

// Exposes the current list of claims for this user.
export const ClaimListContext = React.createContext();

// Exposes the local storage object.
export const LocalStorageContext = React.createContext();

// Stores the current trading group (group of products) being used by the app.
export const TradingGroupContext = React.createContext();

// Exposes the currently authenticated user.
export const AuthContext = React.createContext();

// Exposes the currently policyholder and actions on it.
export const PolicyholderContext = React.createContext();

// Exposes the product and its related attachments.
export const ProductContext = React.createContext();

// Contains objects relating to just the current step in the quote flow and navigation methods.
export const CurrentStepContext = React.createContext();

// The claim flow can take the static schema and uiSchema and apply changes based on the policy or other date - these updated schemas are stored here.
export const ClaimSchemaContext = React.createContext();

// Shares a 'pending' state, this can be used to prevent a user doing anything while a quote is being created, or payment made etc.
export const PendingContext = React.createContext();

// This allows for the metadata of a policy to be overridden.
export const MetadataContext = React.createContext();
