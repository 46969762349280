import React, {useContext, useEffect, useState} from 'react';
import './QuickQuote.css';
import {CurrentStepContext, ProductContext, SubmissionContext} from '../common/context';
import {InProductCurrency} from '../widgets/InProductCurrency';
import {QuoteApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';

const QuickQuoteDelayMs = 500;
const quoteApiClient = new QuoteApiClient(SureAppName);

const QuickQuote = () => {
    const {product} = useContext(ProductContext);
    const {stepUiSchema} = useContext(CurrentStepContext);
    const {submissionWithAssumptions} = useContext(SubmissionContext);
    // Trigger the quote immediately.
    const [{
        refresh,
        quote,
        timeout
    }, updateQuickQuote] = useState({
        refresh: +(new Date()) + .1,
        quote: null
    });

    async function refreshQuickQuote(submission) {
        if (!stepUiSchema['ui:quickQuote']) return;
        if (timeout) clearTimeout(timeout);

        // Delay the request by 500ms to avoid requesting a quote on every single keystroke.
        const newTimeout = setTimeout(async () => {
            // Only display if the quote is not a DTQ.
            if (quote !== null && quote.status !== 'DTQ' && quote.status !== 'Referral') {
                const totalPayables = Object.values(quote)
                        .map(it => (it.quote && it.quote.totalPayable) || 0);
                const totalPayable = Math.round(totalPayables.reduce((a, b) => (a + b * 100), 0)) / 100;
                if (totalPayable > 0) updateQuickQuote({
                    quote: 'loading',
                    timeout: null
                });
            }
            updateQuickQuote({
                quote: await quoteApiClient.requestQuote(product.id, submission)
                        .catch(() => null),
                timeout: null
            });
        }, QuickQuoteDelayMs);
        updateQuickQuote({
            refresh,
            quote,
            timeout: newTimeout
        });
    }

    useEffect(() => {
        refreshQuickQuote(submissionWithAssumptions);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [submissionWithAssumptions]);

    const showFooter = window.location && window.location.search.indexOf('nofoot') === -1;
    if (!showFooter || !quote || quote.status !== 'Quoted') return <span/>;
    const totalPayables = Object.values(quote.quote)
            .map(it => it.totalPayable);
    const totalPayable = Math.round(totalPayables.reduce((a, b) => (a + b * 100), 0)) / 100;
    if (totalPayable <= 0) return null;
    return (
            <h3 className="quick-quote btn-primary animated fade-in">
                Your estimated premium is&nbsp;<InProductCurrency amount={totalPayable}/>
            </h3>
    );
};

export default QuickQuote;
