import uniqBy from "lodash/uniqBy";
import { EIdentityType, hiddenRoles, IRole, isNotHiddenPermissions, TranslateUtils } from "@surelync/common";

const isNotHiddenRole = (category: string, roleName: string) => hiddenRoles.indexOf(category) === -1 && roleName !== "Super";

export const setDataRoles = (roles: IRole[], identityType: EIdentityType) => {
    const result = [];

    roles
        .filter((role) => {
            // SUR-134
            // when a surelync user reviews the list of available roles
            // they should only be able to see the Blocksure Support role
            // if they are a member of an organisation providing Blcoksure support.
            if (role.name === "Blocksure Support") {
                return identityType === EIdentityType.Blocksure;
            } else {
                return isNotHiddenRole(role.category, role.name);
            }
        })
        .forEach((role) => {
            const permissions = role.permissions.filter(isNotHiddenPermissions);
            const translate = TranslateUtils.translateRole(role.name);
            result.push({
                name: translate.name,
                id: role.id,
                description: translate.description || role.description,
                permissions: uniqBy(permissions, (element) => element.id),
                removeButton: !isSeed(role),
            });
        });

    return result;
};

export function isSeed(role: IRole) {
    return (role != undefined && role.category != "Custom")
}
