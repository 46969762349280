import React, {useContext, useEffect, useState} from 'react';
import {PolicyScreen, Screen} from '../shared/Screen';
import {PolicyContext, PolicyholderContext, PolicyListContext, ProductContext} from '../common/context';
import {Tabs} from '../common/Tabs';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {flatMap, flatten} from 'lodash';
import {Menu} from '../shared/Menu';
import {formatDateLocale} from '../shared/util';
import {InProductCurrency} from '../widgets/InProductCurrency';
import {getPaymentSources} from './paymentActions';
import {useDefaultTranslation} from '../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import './Payments.css';
import {convertToNewPath} from "../base";

const tabs = ['upcoming', 'paid', 'overdue'];

// This screen can either render payments for one policies, or all policies the group.
export const Payments = ({match}) => (
        match.params.sequenceId ? (
                <PolicyScreen sequenceId={match.params.sequenceId}>
                    <PolicyContext.Consumer>{({policy}) => (
                            <PaymentScreen policies={policy ? [policy] : []}/>
                    )}</PolicyContext.Consumer>
                </PolicyScreen>
        ) : (
                <Screen>
                    <PolicyListContext.Consumer>{({policies}) => (
                            <PaymentScreen policies={policies}/>
                    )}</PolicyListContext.Consumer>
                </Screen>
        )
);

const PaymentScreen = ({policies}) => {
    const {t} = useDefaultTranslation();
    const [{
        tab,
        index
    }, setTab] = useState({
        tab: 'upcoming',
        index: 0
    });
    const [paymentSources, setPaymentSources] = useState([]);
    const {product: contextProduct} = useContext(ProductContext) || {};
    const {policyholder} = useContext(PolicyholderContext) || {};
    const paymentGateways = policies && [...new Set(policies.map(it => it.product || contextProduct)
            .map(it => it && it.paymentGateway))];
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    // Flatten the policies' transactions and attach the payment source.
    let entries = flatMap(policies, ({
        paymentSource,
        product,
        transactions
    }) => transactions.map(it => ({
        ...it,
        product,
        paymentSource
    })));
    let data = entries.filter(entry => {
        if (tab === 'overdue') return entry.status === 'Failed' || (entry.status === 'Pending' && new Date(entry.due) < new Date());
        else if (tab === 'paid') return entry.status === 'Paid';
        else if (tab === 'cancelled') return entry.status === 'Cancelled';
        else if (tab === 'upcoming') return entry.status === 'Pending';
        return false;
    })
            .sort((a, b) => FormatUtils.compareISODates(a.due, b.due, 'milliseconds'));

    useEffect(() => {
        if ((paymentGateways && paymentGateways.length > 0) && policyholder) {
            Promise.all([getPaymentSources(paymentGateways[0], policyholder.id)])
                    .then(it => setPaymentSources(flatten(it)));
        }
        // eslint-disable-next-line
    }, [paymentGateways && paymentGateways.toString()]);

    const map = [];

    if (data && data.length) {
        data.forEach(({
            currency,
            paymentSource,
            status,
            totalAmount,
            due,
            paid,
            attempts,
            id,
            reportingRef,
            sequenceId
        }, i) => {
            const card = paymentSource && paymentSources.find(it => it.id === paymentSource);
            const latestAttempt = attempts[attempts.length - 1];
            const message = latestAttempt && latestAttempt.message;

            const Entry = ({
                children,
                label,
                title,
                className,
            }) => {
                const titleTranslated = t(label.toLocaleLowerCase('en'), {defaultValue: label});
                return [
                    label && <div key="key" title={title} className="col-xs-4 col-md-2 key text-right">{titleTranslated}</div>,
                    <div key="value" title={title} className={`col-xs-8 col-md-4 text-left ${className}`}>{children}</div>
                ];
            };

            map.push(
                    <div data-menukey={i} className="row section section-small gap">
                        <Entry label="Policy">{reportingRef}</Entry>
                        {!paid && <Entry label="Due" title={due}>{formatDateLocale(due)}</Entry>}
                        {paid && <Entry label="Paid" title={paid}>{formatDateLocale(paid)}</Entry>}
                        <Entry label="Amount"><InProductCurrency currency={currency} amount={totalAmount} defaultValue={totalAmount}/></Entry>
                        {card && <Entry label="Card">•••• •••• •••• {card.last4}</Entry>}
                        {message && <Entry className="payment-reason" label="Failure Reason">{message}</Entry>}
                        {tab === 'overdue' && <div className="col-xs-12 text-center gap">
                            <Link className="btn btn-danger" to={`${newPath}/payment/${id}`}>{t('payNow')}</Link>
                        </div>}
                    </div>
            );
        });
    } else {
        const tabTranslated = t(tab.toLocaleLowerCase('en'));
        map.push(
                <div data-menukey="no-tx" className="gap row">
                    <div className="section gap section-small offset-xs-3 col-6 offset-md-2 col-md-8"><h4
                            className="gap text-center">{t('youHaveNoTransactions', {type: tabTranslated})}</h4></div>
                </div>
        );
    }

    map.push(
            <div data-menukey="home" className="d-flex justify-content-center col-xs-12 key gap">
                <Link role="button" className="btn btn-secondary gap float-right" to={`${newPath}/`}>{t('home')}</Link>
            </div>
    );

    return (
            <div className="container text-center gap">
                <div className="row text-center"><Tabs tabs={tabs} activeTab={tab} onTabClick={tab => setTab({
                    tab,
                    index
                })}/></div>
                <Menu items={map} fullWidth className="col-xs-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 payment"/>
            </div>
    );
};

