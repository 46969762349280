import React from 'react';
import {Screen} from '../shared/Screen';
import {AuthContext} from '../common/context';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const AmendConfirmEmail = () => (
        <Screen>
            <AuthContext.Consumer>{({auth}) => (
                    <ConfirmationScreen auth={auth}/>
            )}</AuthContext.Consumer>
        </Screen>
);

const ConfirmationScreen = ({auth}) => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <div className="menu">
                <div className="offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <div className="bg-success" style={{
                                borderRadius: 5,
                                padding: 10
                            }}>
                                <p>{t('confirmEmail', {email: auth.username})}</p>
                            </div>
                        </div>
                    </div>
                    <hr data-menukey="separator"/>
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <Link data-menukey="home" role="button" className="btn btn-primary gap float-right" to={`${newPath}/`} >{t('home')}</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
};
