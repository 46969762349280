import { IAction } from "@surelync/common";

export const ADD_NOTE_DONE = "ADD_NOTE_DONE";
export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const SELECT_NOTE = "SELECT_NOTE";

export const initialState = {
    error: "",
    fetching: false,
    newNote: null,
    open: false,
    selectedNote: null,
};

export function reducer(state: typeof initialState, action: IAction) {
    switch (action.type) {
        case ADD_NOTE_DONE:
            return { ...initialState, open: false, selectedNote: false, newNote: action.payload };
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case OPEN_DIALOG:
            return { ...state, open: action.payload };
        case SELECT_NOTE:
            return { ...state, open: true, selectedNote: action.payload };
        default:
            return state;
    }
}
