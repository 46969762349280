import React, {useEffect, useState} from 'react';
import {ProductContext} from '../common/context';
import {PolicyholderApiClient, ProductApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {convertToNewPath, SureAppName} from '../base';
import products from '../products';
import {get, merge, set} from 'lodash';
import {validate} from './ProductValidation';
import {loadSubmissionSchema} from '../schema/SchemaUtils';
import {loadAttachments} from '../common/Attachments';
import i18next from 'i18next';
import {useDefaultTranslation} from '../common/Translation';
import {Link, useParams, useRouteMatch} from 'react-router-dom';

const productApiClient = new ProductApiClient(SureAppName);
const policyholderApiClient = new PolicyholderApiClient(SureAppName);

const attachments = {
    logo: {
        name: 'logo.png',
        urlOnly: true
    },
    submissionSchema: {
        name: 'submissionSchema.json',
        urlOnly: false
    },
    claimSchema: {
        name: 'claimSchema.json',
        urlOnly: false
    },
    def: {
        name: 'def.json',
        urlOnly: false
    },
    defaultSubmission: {name: 'defaultSubmission.json', urlOnly: false}
};

/**
 * Provides state for the currently selected product including schemas, attachments, logos etc.
 */
export const ProductProvider = ({
    children,
    productId,
    email,
    hasPassword
}) => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const [product, setProduct] = useState(null);
    const [universalPolicyholderSchema, setUniversalPolicyholderSchema] = useState(null);
    const [{
        logo,
        submissionSchema,
        submissionUiSchema,
        claimSchema,
        claimUiSchema,
        formulator,
        defaultSubmission
    }, setAttachments] = useState({});

    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);

    useEffect(() => {
        if (productId) loadProduct(productId)
                .then(product => {
                    const country = product.country || '*';
                    if (country === '*') console.error('product.country not found, reverting to global schema');
                    policyholderApiClient.getSchema(country)
                            .then(universalPolicyholderSchema => {
                                setUniversalPolicyholderSchema(universalPolicyholderSchema);
                                setProduct(product);
                            });
                });
    }, [productId]);

    useEffect(() => {
        if (!product) return;

        const languages = i18next.languages;
        const attachmentsIncludingLanguageBundles = {...attachments};

        if (global.notProd) {
            attachmentsIncludingLanguageBundles.defaultSubmission = {
                name: 'defaultSubmission.json',
                urlOnly: false
            };
        }

        languages.forEach(language => {
            //SureApp can has ES locale, but product can has ES-AR translation file
            const name = `translation-${language}`;
            if (product.hiddenAttachments.find(it => it.name === `${name}.json`)) {
                attachmentsIncludingLanguageBundles[name] = {name: `${name}.json`, urlOnly: false}
            } else {
                const translationFile = product.hiddenAttachments.find(it => it.name.startsWith(`translation-${language}`));
                if (translationFile) {
                    attachmentsIncludingLanguageBundles[name] = {name: translationFile.name, urlOnly: false};
                }
            }
        });

        loadAttachments(product, attachmentsIncludingLanguageBundles)
                .then(({logo, submissionSchema: schema, claimSchema: rawClaimSchema, def, defaultSubmission, ...languageBundles}) => {
                    // Process the language bundles.
                    languages.forEach(language => {
                        const bundle = languageBundles[`translation-${language}`];
                        if (!bundle) return;

                        i18n.addResourceBundle(language, 'product', bundle, true, true);
                    });

                    // Split the merged schemas into schema + UI schema.
                    let {
                        submissionSchema,
                        submissionUiSchema
                    } = loadSubmissionSchema(schema, def, universalPolicyholderSchema, t, 'submission');
                    // console.info('Quote schema:', submissionSchema);
                    // console.info('Quote UI schema:', submissionUiSchema);

                    let {
                        submissionSchema: claimSchema,
                        submissionUiSchema: claimUiSchema
                    } = rawClaimSchema ? loadSubmissionSchema(rawClaimSchema, def, universalPolicyholderSchema, t, 'claim') : {};
                    // console.info('Claim schema:', claimSchema);
                    // console.info('Claim UI schema:', claimUiSchema);

                    // Determine the correct order for the steps.
                    const steps = get(submissionUiSchema, '["ui:order"]');

                    // Finally we force the first step to have the primary email address field.
                    const firstSchemaStep = steps.filter(it => it !== 'amend')[0];
                    const schemaProperties = submissionSchema.properties[firstSchemaStep].properties || {};
                    if (!schemaProperties.primaryEmailAddress) merge(schemaProperties, {
                        primaryEmailAddress: {
                            type: 'string',
                            title: 'Email'
                        }
                    });

                    if (email && hasPassword) {
                        set(submissionUiSchema, `[${firstSchemaStep}].primaryEmailAddress['ui:disabled']`, true);
                        set(submissionUiSchema, `[${firstSchemaStep}].primaryEmailAddress['ui:field']`, 'notEmptyStringField');
                        set(submissionUiSchema, `[${firstSchemaStep}].primaryEmailAddress['ui:helperText']`, <Link to={`${newPath}/email`}>{t('amendEmailAddress')}</Link>);
                    }

                    setAttachments({
                        logo,
                        submissionSchema,
                        submissionUiSchema,
                        claimSchema,
                        claimUiSchema,
                        formulator,
                        defaultSubmission
                    });
                });
        // eslint-disable-next-line
    }, [product]);

    if (submissionSchema) validate(submissionSchema, submissionUiSchema);

    return (
            <ProductContext.Provider value={{
                product,
                logo,
                submissionSchema,
                submissionUiSchema,
                claimSchema,
                claimUiSchema,
                formulator,
                defaultSubmission
            }}>
                {children}
            </ProductContext.Provider>
    );
};

const loadProduct = async (productId) => {
    // The 'productData' simply allows for fast debugging/development of products locally.
    const productData = products[productId];
    return (productData && productData.product) || productApiClient.getProduct(productId);
};

