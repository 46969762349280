import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";

interface IProps {
    divider?: boolean;
    label: string;
    value: string;
}

export const Line: React.FC<IProps> = ({ divider, label, value }) => {
    return (
        <>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography component="span" variant="body1">
                    {label}
                </Typography>
                <Typography component="span" variant="body2">
                    {value}
                </Typography>
            </Box>
            {divider ? <Divider /> : null}
        </>
    );
};
