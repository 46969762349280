import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    endStyle: {
        display: "flex",
    },
    pagination: {
        display: "flex",
        alignItems: "center",
    },
}));
