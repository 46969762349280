import { ISettlementStatement } from "@surelync/common";

export const setDataSettlementStatement = (data: ISettlementStatement[]) => {
    return data.map((item) => ({
        reportingRef: item.reportingRef,
        transactionId: item.transactionId,
        paid: item.paid,
        available: item.available,
        status: item.status,
        currency: item.currency,
        grossAmount: item.grossAmount,
        paymentFee: item.paymentFee,
        netAmount: item.netAmount,
    }));
};
