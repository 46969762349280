// @flow
import React, {Component} from 'react';
import {errors, validatePasswords} from '../services/api-clients/UserApiClient';

export default class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.isFocused = this.isFocused.bind(this);
        this.state = {
            inputError: {
                short: errors[0],
                long: null,
                capital: errors[2],
                lower: errors[3],
                number: errors[4]
            },
            pwd1: undefined
        };
    }

    onChange(name, value) {
        this.props.onChange(name, value);
        const error = validatePasswords(value);
        this.setState({inputError: error});
        error === undefined ? this.props.checkedPassword(true) : this.props.checkedPassword(false);
    }

    isFocused() {
        const element = document.activeElement;
        if (element.id === 'pwd1') {
            const focused = document.activeElement === document.getElementById(element.id);
            this.setState({[element.id]: focused});
        } else {
            this.setState({pwd1: null});
        }
    }

    translatorOrDefault() {
        return this.props.t || ((it, {defaultValue}) => defaultValue || it);
    }

    render() {
        const t = this.translatorOrDefault();
        const {
            password,
            required,
            style,
            checkedPassword,
            ...props
        } = this.props;
        const passwordLabel = t('password', {defaultValue: 'Password'});
        return (
            <div className={`${style.div}`} onClick={this.isFocused}>
                <label htmlFor="password">{passwordLabel}</label>
                <div>
                    <input
                        {...props}
                        id="pwd1"
                        name="password"
                        className={`${style.input}`}
                        type="password"
                        required={required}
                        placeholder="••••••••"
                        autoComplete="new-password"
                        value={password || ''}
                        onChange={e => this.onChange('password', e.target.value)}
                    />
                    {this.state.pwd1 && this.renderValidationForm(this.state.inputError)}
                </div>
            </div>
        );
    }

    renderValidationForm(errors) {
        const t = this.translatorOrDefault();
        const tenCharacters = t('tenCharacters', '10 characters');
        const lessThan50Characters = t('lessThan50Characters', 'Less than 50 characters');
        const oneUppercaseCharacter = t('oneUppercaseCharacter', '1 upper case character');
        const oneLowercaseCharacter = t('oneLowercaseCharacter', '1 lower case character');
        const oneNumber = t('oneNumber', '1 number');
        const passwordText = t('passwordText', 'Password must have at least:');
        return (
            <div className="notification gap">
                <label>{passwordText}</label>
                {errors && errors.short ? iconField(tenCharacters, 'fa fa-times') : (errors?.long ? null : iconField(tenCharacters, 'fa fa-check'))}
                {errors && errors.long && iconField(lessThan50Characters, 'fa fa-times')}
                {errors && errors.capital ? iconField(oneUppercaseCharacter, 'fa fa-times') : iconField(oneUppercaseCharacter, 'fa fa-check')}
                {errors && errors.lower ? iconField(oneLowercaseCharacter, 'fa fa-times') : iconField(oneLowercaseCharacter, 'fa fa-check')}
                {errors && errors.number ? iconField(oneNumber, 'fa fa-times') : iconField(oneNumber, 'fa fa-check')}
            </div>
        );
    }
}

export const iconField = (text, icon) => {
    return <div>
        {icon === 'fa fa-check' ? <p style={{color: 'grey'}}><i className={`${icon}`} style={{color: 'green'}}/><s> {text} </s></p>
            : <p><i className={`${icon}`} style={{color: 'red'}}/> {text} </p>}
    </div>;
};
