import React, { useContext, useEffect, useReducer } from "react";
import { defaultState, ESearchType, FETCH_DONE, FETCH_START, HomeContext, reducer, SET_MOBILE_SEACH_BUTTON, SET_SEARCH_TYPE } from "./models";
import { SearchIndividual } from "./components/SearchIndividual/SearchIndividual";
import { SearchGlobal } from "./components/SearchGlobal/SearchGlobal";
import { PageContainer } from "../components/PageContainer/PageContainer";
import { SearchAll } from "./components/SearchAll/SearchAll";
import Grid from "@material-ui/core/Grid/Grid";
import { Results } from "./components/Results/Results";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { SearchCompany } from "./components/SearchCompany/SearchCompany";
import PolicyholderApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/PolicyholderApiClient";
import SearchApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/SearchApiClient";
import GlobalContext from "../context/global-context";
import { SearchPolicy } from "./components/SearchPolicy/SearchPolicy";
import { SearchClaim } from "./components/SearchClaim/SearchClaim";
import { useLocation } from "react-router-dom";
import { EContactType, ETypes, EPolicyStatus } from "@surelync/common";
import { useTranslation } from "react-i18next";
import { Box, Button, Hidden } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { BannerClientLogo } from "../components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullHeight: {
            height: "100%",
            // overflow: 'hidden',
            webkitOverflowY: "auto",
            overflowY: "auto" as any,
            [theme.breakpoints.down("md")]: {
                overflow: "visible",
            },
        },
        drawer: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            borderRight: "1px solid #ddd",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                borderRight: "none",
            },
        },
        compact: {
            display: "none",
        },
        slideInLeft: {
            animation: `$fadeEffect 200ms ${theme.transitions.easing.easeIn}`,
        },
        "@keyframes fadeEffect": {
            "0%": {
                opacity: 0,
                transform: "translateX(100%)",
            },
            "100%": {
                opacity: 1,
                transform: "translateX(0)",
            },
        },
    })
);

const HomePage: React.FC = () => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { mobileSearchBtn, pagination, searchParams, sort, type } = state;
    const location = useLocation<{ resetSearch: boolean }>();
    const { t } = useTranslation();

    // fetch data request [searchParams, pagination, sort]
    useEffect(() => {
        if (!searchParams) {
            return;
        }

        let isMount = true;
        const fetchData = async () => {
            const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
            const searchApiClient = new SearchApiClient(namespacedLocalStorage);
            const params = {
                ...searchParams,
                ...pagination,
                sort,
            };

            let queryType: string | string[];
            if (type === ESearchType.All) {
                const tokens = params.search ? params.search.split(" ") : "";

                if (tokens.length === 1 && params.search.startsWith("pol_")) {
                    queryType = ETypes.policy;
                    params.id = params.search;
                } else if (tokens.length === 1 && params.search.startsWith("pho_")) {
                    queryType = ETypes.policyholder;
                    params.id = params.search;
                } else if (tokens.length === 1 && params.search.startsWith("CLA")) {
                    queryType = ETypes.claim;
                    params.reportingRef = params.search;
                } else if (tokens.length === 1 && params.search.startsWith("clm_")) {
                    queryType = ETypes.claim;
                    params.id = params.search;
                } else {
                    queryType = [ETypes.policyholder, ETypes.policy, ETypes.claim];
                    params["*"] = params.search;
                }

                delete params.search; // replaced to '*'
            } else if (type === ESearchType.Individual) {
                queryType = ETypes.policyholder;
                params.contactType = EContactType.Individual;
            } else if (type === ESearchType.Company) {
                queryType = ETypes.policyholder;
                params.contactType = EContactType.Company;
            } else if (type === ESearchType.Quote) {
                queryType = ETypes.policy;
                params.status = EPolicyStatus.Quoted;
            } else if (type === ESearchType.Policy) {
                queryType = ETypes.policy;
                params.status = EPolicyStatus.Bound;
            } else if (type === ESearchType.Referral) {
                queryType = ETypes.policy;
                params.status = EPolicyStatus.Referral;
            } else if (type === ESearchType.Cancellation) {
                queryType = ETypes.policy;
                params.status = EPolicyStatus.Cancelled;
            } else if (type === ESearchType.Decline) {
                queryType = ETypes.policy;
                params.status = EPolicyStatus.Dtq;
            } else if (type === ESearchType.Claim) {
                queryType = ETypes.claim;
            }

            delete params.total; // backend will return empty response

            dispatch({ type: FETCH_START });
            if (window.innerWidth <= 1024) {
                dispatch({ type: SET_MOBILE_SEACH_BUTTON, payload: true });
            }

            try {
                // SUR-178
                // Policyholder API (not Search API) is used to find using email address
                const apiClient =
                    searchParams.search && searchParams.search.includes("@")
                        ? policyholderApiClient.getPolicyholder(searchParams.search)
                        : searchApiClient.search([queryType], params);

                // waiting right sidebar animation
                const responses = await Promise.all([apiClient, new Promise((resolve) => setTimeout(resolve, 500))]);

                // cleanup func
                if (!isMount) return;
                pagination.total = responses[0].total;
                dispatch({ type: FETCH_DONE, payload: { data: responses[0].items || responses[0], pagination } });
            } catch (error) {
                if (!isMount) return;
                // dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });

                // SUR-178 contains("@") Policyholder API (not Search API) is used to find using email address
                // will return error. not empty array.
                dispatch({ type: FETCH_DONE, payload: { data: [] } });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, searchParams, sort]);

    // Click on the header search icon should redirect user to the Home Search page
    useEffect(() => {
        if (location.state && location.state.resetSearch) {
            dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.Global });
        }
    }, [location]);

    const renderSearch = () => {
        switch (type) {
            case ESearchType.All:
                return <SearchAll />;
            case ESearchType.Individual:
                return <SearchIndividual />;
            case ESearchType.Company:
                return <SearchCompany />;
            case ESearchType.Quote:
            case ESearchType.Policy:
            case ESearchType.Referral:
            case ESearchType.Cancellation:
            case ESearchType.Decline:
                return <SearchPolicy />;
            case ESearchType.Claim:
                return <SearchClaim />;
            default:
                return <SearchGlobal />;
        }
    };

    return (
        <HomeContext.Provider value={[state, dispatch]}>
            {/* remove page padding because sidebar has been used */}
            <PageContainer title={t("home")} noPadding>
                {state.type === ESearchType.Global ? (
                    <SearchGlobal />
                ) : (
                    <>
                        <Hidden mdUp>
                            <Box textAlign="right" px={3} pt={1}>
                                <BannerClientLogo />
                            </Box>
                        </Hidden>
                        <Grid container className={classes.fullHeight}>
                            {mobileSearchBtn ? (
                                <Grid item xs={12} className={classes.drawer}>
                                    <Box px={3}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={() => dispatch({ type: SET_MOBILE_SEACH_BUTTON, payload: false })}
                                        >
                                            {t("refineSearch")} <SearchIcon style={{ marginLeft: 8 }} />
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : null}

                            <Grid
                                item
                                className={clsx([mobileSearchBtn ? classes.compact : "", classes.fullHeight, classes.drawer, classes.slideInLeft])}
                                data-testid="side-bar-search"
                            >
                                <Container maxWidth="xs">{renderSearch()}</Container>
                            </Grid>

                            <Grid item xs className={classes.fullHeight}>
                                <Results />
                            </Grid>
                        </Grid>
                    </>
                )}
            </PageContainer>
        </HomeContext.Provider>
    );
};

export default HomePage;
