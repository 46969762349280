import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { ESearchType } from "../../models/search-type.model";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { HomeContext } from "../../models/context";
import { SET_SEARCH_TYPE } from "../../models/action";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: "center",
            border: "1px solid #ddd",
            display: "flex",
            marginBottom: theme.spacing(2),
            padding: "2px 10px",
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            fontSize: "1.2rem",
            fontWeight: "bolder",
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        linkIcon: {
            color: theme.palette.primary.main,
            verticalAlign: "middle",
        },
    })
);

interface ISearchProps {
    activeAll?: boolean;
}

export const SearchItems: React.FC<ISearchProps> = ({ activeAll }) => {
    const classes = useStyles();
    const [, dispatch] = useContext(HomeContext);
    const { t } = useTranslation();

    const handleSearchType = (type: ESearchType) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch({ type: SET_SEARCH_TYPE, payload: type });
    };

    return (
        <Grid container spacing={1} justify="space-between" wrap="nowrap">
            <Grid item>
                {activeAll && <SearchIcon className={classes.linkIcon} fontSize="small" />}
                <Link href="#" onClick={handleSearchType(ESearchType.All)} variant="body1">
                    {t("all")}
                </Link>
            </Grid>
            <Grid item>
                <Link href="#" onClick={handleSearchType(ESearchType.Individual)} variant="body1">
                    {t("individual")}
                </Link>
            </Grid>
            <Grid item>
                <Link href="#" onClick={handleSearchType(ESearchType.Quote)} variant="body1">
                    {t("quote")}
                </Link>
            </Grid>
            <Grid item>
                <Link href="#" onClick={handleSearchType(ESearchType.Policy)} variant="body1">
                    {t("policy")}
                </Link>
            </Grid>
            <Grid item>
                <Link href="#" onClick={handleSearchType(ESearchType.Claim)} variant="body1">
                    {t("claim")}
                </Link>
            </Grid>
        </Grid>
    );
};
