import { JSONSchema7 } from "json-schema";
import { IAction, IClaim, INote, IPolicyholder, IPolicy } from "@surelync/common";
import { ETab } from "./tabs.model";

export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_START = "FETCH_START";
export const SET_ENQUIRIES = "SET_ENQUIRIES";
export const SET_NOTES = "SET_NOTES";
export const SET_TAB = "SET_TAB";
export const EDIT_CONTACT_MODE = "EDIT_CONTACT_MODE";

export type ContactState = {
    activeTab: ETab;
    claims: IClaim[];
    enquiries: INote[];
    error: string;
    fetching: boolean;
    isEditContact: boolean;
    notes: INote[];
    policies: IPolicy[];
    policyholder: IPolicyholder;
    universalPolicyholderSchema: JSONSchema7;
};

export const defaultState: ContactState = {
    activeTab: ETab.Overview,
    claims: null,
    enquiries: null,
    error: null,
    fetching: false,
    isEditContact: false,
    notes: null,
    policies: null,
    policyholder: null,
    universalPolicyholderSchema: null,
};

export const reducer = (state: ContactState, action: IAction) => {
    switch (action.type) {
        case FETCH_START:
            return { ...state, fetching: true, error: null, data: null };
        case FETCH_DONE:
            return { ...state, ...action.payload, fetching: false };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case EDIT_CONTACT_MODE:
            return { ...state, isEditContact: action.payload };
        case SET_ENQUIRIES:
            return { ...state, enquiries: action.payload };
        case SET_NOTES:
            return { ...state, notes: action.payload };
        case SET_TAB:
            return { ...state, activeTab: action.payload, error: null, isEditContact: false };
        default:
            return state;
    }
};
