import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { useTheme } from "@material-ui/core/styles";
import { RoleApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { InfoDialog, ITableData, MessageBox, signInService, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataRoles } from "./helper";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, ConfirmDialog, PageContainer } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";

const permissions = ["ROLE_EDIT"];

const RoleAdministrationPage: React.FC = () => {
    const { currentUser, namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);
    const [selectedRoleId, setSelectedRoleId] = useState<string>(null);
    const showAllRef = useRef<boolean>(false);
    const roleApiClient = new RoleApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);
    const editPermission = signInService.hasPermission(permissions);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        try {
            const { roles } = await roleApiClient.listRoles();
            const dataRoles = setDataRoles(roles, currentUser.authDetails.identityType);

            if (!isMountRef.current) {
                return;
            }
            getColumns();
            setData(dataRoles);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    const getColumns = () => {
        const headerColumnsI18 = getHeaderColumnsI18(editPermission, showAllRef, setSelectedRoleId);
        setHeaderColumns(headerColumnsI18);
    };

    const handleConfirm = (result: boolean) => {
        if (result) {
            deleteRole(selectedRoleId);
        }
        setSelectedRoleId(null);
    };

    const handleShowAll = () => {
        showAllRef.current = !showAllRef.current;
        getColumns();
    };

    const deleteRole = async (id: string) => {
        try {
            await roleApiClient.deleteRole(id);
            fetchData();
        } catch (error) {
            setError(generateErrorMessage(error));
        }
    };

    return (
        <PageContainer title={t("roleAdministration")}>
            {headerColumns && <div data-testid="columns" />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("roleAdministration")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Grid container spacing={1}>
                        {!error && (
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button color="primary" variant="text" onClick={handleShowAll}>
                                        {showAllRef.current ? t("hideAllDetails") : t("showAllDetails")}
                                    </Button>
                                    {editPermission && (
                                        <Button component={Link} color="primary" endIcon={<Add />} variant="text" to="/role-administration/create">
                                            {t("createRole")}
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        )}
                        {error ? (
                            <Grid item xs={12} md={8}>
                                <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                            </Grid>
                        ) : null}

                        {editPermission === null ? null : (
                            <Grid item xs={12}>
                                <TableContainer columns={headerColumns} data={data} loading={loading} theme={theme} fetchData={fetchData} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmDialog message={`${t("ruSureDelete")}?`} open={!!selectedRoleId} onClose={handleConfirm} />
            <InfoDialog
                isError={true}
                message={[t("cannotDeleteRole"), t("roleRetry")]}
                open={!!error}
                theme={theme}
                title={t("failed")}
                onClose={() => setError(null)}
            />
        </PageContainer>
    );
};

export default RoleAdministrationPage;
