import React, {useContext} from 'react';
import {PolicyContext} from '../common/context';

import {PolicyScreen} from '../shared/Screen';
import RenewalPay from './RenewalPay';

export const RenewalScreen = (props) => {
    return (
        <PolicyScreen sequenceId={props.match.params.sequenceId}>
            <RenewalScreenContent {...props} />
        </PolicyScreen>
    );
}

const RenewalScreenContent = () => {
    const {policy} = useContext(PolicyContext);
    
    if (!policy) return null;

    return (
            <>
                <RenewalPay />
            </>
    );
};
