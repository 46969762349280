import React, { useContext, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import GlobalContext from "../../../context/global-context";
import { ESearchType, HomeContext, ISearchPolicyParams, RESET_SEARCH_PARAMS, SET_SEARCH_PARAMS, SET_SEARCH_TYPE } from "../../models";
import { AutocompleteCustom, DateRangeCustom, EContactType, LoadingBtn } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { FTextField } from "../../../components/Formik/FTextField";
import { SearchItemsSelect } from "../SearchItems/SearchItemsSelect";
import { FSelectField } from "../../../components/Formik";
import Divider from "@material-ui/core/Divider";
import { Hidden } from "@material-ui/core";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

type ISearchProps = Record<string, unknown>;

export const SearchPolicy: React.FC<ISearchProps> = () => {
    const { locale, products } = useContext(GlobalContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState(null);
    const [, setEffectiveToDate] = useState(false);
    const [state, dispatch] = useContext(HomeContext);
    const [productName, setProductName] = useState(null);
    const [inputProductName, setInputProductName] = useState("");
    const { fetching } = state;

    const handleClear = () => {
        setDateRange(null);
        setProductName(null);
        setInputProductName("");
        dispatch({ type: RESET_SEARCH_PARAMS });
    };

    // uncheck effectiveToDate checkbox
    const handleDataRange = (value: [Date, Date]) => {
        setDateRange(value);
        setEffectiveToDate(false);
    };

    // SUR-168 hide this is for now.
    // For now clicking the checkbox should set to to today’s date and from to (today - 1 week).
    // const handleIncludeLapsedContracts = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    //   if (checked) {
    //     setDateRange([ FormatUtils.minusToday(1, 'weeks').toJSDate() , new Date()]);
    //   } else {
    //     setDateRange([null, null]);
    //   }
    //   setEffectiveToDate(checked);
    // }

    const handleSubmit = (values: ISearchPolicyParams) => {
        const searchParams: Partial<ISearchPolicyParams> = {};
        const [from, to] = dateRange || [];

        if (inputProductName) {
            searchParams.productName = inputProductName.trim();
        }

        if (values.contactType) {
            searchParams.contactType = values.contactType;
        }

        if (values.contactType === EContactType.Company && values.companyRegistrationNumber) {
            searchParams.companyRegistrationNumber = values.companyRegistrationNumber.trim();
        }

        if (values.postcode) {
            searchParams.postcode = values.postcode.trim();
        }

        if (values.effectiveToDate) {
            searchParams.effectiveToDate = values.effectiveToDate;
        }

        if (from) {
            searchParams.from = FormatUtils.renderFormatDate(from, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (to) {
            searchParams.to = FormatUtils.renderFormatDate(to, FormatUtils.SERVER_DATE_FORMAT);
        }

        dispatch({ type: SET_SEARCH_PARAMS, payload: searchParams });
    };

    const formatDateRange = () => {
        const [from, to] = dateRange || [];
        if (from && to) {
            return `${FormatUtils.renderFormatDate(from, "D")} - ${FormatUtils.renderFormatDate(to, "D")}`;
        } else {
            return "";
        }
    };

    const contactTypeOptions = useMemo(() => {
        return [EContactType.Individual, EContactType.Company].map((item) => ({ label: t(item.toLowerCase()), value: item }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const optionsProduct = useMemo(() => Object.values(products).map((product) => ({ label: product.name, value: product.id })), [products]);

    return (
        <Formik
            initialValues={{
                productName: "",
                contactType: "",
                companyRegistrationNumber: "",
                postcode: "",
                effectiveToDate: false,
            }}
            onSubmit={handleSubmit}
        >
            {({ resetForm, values, setFieldValue }) => (
                <Form>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6">{t("advancedSearch")}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        data-testid="close-btn"
                                        onClick={() => dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.Global })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SearchItemsSelect />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <DateRangeCustom
                                displayValue={formatDateRange()}
                                label={t("createdBetween")}
                                locale={locale}
                                value={dateRange}
                                theme={theme}
                                onChange={handleDataRange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AutocompleteCustom
                                inputValue={inputProductName}
                                label={t("product")}
                                name="productName"
                                options={optionsProduct}
                                theme={theme}
                                value={productName}
                                fullWidth
                                onChange={(event, newValue: string) => {
                                    setProductName(newValue);
                                    setInputProductName(newValue);
                                }}
                                onInput={(event) => {
                                    setInputProductName(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FSelectField label={t("contactType")} name="contactType" options={contactTypeOptions} />
                        </Grid>

                        <Hidden xsUp={values.contactType !== EContactType.Company}>
                            <Grid item xs={12}>
                                <FTextField label={t("companyRegNo")} name="companyRegistrationNumber" />
                            </Grid>
                        </Hidden>

                        <Grid item xs={12}>
                            <FTextField label={t("postcode")} name="postcode" />
                        </Grid>

                        {/*
            // SUR-168 hide this is for now.
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="default" checked={effectiveToDate} onChange={handleIncludeLapsedContracts} />}
                label={t('includeLapsedContracts')}
              />
            </Grid> */}

                        <Grid item xs={12}>
                            <Box my={2} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={fetching}
                                        onClick={() => {
                                            resetForm();
                                            handleClear();
                                        }}
                                    >
                                        {t("clear")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingBtn fetching={fetching} label={t("applyFilters")} theme={theme} type="submit" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
