import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { ProductApiClient } from '@blocksure/blocksure-core/dist/src/services/api-clients';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import { generateConfluenceSpace, generateConfluenceLink } from '@blocksure/blocksure-core/dist/src/utilities/generateConfluenceData';
import ImportCsv from '../@ImportCsv/@ImportCsv';
import ImportImage from '../@ImportImage/@ImportImage';
import { namespacedLocalStorage } from '../helper';


const MainScreen = ({ locale, currentUser }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const productApiClient = new ProductApiClient(namespacedLocalStorage);
    const [products, setProducts] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [schema, setSchema] = useState(null);
    const [showUploadImage, setShowUploadImage] = useState(false);
    const hasImage = false;

    const value = generateConfluenceSpace(locale);

    const handleShowImportImage = () => {
        setShowUploadImage(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { items } = await productApiClient.listProducts();
                setProducts(items);
                setOptions(items.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({ label: name, value: id })));
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const product = await productApiClient.getProduct(selectedValue);
            const attachmentDef = product.hiddenAttachments?.find((a) => a.name === 'def.json');
            const def = attachmentDef ? await productApiClient.getAttachment(selectedValue, attachmentDef.location, true) : {};
            setSchema(def);
        };
        fetchData();
    }, [selectedValue]);

    const renderMain = () => (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={6} sm={8} md={5}>
                    <Typography variant="h5">{t('importFromCSVFile')}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box textAlign={{ xs: 'right', md: 'center' }}>
                        {/* <BannerClientLogo /> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={5} />
            </Grid>
            <Box my={3}>
                <a target="_blank" href={generateConfluenceLink(value)} rel="noreferrer">
                    {`${t('importTool')} - ${t('helpAndFaqs')}`}
                </a>
            </Box>
            <Grid xs={6}>
                <Box mb={2}>
                    <div className="form-group">
                        <label htmlFor="products">Product</label>
                        <select
                            className="form-control"
                            id="products"
                            value={selectedValue}
                            onChange={(event) => {
                                setSelectedValue(event.target.value);
                            }}
                        >
                            {options.map((it) => (<option value={it.value}>{it.label}</option>))}
                        </select>
                    </div>
                </Box>
                {
                    selectedValue ? (
                        <>
                            <Box mb={1}>
                                <Typography>{t('csvFileUsingSchemaOfProduct')}</Typography>
                            </Box>
                            <a href={'href'}
                                className="doc-link"
                                rel="noopener noreferrer"
                                target="_blank">{'children'}</a>
                            <GetAppIcon fontSize="large" />
                        </>

                    ) : null
                }
            </Grid>
        </>
    );
    return (
        <Grid item xs={12}>
            {!showUploadImage && renderMain()}
            <Grid xs={6}>
                {(!hasImage) && <ImportCsv currentUser={currentUser} schema={schema} />}
                {showUploadImage && <ImportImage currentUser={currentUser} locale={locale} schema={schema} />}
                {(!showUploadImage && hasImage) && <button disabled={!schema} onClick={handleShowImportImage}>{t('next')}</button>}
            </Grid>
        </Grid>
    );
};

export default MainScreen;
