import React from 'react';
import {Link} from 'react-router-dom';
import './Menu.css';
import {formatDate, formatDateWithHours} from './util';
import {SinglePrice} from '../quote/QuoteArray';
import {useDefaultTranslation} from '../common/Translation';
import { InProductCurrency } from '../widgets/InProductCurrency';

export const PolicyList = ({
    policies,
    newPath,
    showPrice = true,
    linkFunc = (it => `${newPath}/quote/${it.sequenceId}/payment`)
}) => {
    const {t} = useDefaultTranslation();
    return (
            Object.values(policies)
                    .map(it => (
                            <div key={`${it.sequenceId}`} className="menu-item gap btn-primary">
                                <Link to={linkFunc(it)} className="seriously-no-decoration">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3 className="btn-primary">{it.product.name}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {showPrice && <div className="col-md-12 text-center">
                                            <h1 className="btn-primary">
                                                <SinglePrice
                                                        product={it.product}
                                                        totalPayable={getFullValue(it.totalPayable)}
                                                        premium={getFullValue(it.grossPremium)}
                                                        ipt={getFullValue(it.totals.IPT)}
                                                />
                                            </h1>
                                        </div>}
                                        {it.status === 'Quoted' && <div className="col-md-12 text-center">
                                            <label className="text-danger">{t('quoteExpiresOn', {quoteExpiry: formatDateWithHours(it.quoteExpiryDate)})}</label>
                                        </div>}
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <h5 className="btn-primary">{it.reportingRef}</h5> 
                                        </div>
                                        <div className="col-xs-6 text-right">
                                            <h5 className="btn-primary text-right">{formatDate(it.effectiveFromDate)} - {formatDate(it.effectiveToDate)}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    ))
    );
};

export const PolicyListSmall = ({
    policies,
    newPath,
    linkFunc = (it => `${newPath}/quote/${it.sequenceId}/payment`)
}) => {
    return (
            Object.values(policies)
                    .map(it => (
                            <div key={`${it.sequenceId}`} className="menu-item gap btn-primary p-4">
                                <Link to={linkFunc(it)} className="seriously-no-decoration">
                                    <div className="row">
                                        <div className="col-xs-4">
                                            <h4 className="btn-primary mb-0">{it.product.name}</h4>
                                        </div>
                                        <div className="col-xs-5">
                                            <h4 className="btn-primary mb-0">{it.reportingRef}</h4>
                                        </div>
                                        <div className="col-xs-3 text-right">
                                            <h4 className="btn-primary text-right mb-0">
                                                <InProductCurrency product={it.product} amount={getFullValue(it.totalPayable)}/>
                                            </h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    ))
    );
};

const getFullValue = (total) => {
    return total ? (total.prorata === 0 ? total.fullTerm : total.prorata) : 0;
};
