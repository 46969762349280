/* eslint-disable no-script-url */
import React from 'react';
import ReactMarkdown, {uriTransformer} from 'react-markdown';
import DocLink from '../common/DocLink';

export const Markdown = ({
    value,
    className,
    useParagraph = false
}) => {
    if (!value || typeof value !== 'string')
        return value || '';

    // Some custom tag renderers.
    const renderers = {
        paragraph: ({children}) => useParagraph ? <p>{children}</p> : <span>{children}</span>,
        link: props => <DocLink href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</DocLink>
    };

    return (
            <ReactMarkdown
                    className={className}
                    source={value}
                    transformLinkUri={uri => uriTransformer(uri)
                            .replace('javascript:void(0)', uri)}
                    renderers={renderers}
            />
    );
};
