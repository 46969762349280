import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    chipDefault: {
        margin: theme.spacing(0.5, 1),
    },
    listContentWrapper: {
        overflowY: "hidden",
        display: "block",
        margin: 0,
        boxSizing: "border-box",
    },
    listMain: {
        listStyleType: "disc",
        columns: "2",
        listStylePosition: "inside",
    },
    permissions: {
        padding: 0,
    },
    listIcon: {
        maxWidth: "20px",
        minWidth: "15px",
        width: "100%",
    },
    listIconSVG: {
        fontSize: "0.8rem",
    },
    descriptionBtn: {
        padding: `${theme.spacing(0.5, 0.1)}!important`,
    },
}));
