import React, {useContext} from 'react';
import {
    AuthContext,
    CurrentStepContext,
    MetadataContext,
    PolicyContext,
    PolicyholderContext,
    ProductContext,
    SubmissionContext
} from '../common/context';
import './DebugView.css';
import {merge} from 'lodash';
import {InProductCurrency} from '../widgets/InProductCurrency';

export const QuoteDebugView = () => {
    const {
        metadata,
        updateMetadata
    } = useContext(MetadataContext);
    const dueDateOverridePeriod = metadata['dueDateOverridePeriod'];
    return (
            global.notProd && (
                    <ProductContext.Consumer key="params">{({
                        defaultSubmission,
                        product = {}
                    }) => [
                        <SubmissionContext.Consumer key="submission">{({
                            submission,
                            attachments,
                            updateSubmission
                        }) => (
                                <pre className="debug-view">
                        <div><label><button className="btn btn-secondary" onClick={() => updateSubmission(merge({}, defaultSubmission, submission))}>Set Default Submission</button></label></div>
                        <div><label>Override pay frequency (minutes):</label>
                            <input type="number"
                                   value={dueDateOverridePeriod ? dueDateOverridePeriod.substring(2, dueDateOverridePeriod.length - 1) : ''}
                                   onChange={({target}) => updateMetadata('dueDateOverridePeriod', target.value === '' ? undefined : `PT${target.value}M`)}/>
                        </div>
                        <div><label>Metadata: </label>{JSON.stringify(metadata, null, 4)}</div>
                        <div><label>Submission: </label>{JSON.stringify(submission, null, 4)}</div>
                        <div><label>Attachments: </label>{JSON.stringify(attachments, null, 4)}</div>
                    </pre>
                        )}</SubmissionContext.Consumer>,
                        <CurrentStepContext.Consumer key="params">{({
                            stepNames,
                            firstStep,
                            previousStep,
                            previousStepName,
                            currentStep,
                            nextStep,
                            nextStepName,
                            shouldRegister,
                            shouldQuote,
                            stepSchema,
                            stepUiSchema
                        }) => (
                                <PolicyContext.Consumer key="params">{({policy}) => (
                                        <PolicyholderContext.Consumer key="params">{({policyholder = {}}) => (
                                                <AuthContext.Consumer key="params">{({
                                                    auth,
                                                    hasPassword
                                                }) => (
                                                        <pre className="debug-view">
                                    <div><label>StepNames: </label>{`${stepNames}`}</div>
                                    <div><label>FirstStep: </label>{`${!!firstStep}`}</div>
                                    <div><label>PreviousStep: </label><button className="btn btn-sm btn-secondary"
                                                                              onClick={previousStep}>{`${previousStepName}`}</button></div>
                                    <div><label>CurrentStep: </label>{`${currentStep}`}</div>
                                    <div><label>NextStepName: </label><button className="btn btn-sm btn-secondary"
                                                                              onClick={nextStep}>{`${nextStepName}`}</button></div>
                                    <div><label>ShouldRegister: </label>{`${shouldRegister}`}</div>
                                    <div><label>ShouldQuote: </label>{`${shouldQuote}`}</div>
                                    <div><label>StepSchema: </label>{`${JSON.stringify(stepSchema)}`}</div>
                                    <div><label>StepUiSchema: </label>{`${JSON.stringify(stepUiSchema)}`}</div>
                                    <br/>
                                    <div><label>Product ID: </label>{`${product.id}`}</div>
                                    <div><label>Product Name: </label>{`${product.name}`}</div>
                                                            {policy && <div>
                                                                <br/>
                                                                <div><label>Policy ID: </label>{`${policy.id}`}</div>
                                                                <div><label>Policy Sequence ID: </label>{`${policy.sequenceId}`}</div>
                                                                <div><label>Policy Sequence: </label>{`${policy.sequence}`}</div>
                                                                <div><label>Policy Version: </label>{`${policy.version}`}</div>
                                                                <div><label>Policy Previous Quote ID: </label>{`${policy.previousQuoteId}`}</div>
                                                                <div><label>Policy Quote ID: </label>{`${policy.quoteId}`}</div>
                                                                <div><label>Policy Currency: </label>{`${policy.currency}`}</div>
                                                                <div><label>Policy Status: </label>{`${policy.status}`}</div>
                                                                <div><label>Policy Inception: </label>{`${policy.inceptionDate}`}</div>
                                                                <div><label>Policy Expiry: </label>{`${policy.expiryDate}`}</div>
                                                                <div><label>Policy Eff From: </label>{`${policy.effectiveFromDate}`}</div>
                                                                <div><label>Policy Eff To: </label>{`${policy.effectiveToDate}`}</div>
                                                                <div><label>Premium: </label><InProductCurrency
                                                                        amount={policy.grossPremium && policy.grossPremium.fullTerm}/></div>
                                                                <div><label>IPT: </label><InProductCurrency
                                                                        amount={policy.totals && policy.totals.IPT && policy.totals.IPT.fullTerm}/>
                                                                </div>
                                                                <div><label>Total Payable: </label><InProductCurrency
                                                                        amount={policy.totalPayable && policy.totalPayable.fullTerm}/></div>
                                                            </div>}
                                                            <br/>
                                    <div><label>Auth: </label>{`${auth && JSON.stringify(auth)}`}</div>
                                    <div><label>Set password: </label>{`${hasPassword}`}</div>
                                    <br/>
                                    <div><label>Policyholder ID: </label>{`${policyholder && policyholder.id}`}</div>
                                    <div><label>Policyholder Email: </label>{`${policyholder && policyholder.primaryEmailAddress}`}</div>
                                    <div><label>Policyholder Local: </label>{policyholder && JSON.stringify(policyholder.local, null, 4)}</div>
                                    <div><label>Policyholder Identity Info: </label>{policyholder && JSON.stringify(policyholder.identityInfo, null, 4)}</div>
                                    <div><label>Policyholder Shared: </label>{policyholder && JSON.stringify(policyholder.shared, null, 4)}</div>
                                </pre>
                                                )}</AuthContext.Consumer>
                                        )}</PolicyholderContext.Consumer>
                                )}</PolicyContext.Consumer>
                        )}</CurrentStepContext.Consumer>
                    ]}</ProductContext.Consumer>
            )
    );
};
