import React, {useEffect, useState} from 'react';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {NotAuthenticatedRoutes} from './NotAuthenticatedRoutes';
import {AuthApiClient, PolicyholderApiClient, UserApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {convertToNewPath,  SureAppName} from '../base';
import {PolicyholderProvider} from '../policyholder/PolicyholderProvider';
import {AuthContext} from '../common/context';
import Loading from '../widgets/Loading';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const authApiClient = new AuthApiClient(SureAppName);
const userApiClient = new UserApiClient(SureAppName);
const policyholderApiClient = new PolicyholderApiClient(SureAppName);

/**
 * Provides global state for the currently authenticated user and a method to update it.
 */
export const AuthenticationProvider = ({
    surelyncProps,
    children
}) => {
    const history = useHistory();
    const [auth, setAuth] = useState(null);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [hasPassword, setHasPassword] = useState(null);
    const [policyholder, setPolicyholder] = useState(null);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    const updateAuth = async (loadPolicyholder) => {
        const auth = await authApiClient.whoAmI();

        if (window.location.pathname.includes('renewal/seq') && auth.username === 'anonymous') {
            setRedirectToLogin(true);
        }
        setAuth(auth);
        if (auth.username === 'anonymous') return;
        setHasPassword(await userApiClient.hasSetPassword());
        if (!loadPolicyholder) return;
        const exists = await policyholderApiClient.checkIfPolicyholderExists(auth.username);
        if (!exists) return;
        const policyholder = await policyholderApiClient.getPolicyholder(auth.username);
        setPolicyholder(policyholder);
    };

    useEffect(() => {
        updateAuth(true);
        // eslint-disable-next-line
    }, []);

    const login = async (email, password, reset) => {
        try {
            // We get a token using a password
            if (password) {
                await authApiClient.getToken({
                    auth: {
                        username: email,
                        password
                    }
                });
                updateAuth(!reset);
                // Or if we haven't seen this user before anonymously.
            } else {
                await policyholderApiClient.getPolicyholderToken(email);
                updateAuth(false);
            }
        } catch (e) {
            console.error(`Unable to authenticate [${email}], [${password}].`, e);
            throw new Error(generateErrorMessage(e, 'You have entered an invalid username or password.'));
        }
    };

    const updateAuthEmail = async (email, password) => {
        await authApiClient.clearJwtToken();
        await login(email, password);
    };

    const signOut = async () => {
        sessionStorage.clear();
        await authApiClient.clearJwtToken();
        await updateAuth(true);
        history.push(`${newPath}/`);
    };

    const silentChangeAuth = async () => {
        await updateAuth(true);
    }

    const userExists = (username) => authApiClient.exists(username);

    // If the auth is null then either we're still checking whoAmI or there was a critical error.
    if (!auth) return <Loading/>;

    return (
            <AuthContext.Provider value={{
                auth,
                hasPassword,
                login,
                policyholder,
                silentChangeAuth,
                signOut,
                surelyncProps,
                updateAuthEmail,
                updateAuth,
                userExists
            }}>
                <PolicyholderProvider>
                    {
                        surelyncProps || auth.username !== 'anonymous'
                                ? children
                                : <NotAuthenticatedRoutes login={login} redirectToLogin={redirectToLogin} />
                    }
                </PolicyholderProvider>
            </AuthContext.Provider>
    );
};
