import React, {useContext, useEffect} from 'react';
import {TradingGroupContext} from '../common/context';
import {CurrentStepProvider} from '../step/CurrentStepProvider';
import {PolicyScreen, ProductScreen} from '../shared/Screen';
import {SubmissionProvider} from '../submission/SubmissionProvider';
import {PolicyProvider} from '../policy/PolicyProvider';
import {QuoteDebugView} from '../debug/QuoteDebugView';
import {FLOW_AMEND, FLOW_QUOTE, StepWidget} from '../step/StepWidget';
import {MetadataProvider} from '../submission/MetadataProvider';

export const QuoteFlow = ({
    children,
    amend,
    match,
    history
}) => {
    const isSequence = `${match.params.productOrSequenceId}`.indexOf('seq_') === 0;
    const base = match.url.split('/')
            .filter(it => it !== '')[0];

    const {products} = useContext(TradingGroupContext);

    useEffect(() => {
        if (!isSequence) {
            // If the product is not part of the current group, redirect.
            if (products && !products[match.params.productOrSequenceId]) {
                console.error(`This product is not in the trading group, redirecting to /${base}.`);
                history.push(`/${base}`);
            }
        }
    });

    const flow = amend ? FLOW_AMEND : FLOW_QUOTE;
    const screens = (
            <CurrentStepProvider flow={flow}>
                <MetadataProvider>
                    <SubmissionProvider type="quote">
                        <QuoteDebugView/>
                        <div className="clearfix">
                            {children}
                            <StepWidget flow={flow}/>
                        </div>
                    </SubmissionProvider>
                </MetadataProvider>
            </CurrentStepProvider>
    );

    // Wire slightly differently for existing policy vs new policy (when we pass the product name instead).
    if (isSequence) {
        return (
                <PolicyScreen sequenceId={match.params.productOrSequenceId}>
                    {screens}
                </PolicyScreen>
        );
    } else {
        return (
                <ProductScreen productId={match.params.productOrSequenceId}>
                    <PolicyProvider sequenceId={sessionStorage.getItem(`com.blocksure.${match.params.productOrSequenceId}.sequenceId`)}>
                        {screens}
                    </PolicyProvider>
                </ProductScreen>
        );
    }
};
