import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CellInfo } from "react-table";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { PolicyApiClient, PolicyholderApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {
    ELocale,
    EPolicyStatus,
    generateCsvData,
    ITableData,
    localeService,
    MessageBox,
    policiesTableColumns,
    setDataPolicies,
    TableContainer,
    TableOptions
} from "@surelync/common";
import { useStyles } from "./styles";
import TabsTable from "./components/TabsTable";
import GlobalContext from "../context/global-context";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import { BannerClientLogo, PageContainer } from "../components";
import CustomiseFlyout from "../components/flyouts/CustomiseFlyout";
import { ETabs, StatusByPath } from "./tabs.model";
import Axios from "axios";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { getHeaderColumnsI18 } from "./columns.config";
import Box from "@material-ui/core/Box";

const tableName = "policy";

const PoliciesPage: React.FC = () => {
    const { t } = useTranslation();
    const { namespacedLocalStorage, displayedTableColumns, currentUser, products, setOpenCustomiseFlyout, setDisplayedTableColumns } =
        useContext(GlobalContext);
    const history = useHistory();
    const location = useLocation();
    const tabname = (location.pathname.substring(1) || "policies") as ETabs;
    const [activeTab, setActiveTab] = useState(tabname);
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [globalSearch, setGlobalSearch] = useState<string | null>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);
    const [blobLoading, setBlobLoading] = useState<boolean>(true);
    const [csvData, setCsvData] = useState<any>([]);
    const parentContainerRef = useRef(null);

    const theme = useTheme();
    const classes = useStyles();
    const csvLinkEl = useRef(null);
    const { identityType } = currentUser.authDetails;

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);

    const defaultColumns = useMemo(() => {
        let result = policiesTableColumns.filter((column) => !column.roles || column.roles.includes(identityType));

        // CLIENT-126 In Japan, hide the Insurance Premium tax column from column preferences
        if (localeService.locale === ELocale.jaJP) {
            result = result.filter((column) => column.id !== "ipt");
        }

        return result;
    }, [identityType]);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        setActiveTab(tabname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const handleChange = useCallback((event: React.ChangeEvent<unknown>, pathname: ETabs) => {
        history.push(`/${pathname}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownloadCSV = () => {
        const { sortIndexes } = (data || {}) as any;
        const sortedData = sortIndexes ? sortIndexes.map((i) => data[i]) : [];

        if (!isMountRef.current) {
            return;
        }

        setCsvData(generateCsvData(headerColumns, sortedData));
        setTimeout(() => csvLinkEl.current?.link.click(), 100);
    };

    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname, backBtnlabel: t(tabname) } };
    };

    const getColumns = (statuses) => {
        const isMobile = window.innerWidth < theme.breakpoints.values.md;
        const isQuote = [EPolicyStatus.Quoted, EPolicyStatus.Referral, EPolicyStatus.Dtq].includes(statuses);
        const allColumns = getHeaderColumnsI18(isMobile, handleRedirect, isQuote);
        let resultAfterFiltering = [];

        if (displayedTableColumns) {
            displayedTableColumns.forEach((column) => {
                const isDisplayed = allColumns.find((disabledColumn) => disabledColumn.accessor === column.id);
                if (isDisplayed) {
                    resultAfterFiltering.push(isDisplayed);
                }
            });
        } else {
            resultAfterFiltering = allColumns;
        }

        if (statuses === EPolicyStatus.Referral) {
            resultAfterFiltering.push({
                Header: "",
                accessor: "viewButton",
                Cell: (row: CellInfo) => {
                    if (row.value) {
                        return (
                            <Button component={Link} to={handleRedirect(row.value)} variant="outlined" color="primary" fullWidth size="small">
                                {t("view")}
                            </Button>
                        );
                    }

                    return <div></div>;
                },
                size: "small",
            });
        }

        return resultAfterFiltering;
    };

    const fetchBlob = async () => {
        if (!isMountRef.current) {
            return;
        }

        setBlobLoading(true);

        const result = [];

        try {
            const blobs = await blobApiClient.retrievedTag(`${tableName}-column-preferences`);

            blobs[tableName].forEach((blob) => {
                const key = Object.keys(blob)[0];
                const column = defaultColumns.find((defaultColumn) => defaultColumn.id === key);
                if (column) {
                    result.push(column);
                }
            });

            if (!isMountRef.current) {
                return;
            }

            setDisplayedTableColumns(result);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setDisplayedTableColumns(defaultColumns.filter((c) => !c.isAdditional));
        }
        setBlobLoading(false);
    };

    const fetchData = async (rowsPerPage: number, page: number, isRefresh: boolean) => {
        const load = ["load.product", "load.sections", "load.breakdown", "load.transactions"];

        if (!isMountRef.current) {
            return;
        }

        if (!isRefresh) {
            setLoading(true);
            setError(null);
            setData(null);
        }

        try {
            const policies = await policyApiClient.search({ statuses: StatusByPath[activeTab] }, rowsPerPage, page, load);
            const policyholderIds = policies.items.map((item) => item.policyholderId);

            // Error message "Request failed with status code 431" appears
            // Request Header Fields Too Large
            // Set limit for loading by one request
            const limit = 75;
            const uniqIds = Array.from(new Set(policyholderIds));
            const steps = Math.ceil(uniqIds.length / limit);

            const requests = [];

            for (let i = 0; i < steps; i++) {
                const offset = i * limit;
                requests.push(
                    policyholderApiClient.search({
                        policyholderIds: uniqIds.slice(offset, offset + limit),
                    })
                );
            }

            const responses = await Axios.all(requests);

            if (!isMountRef.current) {
                return;
            }

            const policyholders = {};
            responses.forEach((r) => Object.assign(policyholders, r));

            const headerColumnsI18 = getColumns(StatusByPath[activeTab]);
            const dataPolicies = setDataPolicies(identityType, policies, products, policyholders);

            setData(dataPolicies);
            setTotal(policies.total);
            if (!isRefresh) {
                setHeaderColumns(headerColumnsI18);
            }
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            console.warn("error", error.message);
            setError(generateErrorMessage(error));
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchBlob();
        return () => {
            setDisplayedTableColumns([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filename = useMemo(() => `${t("policies")} ${t(StatusByPath[tabname].toLowerCase())} - ${FormatUtils.now("DD")}.csv`, [tabname]);

    return (
        <PageContainer title={t(tabname)}>
            {defaultColumns && <div data-testid="blob" />}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t(tabname)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TabsTable disabled={loading} value={activeTab} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.endStyle}>
                    <TableOptions
                        theme={theme}
                        searchLabel={t("filterThisPage")}
                        searchChange={setGlobalSearch}
                        downloadLabel={t("download")}
                        downloadClick={handleDownloadCSV}
                        customiseLabel={t("customise")}
                        customiseClick={() => setOpenCustomiseFlyout(true)}
                    />
                </Grid>
                <Grid item xs={12} ref={parentContainerRef}>
                    {error ? (
                        <Grid item xs={12} md={8} lg={6} xl={4}>
                            <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                        </Grid>
                    ) : null}
                    <TableContainer
                        theme={theme}
                        columns={headerColumns}
                        data={data}
                        globalSearch={globalSearch}
                        loading={loading}
                        blobLoading={blobLoading}
                        total={total}
                        displayedTableColumns={displayedTableColumns}
                        fetchData={fetchData}
                        refreshInterval={15000}
                        tabValue={activeTab}
                        withBlob
                    />
                </Grid>
            </Grid>
            <CustomiseFlyout tableName={tableName} defaultColumns={defaultColumns} />
            <CSVLink ref={csvLinkEl} data={csvData} filename={filename} />
        </PageContainer>
    );
};

export default PoliciesPage;
