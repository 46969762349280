import React, {useContext} from 'react';
import {get} from 'lodash';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {TradingGroupContext} from '../common/context';
import {convertToNewPath} from "../base";

export const ProductLink = ({product}) => {
    const {formulator} = useContext(TradingGroupContext) || {};
    const {t} = useDefaultTranslation({product});
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    
    const linkClassName = 'btn btn-lg btn-block btn-primary text-center gap';
    return (
            <Link to={`${newPath}/quote/${product.id}`} id={`${product.id}-lg`} className={linkClassName}>
                <div>{get(formulator, 'mainMenu.newQuote') || t('quoteButton')}</div>
            </Link>
    );
};
