import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { PolicyContext } from "../../models/context";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import LinkButton from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { CurrencyUtils, EIdentityType, EPolicyStatus, ITableData, localeService, RecordsNotFound, TableContainer } from "@surelync/common";
import { setDataPayments } from "./helper";
import { Hidden } from "@material-ui/core";
import { headerColumnsI18 } from "./columns.config";
import GlobalContext from "../../../context/global-context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

interface IProps {
    isSectionLevel?: boolean; // When user select the section do not show modal. Only at the Overview tab need modal.
}

const Payments: React.FC<IProps> = ({ isSectionLevel }) => {
    const classes = useStyles();
    const { currentUser } = useContext(GlobalContext);
    const [{ policy, selectedSection, sequences }] = useContext(PolicyContext);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const theme = useTheme();
    const transactions = sequences.reduce((arr, sequence) => [...arr, ...sequence.transactions], []);
    const { identityType } = currentUser.authDetails;

    useEffect(() => {
        if (!transactions.length) {
            return;
        }

        setHeaderColumns(headerColumnsI18);
        setData(setDataPayments(transactions, identityType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const handlePaymentDetails = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const renderLine = ({ index, isDivider, title, value }) => {
        return (
            <Fragment key={index}>
                <Grid item xs>
                    <Typography variant="body1">{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="right">
                        {value}
                    </Typography>
                </Grid>
                <Hidden xsUp={!isDivider}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Hidden>
            </Fragment>
        );
    };

    const renderLines = () => {
        const lines = [];
        const grossPremium = isSectionLevel ? selectedSection.grossPremium : policy.grossPremium;
        const totals = isSectionLevel ? selectedSection.breakdown : policy.totals;
        const totalPayable = isSectionLevel ? selectedSection.totalPayable : policy.totalPayable;

        grossPremium &&
            lines.push({
                title: t("grossPremium"),
                value: CurrencyUtils._renderCurrency(grossPremium.cumulative, localeService.locale, policy.currency),
            });

        totals["IPT"] &&
            lines.push({
                title: t("ipt"),
                value: CurrencyUtils._renderCurrency(totals["IPT"].cumulative, localeService.locale, policy.currency),
            });

        lines.push({
            title: t("totalPayable"),
            value: CurrencyUtils._renderCurrency(totalPayable.cumulative, localeService.locale, policy.currency),
        });

        if (!isSectionLevel) {
            lines.push({
                title: t("paidToDate"),
                value: CurrencyUtils._renderCurrency(policy.cashMovements.cumulative, localeService.locale, policy.currency),
            });

            lines.push({
                title: t("amountsDue"),
                value: CurrencyUtils._renderCurrency(policy.cashMovements.future, localeService.locale, policy.currency),
            });
        }

        return lines.map((line, index) => renderLine({ ...line, index, isDivider: index !== lines.length - 1 }));
    };

    const displayData = policy.status === EPolicyStatus.Quoted ? [] : data;

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("premiumSummary")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container direction="column" classes={{ root: classes.content }}>
                    <Grid item xs>
                        <Grid container spacing={1}>
                            {renderLines()}
                        </Grid>
                    </Grid>

                    {/* Overview level (or on the Section tab if there is only one Section in the view.) */}
                    <Hidden xsUp={isSectionLevel}>
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <LinkButton href="" color="primary" variant="body2" onClick={handlePaymentDetails}>
                                    {identityType === EIdentityType.Broker ? t("premiumPaymentDetails") : t("policyholderPaymentDetails")}
                                </LinkButton>
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
            <Dialog fullWidth maxWidth="lg" onClose={handleClose} aria-labelledby="payments-dialog" open={Boolean(anchorEl)}>
                <DialogTitle>
                    <Typography component="span" variant="h6">
                        {t("payments")}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <TableContainer columns={headerColumns} data={displayData} fetchData={() => undefined} theme={theme} />

                    {displayData && !displayData.length ? (
                        <Box my={10}>
                            <RecordsNotFound theme={theme} />
                        </Box>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={1}>
                        <Grid item>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {t("close")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Payments;
