import React from "react";
import { CellInfo } from "react-table";
import { DescriptionColumns } from "../components";
import { i18n } from "@surelync/common";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Remove from "@material-ui/icons/Remove";

type IDeleteCallback = (id: string) => void;

export const getHeaderColumnsI18 = (onDeleteRole: IDeleteCallback) => [
    {
        Header: () => i18n.t("name"),
        accessor: "name",
        size: "small",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        Cell: (row: CellInfo) => {
            return <DescriptionColumns permissions={row.row.original.permissions} value={row.value} />;
        },
        size: "large",
    },
    {
        Header: () => <div></div>,
        accessor: "removeButton",
        Cell: (row: CellInfo) => {
            return (
                <Box display="flex" justifyContent="flex-end">
                    {row.value && (
                        <Button color="primary" variant="text" endIcon={<Remove />} size="small" onClick={() => onDeleteRole(row.row.original.id)}>
                            {i18n.t("remove")}
                        </Button>
                    )}
                </Box>
            );
        },
        size: "small",
    },
];
