import React from "react";
import { Link } from "react-router-dom";
import { CurrencyUtils, i18n, localeService } from "@surelync/common";
import Typography from "@material-ui/core/Typography";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const getHeaderColumnsI18 = (onRedirect: (url: string) => any) => [
    {
        Header: () => i18n.t("claimNo"),
        accessor: "claimNo",
        Cell: (row: CellInfo) => (
            <LinkButton component={Link} to={onRedirect(`/claims/${row.row.original.id}`)} variant="caption">
                {row.row.original.claimNo}
            </LinkButton>
        ),
    },
    {
        Header: () => {
            return <div>{i18n.t("status")}</div>;
        },
        accessor: "status",
        Cell: (row: CellInfo) => {
            return <Typography variant="button">{row.value}</Typography>;
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("category")}</div>;
        },
        accessor: "category",
    },
    {
        Header: () => {
            return <div>{i18n.t("dateOfLoss")}</div>;
        },
        accessor: "dateOfLoss",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
    },
    {
        Header: () => {
            return <div>{i18n.t("dateReceived")}</div>;
        },
        accessor: "dateReceived",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
    },
    {
        Header: () => {
            return <div>{i18n.t("paidUpToDate")}</div>;
        },
        accessor: "paidUpToDate",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("lossReserve")}</div>;
        },
        accessor: "lossReserve",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    {
        Header: () => {
            return <div>{i18n.t("totalIncurred")}</div>;
        },
        accessor: "totalIncurred",
        sortType: "basic",
        Cell: (row: CellInfo) => {
            return <div>{CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}</div>;
        },
    },
    // {
    //   Header: () => {
    //     return <div>{i18n.t("description")}</div>;
    //   },
    //   accessor: "description",
    //   Cell: (row: CellInfo) => {
    //     return (
    //       <Tooltip title={row.value} placement="bottom">
    //         <div>{`${i18n.t("description")}...`}</div>
    //       </Tooltip>
    //     );
    //   },
    // },
];
