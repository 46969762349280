import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import { BannerClientLogo, PageContainer } from "../components";
import { ClientLogo } from "./components/ClientLogo/ClientLogo";
import { signInService } from "@surelync/common";
import { useHistory } from "react-router-dom";

const CompanyLogoPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    useLayoutEffect(() => {
        if (!signInService.hasPermission(["BRAND_EDIT"])) {
            history.replace({ pathname: `/404` });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer title={t("logo")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("logo")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ClientLogo />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default CompanyLogoPage;
