import countries from '../../dist/src/utilities/countries.json';

function countryPrefix(country, existingCountry) {
    try {
        return countries.find(({alpha2Code, phonePrefix}) => {
            if (!existingCountry) {
                return alpha2Code === country;
            } else {
                return existingCountry === phonePrefix;
            }
        }).phonePrefix;
    } catch (e) {
        return null;
    }
}

export function StartingPrefix(existingPhoneNumber, country) {
    let existingCountry;
    // If this is an existing phone number get the current extension.
    if (existingPhoneNumber !== undefined && existingPhoneNumber.startsWith('+')) {
        existingCountry = existingPhoneNumber.split(' ')[0];
    }

    let startingPrefix = countryPrefix(country, existingCountry);
    if (startingPrefix === undefined) {
        startingPrefix = countryPrefix(country, null);
    }
    if ((startingPrefix === undefined || startingPrefix == null) && existingPhoneNumber !== undefined) {
        // The longest starting prefix is 3 characters so for example if the phone number is 447399903999 we check +4 then +44 then return +44
        for (let i = 1; i <= 3; i++) {
            let checkPrefix;
            if (existingPhoneNumber.startsWith('+')) {
                checkPrefix = existingPhoneNumber.slice(0, i);
            } else {
                checkPrefix = `+${existingPhoneNumber.slice(0, i)}`;
            }
            const prefix = countries.find(({phonePrefix}) => phonePrefix === checkPrefix);
            if (prefix !== undefined && prefix != null) {
                startingPrefix = prefix.phonePrefix;
                break;
            }
        }
    }

    return startingPrefix;
}
