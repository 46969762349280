import React from 'react';

const Error = ({children}) => (
    <div className="text-center">
        <h4>
            <div className="badge badge-danger">{children}</div>
        </h4>
    </div>
);

export default Error;
