import React, {useContext} from 'react';
import {useDefaultTranslation} from '../common/Translation';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {ClaimList} from '../shared/ClaimList';
import {ClaimListContext} from '../common/context';
import {convertToNewPath} from "../base";

export const ClaimHistory = () => {
    const {t} = useDefaultTranslation();
    const {claims} = useContext(ClaimListContext);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    const claimHistory = t('claimHistory');
    return (
            <div className="screen container">
                <div className="clearfix">&nbsp;</div>
                <div className="section rjsf offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                    <legend><span>{claimHistory}</span></legend>
                    <ClaimList claims={claims} linkFunc={it => `${newPath}/claim/summary/${it.id}`}/>
                    <hr/>
                    <Link data-menukey="back" to={`${newPath}/claim`} className="btn btn-lg btn-block btn-secondary gap">{t('back')}</Link>
                </div>
            </div>
    );
};
