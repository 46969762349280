import React, { RefObject } from "react";
import { i18n } from "@surelync/common";
import { CellInfo } from "react-table";
import { Link } from "react-router-dom";
import LinkButton from "@material-ui/core/Link";
import { DescriptionColumns } from "../components";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Remove from "@material-ui/icons/Remove";
import { Typography } from "@material-ui/core";

type UserFn = (id: string) => void;

export const getHeaderColumnsI18 = (editPermission: boolean, isMobile: boolean, showAllRef: RefObject<boolean>, onUser: UserFn) => [
    {
        Header: () => i18n.t("name"),
        accessor: "name",
        Cell: (row: CellInfo) => {
            // if the table is currently sorted by ​Name​ column, then show the hyperlinked name​ field at the start of ​every​ row
            const [cell0, cell1] = row.row.cells;
            const isSorted = cell0.column.isSorted || cell1.column.isSorted;
            return (
                <div>
                    {row.value === "-" || !editPermission ? (
                        <div>{row.value}</div>
                    ) : (
                        <LinkButton component={Link} to={`/user-administration/${row.row.original.id}`} variant="body1">
                            {isSorted || !row.row.original.canBeHidden ? row.value : ""}
                        </LinkButton>
                    )}
                    <Typography variant="body2">{row.row.original.email}</Typography>
                </div>
            );
        },
        size: isMobile ? "medium" : "large",
    },
    {
        Header: () => i18n.t("role"),
        accessor: "role",
        size: "small",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        disableSortBy: true,
        Cell: (row: CellInfo) =>
            row.row.original.permissions ? (
                <DescriptionColumns showAll={showAllRef.current} permissions={row.row.original.permissions} value={row.value} />
            ) : (
                row.value
            ),
        size: "large",
    },
    {
        Header: () => null,
        accessor: "removeButton",
        disableSortBy: true,
        Cell: (row: CellInfo) => {
            // if the table is currently sorted by ​Name​ column, then show for ​every​ row
            const [cell0, cell1] = row.row.cells;
            const isSorted = cell0.column.isSorted || cell1.column.isSorted;
            return (
                <Box display="flex" justifyContent="flex-end">
                    {(isSorted || row.value) && (
                        <Button color="primary" variant="text" endIcon={<Remove />} size="small" onClick={() => onUser(row.row.original.id)}>
                            {i18n.t("removeUser")}
                        </Button>
                    )}
                </Box>
            );
        },
        size: "small",
    },
];
