import config from './configuration.js';

async function removeNulls(results) {
    // console.log(results);
    // console.log(config.listOfEnumeratedFields);
    results.data.forEach(element => {
        // console.log(element);
        for (let i = 0; i < config.listOfEnumeratedFields.length; i++) {
            const label = config.listOfEnumeratedFields[i].label;
            // console.log(`${label} is ${element[label]}`);
            if (element[label] === null) {
                console.log(`null value in ${element['policyholder.primaryEmailAddress']} ${[label]}`);
                delete element[label];
            }
        }
    });
    console.log(results);
    return results;
}

export default {removeNulls};
