import { EIdentityType, ETransactionStatus, ETransactionType, ISettlementStatement, ITransaction } from "@surelync/common";

export const isCanceled = (transaction: ITransaction | ISettlementStatement) => transaction.status === ETransactionStatus.Cancelled;

const isHiddenForBroker = (transaction: ITransaction, identityType: EIdentityType) =>
    identityType === EIdentityType.Broker && transaction.transactionType === ETransactionType.Correction;

export const setDataPayments = (transactions: ITransaction[], identityType: EIdentityType) => {
    return transactions
        .filter((transaction) => !isCanceled(transaction) && !isHiddenForBroker(transaction, identityType))
        .map((transaction) => ({
            due: transaction.due,
            paid: transaction.paid,
            status: transaction.status,
            currency: transaction.currency,
            totalAmount: transaction.totalAmount,
            creation: transaction.creation,
        }));
};
