import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { PolicyApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { ITableData, MessageBox, RecordsNotFound, signInService, TableContainer } from "@surelync/common";
import { generateErrorMessage } from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataOverdueCollections } from "./helper";
import PolicyPaymentFlyout from "./PolicyPaymentFlyout";
import PaymentAttemptsFlyout from "./PaymentAttemptsFlyout";
import RetryPaymentFlyout from "./RetryPaymentFlyout";
import { BannerClientLogo, PageContainer } from "../components";
import GlobalContext from "../context/global-context";
import { getHeaderColumnsI18 } from "./columns.config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: 0,
            padding: 0,
        },
    })
);

const OverdueCollectionsPage: React.FC = () => {
    const {
        namespacedLocalStorage,
        setOpenPolicyPaymentFlyoutOpen,
        setOpenPolicyPaymentFlyoutSequenceId,
        setOpenPaymentAttemptsFlyoutOpen,
        setOpenPaymentAttemptsFlyoutTransactionId,
        setOpenRetryPaymentFlyoutOpen,
        setOpenRetryPaymentFlyoutTransactionId,
    } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const classes = useStyles();

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const fetchData = async (rowsPerPage: number, page: number) => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        try {
            const policy = await policyApiClient.searchTransactions({ statuses: ["Failed", "Pending"], dueMax: new Date() }, rowsPerPage, page);
            const dataSettlementStatement = setDataOverdueCollections(policy.items);
            const headerColumnsI18 = getHeaderColumnsI18({
                isPolicyholderReadPermission: signInService.hasPermission(["POLICY_HOLDER_READ"]),
                onOpenPayment: handleOpenPayment,
                onOpenRetryPayment: handleOpenRetryPayment,
                onOpenPolicyPayment: handleOpenPolicyPayment,
            });

            if (!isMountRef.current) {
                return;
            }

            setHeaderColumns(headerColumnsI18);
            setData(dataSettlementStatement);
            setTotal(policy.total);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    const handleOpenPayment = (id: string) => {
        setOpenPaymentAttemptsFlyoutOpen(true);
        setOpenPaymentAttemptsFlyoutTransactionId(id);
    };

    const handleOpenRetryPayment = (transactionId: string) => {
        setOpenRetryPaymentFlyoutOpen(true);
        setOpenRetryPaymentFlyoutTransactionId(transactionId);
    };

    const handleOpenPolicyPayment = (sequenceId: string) => {
        setOpenPolicyPaymentFlyoutOpen(true);
        setOpenPolicyPaymentFlyoutSequenceId(sequenceId);
    };

    return (
        <PageContainer title={t("overdueCollections")}>
            <Container maxWidth="xl" classes={{ root: classes.wrapper }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={6} sm={8} md={5}>
                                <Typography variant="h5">{t("overdueCollections")}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={2}>
                                <Box textAlign={{ xs: "right", md: "center" }}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} loading={loading} />
                    </Grid>
                    {data && data.length === 0 && (
                        <Grid item xs={12}>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    )}
                    {error && (
                        <Grid item xs={12} md={8}>
                            <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                        </Grid>
                    )}
                </Grid>
            </Container>
            <PolicyPaymentFlyout />
            <PaymentAttemptsFlyout />
            <RetryPaymentFlyout onTransactionPayment={() => fetchData(10, 0)} />
        </PageContainer>
    );
};

export default OverdueCollectionsPage;
