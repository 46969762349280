import {IAction, IGlobalState} from "./state.model";

export const SET_LOCALE = "SET_LOCALE";
export const SET_CLIENT_LOGO = "SET_CLIENT_LOGO";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_OPEN_CUSTOMISE_FLYOUT = "SET_OPEN_CUSTOMISE_FLYOUT";
export const SET_DISPLAYED_TABLE_COLUMNS = "SET_DISPLAYED_TABLE_COLUMNS";
// export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_CACHE_USER_START = "SET_CACHE_USER_START";
export const SET_CACHE_USER_DONE = "SET_CACHE_USER_DONE";
export const SET_OPEN_POLICY_PAYMENT_FLYOUT_OPEN = "SET_OPEN_POLICY_PAYMENT_FLYOUT_OPEN";
export const SET_OPEN_POLICY_PAYMENT_FLYOUT_SEQUENCE_ID = "SET_OPEN_POLICY_PAYMENT_FLYOUT_SEQUENCE_ID";
export const SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_OPEN = "SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_OPEN";
export const SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_TRANSACTION_ID = "SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_TRANSACTION_ID";
export const SET_OPEN_RETRY_PAYMENT_FLYOUT_OPEN = "SET_OPEN_RETRY_PAYMENT_FLYOUT_OPEN";
export const SET_OPEN_RETRY_PAYMENT_FLYOUT_TRANSACTION_ID = "SET_OPEN_RETRY_PAYMENT_FLYOUT_TRANSACTION_ID";
export const SET_PRODUCTS = "SET_PRODUCTS";

export const defaultState: IGlobalState = {
    cacheUsers: {},
    clientLogo: null,
    currentUser: null,
    displayedTableColumns: null,
    locale: null,
    products: null,
    openCustomiseFlyout: false,
    openPolicyPaymentFlyout: { open: false, sequenceId: null },
    openPaymentAttemptsFlyout: { open: false, transactionId: null },
    openRetryPaymentFlyout: { open: false, transactionId: null },
};

export const globalReducer = (state: IGlobalState, action: IAction) => {
    // console.log(action);
    switch (action.type) {
        case SET_LOCALE:
            return { ...state, locale: action.payload };

        case SET_CACHE_USER_START:
            return {
                ...state,
                cacheUsers: { ...state.cacheUsers, ...action.payload },
            };
        case SET_CACHE_USER_DONE:
            return {
                ...state,
                cacheUsers: { ...state.cacheUsers, ...action.payload },
            };

        case SET_CLIENT_LOGO:
            return { ...state, clientLogo: action.payload };

        case SET_CURRENT_USER:
            return { ...state, currentUser: action.payload };

        case SET_OPEN_CUSTOMISE_FLYOUT:
            return { ...state, openCustomiseFlyout: action.payload };

        case SET_DISPLAYED_TABLE_COLUMNS:
            return { ...state, displayedTableColumns: action.payload };

            // case SET_COUNTRIES:
            //   return { ...state, countries: action.payload };

        case SET_OPEN_POLICY_PAYMENT_FLYOUT_OPEN:
            return {
                ...state,
                openPolicyPaymentFlyout: {
                    ...state.openPolicyPaymentFlyout,
                    open: action.payload,
                },
            };

        case SET_OPEN_POLICY_PAYMENT_FLYOUT_SEQUENCE_ID:
            return {
                ...state,
                openPolicyPaymentFlyout: {
                    ...state.openPolicyPaymentFlyout,
                    sequenceId: action.payload,
                },
            };

        case SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_OPEN:
            return {
                ...state,
                openPaymentAttemptsFlyout: {
                    ...state.openPaymentAttemptsFlyout,
                    open: action.payload,
                },
            };

        case SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_TRANSACTION_ID:
            return {
                ...state,
                openPaymentAttemptsFlyout: {
                    ...state.openPaymentAttemptsFlyout,
                    transactionId: action.payload,
                },
            };

        case SET_OPEN_RETRY_PAYMENT_FLYOUT_OPEN:
            return {
                ...state,
                openRetryPaymentFlyout: {
                    ...state.openRetryPaymentFlyout,
                    open: action.payload,
                },
            };

        case SET_OPEN_RETRY_PAYMENT_FLYOUT_TRANSACTION_ID:
            return {
                ...state,
                openRetryPaymentFlyout: {
                    ...state.openRetryPaymentFlyout,
                    transactionId: action.payload,
                },
            };
        case SET_PRODUCTS:
            return { ...state, products: action.payload };
        default:
            return state;
    }
};
