import React from "react";
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";
import { DatePickerCustom, InputCustom, localeService, SelectCustom } from "@surelync/common";
import { Grid, Typography, useTheme } from "@material-ui/core";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import "./style.css";

const parseDate = (value: string) => {
    const date = FormatUtils.parseDate(value);
    return date.isValid ? date.toJSDate() : null;
};

const widgets = {
    DateWidget: (props) => {
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="body1">{props.label}</Typography>
                </Grid>
                <Grid item xs>
                    <DatePickerCustom
                        {...props}
                        displayValue={props.value ? FormatUtils.renderDate(props.value) : null}
                        error={Boolean(props.rawErrors?.length)}
                        label=""
                        locale={localeService.locale}
                        readOnly={props.readonly}
                        theme={useTheme()}
                        touched={true}
                        value={props.value ? parseDate(props.value) : null}
                        onChange={(value) => props.onChange(FormatUtils.renderFormatDate(value, FormatUtils.SERVER_DATE_FORMAT))}
                    />
                </Grid>
            </Grid>
        );
    },
    SelectWidget: (props) => {
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="body1">{props.label}</Typography>
                </Grid>
                <Grid item xs>
                    <SelectCustom
                        {...props}
                        error={Boolean(props.rawErrors?.length)}
                        label=""
                        options={props.options.enumOptions}
                        readOnly={props.readonly}
                        theme={useTheme()}
                        touched={true}
                        value={props.value || null}
                        onChange={(event) => props.onChange(event.target.value)}
                    ></SelectCustom>
                </Grid>
            </Grid>
        );
    },
    TextWidget: (props) => {
        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="body1">{props.label}</Typography>
                </Grid>
                <Grid item xs>
                    <InputCustom
                        {...props}
                        autoComplete="off"
                        error={Boolean(props.rawErrors?.length)}
                        label=""
                        readOnly={props.readonly}
                        theme={useTheme()}
                        touched={true}
                        onChange={(event) => props.onChange(event.target.value)}
                    ></InputCustom>
                </Grid>
            </Grid>
        );
    },
};

const Form = withTheme(MaterialUITheme);

const CustomForm = (props) => <Form {...props} key="schema" noHtml5Validate liveValidate={true} showErrorList={false} widgets={widgets} />;

export default CustomForm;
