import { CurrencyUtils, i18n, ISettlementStatement, localeService } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const setDataDrawdownDetails = (data: ISettlementStatement[]) => {
    return data.map((item) => ({
        reportingRef: item.reportingRef,
        transactionId: item.transactionId,
        paid: item.paid,
        available: item.available,
        status: i18n.t(item.status.toLowerCase()),
        currency: item.currency,
        grossAmount: item.grossAmount,
        paymentFee: item.paymentFee,
        netAmount: item.netAmount,
        id: item.id,
        dateSettled: item.settled,
    }));
};

export const setDataCsv = (headerTitles, data: ISettlementStatement[]) => {
    const result = headerTitles;

    data.forEach((item) => {
        result.push([
            item.reportingRef,
            item.transactionId,
            item.paid ? FormatUtils.renderDateTime(item.paid) : "",
            item.available ? FormatUtils.renderDateTime(item.available) : "",
            i18n.t(item.status.toLowerCase()),
            item.currency,
            CurrencyUtils._renderCurrency(item.grossAmount, localeService.locale, item.currency),
            CurrencyUtils._renderCurrency(item.paymentFee, localeService.locale, item.currency),
            CurrencyUtils._renderCurrency(item.netAmount, localeService.locale, item.currency),
        ]);
    });

    return result;
};
