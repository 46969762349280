import React from "react";
import {IGlobalState} from "./state.model";
import {namespacedLocalStorage, signInService} from "@surelync/common";

const GlobalContext = React.createContext<IGlobalState>({
    cacheUsers: {},
    clientLogo: null,
    currentUser: {
        authDetails: signInService.loadAuthDetails(),
        userDetails: signInService.loadUserDetails(),
    },
    locale: null,
    namespacedLocalStorage,
    openCustomiseFlyout: false,
    displayedTableColumns: null,
    // countries: null,
    openPolicyPaymentFlyout: { open: false, sequenceId: null },
    openPaymentAttemptsFlyout: { open: false, transactionId: null },
    openRetryPaymentFlyout: { open: false, transactionId: null },
    products: null,
});

export default GlobalContext;
