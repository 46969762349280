import React from "react";
import { i18n } from "@surelync/common";
import { CellInfo } from "react-table";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";

type PermissionFn = (id: string) => void;

export const getHeaderColumnsI18 = (permissionIds: string[], onRemove: PermissionFn) => [
    {
        Header: () => i18n.t("name"),
        accessor: "name",
        size: "small",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        size: "small",
    },
    {
        Header: () => null,
        accessor: "addButton",
        Cell: (row: CellInfo) => {
            return (
                <Box display="flex" justifyContent="flex-end">
                    {row.value && (
                        <Button
                            color="primary"
                            variant="text"
                            endIcon={permissionIds.includes(row.row.original.id) ? <Remove /> : <Add />}
                            size="small"
                            onClick={() => onRemove(row.row.original.id)}
                        >
                            {permissionIds.includes(row.row.original.id) ? i18n.t("remove") : i18n.t("add")}
                        </Button>
                    )}
                </Box>
            );
        },
        size: "small",
    },
];
