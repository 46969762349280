import { sentenceCase } from 'change-case';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import { DateTime } from 'luxon';

export const prepareSchema = (schema) => {
    Object.entries(schema.properties)
        .forEach(([name, property]) => {
            if (property.properties) prepareSchema(property);
            property.title = property.title || sentenceCase(name);
        });
    return schema;
};

export const formatParty = (party) => {
    const tokens = party.split(/[,=]/);
    return tokens[tokens.indexOf('O') + 1];
};

const _formatDate = str => (date) => {
    if (!date) return '-';
    return (DateTime.isDateTime(date) ? date.toLocal() : FormatUtils.parseDate(date)).toFormat(str);
};
export const formatDateLocale = _formatDate('DD');
export const formatDate = FormatUtils.renderDate;
export const formatDateWithHours = FormatUtils.renderDateTime;

export const valueOrEnvVariable = (value, def) => value.indexOf('!!') === 0 ? def : value;

export function splitLocal(formData = {}, uiSchema = {}) {
    const local = {};
    const keys = [...new Set(Object.keys(uiSchema)
        .concat(Object.keys(formData)))];
    keys.filter(it => !it.startsWith('fm:') && !it.startsWith('ui:'))
        .forEach(key => {
            if (key in formData) {
                if (uiSchema[key] && uiSchema[key]['fm:local']) {
                    local[key] = formData[key];
                } else if (typeof formData[key] === 'object') {
                    const { local: childLocal } = splitLocal(formData[key], uiSchema[key]);
                    if (childLocal !== {}) local[key] = childLocal;
                }
            }
        });
    return { local };
}

export function prepareSharedInfoFromSchema(formData = {}, universalSchema = {}, policyholder) {
    const shared = {};
    const existingShared = policyholder.shared || null;
    const schemaProperties = Object.entries(universalSchema.properties)
        .map(([key, value]) => [key, value['ui:order']]);
    schemaProperties.forEach(([key, values]) => {
        shared[key] = fillProperty(key, values, formData, existingShared);
    });
    return { ...formData, ...shared };
}

export function resolveCountry(uiSchema) {
    const countryCode = uiSchema ? (uiSchema['fm:country'] || (uiSchema['ui:options'] && uiSchema['ui:options'].country)) : 'GB';
    if (EuCountryList.includes(countryCode)) {
        return 'EU';
    } else {
        return countryCode;
    }
}


const fillProperty = (key, values, formData, existingShared) => {
    return values.reduce(function (result, val) {
        const formValues = formData[key] ? formData[key] : formData;
        const sharedValues = existingShared ? existingShared[key] : {};
        result[val] = formValues[val] ? formValues[val] : (sharedValues[val] ? sharedValues[val] : '');
        if (formValues[val]) delete formValues[val];
        return result;
    }, {});
};

// Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy,
// Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden.
const EuCountryList = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT',
    'LV', 'LT', 'LU', 'MT', 'NL', 'PO', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];
