import * as React from "react";
import { useContext, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ContactContext, SET_TAB } from "../../../../models";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import HomeIcon from "@material-ui/icons/Home";
import { ETab } from "../../../../models/tabs.model";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import GlobalContext from "../../../../../context/global-context";
import { EIdentityType, ETransactionStatus, PolicyholderUtils, ProductUtils } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        ellipsContent: {
            overflow: "hidden",
            maxWidth: "100%",
        },
    })
);

type IProps = Record<string, unknown>;

const Contact: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ policyholder, policies, universalPolicyholderSchema }, dispatch] = useContext(ContactContext);
    const { currentUser, products } = useContext(GlobalContext);
    const { t } = useTranslation();

    const fieldOrder = universalPolicyholderSchema?.properties?.address["ui:order"];
    const address = useMemo(
        () => (policyholder ? PolicyholderUtils.renderAddress(policyholder, policyholder.shared, fieldOrder) : ""),
        [fieldOrder, policyholder]
    );
    const fullName = useMemo(() => (policyholder ? PolicyholderUtils._renderFullName(policyholder) : ""), [policyholder]);
    const phone = useMemo(() => (policyholder ? PolicyholderUtils._renderPhoneNumber(policyholder.primaryPhoneNumber) : ""), [policyholder]);

    const partiesAndRoles = useMemo(() => {
        const earliestPolicy = {};
        policies.forEach((policy) => {
            const cachedPolicy = earliestPolicy[policy.id];
            const isPaid = !!policy.transactions.find((t) => t.status === ETransactionStatus.Paid);

            // Skip Quoted policy
            if (!isPaid && !cachedPolicy) {
                return;
            }

            // find earliest policy
            if (!cachedPolicy || FormatUtils.compareISODates(policy.effectiveFromDate, cachedPolicy.sinceDate, "days") < 0) {
                earliestPolicy[policy.id] = {
                    sinceDate: policy.effectiveFromDate,
                    Broker: ProductUtils._formatParty(products[policy.productId].partiesAndRoles.Broker),
                    Insurer: ProductUtils._formatParty(products[policy.productId].partiesAndRoles.Insurer),
                };
                const mga = ProductUtils._formatParty(products[policy.productId].partiesAndRoles.MGA);
                if (mga) {
                    earliestPolicy[policy.id].Mga = mga;
                }
            }
        });

        const brokers = {};
        const insurers = {};
        const mgas = {};
        Object.keys(earliestPolicy).forEach((id) => {
            const policy = earliestPolicy[id];
            if (!brokers[policy.Broker] || FormatUtils.compareISODates(policy.sinceDate, brokers[policy.Broker], "days") < 0) {
                brokers[policy.Broker] = policy.sinceDate;
            }
            if (!insurers[policy.Insurer] || FormatUtils.compareISODates(policy.sinceDate, insurers[policy.Insurer], "days") < 0) {
                insurers[policy.Insurer] = policy.sinceDate;
            }
            if (policy.Mga && (!mgas[policy.Mga] || FormatUtils.compareISODates(policy.sinceDate, mgas[policy.Mga], "days") < 0)) {
                mgas[policy.Mga] = policy.sinceDate;
            }
        });
        return { brokers, insurers, mgas };
    }, [policies, products]);

    const renderBrokerInsurer = () => {
        switch (currentUser.authDetails.identityType) {
            case EIdentityType.MGA:
                return (
                    <>
                        {Object.keys(partiesAndRoles.brokers).map((name) =>
                            renderPartyAndRole(name, FormatUtils.renderDate(partiesAndRoles.brokers[name]))
                        )}
                        {Object.keys(partiesAndRoles.insurers).map((name) =>
                            renderPartyAndRole(name, FormatUtils.renderDate(partiesAndRoles.insurers[name]))
                        )}
                    </>
                );
            case EIdentityType.Insurer:
                return Object.keys(partiesAndRoles.brokers).map((name) =>
                    renderPartyAndRole(name, FormatUtils.renderDate(partiesAndRoles.brokers[name]))
                );

                // When identityType = Broker or identityType = Blocksure
                // =====================
                // When (identityType = Broker or identityType = Blocksure) and no MGA is involved in the policy then:
                //       both of these need displayed:
                // “With <broker> since <date>” displayed,
                // “With <insurer> since <date>” displayed
                // When (identityType = Broker or identityType = Blocksure) and an MGA is involved in the policy then:
                // “With <broker> since <date>” displayed,
                // “With <M G A> since <date>” displayed
                // i.e. in this situation, no insurer to be listed on the broker screen.
                // (FYI — a comment about insurance world:  The MGA is the insurer as far as the broker is concerned, i.e. from broker’s perspective.)

            default:
                const secondLineParty = Object.keys(partiesAndRoles.mgas).length ? partiesAndRoles.mgas : partiesAndRoles.insurers;
                return (
                    <>
                        {Object.keys(partiesAndRoles.brokers).map((name) =>
                            renderPartyAndRole(name, FormatUtils.renderDate(partiesAndRoles.brokers[name]))
                        )}
                        {Object.keys(secondLineParty).map((name) => renderPartyAndRole(name, FormatUtils.renderDate(secondLineParty[name])))}
                    </>
                );
            // default:
            //   return Object.keys(partiesAndRoles.insurers)
            //     .map(name => renderPartyAndRole(name, FormatUtils.renderDate(partiesAndRoles.insurers[name])))
        }
    };

    const renderPartyAndRole = (name: string, sinceDate: string) => (
        <Typography key={name} variant="body2">
            {t("withInsurerSince", {
                insurer: name,
                date: sinceDate,
            })}
        </Typography>
    );

    const renderMarketingApproval = () => {
        if (!policyholder || !policyholder.local || policyholder.local.marketingApproval === undefined) {
            return null;
        }

        return (
            <>
                <Typography component="span" variant="body2">
                    {t("marketingApproval")}:
                </Typography>
                &nbsp;
                <Typography component="span" variant="body1">
                    {policyholder?.local?.marketingApproval === "true" ? t("yes") : t("no")}
                </Typography>
            </>
        );
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("contact")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container spacing={3} classes={{ root: classes.content }}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{fullName}</Typography>
                        {renderBrokerInsurer()}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar>
                                            <MailOutlineIcon color="primary" style={{ verticalAlign: "middle" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs classes={{ root: classes.ellipsContent }}>
                                        {policyholder?.primaryEmailAddress ? (
                                            <Typography variant="body1" noWrap title={policyholder?.primaryEmailAddress}>
                                                {policyholder?.primaryEmailAddress}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2">{t("na")}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar>
                                            <PhoneInTalkIcon color="primary" style={{ verticalAlign: "middle" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs classes={{ root: classes.ellipsContent }}>
                                        {phone ? (
                                            <Typography variant="body1">{phone}</Typography>
                                        ) : (
                                            <Typography variant="body2">{t("na")}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Avatar>
                                            <HomeIcon color="primary" style={{ verticalAlign: "middle" }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        {address ? (
                                            <Typography variant="body1">{address}</Typography>
                                        ) : (
                                            <Typography variant="body2">{t("na")}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {renderMarketingApproval()}
                        <Box display="flex" justifyContent="flex-end">
                            <Link
                                href="#"
                                color="primary"
                                variant="body2"
                                onClick={() => dispatch({ type: SET_TAB, payload: ETab.IdentityAndAddress })}
                            >
                                {t("viewAll")}
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Contact;
