import * as React from "react";
import { useContext, useEffect, useReducer } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ContactContext, SET_NOTES, SET_TAB } from "../../../../models";
import GlobalContext from "../../../../../context/global-context";
import { ETab } from "../../../../models/tabs.model";
import NoteApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/NoteApiClient";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import { ADD_NOTE_DONE, FETCH_DONE, FETCH_ERROR, FETCH_START, initialState, OPEN_NEW_NOTE_DIALOG, reducer } from "./reducer";
import { INote, Preloader } from "@surelync/common";
import { SET_CACHE_USER_START } from "../../../../../context/reducers";
import DialogEditNote from "../../../../../components/DialogEditNote/DialogEditNote";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const MAX_NOTE_LENGTH = 120;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

type IProps = Record<string, unknown>;

const ImportantNotes: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ fetching, newNote, notes, open }, dispatch] = useReducer(reducer, initialState);
    const [{ policyholder }, dispatchContact] = useContext(ContactContext);
    const { cacheUsers, namespacedLocalStorage, dispatchGlobal } = useContext(GlobalContext);
    const { t } = useTranslation();

    const noteApiClient = new NoteApiClient(namespacedLocalStorage);
    const ownerId = `${policyholder.id}-notes`;
    const theme = useTheme();

    useEffect(() => {
        let isMount = true;

        const fetchData = async () => {
            try {
                dispatch({ type: FETCH_START });
                const response: INote[] = await noteApiClient.getNotesByOwnerId(ownerId);
                // cleanup func
                if (!isMount) return;

                // how much we can show? 2 big notes or 3 smaller
                const sortData = FormatUtils.sortByProperty(response, "ts").reverse();

                dispatchContact({ type: SET_NOTES, payload: sortData });

                if (sortData && sortData.length) {
                    const maxLength = sortData.slice(0, 3).reduce((total, item) => {
                        return total + item.description.substring(0, MAX_NOTE_LENGTH).length;
                    }, 0);
                    const count = maxLength > MAX_NOTE_LENGTH * 2 ? 2 : 3;
                    dispatch({ type: FETCH_DONE, payload: sortData.slice(0, count) });
                } else {
                    dispatch({ type: FETCH_DONE, payload: null });
                }
            } catch (error) {
                if (!isMount) return;
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerId, newNote]);

    useEffect(() => {
        if (!notes || !notes.length) {
            return;
        }

        const emails = {};
        notes.forEach((note: INote) => {
            if (!cacheUsers[note.email]) emails[note.email] = null;
        });

        dispatchGlobal({ type: SET_CACHE_USER_START, payload: emails });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes]);

    const handleClose = (newNote?: INote) => {
        if (newNote) {
            dispatch({ type: ADD_NOTE_DONE, payload: newNote });
        } else {
            dispatch({ type: OPEN_NEW_NOTE_DIALOG, payload: false });
        }
    };

    const handleViewAll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        dispatchContact({ type: SET_TAB, payload: ETab.Notes });
    };

    const renderAuthor = (note: INote) => {
        if (cacheUsers[note.email]?.firstName) {
            return `${cacheUsers[note.email]?.firstName} ${cacheUsers[note.email]?.lastName}`;
        } else {
            return note.email;
        }
    };

    const renderNote = (description: string) => {
        if (description.length > MAX_NOTE_LENGTH) {
            return `${description.substring(0, MAX_NOTE_LENGTH)} ...`;
        }

        return description;
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("importantNotes")}
            </Typography>
            <Paper className={classes.root}>
                {fetching ? (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Preloader theme={theme} />
                        </Grid>
                    </Grid>
                ) : notes ? (
                    <Grid container direction="column" spacing={3} classes={{ root: classes.content }} data-testid="notes-list">
                        <Grid item xs>
                            {notes.map((note: INote, index: number) => (
                                <Grid container key={index} spacing={3}>
                                    <Grid item xs={1}>
                                        <DescriptionIcon color="primary" style={{ verticalAlign: "middle" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container wrap="nowrap" spacing={2}>
                                            <Grid item>
                                                <Typography variant="body2" noWrap>
                                                    {FormatUtils.renderDateTime(note.ts)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs style={{ overflow: "hidden" }}>
                                                <Typography align="right" variant="body2" noWrap title={renderAuthor(note)}>
                                                    {renderAuthor(note)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body1">{renderNote(note.description)}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            color="primary"
                                            data-testid="add-note"
                                            style={{ visibility: index === 0 ? "visible" : "hidden" }}
                                            onClick={() => dispatch({ type: OPEN_NEW_NOTE_DIALOG, payload: true })}
                                        >
                                            <AddIcon fontSize="large" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item>
                            <Box display="flex" justifyContent="flex-end">
                                <Link href="#" color="primary" variant="body2" onClick={handleViewAll}>
                                    {t("viewAll")}
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <IconButton
                                        color="primary"
                                        data-testid="add-first-note"
                                        onClick={() => dispatch({ type: OPEN_NEW_NOTE_DIALOG, payload: true })}
                                    >
                                        <AddIcon fontSize="large" />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{t("addNotes")}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <DialogEditNote open={open} ownerId={ownerId} placeholder={`${t("addNotes")}...`} onClose={handleClose} />
        </>
    );
};

export default ImportantNotes;
