import React, {useContext, useEffect} from 'react';
import {CurrentStepContext, PendingContext, PolicyContext} from '../common/context';
import Schema from '../schema/Schema';
import {formatDateWithHours} from '../shared/util';
import {Redirect, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import {convertToNewPath} from "../base";

const schema = title => ({
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
        amendDate: {
            title,
            type: 'string',
            format: 'alt-date'
        },
    },
    required: ['amendDate']
});

const uiSchema = (yearFrom, yearTo) => ({
    amendDate: {
        'ui:widget': 'alt-date2',
        'ui:options': {'yearsRange': [yearFrom, yearTo]},
    },
});

export const AmendScreen = ({children}) => {
    const {t} = useDefaultTranslation();
    const {policy} = useContext(PolicyContext);
    const {
        amendDate,
        updateAmendDate
    } = useContext(PolicyContext);
    const {nextStep} = useContext(CurrentStepContext);
    const {pending} = useContext(PendingContext);
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);

    const onSubmit = () => {
        if (amendDate) nextStep();
    };

    const onChange = ({formData}) => {
        // Null until fully populated.
        if (formData.amendDate) updateAmendDate(formData.amendDate);
    };

    useEffect(() => {
        if (!amendDate) updateAmendDate(FormatUtils.today()
                .toISO());
    });

    if (policy === undefined) return '';

    if (!policy) return <Redirect to={`${newPath}/amend`}/>;

    const effectiveFrom = FormatUtils.parseDate(policy.effectiveFromDate);
    const effectiveTo = FormatUtils.parseDate(policy.effectiveToDate);
    const uiSchemaWithDates = uiSchema(effectiveFrom.year, effectiveTo.year);

    const formattedEffectiveFrom = formatDateWithHours(effectiveFrom);
    const formattedEffectiveTo = formatDateWithHours(effectiveTo);

    function validate(formData, errors) {
        if (effectiveFrom > FormatUtils.parseDate(formData.amendDate)) {
            const amendmentMustBeBefore = t('amendmentMustBeBefore', {effectiveFrom: formattedEffectiveFrom});
            errors.addError(amendmentMustBeBefore);
        }
        if (effectiveTo < FormatUtils.parseDate(formData.amendDate)) {
            const amendmentMustBeAfter = t('amendmentMustBeAfter', {effectiveTo: formattedEffectiveTo});
            errors.addError(amendmentMustBeAfter);
        }
        return errors;
    }

    const effectiveDateMessage = t('yourPolicyIsEffectiveFromTo', {
        effectiveFrom: formattedEffectiveFrom,
        effectiveTo: formattedEffectiveTo
    });
    const amendDateTitle = t('amendDateTitle');
    return (
            <div className="section rjsf offset-md-3 col-md-6 gap">
                <h2>{t('policyAmendmentTitle')}</h2>
                <p>{t('policyAmendmentDescription')}</p>
                <Schema schema={schema(amendDateTitle)} uiSchema={uiSchemaWithDates} formData={{amendDate: amendDate || `${new Date()}`}}
                        onChange={onChange} onSubmit={onSubmit} pending={pending} validate={validate}>
                    <h4 className="text-center"><label className="badge badge-info badge-wrap">{effectiveDateMessage}</label></h4>
                    {children}
                </Schema>
            </div>
    );
};
