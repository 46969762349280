import React from 'react';
import {TradingGroupContext} from '../common/context';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {Markdown} from '../schema/Markdown';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const ClaimInfo = () => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <TradingGroupContext.Consumer>{({formulator: {claim: {info = []} = {}} = {}} = {}) => (
                    <div className="screen container">
                        <div className="clearfix">&nbsp;</div>
                        {info.length ? (
                            <div className="section offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                                <ClaimInfoArray claimInfo={info}/>
                            </div>
                        ) : null}
                        <div className="clearfix">&nbsp;</div>
                        <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                            <Link data-menukey="back" to={`${newPath}/claim`} className="btn btn-lg btn-block btn-secondary gap">{t('back')}</Link>
                        </div>
                    </div>
            )}</TradingGroupContext.Consumer>
    );
};

const ClaimInfoArray = ({claimInfo = []}) =>
        <Markdown key="claim-info" value={claimInfo.join('\n')} useParagraph={true}/>;
