import { i18n } from "@surelync/common";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("attemptedDate"),
        accessor: "attemptedDate",
    },
    {
        Header: () => i18n.t("message"),
        accessor: "message",
    },
    {
        Header: () => i18n.t("paymentRef"),
        accessor: "paymentReference",
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
    },
];
