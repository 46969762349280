import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useContext } from "react";
import GlobalContext from "../../context/global-context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            maxHeight: 50,
        },
    })
);

type IProps = Record<string, unknown>

export const BannerClientLogo: React.FC<IProps> = () => {
    const classes = useStyles();
    const { clientLogo } = useContext(GlobalContext);

    if (!clientLogo) {
        return <Box mt={7} />;
    }

    return <img className={classes.logo} src={clientLogo} alt="banner client logo" />;
};
