import * as React from "react";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LinkButton from "@material-ui/core/Link";
import GlobalContext from "../../context/global-context";
import Divider from "@material-ui/core/Divider";
import {
    CurrencyUtils,
    EIdentityType,
    IPolicy,
    IPolicyholder,
    IProduct,
    localeService,
    PolicyholderUtils,
    PolicyUtils,
    ProductUtils,
} from "@surelync/common";
import { Hidden } from "@material-ui/core";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
    })
);

interface IProps {
    addressFieldOrder: string[];
    isHideTitle?: boolean;
    isMultiSections?: boolean;
    policy: IPolicy;
    policyholder: IPolicyholder;
    product: IProduct;
    sectionName?: string;
    sequences: IPolicy[];
    showSumInsured?: boolean;
    title: string;
}

const PolicyOverview: React.FC<IProps> = ({
    addressFieldOrder,
    isHideTitle,
    isMultiSections,
    policy,
    policyholder,
    product,
    sectionName,
    sequences,
    showSumInsured,
    title,
}) => {
    const classes = useStyles();
    const { currentUser } = useContext(GlobalContext);
    const { t } = useTranslation();
    const { identityType } = currentUser.authDetails;

    const address = useMemo(() => {
        const address = policy && policy.submission && (policy.submission.yourDetails || policy.submission.aboutYou);
        return address ? PolicyholderUtils.renderAddress(policyholder, address, addressFieldOrder) : "";
    }, [addressFieldOrder, policy, policyholder]);
    const effectiveFromDate = useMemo(() => {
        if (!policy) return "";

        const fromDate = sequences[sequences.length - 1].inceptionDate;
        return FormatUtils.renderDate(fromDate);
    }, [policy, sequences]);
    const effectiveToDate = useMemo(() => {
        if (!policy) return "";
        // const currentPolicy = sequences ? sequences.find(s => s.sequence === s.version) : policy;
        return FormatUtils.renderDate(policy.effectiveToDate);
    }, [policy]);
    const fullName = useMemo(() => (policyholder ? PolicyholderUtils._renderFullName(policyholder) : ""), [policyholder]);
    const mga = ProductUtils._formatParty(product.partiesAndRoles.MGA);
    const broker = ProductUtils._formatParty(product.partiesAndRoles.Broker);

    const insurer = sectionName
        ? ProductUtils._formatParty(product.sections[sectionName].rating.party)
        : ProductUtils._formatParty(product.partiesAndRoles.Insurer);

    const sumInsured = useMemo(() => {
        if (!policy) {
            return null;
        }

        const sumInsured = isMultiSections && sectionName ? policy.sections[sectionName].submission.sumInsured : policy.sumInsured;

        return CurrencyUtils._renderCurrency(sumInsured, localeService.locale, policy.currency);
    }, [isMultiSections, policy, sectionName]);

    const renderPartiesAndRoles = () => {
        switch (identityType) {
            case EIdentityType.Insurer:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{mga ? t("mga") : t("brokerCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{mga || broker}</Typography>
                        </Grid>
                    </>
                );
            case EIdentityType.MGA:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("brokerCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{broker}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("insuranceCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{isMultiSections && !sectionName ? t("multiSection") : insurer}</Typography>
                        </Grid>
                    </>
                );
            default:
                return (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="body1">{mga ? t("mga") : t("insuranceCompany")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2">{mga || (isMultiSections && !sectionName ? t("multiSection") : insurer)}</Typography>
                        </Grid>
                    </>
                );
        }
    };

    return (
        <>
            <Typography gutterBottom variant="body1" style={{ visibility: isHideTitle ? "hidden" : "visible" }}>
                {title}
            </Typography>
            <Paper className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("product")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap title={product?.name}>
                            {product?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("policyholder")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <LinkButton component={Link} to={`/contacts/${policyholder.id}`} variant="body1">
                            {fullName}
                        </LinkButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("status")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{PolicyUtils.getStatus(policy.status)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body1">{t("descriptionOfRisk")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap title={address}>
                            {address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body1">{t("policyDates")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {effectiveFromDate} - {effectiveToDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {product && renderPartiesAndRoles()}

                    {/* Hidden in the pages [Claim Details] and [Refferal Details] */}
                    {/* Show in the [Policy Details] page */}
                    <Hidden xsUp={!showSumInsured}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">{t("sumInsured")}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" noWrap>
                                {isMultiSections && !sectionName ? t("multiSection") : sumInsured}
                            </Typography>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
        </>
    );
};

export default PolicyOverview;
