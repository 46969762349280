import BaseApiClient from './BaseApiClient';

export default class SearchApiClient extends BaseApiClient {
    constructor(...args) {
        super('/search/api/v1/', ...args);
    }

    async search(types, query) {
        const params = {types, ...query};
        const response = await super.handleGet(this.basepath, {params});
        super.handleResponse(response);
        return response.data;
    }

    addLoadParams(load, config) {
        load.forEach(it => {
            config.params[it] = true;
        });
    }
}
