import React from 'react';
import {Markdown} from '../Markdown';

const DescriptionField = ({
    id,
    description
}) => {
    if (!description) return null;
    if (typeof description === 'string') {
        description = <Markdown value={description}/>;
    }

    return typeof description === 'string' ?
            <p id={id} className="field-description">{description}</p> :
            <div id={id} className="field-description">{description}</div>;
};

export default DescriptionField;
