import React from "react";
import { CurrencyUtils, i18n, localeService, PlaceHolder } from "@surelync/common";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { Link } from "react-router-dom";
import LinkButton from "@material-ui/core/Link";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("dateSettled"),
        accessor: "dateSettled",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => i18n.t("drawdownID"),
        accessor: "drawdownId",
        Cell: (row: CellInfo) =>
            row.value === "-" ? (
                row.value
            ) : (
                <LinkButton component={Link} to={`/statement-details/${row.value}`} variant="body1">
                    {row.value}
                </LinkButton>
            ),
        size: "large",
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        size: "small",
    },
    {
        Header: () => i18n.t("amountCollected"),
        accessor: "grossAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
    },
    {
        Header: () => i18n.t("stripeFee"),
        accessor: "stripeFee",
        sortType: "basic",
        Cell: (row: CellInfo) =>
            row.value ? CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency) : PlaceHolder,
    },
    {
        Header: () => i18n.t("status"),
        accessor: "state",
        size: "small",
    },
    {
        Header: () => i18n.t("externalRef"),
        accessor: "externalRef",
    },
];
