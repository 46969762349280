import React from 'react';
import FlatfileImporter from 'flatfile-csv-importer';
import MainScreen from './@MainScreen/@MainScreen';

FlatfileImporter.setVersion(2); // sets version of flatfile

const App = (props) => {
    console.log(props);
    const {locale, currentUser} = props.surelyncProps;

    return (
        <div className="Csv-Importer">
            <MainScreen locale={locale} currentUser={currentUser} />

            {/* <FlatfileButton
                licenseKey={config.licence}
                // mandatory field
                customer={{userId: '12345'}}
                settings={
                    {
                        type: 'Contact',
                        fields: flatfileFields.fields

                    }
                }
                onRecordInit={(record) => validation.validateRecords(record)} // returns correctly validated records
                onRecordChange={(record) => validation.validateRecords(record)} // returns correctly validated records
                onData={
                    async (results) => {
                        return onDataReturnFunc(results);
                    }
                }>
                Import Contacts </FlatfileButton>
            <button id="sendRequestButton" onClick={clickSendRequestButton}>
                Send Requests
            </button> */}
        </div>
    );
};

export default App;
