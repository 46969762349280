import React from 'react';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const Dtq = ({productId}) => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <div className="offset-md-2 col-md-8">
                <div className="text-center">
                    <h1>{t('dtqHeader')}</h1>
                </div>
                <br/>
                <h4>{t('dtqBody')}</h4>
                <br/>
                <h4><b>{t('dtqFooter')}</b></h4>
                <br/>
                <div className="text-center">
                    <Link data-menukey="Back" to={productId ? `${newPath}/quote/${productId}` : `${newPath}/quote`}
                          className="btn btn-lg btn-block btn-secondary">{t('back')}</Link>
                </div>
            </div>
    );
};
