import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Form from 'react-jsonschema-form';
import {get} from 'lodash';
import UserApiClient from '../services/api-clients/UserApiClient';
import {trimEmailValue} from '../utilities/FormatUtils';

import Error from './widgets/Error';
import {Button} from './widgets';
import './TriggerPasswordResetComponent.css';

const UiSchema = emailTitle => ({
    properties: {
        email: {
            type: 'string',
            title: emailTitle,
            format: 'email'
        }
    }
});

class TriggerPasswordResetComponent extends Component {
    fields = [
        {
            type: 'email',
            name: 'email',
            label: 'Email'
        }
    ];

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            values: {},
            errors: {},
            error: null
        };
    }

    onChange = name => value => this.setState({
        values: {
            ...this.state.values,
            [name]: value
        }
    });

    componentDidMount() {
        const {email} = this.props.location && this.props.location.state || {};
        this.setState({values: {email}});
    }

    async onSubmit() {
        const t = this.translatorOrDefault();
        this.setState({
            error: null,
            success: null,
            pending: true
        });
        try {
            const {email} = this.state.values;
            const trimmedEmail = trimEmailValue(email);
            const userApiClient = new UserApiClient();
            await userApiClient.triggerPasswordReset(
                trimmedEmail, `${this.props.forwardUrl}?email=${encodeURIComponent(email)}`, this.props.productId, this.props.appName, this.props.lng
            );
            if (this.props.onReset) this.props.onReset();
            const resetPasswordTriggered = t('resetPasswordTriggered', {defaultValue: ''});
            this.setState({
                error: null,
                success: resetPasswordTriggered,
                pending: false
            });
        } catch (e) {
            this.setState({
                error: t('unableToResetPassword', {defaultValue: 'Unable to reset password'}),
                pending: false
            });
        }
    }

    render() {
        const t = this.translatorOrDefault();
        const {
            product,
            logo,
            productPath
        } = this.props;
        const title = t('resetPasswordTitle');
        const continueButton = t('continue');
        const emailTitle = t('emailAddress');
        const description = t('resetPasswordDescription');
        const newPath = productPath || '';

        const productName = get(product, 'name');
        return (
            <div className="section no-border">
                <div className="reset-login">
                    <Link to={`${newPath}/`}><img className="logo" alt={productName} src={logo} style={{maxHeight: 100}}/></Link>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <br/>
                    <div className="field">
                        {this.resetForm(continueButton, emailTitle)}
                    </div>
                </div>
            </div>
        );
    }

    translatorOrDefault() {
        return this.props.t || ((it, {defaultValue}) => defaultValue || it);
    }

    resetForm(continueButton, emailTitle) {
        const {
            state: {
                success,
                error,
                values,
                pending
            }
        } = this;
        return (
            <div className="field">
                <Form
                    key="schema"
                    disabled={pending || success}
                    pending={pending}
                    formData={values}
                    noValidate
                    omitExtraData
                    liveOmit
                    schema={UiSchema(emailTitle)}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                >
                    {error && <Error>{error}</Error>}
                    {success && <div className="alert alert-info">{success}</div>}
                    <Button pending={pending} disabled={success || pending} className="btn btn-primary float-right"
                        type="submit">{continueButton}</Button>
                </Form>
            </div>
        );
    }
}

export default TriggerPasswordResetComponent;
