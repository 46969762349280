// These are the different kinds of 'step' we can display.
import {AuthContext, CurrentStepContext, PolicyholderContext} from '../common/context';
import QuoteArray from '../quote/QuoteArray';
import {ButtonPanel} from './ButtonPanel';
import Pay from '../pay/Pay';
import PolicySummary from '../summary/PolicySummary';
import {ResetPasswordComponent} from '@blocksure/blocksure-core/dist/src/components';
import {convertToNewPath, SureAppName} from '../base';
import {QuoteStep} from '../quote/QuoteStep';
import React, {useContext} from 'react';
import {AmendScreen} from '../amend/AmendScreen';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {ClaimStep} from '../claim/ClaimStep';
import ClaimConfirmation from '../claim/ClaimConfirmation';

export const FLOW_QUOTE = 'flow-quote';
export const FLOW_AMEND = 'flow-amend';
export const FLOW_CLAIM = 'flow-claim';

export const StepWidget = ({flow}) => {
    const {
        surelyncProps,
        updateAuth
    } = useContext(AuthContext);
    const {policyholder} = useContext(PolicyholderContext);
    const {nextStep} = useContext(CurrentStepContext);
    const {t} = useDefaultTranslation();
    const onComplete = () => {
        updateAuth(true);
        nextStep();
    };
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <CurrentStepContext.Consumer>{({stepUiSchema}) => {
                switch (stepUiSchema['ui:widget']) {
                    case 'amend-screen':
                        return <AmendScreen><ButtonPanel/></AmendScreen>;
                    case 'quote-screen':
                        return <QuoteArray><ButtonPanel showNext={false}/></QuoteArray>;
                    case 'quote-summary-screen':
                        return <Pay amend={flow === FLOW_AMEND}><ButtonPanel/></Pay>;
                    case 'payment-screen':
                        return <Pay amend={flow === FLOW_AMEND}><ButtonPanel/></Pay>;
                    case 'password-screen':
                        return <ResetPasswordComponent t={t} onComplete={onComplete} appName={SureAppName}
                                                       primaryEmailAddress={policyholder.primaryEmailAddress} inline/>;
                    case 'summary-screen':
                        switch (flow) {
                            case FLOW_CLAIM:
                                return <ClaimConfirmation><ButtonPanel/></ClaimConfirmation>;
                            default:
                                return (
                                        <PolicySummary>
                                            {surelyncProps ? null : <Link to={`${newPath}/`} className="btn btn-secondary float-right">{t('home')}</Link>}
                                        </PolicySummary>
                                );
                        }
                    default:
                        switch (flow) {
                            case FLOW_CLAIM:
                                return <ClaimStep amend={false}><ButtonPanel/></ClaimStep>;
                            default:
                                return <QuoteStep amend={flow === FLOW_AMEND}><ButtonPanel/></QuoteStep>;
                        }
                }
            }}</CurrentStepContext.Consumer>
    );
};
