import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Link as LinkButton, Typography, useTheme } from "@material-ui/core";
import { BannerClientLogo, PageContainer } from "../components";
import GlobalContext from "../context/global-context";
import { HelpAndFAQs, isEnglish } from "@surelync/common";

const SupportPage: React.FC = () => {
    const { locale } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <PageContainer title={t("support")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box textAlign="center" pb={2}>
                        <BannerClientLogo />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="h5">
                        {t("support")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        <HelpAndFAQs locale={locale} theme={theme} />
                    </Typography>
                </Grid>

                {isEnglish(locale) ? (
                    <>
                        <HorizontalLine width="385px" />
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                <LinkButton
                                    target="_blank"
                                    href="https://blocksure.atlassian.net/wiki/spaces/KB/pages/2390458371/Registering+for+access+to+support"
                                    rel="noopener"
                                >
                                    {t("register4Support")}
                                </LinkButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                <LinkButton target="_blank" href="https://blocksure.atlassian.net/servicedesk/customer/portals" rel="noopener">
                                    {t("raiseSupportTicket")}
                                </LinkButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" align="center">
                                <LinkButton
                                    target="_blank"
                                    href="https://blocksure.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&status=open"
                                    rel="noopener"
                                >
                                    {t("viewSupportTickets")}
                                </LinkButton>
                            </Typography>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </PageContainer>
    );
};

const HorizontalLine = ({ width }) => (
    <hr
        style={{
            width: width,
            margin: "25px auto",
        }}
    />
);

export default SupportPage;
