import React, { RefObject } from "react";
import { CurrencyUtils, ISettlementStatement, i18n, localeService, PlaceHolder } from "@surelync/common";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { Checkbox } from "@material-ui/core";

export const getHeaderColumnsI18 = (
    selectAllRef: RefObject<boolean>,
    selectedRows: RefObject<ISettlementStatement[]>,
    onSelectedRow: (row: ISettlementStatement) => void,
    onSelectedAllRows: () => any
) => [
    {
        Header: () => {
            return <div>{i18n.t("policyId")}</div>;
        },
        accessor: "reportingRef",
        Cell: (row: CellInfo) => row.value,
        size: "medium",
    },
    {
        Header: () => {
            return <div>{i18n.t("transactionReference")}</div>;
        },
        accessor: "transactionId",
        Cell: (row: CellInfo) => row.value,
        size: "large",
    },
    {
        Header: (data) => {
            return (
                <div>
                    {i18n.t("selectAll")}
                    <Checkbox color="default" size="small" checked={selectAllRef.current} onChange={onSelectedAllRows} />
                </div>
            );
        },
        accessor: "selectAll",
        Cell: (row: CellInfo) => {
            const isSelected = !!selectedRows.current.find((r) => r.id === row.row.original.id);
            return new Date(row.row.original.available) < new Date() ? (
                <Checkbox
                    color="default"
                    size="small"
                    checked={isSelected}
                    onChange={() => {
                        onSelectedRow(row.row.original);
                    }}
                />
            ) : null;
        },
        disableSortBy: true,
        size: "small",
    },
    {
        Header: () => {
            return <div>{i18n.t("dateCollected")}</div>;
        },
        accessor: "paid",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDateTime(row.value) : PlaceHolder),
    },
    {
        Header: () => {
            return <div>{i18n.t("dateAvailable")}</div>;
        },
        accessor: "available",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDateTime(row.value) : PlaceHolder),
    },
    {
        Header: () => {
            return <div>{i18n.t("status")}</div>;
        },
        accessor: "status",
        Cell: (row: CellInfo) => i18n.t(row.value.toLowerCase()),
        size: "small",
    },
    {
        Header: () => {
            return <div>{i18n.t("currency")}</div>;
        },
        accessor: "currency",
        Cell: (row: CellInfo) => row.value,
        size: "small",
    },
    {
        Header: () => {
            return <div>{i18n.t("amountCollected")}</div>;
        },
        accessor: "grossAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "medium",
    },
    {
        Header: () => {
            return <div>{i18n.t("paymentFee")}</div>;
        },
        accessor: "paymentFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "medium",
    },
    {
        Header: () => {
            return <div>{i18n.t("netAmount")}</div>;
        },
        accessor: "netAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "medium",
    },
];
