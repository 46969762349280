import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Form from 'react-jsonschema-form';
import AuthApiClient from '../../services/api-clients/AuthApiClient';
import './Login.css';
import {Button, Error} from '../widgets';
import {trimEmailValue} from '../../utilities/FormatUtils';

const Schema = (emailAddressText, passwordText, accountExistsText) => ({
    properties: {
        email: {
            type: 'string',
            title: emailAddressText,
            format: 'email'
        },
        password: {
            type: 'string',
            description: accountExistsText,
            title: passwordText,
            format: 'password'
        }
    }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {formData: {email: ''}};
    }

    onChange = ({formData}) => {
        let existing = this.state.existing;
        if (formData.email !== this.state.formData.email) {
            existing = false;
            formData.password = null;
        }
        return this.setState({
            existing,
            formData
        });
    };

    async onSubmit({formData}) {
        try {
            this.setState({pending: true});
            const {
                email,
                password
            } = formData;
            const trimmedEmail = trimEmailValue(email);
            const existing = await (new AuthApiClient()).exists(trimmedEmail);
            const t = this.translatorOrDefault();
            // If the email does not exist in the system, we can go ahead and create it. Or if the password has been entered then we can attempt to auth with that.
            if (!existing || password) {
                try {
                    await this.props.login(trimmedEmail, password);
                } catch (e) {
                    this.setState({error: t('invalidPassword')});
                }
            } else {
                this.setState({existing});
            }
        } finally {
            this.setState({pending: false});
        }
    }

    translatorOrDefault() {
        return this.props.t || ((it, {defaultValue}) => defaultValue || it);
    }

    render() {
        const {
            history,
            logo,
            title,
            productPath
        } = this.props;
        const display = this.display(title);
        const newPath = productPath || '';
        return (
            <div className="section screen no-border">
                <div className="login">
                    {logo && <img className="logo" alt={title} src={logo} onClick={() => history.push(`${newPath}/`)}/>}
                    {display}
                    <br/>
                    <div className="field">
                        {this.loginForm(newPath)}
                    </div>
                </div>
            </div>
        );
    }

    display(title) {
        const t = this.translatorOrDefault();
        const loginTitle = t('loginTitle', {defaultValue: `Welcome to ${title}`, productName: title});
        const loginDescription = t('loginDescription', {defaultValue: 'Please enter your email address to begin.'});
        if (this.props.lng != null && this.props.lng.includes('ja')) {
            return (<><br/><h3>{loginDescription}</h3></>);
        }
        return (<><h3>{loginTitle}</h3><p>{loginDescription}</p></>);
    }

    loginForm(newPath) {
        const t = this.translatorOrDefault();
        const {
            formData,
            existing,
            pending
        } = this.state;
        const error = this.props.error || this.state.error;
        const uiSchema = {password: {'ui:widget': existing ? 'password' : 'hidden'}};
        const emailAddress = t('emailAddress', {defaultValue: 'Email Address'});
        const password = t('password', {defaultValue: 'Password'});
        const accountExists = t('accountExists', {defaultValue: 'You already have an account with us, please log in below.'});
        const schema = Schema(emailAddress, password, accountExists);
        const clickToResetPassword = t('clickToResetPassword', {defaultValue: 'Click here to reset your password.'});
        const continueText = t('continue', {defaultValue: 'Continue'});
        return (
            <Form key="schema" formData={formData} noValidate omitExtraData liveOmit schema={schema} uiSchema={uiSchema} onChange={this.onChange}
                onSubmit={this.onSubmit}>
                <div>
                    {existing && (
                        <div className="field text-right" style={{marginTop: -20}}>
                            <Link to={{
                                pathname: `${newPath}/reset`,
                                state: {email: formData.email}
                            }}>{clickToResetPassword}</Link>
                        </div>
                    )}
                    {error && <Error>{error}</Error>}
                    <div className="text-right">
                        <Button pending={pending} className="btn btn-primary" type="submit">{continueText}</Button>
                    </div>
                </div>
            </Form>
        );
    }
}

Login.defaultProps = {
    login: () => {
    }
};

Login.contextTypes = {
    router: PropTypes.object
};

export default Login;
