import React, {useEffect, useState} from 'react';
import {PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {convertToNewPath,  SureAppName} from '../base';
import {PayForm} from '../pay/PayForm';
import {pay} from '../pay/paymentActions';
import Loading from '../widgets/Loading';
import {Screen} from '../shared/Screen';
import {formatDate} from '../shared/util';
import {useParams, useRouteMatch} from "react-router-dom";

const policyApiClient = new PolicyApiClient(SureAppName);

export const Transaction = ({
    match,
    history
}) => {
    const [transaction, setTransaction] = useState(undefined);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    useEffect(() => {
        policyApiClient.getTransaction(match.params.transactionId)
                .then(setTransaction);

    }, [match.params.transactionId]);

    const payAndContinue = async (paymentSourceId) => {
        await pay(transaction.id, paymentSourceId);
        history.push(`${newPath}/payments`);
    };

    if (!transaction) return <Loading/>;

    return (
            <Screen>
                <div className="section pay offset-md-2 col-md-8">
                    <h4 className="text-center"><label className="label label-info label-wrap">
                        You are paying for an installment on your policy {transaction.reportingRef} that was due on {formatDate(transaction.due)}
                    </label></h4>
                    <hr/>
                    <PayForm
                            policyholderId={transaction.policyholderId}
                            paymentGateway={transaction.paymentGateway}
                            currency={transaction.currency}
                            amount={transaction.totalAmount}
                            back={history.goBack}
                            pay={payAndContinue}
                    />
                </div>
            </Screen>
    );
};
