import React from "react";
import { Link } from "react-router-dom";
import { CurrencyUtils, EIdentityType, EPolicyStatus, i18n, localeService } from "@surelync/common";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import Typography from "@material-ui/core/Typography";

export const getHeaderColumnsI18 = (isMobile: boolean, onRedirect: (url: string) => any) => [
    {
        Header: () => `${i18n.t("policy")} / ${i18n.t("quoteNo")}`,
        accessor: "policyNoAndQuoteNo",
        Cell: (row: CellInfo) => {
            const isQuote = [EPolicyStatus.Quoted, EPolicyStatus.Referral, EPolicyStatus.Dtq].includes(row.row.original.policies);
            return (
                <>
                    <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.id}`)} variant="caption">
                        {isQuote ? row.row.original.quoteNo : row.row.original.policyNo}
                    </LinkButton>
                </>
            );
        },
        size: isMobile ? "small" : "medium",
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => <Typography variant="button">{row.value}</Typography>,
        size: "small",
    },
    {
        Header: () => i18n.t("effective"),
        accessor: "effective",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        size: "small",
    },
    {
        Header: () => i18n.t("renew"),
        accessor: "renew",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        size: "small",
    },
    {
        Header: () => i18n.t("product"),
        accessor: "product",
        size: isMobile ? "small" : "medium",
    },
    {
        Header: () => i18n.t("mga"),
        accessor: "mga",
        size: "small",
    },
    {
        roles: [EIdentityType.Broker, EIdentityType.Blocksure, EIdentityType.MGA],
        Header: () => i18n.t("insurer"),
        accessor: "insurer",
        Cell: (row: CellInfo) =>
            row.row.original.isMultiSections ? (
                <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.id}`)} variant="caption">
                    {i18n.t("multiSection")}
                </LinkButton>
            ) : (
                row.value
            ),
        size: isMobile ? "small" : "medium",
    },
    {
        roles: [EIdentityType.Insurer, EIdentityType.MGA],
        Header: () => i18n.t("agent"),
        accessor: "agent",
        size: isMobile ? "small" : "medium",
    },
    {
        roles: [EIdentityType.MGA],
        Header: () => i18n.t("grossBrokerage"),
        accessor: "grossBrokerage",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        roles: [EIdentityType.MGA],
        Header: () => i18n.t("mgaCommission"),
        accessor: "mgaCommission",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        roles: [EIdentityType.Insurer, EIdentityType.MGA],
        Header: () => i18n.t("totalDeductions"),
        accessor: "totalDeductions",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        Header: () => i18n.t("premium"),
        accessor: "premium",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        Header: () => i18n.t("accountBalance"),
        accessor: "accountBalance",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        Header: () => i18n.t("lastUpdate"),
        accessor: "lastUpdate",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        size: "small",
    },
];
