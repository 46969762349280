import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { AuditApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {
    DateRangeCustom,
    IAuditEvent,
    InputCustom,
    IPaginableResponse,
    ITableData,
    LoadingBtn,
    MessageBox,
    RecordsNotFound,
    SelectCustom,
    TableContainer,
} from "@surelync/common";
import { prepareData } from "./helper";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, PageContainer } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import sortBy from "lodash/sortBy";

interface IFilters {
    dateRange?: [Date, Date];
    eventTypes?: string;
    emails?: string;
}

interface ISearchParams {
    from?: string;
    to?: string;
    eventTypes?: string;
    emails?: string;
    limit: number;
    offset: number;
}

interface ISelectOption {
    label: string;
    value: string;
}

type EventTypeResponse = { eventTypes: string[] };

const AuditEvents: React.FC = () => {
    const { locale, namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const [data, setData] = useState<ITableData[]>(null);
    const [error, setError] = useState<string>(null);
    const [filters, setFilters] = useState<IFilters>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [optionsEvent, setOptionsEvents] = useState<ISelectOption[]>([]);
    const [total, setTotal] = useState<number | null>(null);

    const isMountRef = useRef(true);
    const headerColumns = useMemo(getHeaderColumnsI18, []);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const auditApiClient = new AuditApiClient(namespacedLocalStorage);
                const result: EventTypeResponse = await auditApiClient.getEventType();
                if (!isMountRef.current) {
                    return;
                }
                const options = result.eventTypes.map((it) => ({ label: t(`a${it}`), value: it }));
                setOptionsEvents([{ label: t("all"), value: "*" }, ...sortBy(options, "label")]);
            } catch (error) {
                if (!isMountRef.current) {
                    return;
                }
                setError(generateErrorMessage(error));
            }
        };

        fetchEvents();

        return () => {
            isMountRef.current = false;
        };
    }, [namespacedLocalStorage, t]);

    const fetchData = useCallback(
        async (rowsPerPage: number, page: number) => {
            if (!isMountRef.current) {
                return;
            }

            setLoading(true);
            setData(null);
            setError(null);

            try {
                const auditApiClient = new AuditApiClient(namespacedLocalStorage);
                const { dateRange, eventTypes } = filters;
                const email = filters.emails?.trim() || "";
                const [from, to] = dateRange || [];
                const params: ISearchParams = {
                    limit: rowsPerPage,
                    offset: page,
                };
                if (from && to) {
                    params.from = FormatUtils.renderFormatDate(from, FormatUtils.SERVER_DATE_FORMAT);
                    params.to = FormatUtils.renderFormatDate(to, FormatUtils.SERVER_DATE_FORMAT);
                }
                if (eventTypes && eventTypes !== "*") {
                    params.eventTypes = eventTypes;
                }
                if (email) {
                    params.emails = email;
                }

                const result: IPaginableResponse<IAuditEvent> = await auditApiClient.search(params);
                if (!isMountRef.current) {
                    return;
                }

                setTotal(result.total);
                setData(prepareData(result.items));
            } catch (error) {
                if (!isMountRef.current) {
                    return;
                }
                setError(generateErrorMessage(error));
            }
            setLoading(false);
        },
        [filters, namespacedLocalStorage]
    );

    const formatDateRange = useCallback(() => {
        const [from, to] = filters.dateRange || [];
        if (from && to) {
            return `${FormatUtils.renderFormatDate(from, "D")} - ${FormatUtils.renderFormatDate(to, "D")}`;
        } else {
            return "";
        }
    }, [filters]);

    return (
        <PageContainer title={t("auditEvents")}>
            <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("auditEvents")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <DateRangeCustom
                        displayValue={formatDateRange()}
                        label={t("creationDate")}
                        locale={locale}
                        theme={theme}
                        value={filters.dateRange}
                        onChange={(dateRange) => setFilters({ ...filters, dateRange })}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <SelectCustom
                        label={t("eventType")}
                        options={optionsEvent}
                        theme={theme}
                        value={filters.eventTypes || "*"}
                        onChange={(event) => setFilters({ ...filters, eventTypes: event.target.value })}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <InputCustom
                        autoComplete="off"
                        label={t("email")}
                        value={filters.emails || ""}
                        fullWidth
                        theme={theme}
                        onChange={(event) => setFilters({ ...filters, emails: event.target.value })}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <Button color="secondary" disabled={loading} onClick={() => setFilters({})} data-testid="clear-btn">
                        {t("clear")}
                    </Button>
                    &nbsp;
                    <LoadingBtn fetching={loading} label={t("applyFilters")} theme={theme} onClick={() => fetchData(10, 0)} />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} loading={loading} />
                </Grid>
                {data && data.length === 0 && (
                    <Grid item xs={12}>
                        <RecordsNotFound theme={theme} />
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12} md={8}>
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                    </Grid>
                )}
            </Grid>
        </PageContainer>
    );
};

export default AuditEvents;
