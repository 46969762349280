import React from "react";
import { CellInfo } from "react-table";
import { DescriptionColumns } from "../components";
import { i18n } from "@surelync/common";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("name"),
        accessor: "name",
        size: "small",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        Cell: (row: CellInfo) => {
            return <DescriptionColumns permissions={row.row.original.permissions} value={row.value} />;
        },
        size: "large",
    },
];
