import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import GlobalContext from "../../../context/global-context";
import { ESearchType, HomeContext, ISearchCompanyParams, RESET_SEARCH_PARAMS, SET_SEARCH_PARAMS, SET_SEARCH_TYPE } from "../../models";
import { DateRangeCustom, LoadingBtn } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { FTextField } from "../../../components/Formik/FTextField";
import { SearchItemsSelect } from "../SearchItems/SearchItemsSelect";
import { FCheckboxField } from "../../../components/Formik";
import Divider from "@material-ui/core/Divider";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

type ISearchProps = Record<string, unknown>;

export const SearchCompany: React.FC<ISearchProps> = () => {
    const { locale } = useContext(GlobalContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState(null);
    const [state, dispatch] = useContext(HomeContext);

    const { fetching } = state;

    const handleClear = () => {
        setDateRange(null);
        dispatch({ type: RESET_SEARCH_PARAMS });
    };

    const handleSubmit = (values: ISearchCompanyParams) => {
        const searchParams: ISearchCompanyParams = {};
        const [from, to] = dateRange || [];

        if (values.policies) {
            searchParams.policies = true;
        }

        if (values.claims) {
            searchParams.claims = true;
        }

        if (values.companyName) {
            searchParams.companyName = values.companyName.trim();
        }

        if (values.companyRegistrationNumber) {
            searchParams.companyRegistrationNumber = values.companyRegistrationNumber.trim();
        }

        if (values.policyholderId) {
            searchParams.policyholderId = values.policyholderId.trim();
        }

        if (values.postcode) {
            searchParams.postcode = values.postcode.trim();
        }

        if (from) {
            searchParams.from = FormatUtils.renderFormatDate(from, FormatUtils.SERVER_DATE_FORMAT);
        }

        if (to) {
            searchParams.to = FormatUtils.renderFormatDate(to, FormatUtils.SERVER_DATE_FORMAT);
        }

        dispatch({ type: SET_SEARCH_PARAMS, payload: searchParams });
    };

    const formatDateRange = () => {
        const [from, to] = dateRange || [];
        if (from && to) {
            return `${FormatUtils.renderFormatDate(from, "D")} - ${FormatUtils.renderFormatDate(to, "D")}`;
        } else {
            return "";
        }
    };

    return (
        <Formik
            initialValues={{
                companyName: "",
                companyRegistrationNumber: "",
                postcode: "",
                claims: false,
                policies: false,
            }}
            onSubmit={handleSubmit}
        >
            {({ resetForm }) => (
                <Form>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6">{t("advancedSearch")}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        data-testid="close-btn"
                                        onClick={() => dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.Global })}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SearchItemsSelect />

                            <Box my={1}>
                                <FCheckboxField color="default" label={t("activePolicy")} name="policies" />
                                <FCheckboxField color="default" label={t("openClaims")} name="claims" />
                            </Box>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <FTextField label={t("companyName")} name="companyName" />
                        </Grid>

                        <Grid item xs={12}>
                            <FTextField label={t("companyRegNo")} name="companyRegistrationNumber" />
                        </Grid>

                        <Grid item xs={12}>
                            <DateRangeCustom
                                displayValue={formatDateRange()}
                                label={t("createdBetween")}
                                locale={locale}
                                value={dateRange}
                                theme={theme}
                                onChange={setDateRange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FTextField label={t("postcode")} name="postcode" />
                        </Grid>

                        <Grid item xs={12}>
                            <Box my={2} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-end" spacing={1}>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={fetching}
                                        onClick={() => {
                                            resetForm();
                                            handleClear();
                                        }}
                                    >
                                        {t("clear")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingBtn fetching={fetching} label={t("applyFilters")} theme={theme} type="submit" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
