import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { PaymentApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { ITableData, MessageBox, RecordsNotFound, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataDrawdowns } from "./helper";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, PageContainer } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";

const DrawdownsPage: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);

    const paymentApiClient = new PaymentApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const fetchData = async (rowsPerPage: number, page: number) => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        try {
            const batches = await paymentApiClient.listBatches(rowsPerPage, page);
            const headerColumnsI18 = getHeaderColumnsI18();
            const dataSettlementStatement = setDataDrawdowns(batches);

            if (!isMountRef.current) {
                return;
            }

            setTotal(batches.total);
            setHeaderColumns(headerColumnsI18);
            setData(dataSettlementStatement);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    return (
        <PageContainer title={t("drawdowns")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("drawdowns")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} loading={loading} />
                </Grid>
                {data && data.length === 0 && (
                    <Grid item xs={12}>
                        <RecordsNotFound theme={theme} />
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12} md={8}>
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                    </Grid>
                )}
            </Grid>
        </PageContainer>
    );
};

export default DrawdownsPage;
