import React from 'react';
import {Redirect, useParams, useRouteMatch} from 'react-router-dom';
import {convertToNewPath} from "../base";

// FIXME hardcode '/f' it is not good
const parsePublicURL = () => {
    const [protocol, , domain]: string[] = window.location.href.split('/', 3);
    return `${protocol}//${domain}/sureapp`;
};

/** Provides routes underneath the :theme url parameter */
const ThemeRoute = ({
    children,
    setTheme,
    surelyncProps
}) => {
    const {theme} = useParams();
    const effectiveTheme = setTheme || theme;
    const publicURL = process.env.PUBLIC_URL || parsePublicURL();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    if (surelyncProps) {
        return children;
    } else if (!setTheme && !Themes.map(it => it.toLowerCase())
            .includes(effectiveTheme)) {
        return <Redirect to={`${newPath}/blocksure`}/>;
    }
    return (
            <div>
                <link rel="stylesheet" type="text/css" href={`${publicURL}/css/${effectiveTheme}.css`}/>
                <link rel="stylesheet" type="text/css" href={`${publicURL}/css/${effectiveTheme}-tweaks.css`}/>
                {children}
            </div>
    );
};

export const Themes = [
    'Cerulean',
    'Cosmo',
    'Cyborg',
    'Darkly',
    'Flatly',
    'Journal',
    'Lumen',
    'Paper',
    'Readable',
    'Sandstone',
    'Simplex',
    'Slate',
    'Spacelab',
    'Superhero',
    'United',
    'Yeti',
    'ComAndGen',
    'InsureNow',
    'Blocksure',
    'Blocksure-Alt'
];

export default ThemeRoute;
