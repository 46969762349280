import config from './configuration.js';

// this is an object where the fields of flatfile are defined to make main more readable
function trimEnd(str, count) { // It was suggested I create a function in the helper file to reuse in the project,
    // but I don't know how to do that, but I will create a new funciton that can be copied into the helper file, and it can be replaced
    str = str.substring(0, str.length - count);
    return str;
}

function genfields() { // goes through list of std fields from config and generates the fields array
    const generatedFields = [];
    for (let i = 0; i < config.listOfStdFields.length; i++) {
        let currentField = config.listOfStdFields[i];
        if (currentField.endsWith('!*')) { // if field is mandatory and unique
            currentField = trimEnd(currentField, 2); // remove !*
            generatedFields.push(
                {
                    label: currentField,
                    key: currentField,
                    validators: [
                        {validate: 'required'},
                        {validate: 'unique'}
                    ]
                }
            );
        } else if (currentField.endsWith('*')) { // if field is mandatory
            currentField = trimEnd(currentField, 1); // remove *
            generatedFields.push(
                {
                    label: currentField,
                    key: currentField,
                    validators: [
                        {validate: 'required'},
                    ]
                }
            );
        } else if (currentField.endsWith('!')) { // if field is unique
            currentField = trimEnd(currentField, 1); // remove !
            generatedFields.push(
                {
                    label: currentField,
                    key: currentField,
                    validators: [
                        {validate: 'unique'},
                    ]
                }
            );
        } else { // if field is neither unique or mandatory
            generatedFields.push(
                {
                    label: currentField,
                    key: currentField
                }
            );
        }
    }
    for (let i = 0; i < config.listOfEnumeratedFields.length; i++) {
        generatedFields.push(
            config.listOfEnumeratedFields[i]
        );
    }
    // console.log(`generated fields are ${JSON.stringify(generatedFields)}`);
    return generatedFields;
}

const fields = genfields();
export default {fields};
