import React from "react";
import { FieldAttributes, useField } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Colors = "primary" | "default";
type FCheckboxProps = { color: Colors; label: string } & FieldAttributes<unknown>;

export const FCheckboxField: React.FC<FCheckboxProps> = ({ label, color, value, ...props }) => {
    const [field] = useField<unknown>(props);
    const inputProps = { "data-testid": props.name } as any;
    return <FormControlLabel {...field} control={<Checkbox color={color} checked={value} inputProps={inputProps} />} label={label} />;
};
