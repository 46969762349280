import * as React from "react";
import { useEffect, useReducer } from "react";
import GlobalContext from "./global-context";
import {
    defaultState,
    globalReducer,
    SET_CACHE_USER_DONE,
    SET_CLIENT_LOGO,
    SET_CURRENT_USER,
    SET_DISPLAYED_TABLE_COLUMNS,
    SET_LOCALE,
    SET_OPEN_CUSTOMISE_FLYOUT,
    SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_OPEN,
    SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_TRANSACTION_ID,
    SET_OPEN_POLICY_PAYMENT_FLYOUT_OPEN,
    SET_OPEN_POLICY_PAYMENT_FLYOUT_SEQUENCE_ID,
    SET_OPEN_RETRY_PAYMENT_FLYOUT_OPEN,
    SET_OPEN_RETRY_PAYMENT_FLYOUT_TRANSACTION_ID,
    SET_PRODUCTS
} from "./reducers";
import { CurrencyUtils, IUser, IProduct, localeService, namespacedLocalStorage, signInService } from "@surelync/common";
import { IColumns } from "./state.model";
import axios from "axios";
import AuthApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/AuthApiClient";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import { PaymentApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

type IProps = Record<string,unknown>;

const GlobalState: React.FC<IProps> = ({ children }) => {
    const [globalState, dispatch] = useReducer(globalReducer, defaultState);
    const { cacheUsers } = globalState;
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);
    const paymentApiClient = new PaymentApiClient(namespacedLocalStorage);
    const productApiClient = React.useMemo(() => new ProductApiClient(namespacedLocalStorage), []);

    useEffect(() => {
        let isMount = true;

        try {
            const getMoneyPrecision = async () => {
                const response = await paymentApiClient.getCurrencies();
                if (!isMount) return;
                CurrencyUtils.moneyPrecision = response;
            };

            getMoneyPrecision();
        } catch (error) {
            console.error(error);
        }

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const suscription = localeService.i18nLoader$.subscribe((value: boolean) => {
            if (!localeService.i18nReady) return;
            const { locale } = localeService;
            dispatch({ type: SET_LOCALE, payload: locale });
            FormatUtils.setGlobalLocale(locale);
        });

        const currentUser: IUser = {
            authDetails: signInService.loadAuthDetails(),
            userDetails: signInService.loadUserDetails(),
        };

        dispatch({
            type: SET_CURRENT_USER,
            payload: currentUser,
        });

        return () => suscription.unsubscribe();
    }, []);

    useEffect(() => {
        let isMount = true;

        try {
            const getClientLogo = async () => {
                const blob = await blobApiClient.retrievedTagGlobal("client-logo");
                if (!isMount) return;
                dispatch({ type: SET_CLIENT_LOGO, payload: blob });
            };

            getClientLogo();
        } catch (error) {
            // console.error(error);
        }

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLocale = (locale: string) => {
        localeService.setLocale(locale);
    };

    const setOpenCustomiseFlyout = (open: boolean) => {
        dispatch({ type: SET_OPEN_CUSTOMISE_FLYOUT, payload: open });
    };

    const setDisplayedTableColumns = (columns: IColumns[]) => {
        dispatch({ type: SET_DISPLAYED_TABLE_COLUMNS, payload: columns });
    };

    // const setCountries = (countries: ICountry[]) => {
    //   dispatch({ type: SET_COUNTRIES, payload: countries });
    // };

    const setOpenPolicyPaymentFlyoutOpen = (open: boolean) => {
        dispatch({ type: SET_OPEN_POLICY_PAYMENT_FLYOUT_OPEN, payload: open });
    };

    const setOpenPolicyPaymentFlyoutSequenceId = (sequenceId: string) => {
        dispatch({
            type: SET_OPEN_POLICY_PAYMENT_FLYOUT_SEQUENCE_ID,
            payload: sequenceId,
        });
    };

    const setOpenPaymentAttemptsFlyoutOpen = (open: boolean) => {
        dispatch({ type: SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_OPEN, payload: open });
    };

    const setOpenPaymentAttemptsFlyoutTransactionId = (transactionId: string) => {
        dispatch({
            type: SET_OPEN_PAYMENT_ATTEMPTS_FLYOUT_TRANSACTION_ID,
            payload: transactionId,
        });
    };

    const setOpenRetryPaymentFlyoutOpen = (open: boolean) => {
        dispatch({ type: SET_OPEN_RETRY_PAYMENT_FLYOUT_OPEN, payload: open });
    };

    const setOpenRetryPaymentFlyoutTransactionId = (transactionId: string) => {
        dispatch({
            type: SET_OPEN_RETRY_PAYMENT_FLYOUT_TRANSACTION_ID,
            payload: transactionId,
        });
    };

    useEffect(() => {
        if (!cacheUsers) {
            return;
        }

        let cancelRequest = false;

        const fetchData = async () => {
            if (!cacheUsers) {
                return;
            }
            const authApiClient = new AuthApiClient(namespacedLocalStorage);
            const requests = Object.keys(cacheUsers)
                .filter((email) => cacheUsers[email] === null) // fetch only new users
                .map((email) => authApiClient.getUser(email));

            if (!requests.length) {
                return;
            }

            const responses = await axios.all(requests);
            if (cancelRequest) return;
            const users = {};
            responses.forEach((response) => (users[response.data.email] = response.data));
            dispatch({ type: SET_CACHE_USER_DONE, payload: users });
        };

        fetchData();

        return function cleanup() {
            cancelRequest = true;
        };
    }, [cacheUsers]);

    const isI18Ready = globalState.locale;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const loadProduct = { "load.approvals": true, "load.attachments": true, "load.roles": true, "load.payment": true };
                const response = await productApiClient.search(loadProduct);
                const products = response.items.reduce((cache, product: IProduct) => {
                    cache[product.id] = product;
                    return cache;
                }, {});
                dispatch({ type: SET_PRODUCTS, payload: products });
            } catch (error) {
                console.warn("error", error.message);
            }
        };

        fetchData();
    }, [productApiClient]);

    return (
        <GlobalContext.Provider
            value={{
                ...globalState,
                namespacedLocalStorage,
                dispatchGlobal: dispatch,
                setLocale,
                setOpenCustomiseFlyout,
                setDisplayedTableColumns,
                setOpenPolicyPaymentFlyoutOpen,
                setOpenPolicyPaymentFlyoutSequenceId,
                setOpenPaymentAttemptsFlyoutOpen,
                setOpenPaymentAttemptsFlyoutTransactionId,
                setOpenRetryPaymentFlyoutOpen,
                setOpenRetryPaymentFlyoutTransactionId,
            }}
        >
            {isI18Ready && children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
