import React from 'react';
import {Link, Redirect, useParams, useRouteMatch} from 'react-router-dom';
import {PolicyListContext, TradingGroupContext} from '../common/context';
import {Menu} from '../shared/Menu';
import {Screen} from '../shared/Screen';
import {PolicyList, PolicyListSmall} from '../shared/PolicyList';
import {ProductLink} from '../product/ProductLink';
import {useDefaultTranslation} from '../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import {convertToNewPath} from "../base";

export const CoverMenu = () => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <Screen>
                <TradingGroupContext.Consumer>{({products = {}}) => (
                        <PolicyListContext.Consumer>{({quotes = [], renewals = []}) => {
                            let items = [];
                            const renewalsReportingRef = renewals?.map((renewal) => renewal.reportingRef);
                            const loadedProducts = Object.values(products || {});
                            loadedProducts.sort((a, b) => FormatUtils.compareISODates(a.ts, b.ts, 'milliseconds'));
                            
                            items.push(<h4 data-menukey="quote-title">{t('getNewQuote')}:</h4>);
                            loadedProducts.forEach(product => items.push(<ProductLink data-menukey={`product-${product.id}`} product={product}/>));

                            const breakout = [];
                            let unexpiredQuotes = quotes && quotes.filter(it => {
                                return it.status === 'Quoted' && !renewalsReportingRef?.includes(it.reportingRef)
                            });
                            if (unexpiredQuotes && unexpiredQuotes.length > 0) {
                                breakout.push(<h4 data-menukey="outstanding-title">{t('latestQuote')}:</h4>);
                                breakout.push(<PolicyList data-menukey="policies" newPath={newPath} policies={[unexpiredQuotes[0]]}/>);
                            }
                            if (unexpiredQuotes && unexpiredQuotes.length > 1) {
                                breakout.push(<h4 data-menukey="outstanding-title" className={"my-3"}>{t('otherOutstandingQuotes')}:</h4>);
                                breakout.push(<PolicyListSmall data-menukey="policies" newPath={newPath} policies={unexpiredQuotes}/>);
                            }
                            breakout.forEach(it => items.push(it));

                            items.push(<hr data-menukey="separator2"/>);
                            items.push(<Link data-menukey="Back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary">{t('back')}</Link>);
                            if (loadedProducts.length === 1 && unexpiredQuotes && unexpiredQuotes.length === 0) {
                                return <Redirect to={`${newPath}/quote/${loadedProducts[0].id}`}/>;
                            }
                            return <Menu items={items} breakout={breakout}/>;

                        }}</PolicyListContext.Consumer>
                )}</TradingGroupContext.Consumer>
            </Screen>
    );
};
