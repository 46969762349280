import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { EIdentityType, history, i18n, PageNotFoundPage, theme } from "@surelync/common";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { Layout } from "./components/Layout/Layout";
import GlobalState from "./context/GlobalState";
import HomePage from "./@Home/HomePage";
import ProfilePage from "./@Profile/ProfilePage";
import ClaimsPage from "./@Claims/ClaimsPage";
import ContactOverviewPage from "./@ContactOverview/ContactOverviewPage";
import SupportPage from "./@Support/SupportPage";
import AboutPage from "./@About/AboutPage";
import UserAdministrationPage from "./@UserAdministration/UserAdministrationPage";
import UserAdministrationEditPage from "./@UserAdministrationEdit/UserAdministrationEditPage";
import PolicyDetailsPage from "./@PolicyDetails/PolicyDetailsPage";
import RoleAdministrationPage from "./@RoleAdministration/RoleAdministrationPage";
import RoleAdministrationEditPage from "./@RoleAdministrationEdit/RoleAdministrationEditPage";
import PaymentGatewayPage from "./@PaymentGateway/PaymentGatewayPage";
import CompanyLogoPage from "./@CompanyLogo/CompanyLogoPage";
import ReferralPage from "./@Referral/ReferralPage";
import SettlementsPage from "./@Settlements/SettlementsPage";
import ClaimDetailsPage from "./@ClaimDetails/ClaimDetailsPage";
import DrawdownsPage from "./@Drawdowns/DrawdownsPage";
import DrawdownDetailsPage from "./@DrawdownDetails/DrawdownDetailsPage";
import OverdueCollectionsPage from "./@OverdueCollections/OverdueCollectionsPage";
import PoliciesPage from "./@Policies/PoliciesPage";
import PolicyMTAPage from "./@PolicyMTA/PolicyMTAPage";
import AuditEvents from "./@AuditEvents/AuditEvents";
import CsvImport from "./@CsvImport/CsvImport";

const BROKER_IDENTITY = [EIdentityType.Broker];

const IDENTITIES_EXCLUDING_BLOCKSURE = [...BROKER_IDENTITY, EIdentityType.Insurer, EIdentityType.MGA];

const ALL_IDENTITIES = [...IDENTITIES_EXCLUDING_BLOCKSURE, EIdentityType.Blocksure];

function App() {
    return (
        <GlobalState>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Layout>
                        <Router history={history}>
                            <Switch>
                                <PrivateRoute path="/" exact identityTypes={ALL_IDENTITIES} component={HomePage} />
                                <PrivateRoute path="/audit-events" identityTypes={ALL_IDENTITIES} component={AuditEvents} />
                                <PrivateRoute path="/contacts/:id" identityTypes={ALL_IDENTITIES} component={ContactOverviewPage} />
                                <PrivateRoute path="/profile" identityTypes={ALL_IDENTITIES} component={ProfilePage} />
                                <PrivateRoute
                                    path="/policies"
                                    exact={true}
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    permissions={["POLICY_READ", "POLICY_HOLDER_READ"]}
                                    permissionType="all"
                                    component={PoliciesPage}
                                />
                                <PrivateRoute path="/quotes" exact={true} identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE} component={PoliciesPage} />
                                <PrivateRoute
                                    path="/referrals"
                                    exact={true}
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    component={PoliciesPage}
                                />
                                <PrivateRoute path="/dtqs" exact={true} identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE} component={PoliciesPage} />
                                <PrivateRoute
                                    path="/cancellations"
                                    exact={true}
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    component={PoliciesPage}
                                />
                                <PrivateRoute
                                    path="/policies/:id/:sequenceId"
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    permissions={["POLICY_READ", "POLICY_HOLDER_READ"]}
                                    permissionType="all"
                                    component={PolicyDetailsPage}
                                />
                                <PrivateRoute
                                    path="/policies/:id"
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    permissions={["POLICY_READ", "POLICY_HOLDER_READ"]}
                                    permissionType="all"
                                    component={PolicyDetailsPage}
                                />
                                {/*
                                    'tradingId' it is for the theme provider.
                                    if 'insurenow' then will be loaded styles from Formulator /public/css/insurenow.css
                                */}
                                <PrivateRoute
                                    path="/sureapp/:tradingId/amend/:policyId"
                                    identityTypes={BROKER_IDENTITY}
                                    component={() => (<PolicyMTAPage titleKey="policyAmendment" />)}
                                />
                                <PrivateRoute
                                    path="/import"
                                    identityTypes={BROKER_IDENTITY}
                                    component={() => (<CsvImport />)}
                                />
                                <PrivateRoute
                                    path="/import-status"
                                    identityTypes={BROKER_IDENTITY}
                                    component={() => (<div>Page import-status</div>)}
                                />
                                <PrivateRoute
                                    path="/f/:tradingId/renewal/:policyId"
                                    identityTypes={BROKER_IDENTITY}
                                    component={() => (<PolicyMTAPage titleKey="renewal" />)}
                                />
                                <PrivateRoute path="/claims" exact={true} identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE} component={ClaimsPage} />
                                <PrivateRoute
                                    path="/claims/:id"
                                    exact={true}
                                    identityTypes={IDENTITIES_EXCLUDING_BLOCKSURE}
                                    component={ClaimDetailsPage}
                                />
                                <PrivateRoute path="/support" identityTypes={ALL_IDENTITIES} component={SupportPage} />
                                <PrivateRoute path="/about" identityTypes={ALL_IDENTITIES} component={AboutPage} />
                                <PrivateRoute path="/payment-gateway" identityTypes={ALL_IDENTITIES} component={PaymentGatewayPage} />
                                <PrivateRoute path="/company-logo" identityTypes={ALL_IDENTITIES} component={CompanyLogoPage} />
                                <PrivateRoute path="/user-administration" exact identityTypes={ALL_IDENTITIES} component={UserAdministrationPage} />
                                <PrivateRoute
                                    path="/user-administration/create"
                                    exact
                                    identityTypes={ALL_IDENTITIES}
                                    component={UserAdministrationEditPage}
                                />
                                <PrivateRoute
                                    path="/user-administration/:id"
                                    exact
                                    identityTypes={ALL_IDENTITIES}
                                    component={UserAdministrationEditPage}
                                />
                                <PrivateRoute path="/role-administration" exact identityTypes={ALL_IDENTITIES} component={RoleAdministrationPage} />
                                <PrivateRoute
                                    path="/role-administration/create"
                                    exact
                                    identityTypes={ALL_IDENTITIES}
                                    component={RoleAdministrationEditPage}
                                />
                                <PrivateRoute
                                    path="/role-administration/:id"
                                    exact
                                    identityTypes={ALL_IDENTITIES}
                                    component={RoleAdministrationEditPage}
                                />
                                <PrivateRoute path="/referral/:id" exact identityTypes={ALL_IDENTITIES} component={ReferralPage} />
                                <PrivateRoute path="/settlements" exact identityTypes={ALL_IDENTITIES} component={SettlementsPage} />
                                <PrivateRoute path="/statements" exact identityTypes={ALL_IDENTITIES} component={DrawdownsPage} />
                                <PrivateRoute path="/statement-details/:id" exact identityTypes={ALL_IDENTITIES} component={DrawdownDetailsPage} />
                                <PrivateRoute path="/overdue-premiums" exact identityTypes={ALL_IDENTITIES} component={OverdueCollectionsPage} />
                                <Route path="*" component={() => <PageNotFoundPage theme={theme} />} />
                            </Switch>
                        </Router>
                    </Layout>
                </ThemeProvider>
            </I18nextProvider>
        </GlobalState>
    );
}

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync",
    seed: "main",
});

export default function Root() {
    // Fix: Application unexpected behavior on clicking browser history back/forward buttons
    if (`/surelync${history.location.pathname}` !== window.location.pathname) {
        history.replace(window.location.pathname.replace(`/surelync`, ""));
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <App />
        </StylesProvider>
    );
}
