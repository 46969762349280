import * as React from "react";
import { useContext, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ClaimContext } from "../../models/context";
import Divider from "@material-ui/core/Divider";
import { CurrencyUtils, localeService } from "@surelync/common";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        scrollable: {
            overflow: "hidden",
            overflowY: "auto",
        },
    })
);

type IProps = Record<string, unknown>;

const SumsInsured: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ policy }] = useContext(ClaimContext);
    const { t } = useTranslation();

    const totalSumsInsured = useMemo(
        () => (policy ? CurrencyUtils._renderCurrency(policy.sumInsured, localeService.locale, policy.currency) : ""),
        [policy]
    );

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("sumsInsured")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="body1">{t("totalSumInsuredLower")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" noWrap>
                            {totalSumsInsured}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default SumsInsured;
