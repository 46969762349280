import { IColumn } from "./models";

export const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export const availableColumns = (defaultColumns: IColumn[], displayedTableColumns: IColumn[]) => {
    const resultAfterFiltering = [];

    if (displayedTableColumns) {
        defaultColumns.forEach((column) => {
            const isDisplayed = displayedTableColumns.find((disabledColumn) => disabledColumn.id === column.id);
            if (!isDisplayed) {
                resultAfterFiltering.push(column);
            }
        });
    }

    return displayedTableColumns ? resultAfterFiltering : defaultColumns;
};
