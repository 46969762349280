import {useContext} from 'react';
import {ProductContext, TradingGroupContext} from './context';
import {useTranslation} from 'react-i18next';

export const useDefaultTranslation = (config) => {
    const {group} = useContext(TradingGroupContext) || {};
    const {product} = useContext(ProductContext) || {};
    const {t, i18n} = useTranslation();
    return {
        i18n, t: (key, values) => {
            const configForTranslation = {group, product, ...values, ...config};
            // The default here is to return the key itself which we definitely do not want.
            if (configForTranslation.defaultValue === undefined) configForTranslation.defaultValue = null;
            return t([`product:${key}`, `group:${key}`, key], configForTranslation);
        }
    };
};
