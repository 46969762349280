import { CurrencyUtils, i18n, IPolicy, localeService, PolicyUtils } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const setDataOverdueCollections = (data: IPolicy[]) => {
    return data.map((item) => ({
        sequenceId: item.sequenceId,
        policyIssueDate: FormatUtils.renderDateTime(item.creation),
        policyholder: item.policyholderId,
        reportingRef: item.reportingRef,
        dateDueFromPolicyholder: FormatUtils.renderDateTime(item.due),
        status: i18n.t(item.status.toLowerCase()),
        failureReason: PolicyUtils._getTransactionAttempt(item, "message"),
        collectedAttemptDetails: "-",
        retryCollection: "-",
        currency: item.currency,
        amountPayableByPolicyholder: CurrencyUtils._renderCurrency(item.totalAmount, localeService.locale, item.currency),
        transactionReference: item.id,
        policyPaymentSchedule: "-",
        inception: FormatUtils.renderDateTime(item.inceptionDate),
        expiry: FormatUtils.renderDateTime(item.expiryDate),
        policyStatus: i18n.t(item.policyStatus.toLowerCase()),
        cancelPolicy: "-",
    }));
};
