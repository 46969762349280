import React from 'react';
import {Link} from 'react-router-dom';
import './Menu.css';
import {formatDate} from './util';
import Loading from '../widgets/Loading';

export const ClaimList = ({
    claims,
    linkFunc = (it => `/quote/${it.sequenceId}/payment`)
}) => {
    if (!claims) return <Loading inline/>;
    return (
            Object.values(claims)
                    .map(it => (
                            <div key={`${it.id}`} className="menu-item gap btn-primary">
                                <Link to={linkFunc(it)} className="seriously-no-decoration">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3 className="btn-primary">{it.product.name}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h5 className="btn-primary">{it.category}{it.subCategory ? ` / ${it.subCategory}` : ''}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <h5 className="btn-primary">{it.reportingRef}</h5>
                                        </div>
                                        <div className="col-xs-6 text-right">
                                            <h5 className="btn-primary text-right">{formatDate(it.dateOfIncident)}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                    ))
    );
};
