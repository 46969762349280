import React, {useContext} from 'react';
import {SubmissionContext} from './context';

const chunk = (arr, size) => Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
        arr.slice(i * size, i * size + size)
);

export const ImageArray = ({
    children,
    images,
    maxItems,
    onClick,
    rawErrors
}) => {
    const {attachments} = useContext(SubmissionContext);
    return (
            <div>
                {children}
                {chunk(images || [], 4)
                        .map((row, i) =>
                                <div key={i} className="row justify-content-center">
                                    {row.map(name => <ImageThumbnail maxItems={maxItems} key={name} data={attachments[name]} onClick={onClick}
                                                                     name={name} rawErrors={rawErrors}/>)}
                                </div>
                        )}
            </div>
    );
};

const ImageThumbnail = ({
    onClick,
    data,
    name,
    maxItems,
    rawErrors
}) => {
    let content = name;
    if (data && data.type && data.type.indexOf('image/') === 0) {
        content = <img src={URL.createObjectURL(data)} alt={name} style={{
            maxHeight: 150,
            maxWidth: '100%'
        }}/>;
    }
    if (data && data.url) {
        content = <img src={data.url} alt={name} style={{
            maxHeight: 150,
            maxWidth: '100%'
        }}/>;
    }

    const hasError = Array.isArray(rawErrors);

    const renderErrors = () => {
        if (hasError && rawErrors?.length) {
            return [...(new Set(rawErrors))]
            .filter(elem => !!elem)
            .map((error, index) => <li className="text-danger" key={index}>{error}</li>)
        } else if (hasError && !rawErrors?.length) {
            return <li className="text-danger">{'should NOT have fewer than 1 items'}</li>
        }
        return null
    }

    return (
        <>
            <div key={name} className={maxItems === 1 ? 'offset-xs-3 offset-md-4 col-6 col-md-4' : 'col-6 col-md-4'}>
                {onClick && content ?
                        <button className="card card-body text-center" onClick={e => onClick(e, name)} style={{
                            width: '100%',
                            maxWidth: '100%'
                        }}>{content}</button> :
                    <div className="card card-body text-center">{content}</div>}
            </div>
            <ul className="error-detail bs-callout bs-callout-info">
                  {renderErrors()}
            </ul>
        </>
    );
};
