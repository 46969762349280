import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import GlobalContext from "../../../context/global-context";
import { PolicyContext } from "../../models/context";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CurrencyUtils, EIdentityType, ITableData, localeService, PolicyUtils, RecordsNotFound, TableContainer } from "@surelync/common";
import Button from "@material-ui/core/Button";
import { setDataSettlementStatement } from "./helper";
import Hidden from "@material-ui/core/Hidden";
import { headerColumnsI18 } from "./columns.config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

interface IProps {
    isSectionLevel?: boolean;
}

const AmountsDue: React.FC<IProps> = ({ isSectionLevel }) => {
    const classes = useStyles();
    const [{ policy, selectedSection, sequences, settlements }] = useContext(PolicyContext);
    const { currentUser } = useContext(GlobalContext);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);

    const theme = useTheme();
    const { identityType, party } = currentUser.authDetails;

    const partyLoggedInToSurelync = party;

    useEffect(() => {
        const dataSettlementStatement = setDataSettlementStatement(settlements);

        setHeaderColumns(headerColumnsI18);
        setData(dataSettlementStatement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const handlePaymentDetails = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderLine = ({ index, isDivider, title, value }) => {
        return (
            <Fragment key={index}>
                <Grid item xs>
                    <Typography variant="body1">{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="right">
                        {value}
                    </Typography>
                </Grid>
                <Hidden xsUp={!isDivider}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Hidden>
            </Fragment>
        );
    };

    const renderLines = () => {
        const data = isSectionLevel ? selectedSection.breakdown : policy.totals;
        const partyCashMovements = policy.partyCashMovements[partyLoggedInToSurelync];
        const lines = [];

        // Brokerage/Commission net of tax withheld “Net Brokerage”.cumulative + “Broker Platform Fee”.cumulative
        // Tax withheld → “Tax”.cumulative
        // Brokerage/Commission → “Brokerage”.cumulative
        // Total payable →  partyCashMovements.cumulative toDate + future summed across all sequences for party = Broker
        // Paid to date → partyCashMovements.toDate summed across all sequences for party = Broker
        // Amount due → partyCashMovements.future summed across all sequences for party = Broker

        // The value “Net Commission” is a term specific to products where tax is withheld.
        // If there is no tax withheld, then this line item should not be displayed.
        data["Tax"] &&
            (data["Net Brokerage"] || data["Broker Platform Fee"]) &&
            lines.push({
                title: t("commissionNet"),
                value: CurrencyUtils._renderCurrency(
                    PolicyUtils.getValueByKey(data, "Net Brokerage", "cumulative") +
                        PolicyUtils.getValueByKey(data, "Broker Platform Fee", "cumulative"),
                    localeService.locale,
                    policy.currency
                ),
            });

        data["Tax"] &&
            lines.push({
                title: t("taxWithheld"),
                value: CurrencyUtils._renderCurrency(data["Tax"].cumulative, localeService.locale, policy.currency),
            });

        data["Brokerage"] &&
            lines.push({
                title: t("commission"),
                value: CurrencyUtils._renderCurrency(data["Brokerage"].cumulative, localeService.locale, policy.currency),
            });

        if (!isSectionLevel && partyCashMovements && identityType !== EIdentityType.Broker) {
            const totalPayable = sequences.reduce(
                (total, sequence) =>
                    total + sequence.partyCashMovements[partyLoggedInToSurelync].toDate + sequence.partyCashMovements[partyLoggedInToSurelync].future,
                0
            );

            const paidToDate = sequences.reduce((total, sequence) => total + sequence.partyCashMovements[partyLoggedInToSurelync].toDate, 0);

            const amountDue = sequences.reduce((total, sequence) => total + sequence.partyCashMovements[partyLoggedInToSurelync].future, 0);

            lines.push({
                title: t("totalPayable"),
                value: CurrencyUtils._renderCurrency(totalPayable, localeService.locale, policy.currency),
            });

            lines.push({
                title: t("paidToDate"),
                value: CurrencyUtils._renderCurrency(paidToDate, localeService.locale, policy.currency),
            });

            lines.push({
                title: t("amountsDue"),
                value: CurrencyUtils._renderCurrency(amountDue, localeService.locale, policy.currency),
            });
        }

        if (identityType === EIdentityType.Broker) {
            const administrationFee = PolicyUtils.getValueByKey(policy.totals, "MTA Admin Fee", "cumulative");
            lines.push({
                title: t("administrationFee"),
                value: CurrencyUtils._renderCurrency(administrationFee, localeService.locale, policy.currency),
            });
        }

        return lines.map((line, index) => renderLine({ ...line, index, isDivider: index !== lines.length - 1 }));
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {identityType === EIdentityType.Broker ? t("commissionSummary") : t("amountsDue")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container direction="column" classes={{ root: classes.content }}>
                    <Grid item xs>
                        <Grid container spacing={1}>
                            {renderLines()}
                        </Grid>
                    </Grid>

                    <Hidden xsUp={isSectionLevel || identityType === EIdentityType.Broker}>
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <Link href="" color="primary" variant="body2" onClick={handlePaymentDetails}>
                                    {t("paymentDetails")}
                                </Link>
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
            <Dialog fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="amount-due-dialog" open={Boolean(anchorEl)}>
                <DialogTitle>
                    <Typography component="span" variant="h6">
                        {t("realtimeSettlementForPolicy")} {policy?.reportingRef}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TableContainer columns={headerColumns} data={data} fetchData={() => undefined} theme={theme} />
                    {!data?.length ? (
                        <Box my={10}>
                            <RecordsNotFound theme={theme} />
                        </Box>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={1}>
                        <Grid item>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {t("close")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AmountsDue;
