import React, { useContext, useMemo } from "react";
import { ESearchType } from "../../models/search-type.model";
import { useTranslation } from "react-i18next";
import { HomeContext } from "../../models/context";
import { SET_SEARCH_TYPE } from "../../models";
import { SelectCustom } from "@surelync/common";
import { useTheme } from "@material-ui/core";
import GlobalContext from "../../../context/global-context";

interface ISearchProps {
    activeAll?: boolean;
}

export const SearchItemsSelect: React.FC<ISearchProps> = ({ activeAll }) => {
    const { locale } = useContext(GlobalContext);
    const [{ type }, dispatch] = useContext(HomeContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const options = useMemo(
        () => [
            {
                label: t("all"),
                value: ESearchType.All,
            },
            {
                label: t("individual"),
                value: ESearchType.Individual,
            },
            {
                label: t("company"),
                value: ESearchType.Company,
            },
            {
                label: t("quote"),
                value: ESearchType.Quote,
            },
            {
                label: t("policy"),
                value: ESearchType.Policy,
            },
            {
                label: t("claim"),
                value: ESearchType.Claim,
            },
            {
                label: t("referral"),
                value: ESearchType.Referral,
            },
            {
                label: t("decline"),
                value: ESearchType.Decline,
            },
            {
                label: t("cancellation"),
                value: ESearchType.Cancellation,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locale]
    );

    return (
        <SelectCustom
            label={t("searchItem")}
            options={options}
            theme={theme}
            value={type}
            onChange={(event) => dispatch({ type: SET_SEARCH_TYPE, payload: event.target.value })}
        />
    );
};
