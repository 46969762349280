import React, {useContext} from 'react';
import {ProductContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';

// Renders a value using the current product's currency.
export const InProductCurrency = ({
    currency,
    amount,
    product,
    trim = false,
    defaultValue = ''
}) => {
    const {i18n} = useDefaultTranslation();
    const {product: productOrDefault = product} = useContext(ProductContext) || {product};
    return <span>{inProductCurrencyFunc(productOrDefault, amount, i18n.language, currency, product, trim, defaultValue)}</span>;
};

export const inProductCurrencyFunc = (productOrDefault, amount, language, currency, product, trim, defaultValue) => {
    if (!currency) currency = productOrDefault.payment && productOrDefault.payment.currency;
    if (!currency) throw Error('Unable to find product context. You can pass one in as a prop.');
    const format = Intl.NumberFormat(language, {
        style: 'currency',
        currency
    });
    if (amount === undefined || amount === null) return defaultValue;
    let result = format.format(amount);
    if (trim) result = result.replace(/\.00$/, '');
    return result || defaultValue;
};
