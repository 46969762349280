import {SureAppName} from '../base';
import {PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const policyApiClient = new PolicyApiClient(SureAppName);

export const renewalBind = async (policyId, paymentSource) => {
    try {
        const data = await policyApiClient.requestRenewalBind(policyId, paymentSource);
        return data;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, 'Unable to process payment.'));
    }
};

export const renewal = async (sequenceId, previousSequenceId, status, execute) => {
    try {
        const data = await policyApiClient.requestRenewal(sequenceId, previousSequenceId, status, execute);
        const {
            id,
            sequenceId: _sequenceId,
        } = data
        if (!id) throw new Error('Payment failed');
        return _sequenceId;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, 'Unable to process payment.'));
    }
};

export const renewalById = async (sequenceId, previousSequenceId, status, renewalId) => {
    try {
        const data = await policyApiClient.requestRenewalById(sequenceId, previousSequenceId, status, renewalId);
        const {
            id,
            sequenceId: _sequenceId,
        } = data
        if (!id) throw new Error('Payment failed');
        return _sequenceId;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, 'Unable to process payment.'));
    }
};
