import React from 'react';
import blocksureLogoSmall from '../image/blocksure-small.png';
import './Footer.css';
import {TradingGroupContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';

const Footer = () => {
    return (
            <TradingGroupContext.Consumer>{({
                formulator: {
                    footLinks,
                    footer,
                    contact,
                    copyright
                } = {}
            }) => (
                    <div className="footer">
                        <div className="container">
                            {!global.embedded && <h5>
                                {footLinks && footLinks.map(it => <span key={it.url} className="foot-link"><a target="_blank"
                                                                                                              rel="noopener noreferrer"
                                                                                                              href={it.url}>{it.text}</a></span>)}
                            </h5>}
                            {!global.embedded && <h4>
                                {contact && contact.map(it => <span key={it.url} className="foot-link"><a target="_blank" rel="noopener noreferrer"
                                                                                                          href={it.url}><i
                                        className={`fa fa-${it.icon}`}/></a></span>)}
                            </h4>}
                            <PoweredBy/>
                            {!global.embedded && <p>
                                {/* eslint-disable-next-line no-template-curly-in-string */}
                                {copyright && copyright.replace('${year}', new Date().getFullYear())}
                            </p>}
                            {!global.embedded && <p>
                                {footer}
                            </p>}
                        </div>
                    </div>
            )}</TradingGroupContext.Consumer>
    );
};

export const PoweredBy = () => {
    const {t} = useDefaultTranslation();
    return (
            <div className="powered-by text-center">
                <a target="_blank" rel="noopener noreferrer" href="https://blocksure.com">
                    {t('poweredBy')}
                    <img src={blocksureLogoSmall} alt="Blocksure"/>
                </a>
            </div>
    );
};

export default Footer;
