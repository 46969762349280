/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {pad, parseDateString, shouldRender, toDateString} from 'react-jsonschema-form/lib/utils';
import {useDefaultTranslation} from '../../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';

function rangeOptions(start, stop, suffix = '') {
    if (start < 0) start = new Date().getFullYear() + start;
    if (stop < 0) stop = new Date().getFullYear() + stop;
    if (start > stop) return rangeOptions(stop, start)
            .reverse();
    let options = [];
    for (let i = start; i <= stop; i++) {
        options.push({
            value: i,
            label: pad(i, 2) + suffix
        });
    }
    return options;
}

function readyForChange(state) {
    return Object.keys(state)
            .every(key => state[key] !== -1);
}

function DateElement(props) {
    const {t} = useDefaultTranslation();
    const {
        type,
        range,
        value,
        select,
        rootId,
        disabled,
        readonly,
        autofocus,
        registry,
        onBlur,
        suffix
    } = props;
    const id = rootId + '_' + type;
    const {SelectWidget} = registry.widgets;
    return (
            <SelectWidget
                    schema={{type: 'integer'}}
                    id={id}
                    className="form-control"
                    options={{enumOptions: rangeOptions(range[0], range[1], suffix)}}
                    placeholder={t(type, {defaultValue: type})}
                    value={value}
                    disabled={disabled}
                    readonly={readonly}
                    autofocus={autofocus}
                    onChange={value => select(type, value)}
                    onBlur={onBlur}
            />
    );
}

class AltDateWidget extends Component {
    static defaultProps = {
        time: false,
        disabled: false,
        readonly: false,
        autofocus: false,
        options: {
            yearsRange: [1900, new Date().getFullYear() + 2],
        },
    };

    constructor(props) {
        super(props);
        this.state = this.getTimeObject(props.value, props.time);
    }

    getTimeObject(date, hasTime) {
        if (date) {
            const localDate = FormatUtils.parseDate(date);
            
            const timeObj = {
                year: localDate.year,
                day: localDate.day,
                hour: localDate.hour,
                month: localDate.month
            }
            if (hasTime) {
                timeObj.minute = localDate.minute;
                timeObj.second = localDate.second;
            }
            return timeObj;
        }
        return parseDateString(date, hasTime);
    }
    get dateElementProps() {
        const {
            time,
            options
        } = this.props;
        const {
            year,
            month,
            day,
            hour,
            minute,
            second
        } = this.state;
        const defaultData = {
            yearData: {
                type: 'year',
                range: options.yearsRange,
                value: year
            },
            monthData: {
                type: 'month',
                range: [1, 12],
                value: month
            },
            dayData: {
                type: 'day',
                range: [1, 31],
                value: day
            }
        }
        let data = [];
        if (options.order) {
            data = options.order.map((item) => (defaultData[`${item}Data`]))
        } else {
            data = [defaultData.yearData, defaultData.monthData, defaultData.dayData];
        }
        if (time) {
            const noMinutes = options.minute !== false;
            data.push({
                type: 'hour',
                range: [0, 23],
                suffix: noMinutes ? '' : ':00',
                value: hour
            });
            if (noMinutes) data.push({
                type: 'minute',
                range: [0, 59],
                value: minute
            });
            if (noMinutes && options.second !== false) data.push({
                type: 'second',
                range: [0, 59],
                value: second
            });
        }
        return data;
    }

    componentDidMount() {
        const { value, time } = this.props;
        if (value) {
            this.setState({
                dif: this.getTimeObject(value, time).hour - parseDateString(value, time).hour
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(this.getTimeObject(nextProps.value, nextProps.time));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shouldRender(this, nextProps, nextState);
    }

    onChange = (property, value) => {
        const emptyValue = typeof value === 'undefined';
        this.setState(
                {[property]: emptyValue ? undefined : value},
                () => {
                    // Only propagate to parent state if we have a complete date{time}
                    if (readyForChange(this.state)) {
                        const localState = this.state.dif ? {
                            ...this.state,
                            hour: this.state.hour - this.state.dif
                        } : this.state;

                        this.props.onChange(emptyValue ? undefined : toDateString(localState, this.props.time));
                    }
                }
        );
    };

    setNow = event => {
        event.preventDefault();
        const {
            time,
            disabled,
            readonly,
            onChange
        } = this.props;
        if (disabled || readonly) return;
        const nowDateObj = this.getTimeObject(new Date().toJSON(), time);
        this.setState(nowDateObj, () => onChange(toDateString(this.state, time)));
    };

    clear = event => {
        event.preventDefault();
        const {
            time,
            disabled,
            readonly,
            onChange
        } = this.props;
        if (disabled || readonly) return;
        this.setState(this.getTimeObject('', time), () => onChange(undefined));
    };

    render() {
        const {
            id,
            disabled,
            readonly,
            autofocus,
            registry,
            onBlur,
            options
        } = this.props;
        return (
                <ul className="list-inline">
                    {this.dateElementProps.map((elemProps, i) => (
                            <li key={i} className="list-inline-item">
                                <DateElement
                                        rootId={id}
                                        select={this.onChange}
                                        {...elemProps}
                                        disabled={disabled}
                                        readonly={readonly}
                                        registry={registry}
                                        onBlur={onBlur}
                                        autofocus={autofocus && i === 0}
                                />
                            </li>
                    ))}
                    {(options.hideNowButton !== 'undefined'
                            ? !options.hideNowButton
                            : true) && (
                            <li>
                                <a href="#" className="btn btn-info btn-now" onClick={this.setNow}>
                                    Now
                                </a>
                            </li>
                    )}
                    {(options.hideClearButton !== 'undefined'
                            ? !options.hideClearButton
                            : true) && (
                            <li>
                                <a
                                        href="#"
                                        className="btn btn-warning btn-clear"
                                        onClick={this.clear}>
                                    Clear
                                </a>
                            </li>
                    )}
                </ul>
        );
    }
}

if (process.env.NODE_ENV !== 'production') {
    AltDateWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        time: PropTypes.bool,
        options: PropTypes.object,
    };
}

export default AltDateWidget;
