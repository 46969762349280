import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { SearchItems } from "../SearchItems/SearchItemsLinks";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import GlobalContext from "../../../context/global-context";
import { HomeContext } from "../../models/context";
import { RESET_SEARCH_PARAMS, SET_SEARCH_PARAMS, SET_SEARCH_TYPE } from "../../models/action";
import { DateRangeCustom, InputCustom, LoadingBtn } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { DateTime } from "luxon";
import { ESearchType, ISearchAllParams } from "../../models";

type ISearchProps = Record<string, unknown>;

export const SearchAll: React.FC<ISearchProps> = () => {
    const { locale } = useContext(GlobalContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [state, dispatch] = useContext(HomeContext);

    const { fetching, searchParams } = state;

    const [search, setSearch] = useState(searchParams ? searchParams.search : "");
    const [dateRange, setDateRange] = useState(null);
    const handleAnyDate = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setDateRange(null);
    };

    const handleDateRange = (dateRange) => {
        const from = new Date(FormatUtils.toUTC(dateRange[0]));
        const to = new Date(FormatUtils.toUTC(dateRange[1]));

        setDateRange([from, to]);
    };

    const handleLast24Hours = (event: React.SyntheticEvent) => {
        const from = DateTime.utc().minus({ days: 1 }).toJSDate();
        const to = DateTime.utc().toJSDate();

        event.preventDefault();
        setDateRange([from, to]);
    };

    const handleLastMonth = (event: React.SyntheticEvent) => {
        const from = DateTime.utc().minus({ months: 1 }).startOf("month").toJSDate();
        const to = DateTime.utc().minus({ months: 1 }).endOf("month").toJSDate();

        event.preventDefault();
        setDateRange([from, to]);
    };

    const handleClear = () => {
        setSearch("");
        setDateRange(null);
        dispatch({ type: RESET_SEARCH_PARAMS });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        const searchParams: ISearchAllParams = {};
        const [from, to] = dateRange || [];

        if (search) {
            searchParams.search = search.trim();
        }

        if (from) {
            searchParams.from = from.toISOString();
        }

        if (to) {
            searchParams.to = to.toISOString();
        }

        dispatch({ type: SET_SEARCH_PARAMS, payload: searchParams });
    };

    const formatDateRange = () => {
        const [from, to] = dateRange || [];
        if (from && to) {
            return `${DateTime.fromJSDate(from).toUTC().toFormat("D")} - ${DateTime.fromJSDate(to).toUTC().toFormat("D")}`;
        } else {
            return "";
        }
    };

    return (
        <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">{t("advancedSearch")}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton data-testid="close-btn" onClick={() => dispatch({ type: SET_SEARCH_TYPE, payload: ESearchType.Global })}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <InputCustom
                    autoComplete="off"
                    label={t("searchItem")}
                    value={search}
                    fullWidth
                    theme={theme}
                    onChange={(event) => setSearch(event.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </Grid>
            <Grid item xs={12}>
                <SearchItems activeAll={true} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">{t("lastUpdate")}</Typography>
                <Box mt={1}>
                    <Divider />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={handleAnyDate}>
                            {t("anyDate")}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={handleLast24Hours}>
                            {t("lastTwentyFourHours")}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={handleLastMonth}>
                            {t("lastMonth")}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DateRangeCustom
                    displayValue={formatDateRange()}
                    label={t("updatedBetween")}
                    locale={locale}
                    value={dateRange}
                    theme={theme}
                    onChange={handleDateRange}
                />
            </Grid>
            <Grid item xs={12}>
                <Box my={2} />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item>
                        <Button color="secondary" disabled={fetching} onClick={handleClear} data-testid="clear-btn">
                            {t("clear")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingBtn fetching={fetching} label={t("applyFilters")} theme={theme} onClick={handleSubmit} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
