import React, {useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import {useDefaultTranslation} from '../../common/Translation';
import {SubmissionContext} from '../../common/context';
import {ImageArray} from '../../common/ImageArray';

export const ImageUpload = (props) => {
    const {t} = useDefaultTranslation();
    const {updateAttachments} = useContext(SubmissionContext);
    const {
        id,
        value,
        disabled,
        readonly,
        autofocus,
        onBlur,
        onFocus,
        onChange,
        schema,
        options,
        rawErrors,
    } = props;
    const {maxItems} = schema;
    const {fileName} = options || {};

    const onDrop = acceptedFiles => {
        const files = {};
        const names = acceptedFiles.slice(0, maxItems)
                .map(it => {
                    let newFilename = fileName || it.name;
                    files[newFilename] = it;
                    return newFilename;
                });
        const remove = value.slice(0, Math.max(0, value.length - maxItems + 1));
        updateAttachments(files, remove.filter(it => !names.includes(it)));
        onChange([...value.filter(it => !remove.includes(it)), ...names]);
    };

    const remove = (e, name) => {
        if (e) {
            e.preventDefault();
            e.target.blur();
        }
        setImmediate(() => onChange(value.filter(it => it !== name)));
        updateAttachments({}, [name]);
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({onDrop});

    const dropFilesHere = t('dropFilesHere', {defaultValue: 'Drop files here...'});
    const dragOrClickHerToUpload = t('dragOrClickHerToUpload', {defaultValue: 'Drag or click here to upload files...'});
    return (
            <ImageArray images={value} onClick={(e, name) => remove(e, name)} maxItems={maxItems} rawErrors={rawErrors}>
                <div className="card text-white bg-info">
                    <div {...getRootProps()} className="text-center card-header">
                        <input onBlur={onBlur} onFocus={onFocus} id={id} {...getInputProps()} disabled={disabled} readOnly={readonly}
                               autoFocus={autofocus}/>
                        <h4>{isDragActive ? <p>{dropFilesHere}</p> : <p>{dragOrClickHerToUpload}</p>}</h4>
                    </div>
                </div>
            </ImageArray>
    );
};
