import * as React from "react";
import { useContext } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ClaimContext } from "../../models/context";
import { RecordsNotFound } from "@surelync/common";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        scrollable: {
            overflow: "hidden",
            overflowY: "auto",
        },
    })
);

type IProps = Record<string, unknown>;

const LossDescription: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ claim }] = useContext(ClaimContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const description = claim?.lossDescription.trim() || "";

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("lossDescription")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={1} classes={{ root: classes.content }}>
                    <Grid item xs classes={{ root: classes.scrollable }}>
                        {description ? (
                            <Typography variant="body1">{description}</Typography>
                        ) : (
                            <Grid container justify="center" alignItems="center" style={{ height: "90%" }}>
                                <Grid item>
                                    <RecordsNotFound theme={theme} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default LossDescription;
