import { ESearchType } from "./search-type.model";
import { ISearchAllParams } from "./search.model";
import { ESortDirection, IAction, IPagination, ISearchResponse, namespacedLocalStorage } from "@surelync/common";

export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_START = "FETCH_START";
export const RESET_SEARCH_PARAMS = "RESET_SEARCH_PARAMS";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const SET_SORT_TYPE = "SET_SORT_TYPE";
export const SET_MOBILE_SEACH_BUTTON = "SET_MOBILE_SEACH_BUTTON";

export type HomeState = {
    data: ISearchResponse[];
    error: string;
    fetching: boolean;
    mobileSearchBtn: boolean;
    pagination: IPagination;
    searchParams: ISearchAllParams;
    sort: ESortDirection;
    type: ESearchType;
};
const pageSize = namespacedLocalStorage.getItem("search-page-size") || 25;
const sort = namespacedLocalStorage.getItem("search-sort");
const defaultPagination = { limit: +pageSize, offset: 0 };

export const defaultState = {
    data: null,
    error: null,
    fetching: false,
    mobileSearchBtn: false,
    pagination: defaultPagination,
    searchParams: null,
    sort: sort || "desc",
    type: ESearchType.Global,
};

export const reducer = (state: HomeState, action: IAction) => {
    // console.log(action);
    switch (action.type) {
        case SET_SEARCH_TYPE:
            return { ...defaultState, type: action.payload };
        case FETCH_START:
            return { ...state, fetching: true, error: null, data: null };
        case FETCH_DONE:
            return {
                ...state,
                ...action.payload,
                fetching: false,
            };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case SET_SEARCH_PARAMS:
            return { ...state, searchParams: action.payload, pagination: { ...defaultPagination }, data: null };
        case RESET_SEARCH_PARAMS:
            return { ...state, searchParams: null, data: null, pagination: { ...defaultPagination } };
        case SET_PAGINATION:
            return { ...state, pagination: action.payload };
        case SET_SORT_TYPE:
            return { ...state, sort: action.payload };

        case SET_MOBILE_SEACH_BUTTON:
            return { ...state, mobileSearchBtn: action.payload };
        default:
            return state;
    }
};
