export function validate(submissionSchema, submissionUiSchema) {
    if (!submissionSchema.properties)
        fail("Submission schema does not contain a 'properties' property, actual properties: " + Object.keys(submissionSchema.properties));

    const uiPropertyOrder = submissionUiSchema['ui:order'];
    const submissionSchemaProps = Object.keys(submissionSchema.properties);

    let missingFromSubmissionSchema = uiPropertyOrder.filter(val => !submissionSchemaProps.includes(val));
    if (!missingFromSubmissionSchema)
        fail(`Submission schema has values in [uiSchema[ui:order]] that are missing from [schema.properties], actual properties: ${missingFromSubmissionSchema}`);

    let missingFromUiSchema = uiPropertyOrder.filter(val => !submissionSchemaProps.includes(val));
    if (!missingFromUiSchema)
        fail(`Submission schema has values in [schema.properties] that are missing from [uiSchema[ui:order]], actual properties: ${missingFromUiSchema}`);

    ["payment", "summary"].forEach(mandatoryProperty => {
        if (!uiPropertyOrder.includes(mandatoryProperty)) {
            fail(`Submission schema is missing [${mandatoryProperty}] from [uiSchema[ui:order]]`);
        }
    })

    const firstProperty = uiPropertyOrder.filter(it => it !== 'amend')[0];
    const schemaForFirstProperty = submissionSchema.properties[firstProperty];
    if (schemaForFirstProperty == null || schemaForFirstProperty.properties == null)
        fail(`Schema must have at least one property`);
    else if (schemaForFirstProperty.properties.primaryEmailAddress == null)
        fail(`Schema must include the 'primaryEmailAddress' as a field`);

    uiPropertyOrder.forEach(property => {
        const propertySchema = submissionSchema.properties[property];
        if (propertySchema.title == null)
            fail(`Step [${property}] must have a title`);
    })
}

function fail(message) {
    window.console && console.error("Product failed validation: "+ message);
    throw Error(message)
}
