import React from 'react';
import {Markdown} from '../Markdown';

const REQUIRED_FIELD_SYMBOL = '*';

function TitleField({
    id,
    title,
    required,
    ...props
}) {
    return (
            <legend id={id}>
                {typeof title === 'string' ? <Markdown value={title}/> : title}
                {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
            </legend>
    );
}

export default TitleField;
