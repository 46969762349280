import React, {useContext, useEffect} from 'react';
import DocLink from '../common/DocLink';
import {getPrice, SinglePrice} from '../quote/QuoteArray';
import {PolicyContext, ProductContext} from '../common/context';
import {Instalments} from '../shared/Instalments';
import {formatParty} from '../shared/util';
import {SummaryPanel} from '../common/SummaryPanel';
import {useDefaultTranslation} from '../common/Translation';
import {get} from 'lodash';

const PolicySummary = ({children}) => {
    const {t} = useDefaultTranslation();
    const {
        product,
        submissionUiSchema
    } = useContext(ProductContext);
    const {policy} = useContext(PolicyContext);
    const summaryConfig = get(submissionUiSchema, 'summary[\'ui:options\']');
    const {paySummaryItems} = get(submissionUiSchema, 'payment[\'ui:options\']');

    useEffect(() => {
        if (!policy) return;
        window.ga('ec:addProduct', {
            id: `${policy.status}_${policy.sequenceId}`,
            name: `${policy.reportingRef} Payment`,
            price: policy.totalPayable.cumulative,
            quantity: 1
        });
        window.ga('ec:setAction', 'purchase', {
            affiliation: 'Payment',
            id: `${policy.status}_${policy.sequenceId}`,
            revenue: policy.totalPayable.cumulative,
        });
        window.ga('send', 'pageview');
    }, [policy]);

    if (!policy) return '-';

    return (
            <div className="container">
                <div className="row">
                    <div className="section offset-md-3 col-md-6">
                        <div className="text-center">
                            <h1>{t('policySummaryTitle')}</h1>
                        </div>
                        <div className="text-center">
                            <h4>{t('youAreNowInsuredWith', {insurerName: product.partiesAndRoles && formatParty(product.partiesAndRoles.Insurer)})}</h4>
                            <hr/>
                        </div>
                        <div className="text-center">
                            <SinglePrice {...getPrice(policy)} product={product}/>
                        </div>
                        <div>
                            <p className="text-center"><Instalments transactions={policy && policy.transactions}/></p>
                            <hr/>
                        </div>
                        <div className="col-10 offset-1">
                            <SummaryPanel submission={policy.submission} items={paySummaryItems} product={product}/>
                        </div>
                        <div className="text-center">
                            <hr/>
                            {t('policySummaryFooter')}
                        </div>
                        {summaryConfig.links && summaryConfig.links.length > 0 && <SummaryLinks {...{
                            summaryConfig,
                            product,
                            policy
                        }} />}

                        {children && <div>
                            <hr/>
                            {children}</div>}

                    </div>
                </div>
            </div>
    );
};

const SummaryLinks = ({
    summaryConfig,
    product,
    policy
}) => {
    const {t} = useDefaultTranslation();
    return (
            <div className="row">
                <div className="col-md-12 text-center gap">
                    {summaryConfig.links.map(({
                        name,
                        text,
                        url
                    }) => {
                        const textTranslated = t(`summary.links.${name}.tx-text`, {defaultValue: text});
                        return <DocLink key={url} href={url} sequenceId={policy.sequenceId} {...{product}} className="pay-doc-link btn btn-primary"
                                        defaultValue="">{textTranslated}</DocLink>;
                    })}
                </div>
            </div>
    );
};

export default PolicySummary;
