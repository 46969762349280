import * as React from "react";
import { useContext, useEffect, useReducer } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { LoadingBtn, namespacedLocalStorage, signInService } from "@surelync/common";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import GlobalContext from "../../../context/global-context";
import { SET_CLIENT_LOGO } from "../../../context/reducers";
import { initialState, reducer, SAVE_BLOB_DONE, SAVE_BLOB_FAILED, SAVE_BLOB_START, SET_CROPPER, SET_EDIT_MODE, SET_IMAGE } from "./reducer";
import Hidden from "@material-ui/core/Hidden";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const MIN_WIDTH = 600;
const MIN_HEIGHT = 300;

export const ClientLogo: React.FC = () => {
    const { clientLogo, dispatchGlobal } = useContext(GlobalContext);
    const [{ cropper, editable, image, uploading }, dispatch] = useReducer(reducer, initialState);

    const { t } = useTranslation();
    const theme = useTheme();

    const blobApiClient = new BlobApiClient(namespacedLocalStorage);

    useEffect(() => {
        // Logo can be uploaded by SureLync user having permission for BRAND_EDIT
        const enable = signInService.hasPermission(["BRAND_EDIT"]);
        dispatch({ type: SET_EDIT_MODE, payload: enable });
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const file = e.target.files[0];
        const reader = new FileReader();
        const createImg = () => {
            const image = new Image();
            image.src = reader.result as string;

            image.onload = () => {
                // access image size here
                // console.log(image.width, image.height);

                // need crop
                if (image.width > MIN_WIDTH || image.height > MIN_HEIGHT) {
                    dispatch({ type: SET_IMAGE, payload: reader.result });
                } else {
                    saveBlob(image.src);
                }
            };
        };
        reader.onload = createImg;
        reader.readAsDataURL(file);
    };

    const getCropData = async () => {
        const data = cropper.getCroppedCanvas({
            minWidth: 400,
            minHeight: 300,
            maxWidth: 800,
            maxHeight: 600,
            // imageSmoothingEnabled: false,
            // imageSmoothingQuality: 'high'
        });

        const imgBase64 = data ? data.toDataURL() : "empty for tests";
        saveBlob(imgBase64);
    };

    const saveBlob = async (imgBase64: string) => {
        try {
            dispatch({ type: SAVE_BLOB_START });
            const response = await blobApiClient.setBlob(imgBase64);
            await blobApiClient.assignTagGlobal(response.hash, "client-logo", "*", "*");
            dispatch({ type: SAVE_BLOB_DONE, payload: imgBase64 });
            dispatchGlobal({ type: SET_CLIENT_LOGO, payload: imgBase64 });
        } catch (error) {
            dispatch({ type: SAVE_BLOB_FAILED });
        }
    };

    if (!editable) {
        return null;
    }

    return (
        <Grid container spacing={1} data-testid="client-logo">
            <Grid item xs={12}>
                <Typography variant="body2">
                    {t("logo")} ({t("displayedToAllUsers")}) &mdash; 600x300
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {image ? (
                    <Cropper
                        style={{ maxHeight: 400, maxWidth: 800 }}
                        aspectRatio={4 / 3}
                        initialAspectRatio={4 / 3}
                        src={image}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={70}
                        minCropBoxWidth={100}
                        background={false}
                        responsive={true}
                        autoCropArea={0}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => dispatch({ type: SET_CROPPER, payload: instance })}
                    />
                ) : clientLogo ? (
                    <img src={clientLogo} alt="cropped" style={{ maxWidth: 200, maxHeight: 150 }} />
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {image ? (
                        <>
                            <Grid item>
                                <Button color="secondary" variant="contained" onClick={() => dispatch({ type: SET_IMAGE, payload: null })}>
                                    {t("cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingBtn fetching={uploading} label={t("save")} theme={theme} onClick={getCropData} />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Hidden xsUp={!clientLogo}>
                                <Grid item>
                                    <Button variant="contained" color="secondary" component="label" disabled={uploading} onClick={() => saveBlob("")}>
                                        {t("remove")}
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Grid item>
                                <Button component="label" disabled={uploading} endIcon={<CloudUploadIcon />} variant="contained">
                                    {clientLogo ? t("replaceFile") : t("upload")}
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        accept="image/png,image/jpeg"
                                        data-testid="input-file"
                                        onChange={handleChange}
                                    />
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
