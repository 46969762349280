import {CurrentStepContext, PendingContext} from '../common/context';
import {Button} from '@blocksure/blocksure-core/dist/src/components/widgets';
import React from 'react';
import {useDefaultTranslation} from '../common/Translation';

function defaultNextButtonTexts(t, currentStep, nextStep) {
    if (currentStep === 'payment') return t('pay');
    if (nextStep === 'quote') return t('getQuotes');
    return t('next');
}

function defaultBackButtonTexts(t, currentStep) {
    if (currentStep === 'payment') return t('amend');
    return t('back');
}

export const ButtonPanel = ({
    showNext = true,
    showBack = true
}) => {
    const {t} = useDefaultTranslation();
    return (
            <PendingContext.Consumer>{({pending}) => (
                    <CurrentStepContext.Consumer>{({
                        previousStep,
                        stepUiSchema,
                        currentStep,
                        nextStep,
                        nextStepName
                    }) => {

                        const backLabel = stepUiSchema['ui:backLabel'] || defaultBackButtonTexts(t, currentStep);
                        const nextLabel = stepUiSchema['ui:nextLabel'] || defaultNextButtonTexts(t, currentStep, nextStepName);

                        return (
                                <div>
                                    {showNext && nextStep &&
                                    <Button pending={pending} className="btn btn-primary float-right" type="submit">{nextLabel}</Button>}
                                    {showBack && previousStep && <Button disabled={pending} className="btn btn-secondary float-right" type="button"
                                                                         onClick={previousStep}>{backLabel}</Button>}
                                    <div className="clearfix"/>
                                </div>
                        );
                    }}</CurrentStepContext.Consumer>
            )}</PendingContext.Consumer>
    );
};
