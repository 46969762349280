import * as React from "react";
import { useContext, useReducer } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/global-context";
import NoteApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/NoteApiClient";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { IAction, INewNote, INote, LoadingBtn } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

export const ADD_NOTE_START = "ADD_NOTE_START";
export const ADD_NOTE_DONE = "ADD_NOTE_DONE";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";
export const CANCEL = "CANCEL";
export const NOTE_CHANGED = "NOTE_CHANGED";

const MAX_LENGTH = 1024;

export const initialState = {
    error: "",
    newDescription: null,
    uploading: false,
};

export function reducer(state: typeof initialState, action: IAction) {
    switch (action.type) {
        case ADD_NOTE_START:
            return { ...state, uploading: true };
        case ADD_NOTE_DONE:
        case CANCEL:
            return { ...initialState };
        case ADD_NOTE_ERROR:
            return { ...state, uploading: false, error: action.payload };
        case NOTE_CHANGED:
            return { ...state, error: false, newDescription: action.payload };
        default:
            return state;
    }
}

interface IProps {
    note?: INote;
    open: boolean;
    ownerId: string;
    placeholder: string;
    onClose: (newNote?: INote) => void;
}

const DialogEditNote: React.FC<IProps> = ({ note, open, ownerId, placeholder, onClose }) => {
    const { currentUser, namespacedLocalStorage } = useContext(GlobalContext);
    const [{ error, newDescription, uploading }, dispatch] = useReducer(reducer, { ...initialState });
    const theme = useTheme();
    const { t } = useTranslation();

    const noteApiClient = new NoteApiClient(namespacedLocalStorage);

    const handleClose = () => {
        onClose();
        dispatch({ type: CANCEL });
    };

    const handleSubmit = async () => {
        if (!newDescription || !newDescription.trim() || (note && newDescription === note.description)) {
            return;
        }

        const params: INewNote = {
            email: currentUser.authDetails.username,
            ownerId,
            description: newDescription,
        };

        if (note) {
            (params as INote).id = note.id;
        }

        try {
            dispatch({ type: ADD_NOTE_START });
            const response = await noteApiClient.addNote(params);
            dispatch({ type: ADD_NOTE_DONE });
            onClose(response.data);
        } catch (error) {
            dispatch({ type: ADD_NOTE_ERROR, payload: generateErrorMessage(error) });
        }
    };

    const renderLimit = () => {
        const description = newDescription || (note ? note.description : "");
        return <Typography variant="caption">{`${description ? description.length : 0}/${MAX_LENGTH}`}</Typography>;
    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="add-note-dialog" open={open}>
            <DialogContent>
                <TextField
                    autoFocus={!note} // focus is not at the end of the text. looks not good
                    error={!!error}
                    defaultValue={note ? note.description : ""}
                    disabled={uploading}
                    fullWidth
                    id="note-text-area"
                    data-testid="note-text-area"
                    inputProps={{ maxLength: MAX_LENGTH }}
                    helperText={error || renderLimit()}
                    multiline
                    placeholder={placeholder}
                    minRows={10}
                    value={newDescription || undefined}
                    variant="outlined"
                    onChange={(event) => dispatch({ type: NOTE_CHANGED, payload: event.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item>
                        <Button color="secondary" disabled={uploading} variant="contained" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingBtn
                            fetching={uploading}
                            color="primary"
                            label={`${t("save")}`}
                            theme={theme}
                            variant="contained"
                            onClick={handleSubmit}
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DialogEditNote;
