import { IAction } from "@surelync/common";

export const ADD_NOTE_DONE = "ADD_NOTE_DONE";
export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const OPEN_NEW_NOTE_DIALOG = "OPEN_NEW_NOTE_DIALOG";

export const initialState = {
    error: "",
    fetching: false,
    newNote: null,
    notes: null,
    open: false,
};

export function reducer(state: typeof initialState, action: IAction) {
    switch (action.type) {
        case ADD_NOTE_DONE:
            return { ...state, open: false, newNote: action.payload };
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false, notes: action.payload };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case OPEN_NEW_NOTE_DIALOG:
            return { ...state, open: action.payload };
        default:
            return state;
    }
}
