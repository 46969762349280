import { i18n, IClaim, IPolicy, PolicyUtils } from "@surelync/common";

export const setDataFees = (claims: IClaim[], policies: IPolicy[]) => {
    const result = [];

    policies.forEach((item) => {
        const mgaCommission = PolicyUtils.getValueByKey(item.totals, "MGA Commission", "cumulative");
        const totalDeductions = PolicyUtils._getTotalDeduction(item);

        if (item.totals["MTA Admin Fee"]) {
            result.push({
                typeOfFee: i18n.t("mtaAdminFee"),
                policy: item.reportingRef,
                claim: "-",
                dateFeeCharged: item.issueDate,
                adminFeeAmount: PolicyUtils.getValueByKey(item.totals, "MTA Admin Fee", "delta"),
                totalDeductions: Number(totalDeductions),
                grossBrokerage: PolicyUtils.getValueByKey(item.totals, "Brokerage", "cumulative"),
                mgaCommission,
                currency: item.currency,
                policyId: item.id,
            });
        }
    });

    claims.forEach((item: any) => {
        const policyClaim = policies.find((policyItem) => policyItem.id === item.policyId);

        if (!policyClaim) {
            return;
        }

        const mgaCommission = PolicyUtils.getValueByKey(policyClaim.totals, "MGA Commission", "cumulative");
        const totalDeductions = PolicyUtils._getTotalDeduction(policyClaim);

        result.push({
            typeOfFee: i18n.t("claimAdminFee"),
            policy: policyClaim.reportingRef,
            claim: item.reportingRef,
            dateFeeCharged: item.creationDate,
            adminFeeAmount: item.claimFee,
            totalDeductions: Number(totalDeductions),
            grossBrokerage: PolicyUtils.getValueByKey(policyClaim.totals, "Brokerage", "cumulative"),
            mgaCommission,
            currency: item.currency,
            policyId: item.policyId,
            claimId: item.id,
        });
    });

    return result;
};
