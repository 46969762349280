import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { IRole, isNotHiddenPermissions, IUser, IUserDetails, TranslateUtils } from "@surelync/common";

const formatUserName = (user: IUserDetails) => {
    return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`;
};

export const setDataUsers = (roles: IRole[], users: IUser[], emailUserEnabled: boolean) => {
    const result = [];

    orderBy(users, ["firstName", "lastName"])
        .filter((user) => {
            if (emailUserEnabled) {
                return user.email && user.email !== "super";
            }
            return user.firstName && user.lastName && user.email !== "super";
        })
        .forEach((user) => {
            let userRoles = roles.filter((role) => user.roles.find((userRole) => userRole.id === role.id));

            if (userRoles.length === 0) {
                result.push({
                    canBeHidden: false,
                    name: formatUserName(user),
                    id: user.id,
                    role: "-",
                    description: "-",
                    permissions: null,
                    removeButton: true,
                });
            }

            userRoles = userRoles.map((role) => {
                const translate = TranslateUtils.translateRole(role.name);
                return {
                    ...role,
                    role: translate.name,
                    description: translate.description || role.description,
                };
            });

            orderBy(userRoles, "role").forEach((role, index) => {
                const permissions = role.permissions.filter(isNotHiddenPermissions);

                result.push({
                    canBeHidden: index !== 0,
                    email: user.email,
                    name: formatUserName(user),
                    id: user.id,
                    role: role.role,
                    description: role.description,
                    permissions: uniqBy(permissions, (element) => element.id),
                    removeButton: index === 0 ? true : false,
                });
            });
        });

    return result;
};
