import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './YesNoField.css';
import {useDefaultTranslation} from '../../common/Translation';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';

const YesNoField = ({
    formData,
    required,
    disabled,
    readonly,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    id,
    schema,
    options = {},
    registry
}) => {
    const {t} = useDefaultTranslation();
    const {
        title,
        description
    } = schema;
    const {enumDisabled = []} = options;
    const {DescriptionField} = (registry && registry.fields) || {};
    const [isValid, setIsValid] = useState(required);
    const name = Math.random()
            .toString();
    const enumOptions = options.enumOptions ? options.enumOptions : setOptions({
        schema,
        t
    });
    const splitTitles = setTitleText({
        title,
        required
    });
    return (
            <div className="yes-no">
                {description && <DescriptionField description={description}/>}
                <label className="col-form-label">
                    <div className="field-radio-group btn-group float-right" data-toggle="buttons" id={id}>
                        {enumOptions.map((option, i) => {
                            const checked = option.value === formData;
                            const disabledCls = disabled || readonly ? 'disabled' : '';
                            return (
                                    <label key={i} className={`btn ${checked ? 'btn-primary' : 'btn-secondary'} radio-inline ${disabledCls}`}>
                                        <input
                                                type="radio"
                                                checked={checked}
                                                name={name}
                                                required={required}
                                                value={option.value}
                                                disabled={disabled || readonly || enumDisabled.indexOf(option.value) > -1}
                                                autoFocus={autofocus && i === 0}
                                                onChange={event => {
                                                    resetRequiredMessage(event);
                                                    onChange(option.value);
                                                    setIsValid(checkValidity(required, event.target.value))
                                                }}
                                                onBlur={onBlur && (event => {
                                                    resetRequiredMessage(event);
                                                    onBlur(id, event.target.value);
                                                })}
                                                onFocus={onFocus && (event => onFocus(id, event.target.value))}
                                                onInvalid={!isValid ? (event => changeRequiredMessage(event, t)) : null}
                                        />
                                        <span>{option.label}</span>
                                    </label>
                            );
                        })}
                    </div>
                    {splitTitles && splitTitles.map((it, i) => <div key={i}>{it}</div>)}</label>
            </div>
    );
};

YesNoField.defaultProps = {
    autofocus: false,
};

function checkValidity(required, value) {
    if(!required) {
        return true;
    }
    return value != null || value !== undefined;
}

if (process.env.NODE_ENV !== 'production') {
    YesNoField.propTypes = {
        schema: PropTypes.object.isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
    };
}

const setOptions = ({
    schema,
    t
}) => {
    let arr = [];
    const labels = schema.enumNames ? Object.values(schema.enumNames) : [];
    const values = schema.enum ? Object.values(schema.enum) : [];
    if (schema && (labels.length !== 0 || values.length !== 0) && (labels.length === values.length)) {
        for (let i = 0; i < values.length; i++) {
            const title = labels[i];
            arr.push({
                label: t(title),
                value: values[i]
            });
        }
        return arr;
    } else {
        return [{
            label: t('no'),
            value: false
        }, {
            label: t('yes'),
            value: true
        }];
    }
};

const setTitleText = ({
    title,
    required
}) => {
    let splitTitles = title ? title.split('\n') : [];
    const lastItem = splitTitles[splitTitles.length - 1];
    if (required) splitTitles[splitTitles.length - 1] = lastItem + '*';
    return splitTitles;
};

export default YesNoField;
