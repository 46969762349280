import React, {useContext, useEffect, useState} from 'react';
import {ClaimContext, PolicyContext} from '../common/context';
import {ClaimApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';

const claimApiClient = new ClaimApiClient(SureAppName);

export const ClaimProvider = ({
    children,
    claimId
}) => {
    const {policy} = useContext(PolicyContext) || {};
    const [claim, setClaim] = useState(undefined);
    const incidentDate = sessionStorage.getItem(`com.blocksure.incidentDate`);

    const updateClaim = async claimId => {
        await claimApiClient.getClaim(claimId)
                .then(loaded => {
                    sessionStorage.setItem(`com.blocksure.claimId`, claimId);
                    loaded.policy = policy;
                    setClaim(loaded);
                });
    };

    useEffect(() => {
        console.info(claimId);
        if (claimId) {
            updateClaim(claimId);
        } else {
            setClaim(null);
        }
        // eslint-disable-next-line
    }, [policy, claimId]);

    return (
            <ClaimContext.Provider value={{
                claim,
                incidentDate,
                updateClaim
            }}>
                <div>{children}</div>
            </ClaimContext.Provider>
    );
};
