import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@material-ui/core/";
import { PolicyApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { Preloader, MessageBox } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import GlobalContext from "../../context/global-context";

interface IProps {
    onTransactionPayment: () => void;
}

const RetryPaymentFlyout: React.FC<IProps> = ({ onTransactionPayment }) => {
    const { t } = useTranslation();
    const { openRetryPaymentFlyout, namespacedLocalStorage, setOpenRetryPaymentFlyoutOpen } = useContext(GlobalContext);

    const [failed, setFailed] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const theme = useTheme();

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const handleClose = () => {
        if (!isMountRef.current) {
            return;
        }

        setOpenRetryPaymentFlyoutOpen(false);
        setError(null);
        setFailed(false);
    };

    const handleTransactionPayment = async (transactionId) => {
        if (!isMountRef.current) {
            return;
        }

        setFailed(true);

        let transaction;
        let policy;

        try {
            transaction = await policyApiClient.getTransaction(transactionId);
            policy = await policyApiClient.getPolicyBySequenceId(transaction.sequenceId);
            await policyApiClient.requestPayment(policy.paymentSource, transactionId);
            const updatedTransaction = await policyApiClient.getTransaction(transactionId);
            const totalAttempts = updatedTransaction.attempts.length;
            const attempted = updatedTransaction.attempts[totalAttempts - 1];

            if (!isMountRef.current) {
                return;
            }

            if (attempted.status.toUpperCase() === "SUCCESS") {
                onTransactionPayment();
            } else {
                setFailed(false);
                setError(attempted.message);
            }
        } catch (e) {
            if (!isMountRef.current) {
                return;
            }

            setFailed(false);
            setError(generateErrorMessage(e));
        }
    };

    return (
        <Dialog
            open={openRetryPaymentFlyout.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("retryTxnPay")}</DialogTitle>
            <DialogContent dividers>
                <Box m={3}>
                    {error && <MessageBox message={error} theme={theme} variant="error" />}
                    {failed ? (
                        <Preloader theme={theme} />
                    ) : (
                        <Typography variant="body1" align="left">
                            {t("rUSureRetryTxnPay", { transactionId: openRetryPaymentFlyout.transactionId })}
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    {t("cancel")}
                </Button>
                {!failed && (
                    <Button
                        data-testid="btn-transaction"
                        onClick={() => handleTransactionPayment(openRetryPaymentFlyout.transactionId)}
                        color="primary"
                    >
                        {t("retry")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default RetryPaymentFlyout;
