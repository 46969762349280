import { JSONSchema7 } from "json-schema";
import { IAction, IAttachment, IClaim, IPolicy, IProduct, IPolicyholder, ISection, ISettlementStatement } from "@surelync/common";

export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const OPEN_CANCEL_POLICY = "OPEN_CANCEL_POLICY";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export interface PolicyState {
    activeTab: number;
    claims: IClaim[];
    documents: IAttachment[];
    error: string;
    fetching: boolean;
    openCancelPolicy: boolean;
    policy: IPolicy;
    policyholder: IPolicyholder;
    product: IProduct;
    selectedSection: ISection;
    sequences: IPolicy[];
    settlements: ISettlementStatement[];
    universalPolicyholderSchema: JSONSchema7;
}

export const initialState = {
    activeTab: 0,
    claims: null,
    error: "",
    fetching: false,
    openCancelPolicy: false,
    policy: null,
    product: null,
    selectedSection: null,
    sequences: null,
    settlements: null,
    universalPolicyholderSchema: null,
};

export function reducer(state: typeof initialState, action: IAction) {
    switch (action.type) {
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false, ...action.payload };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case OPEN_CANCEL_POLICY:
            return { ...state, openCancelPolicy: action.payload };
        case SET_ACTIVE_TAB:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}
