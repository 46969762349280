import React from 'react';
import {Link} from 'react-router-dom';
import {formatDate} from './util';
import './Menu.css';

export const RenewalList = ({
    renewals,
    linkFunc = (it => `/renewal/${it.sequenceId}`)
}) => {
    return (
            renewals.map(it => (
                    <div key={`${it.sequenceId}`} className="menu-item gap btn-primary">
                        <Link to={linkFunc(it)} className="seriously-no-decoration">
                            <div className="row p-3">
                                <div className="col-xs-6">
                                    <h5 className="btn-primary">{it.reportingRef}</h5>
                                </div>
                                <div className="col-xs-6 text-right">
                                    <h5 className="btn-primary text-right">{formatDate(it.inceptionDate)} - {formatDate(it.expiryDate)}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
            ))
    );
};
