import React from "react";
import { CurrencyUtils, ETransactionStatus, i18n, localeService } from "@surelync/common";
import Typography from "@material-ui/core/Typography";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

type ColorFn = (value: number, status: ETransactionStatus) => "error" | "textPrimary";
const getColor: ColorFn = (value, status) => (value < 0 || status === ETransactionStatus.Failed ? "error" : "textPrimary");

export const headerColumnsI18 = [
    {
        Header: () => i18n.t("policyId"),
        accessor: "reportingRef",
        Cell: (row: CellInfo) => (
            <Typography color="textPrimary" variant="caption">
                {row.value}
            </Typography>
        ),
    },
    {
        Header: () => i18n.t("transactionReference"),
        accessor: "transactionId",
        Cell: (row: CellInfo) => (
            <Typography color="textPrimary" variant="caption">
                {row.value}
            </Typography>
        ),
    },
    {
        Header: () => i18n.t("dateCollected"),
        accessor: "paid",
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDateTime(row.value) : null),
    },
    {
        Header: () => i18n.t("dateAvailable"),
        accessor: "available",
        Cell: (row: CellInfo) => (row.value ? FormatUtils.renderDateTime(row.value) : null),
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => (row.value ? i18n.t(row.value.toLowerCase()) : ""),
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        Cell: (row: CellInfo) => row.value,
    },
    {
        Header: () => i18n.t("amountCollected"),
        accessor: "grossAmount",
        Cell: (row: CellInfo) => (
            <Typography color={getColor(row.value, row.row.original.status)}>
                {CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}
            </Typography>
        ),
    },
    {
        Header: () => i18n.t("paymentFee"),
        accessor: "paymentFee",
        Cell: (row: CellInfo) => (
            <Typography color={getColor(row.value, row.row.original.status)}>
                {CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}
            </Typography>
        ),
    },
    {
        Header: () => i18n.t("netAmount"),
        accessor: "netAmount",
        Cell: (row: CellInfo) => (
            <Typography color={getColor(row.value, row.row.original.status)}>
                {CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)}
            </Typography>
        ),
    },
];
