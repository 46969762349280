import React from "react";
import { Tab, Tabs as MuiTabs } from "@material-ui/core";

interface IProps {
    names: string[];
    value: number;
    onChange: (newValue: number) => void;
}

const Tabs: React.FC<IProps> = ({ names, value, onChange }) => {
    const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        onChange(newValue);
    };

    return (
        <MuiTabs aria-label="tabs" indicatorColor="primary" scrollButtons="auto" value={value} variant="scrollable" onChange={handleTabChange}>
            {names && names.map((name) => <Tab label={name} key={name} />)}
        </MuiTabs>
    );
};

export default Tabs;
