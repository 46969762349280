import * as React from "react";
import { useContext, useEffect, useMemo, useReducer } from "react";
import {
    BackButton,
    BannerClientLogo,
    DialogEditNote,
    DocumentsOverview,
    OpenEnquiries,
    PageContainer,
    PolicyOverview,
} from "../components";

import {
    FETCH_DONE,
    FETCH_ERROR,
    FETCH_START,
    initialState,
    OPEN_ACTIONS,
    OPEN_ADD_ENQUIRY_DIALOG,
    reducer,
    SET_MESSAGE,
    SET_NEW_ENQUIRY_ID,
} from "./models/reducer";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ClaimApiClient, PolicyApiClient, PolicyholderApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import GlobalContext from "../context/global-context";
import { ClaimContext } from "./models/context";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IClaim, IPolicy, IPolicyholder, IProduct, ITransaction, MessageBox, PolicyholderUtils, PolicyUtils } from "@surelync/common";
import ClaimOverview from "./components/ClaimOverview/ClaimOverview";
import LossDescription from "./components/LossDescription/LossDescription";
import SumsInsured from "./components/SumsInsured/SumsInsured";
import { ETab } from "../@ContactOverview/models/tabs.model";
import { INote } from "../components/OpenEnquiries/note.models";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            display: "flex",
            flexDirection: "column",
        },
    })
);

const ClaimDetailsPage: React.FC = () => {
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const {
        anchorEl,
        claim,
        documents,
        error,
        fetching,
        message,
        newEnquiryId,
        openEnquiry,
        policy,
        policyholder,
        product,
        sequences,
        universalPolicyholderSchema,
    } = state;

    // fetch data request
    useEffect(() => {
        let isMount = true;
        const fetchData = async () => {
            const claimApiClient = new ClaimApiClient(namespacedLocalStorage);
            const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
            const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
            const productApiClient = new ProductApiClient(namespacedLocalStorage);

            try {
                dispatch({ type: FETCH_START });
                const load = [
                    "load.submission",
                ];
                const appName = namespacedLocalStorage.getItem("app-name");
                const claim: IClaim = await claimApiClient.getClaim(id);
                const sequences = await policyApiClient.getSequences(claim.policyId, load);
                const policy: IPolicy = sequences.items.find((s) => s.sequenceId === claim.sequenceId);
                const policyholder: IPolicyholder = await policyholderApiClient.getPolicyholder(claim.claimant);
                const countryCode = policyholder?.shared?.schemaCountry;
                const universalPolicyholderSchema = await policyholderApiClient.getSchema(countryCode, policyholder?.type);
                const product: IProduct = await productApiClient.getProduct(claim.productId);
                const lang = policyholder.shared.locale || policy.meta.language || "en";

                const templateNames = ["PolicyTemplate", "StatementOfFactTemplate"];

                let documents = [];

                // display only one Claim Template
                const claimTemplate = product.dynamicDocumentTemplates.find((item) => item.name === "ClaimNewTemplate" || item.name === "ClaimTemplate");

                if (claimTemplate) {
                    documents.push({
                        ...claimTemplate,
                        modifiedDate: claim.creationDate,
                        url: `${claimApiClient.basepath}${claim.id}?format=PDF&app-name=${appName}&lng=${lang}`,
                    });
                }

                documents = documents.concat(
                    claim.attachments.map((c) => ({
                        ...c,
                        modifiedDate: claim.creationDate,
                        url: `${claimApiClient.basepath}${claim.id}/${c.location}?app-name=${appName}&lng=${lang}`,
                    }))
                );
                documents = documents.concat(
                    product.dynamicDocumentTemplates
                        .filter((item) => templateNames.includes(item.name))
                        .map((item) => ({
                            ...item,
                            name: PolicyUtils.getDocumentName(item.name, policy, product) || item.name,
                            modifiedDate: policy.effectiveFromDate,
                            url: `${policyApiClient.basepath}${policy.sequenceId}?format=PDF&docType=${item.name}&app-name=${appName}&lng=${lang}`,
                        }))
                );
                documents = documents.concat(
                    product.staticAttachments
                        .filter((item) => item.contentType === "application/pdf")
                        .map((item) => ({ ...item, url: null, product: true }))
                );

                // cleanup func
                if (!isMount) return;

                dispatch({
                    type: FETCH_DONE,
                    payload: {
                        claim,
                        documents,
                        policy,
                        policyholder,
                        product,
                        sequences: sequences.items,
                        universalPolicyholderSchema,
                    },
                });
            } catch (error) {
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Set Important Message
    useEffect(() => {
        if (!policy) {
            return;
        }

        const isOverdueDate = (date: string) => FormatUtils.compareDates(FormatUtils.parseDate(date), FormatUtils.today(), "hours") < 0;
        const overdueStatuses = ["Pending", "Failed"];

        const overduePayments = policy.transactions.filter(
            (transaction: ITransaction) => isOverdueDate(transaction.due) && overdueStatuses.includes(transaction.status)
        );

        dispatch({
            type: SET_MESSAGE,
            payload: overduePayments.length ? t("outstandingPremium") : t("allPremium"),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const handleActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch({ type: OPEN_ACTIONS, payload: event.currentTarget });
    };

    const handleNewEnquiry = () => {
        handleClose();
        dispatch({ type: OPEN_ADD_ENQUIRY_DIALOG, payload: true });
    };

    const handleClose = () => {
        dispatch({ type: OPEN_ACTIONS, payload: null });
    };

    const handleEnquireClose = (newEnquiry: INote) => {
        dispatch({ type: OPEN_ADD_ENQUIRY_DIALOG, payload: false });
        if (newEnquiry) {
            dispatch({ type: SET_NEW_ENQUIRY_ID, payload: newEnquiry.id });
        }
    };

    const handleEnquiriesViewAll = () => {
        history.push({
            pathname: `/contacts/${policyholder.id}`,
            state: { activeTab: ETab.Enquiries },
        });
    };

    const renderTitle = policy ? `${t("claim")} - ${t("id")} ${id}` : t("claim");

    const fullName = useMemo(() => (policyholder ? PolicyholderUtils._renderFullName(policyholder) : ""), [policyholder]);
    const lastUpdated = useMemo(() => (claim ? FormatUtils.renderDate(policy.ts) : ""), [claim, policy?.ts]);

    return (
        <ClaimContext.Provider value={[state, dispatch]}>
            <PageContainer isBreadcrumb={true} fetching={fetching} title={renderTitle}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} sm={8} md={5}>
                                <Box mb={1}>
                                    <BackButton title={fullName} />
                                </Box>
                                <Typography variant="h5">
                                    {t("claim")} - {claim?.reportingRef}
                                </Typography>
                                <Typography color="textPrimary" variant="body2">
                                    {`${t("id")} - ${id}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={4} md={2}>
                                <Box textAlign={{ xs: "right", md: "center" }}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5}>
                                <Hidden xsUp={!claim}>
                                    <Box pt={4} textAlign="right">
                                        <Typography variant="body2">
                                            {t("lastUpdated")}: {lastUpdated}
                                        </Typography>
                                    </Box>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {error ? (
                            <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch({ type: FETCH_ERROR, payload: null })} />
                        ) : message ? (
                            <MessageBox message={message} theme={theme} variant="important" onClose={() => dispatch({ type: SET_MESSAGE, payload: null })} />
                        ) : null}
                    </Grid>

                    <Hidden xsUp={!claim}>
                        <Grid item xs={12} md={6}>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Button aria-controls="actions-menu" aria-haspopup="true" variant="text" onClick={handleActions}>
                                        {t("actions")} <ArrowDropDownIcon />
                                    </Button>
                                    <Menu
                                        id="actions-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleNewEnquiry}>{t("newClaimsEnquiry")}</MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Hidden xsUp={!claim}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="stretch" wrap="wrap">
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <ClaimOverview />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <LossDescription />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <SumsInsured />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <PolicyOverview
                                        addressFieldOrder={universalPolicyholderSchema?.properties?.address["ui:order"]}
                                        policy={policy}
                                        policyholder={policyholder}
                                        product={product}
                                        sequences={sequences}
                                        title={t("productOverview")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <DocumentsOverview
                                        documents={documents}
                                        policy={policy}
                                        policyholder={policyholder}
                                        title={t("policyDocuments")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className={classes.card}>
                                    <OpenEnquiries newEnquiryId={newEnquiryId} policyholder={policyholder} onViewAll={handleEnquiriesViewAll} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
                <DialogEditNote
                    open={openEnquiry}
                    ownerId={`${policyholder?.id}-enquiries`}
                    placeholder={`${t("newEnquiry")}...`}
                    onClose={handleEnquireClose}
                />
            </PageContainer>
        </ClaimContext.Provider>
    );
};

export default ClaimDetailsPage;
