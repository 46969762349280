import React from 'react';
import './Loading.css';

const Loading = ({
    message,
    inline = false
}) => (
        <div>
            <div className={`loader ${inline ? 'inline' : ''}`}>
                <div className="loader02"/>
                <h4 className="loading-message">{message}</h4>
            </div>
        </div>
);

export default Loading;
