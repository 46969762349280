import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Upload from '../image-upload/upload';
import ImportCsv from '../@ImportCsv/@ImportCsv';

const ImportImage = ({currentUser, locale, schema}) => {
    const theme = useTheme();
    const { t } = useTranslation();


    return (
        <Grid item xs={12}>
            <Grid container alignItems="flex-end">
                <Grid item xs={6} sm={8} md={5}>
                    <Typography variant="h5">{t('importFromCSVFile')}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box textAlign={{ xs: 'right', md: 'center' }}>
                        {/* <BannerClientLogo /> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={5} />
            </Grid>
            <Typography variant="body1">
                The product requires file attachments on each policy. Please drag onto the logo below all such files;
                these filenames must match those that you provided in your CSV file.
            </Typography>
            <div className="Card">
                <Upload onFilesAdded={() => {}} currentUser={currentUser} nextStep={<ImportCsv currentUser={currentUser} schema={schema} />} />
            </div>
        </Grid>
    );
};

export default ImportImage;
