import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Add from "@material-ui/icons/Add";
import { useTheme } from "@material-ui/core/styles";
import { RoleApiClient, UserApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { errors, validatePasswords } from "@blocksure/blocksure-core/dist/src/services/api-clients/UserApiClient";
import { AutocompleteCustom, history, InputCustom, ITableData, MessageBox, PasswordValidation, SelectCustom, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { trimEmailValue } from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { setDataRoleName, setDataUser, setDataUserName } from "./helper";
import { validate } from "./validate";
import { IError } from "./models";
import { BannerClientLogo, DescriptionColumns, PageContainer } from "../components";
import GlobalContext from "../context/global-context";
import { getHeaderColumnsI18 } from "./columns.config";
import Box from "@material-ui/core/Box";

const inputErrors = {
    short: errors[0],
    long: null,
    capital: errors[2],
    lower: errors[3],
    number: errors[4],
};

const UserAdministrationEditPage: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();
    const params = useParams<{ id: string }>();
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [mode, setMode] = useState(params.id ? "create" : "clone");
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [addRoleIds, setAddRoleIds] = useState([]);
    const [userToClone, setUserToClone] = useState(null);
    const [choosenRoleIds, setChoosenRoleIds] = useState([]);
    const [errors, setErrors] = useState<IError>(inputErrors);
    const [errorMessage, setErrorMessage] = useState();
    const [isShowValidationLabel, setIsShowValidationLabel] = useState<boolean>(false);
    const [user, setUser] = useState<any>({});

    const userApiClient = new UserApiClient(namespacedLocalStorage);
    const roleApiClient = new RoleApiClient(namespacedLocalStorage);

    const optionCloneUsers = useMemo(() => {
        return users.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
        }));
    }, [users]);

    const optionAddRoles = useMemo(() => {
        return roles.map((item) => ({
            label: item.name,
            value: item.id,
            disabled: choosenRoleIds.includes(item.id),
        }));
    }, [roles, choosenRoleIds]);

    const fetchDataClone = async () => {
        if (params.id) {
            const user = await userApiClient.getUser(params.id);

            if (!isMountRef.current) {
                return;
            }

            setMode("create");
            setUser(user);
            formik.setFieldValue("email", user.email);
            formik.setFieldValue("firstName", user.firstName);
            formik.setFieldValue("lastName", user.lastName);
            formik.setFieldValue("password", user.password);
        }

        const { users } = await userApiClient.listUsers();
        const { roles } = await roleApiClient.listRoles();

        if (!isMountRef.current) {
            return;
        }

        const usersData = setDataUserName(users);
        const rolesData = setDataRoleName(roles);

        setUsers(usersData);
        setRoles(rolesData);
    };

    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        fetchDataClone();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: { email: "", firstName: "", lastName: "", password: "" },
        validate: validate(params.id, choosenRoleIds),
        onSubmit: async ({ email, firstName, lastName, password }) => {
            try {
                if (params.id) {
                    const editUser = {
                        email,
                        firstName,
                        lastName,
                        password,
                        roles: choosenRoleIds,
                    };
                    await userApiClient.updateUser(params.id, editUser);
                } else {
                    const newUser = {
                        email,
                        firstName,
                        lastName,
                        password,
                        roles: choosenRoleIds,
                    };
                    await userApiClient.createUser(newUser);
                }
                history.push("/user-administration");
            } catch (err) {
                setErrorMessage(generateErrorMessage(err));
            }
        },
    });

    const selectFormik = useFormik({
        initialValues: {
            role: "",
        },
        onSubmit: ({ role }) => {
            setChoosenRoleIds([...choosenRoleIds, role]);
            formik.setFieldError("role", null);
        },
    });

    const deleteCurrentRole = (id: string) => {
        if (choosenRoleIds.includes(id)) {
            const newChoosenIds = choosenRoleIds.filter((choosenRole) => choosenRole !== id);

            if (!isMountRef.current) {
                return;
            }

            setChoosenRoleIds(newChoosenIds);
            return;
        }
    };

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);
        try {
            const { roles } = await roleApiClient.listRoles();
            const headerColumnsI18 = getHeaderColumnsI18(deleteCurrentRole);
            const dataPolicies = setDataUser(roles, choosenRoleIds);

            if (!isMountRef.current) {
                return;
            }

            setData(dataPolicies);
            setHeaderColumns(headerColumnsI18);
        } catch (error) {
            console.warn("error", error.message);
        }

        if (!isMountRef.current) {
            return;
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosenRoleIds]);

    useEffect(() => {
        if (user && user.roles && user.roles.some((role) => !choosenRoleIds.includes(role.id))) {
            if (user.roles) {
                setChoosenRoleIds(user.roles.map((role) => role.id));
            }
        }

        const headerColumnsI18 = getHeaderColumnsI18(deleteCurrentRole);
        setHeaderColumns(headerColumnsI18);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const addRole = roles.filter((role) => addRoleIds.includes(role.id))[0];

    const handleChange = (password) => {
        const errorResponse = validatePasswords(password);
        if (!errorResponse) {
            setErrors({});
            return;
        }

        setErrors(errorResponse);
    };

    const handleCloneUserRoles = () => {
        let userRoles = [];

        users
            .filter((user) => user.id === userToClone)
            .forEach((user) => {
                const roleIds = user.roles.map((role) => role.id).filter((roleId) => !userRoles.includes(roleId));
                userRoles = [...userRoles, ...roleIds];
            });

        setChoosenRoleIds(userRoles);
        setUserToClone(null);
    };

    const title = params.id ? t("editUser") : t("createUser");

    return (
        <PageContainer title={title}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{title}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                        {mode === "create" && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("personalDetailsCreateUser")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <form onSubmit={formik.handleSubmit} id="user-form">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <InputCustom
                                                    autoComplete="off"
                                                    data-testid="email"
                                                    error={formik.errors.email as string}
                                                    label={`${t("email")}*`}
                                                    name="email"
                                                    theme={theme}
                                                    touched={formik.touched.email as boolean}
                                                    type="email"
                                                    value={formik.values.email}
                                                    onBlur={(e) => {
                                                        formik.setFieldValue("email", trimEmailValue(e.target.value));
                                                        formik.handleBlur(e);
                                                    }}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputCustom
                                                    autoComplete="off"
                                                    data-testid="firstName"
                                                    error={formik.errors.firstName as string}
                                                    label={`${t("firstName")}*`}
                                                    name="firstName"
                                                    theme={theme}
                                                    touched={formik.touched.firstName as boolean}
                                                    value={formik.values.firstName}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputCustom
                                                    autoComplete="off"
                                                    data-testid="lastName"
                                                    error={formik.errors.lastName as string}
                                                    label={`${t("lastName")}*`}
                                                    name="lastName"
                                                    theme={theme}
                                                    touched={formik.touched.lastName as boolean}
                                                    value={formik.values.lastName}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputCustom
                                                    autoComplete="new-password"
                                                    data-testid="password"
                                                    error={formik.errors.password as string}
                                                    label={`${t("password")}${params.id ? "" : "*"}`}
                                                    name="password"
                                                    type="password"
                                                    theme={theme}
                                                    touched={formik.touched.password as boolean}
                                                    value={formik.values.password}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(event) => {
                                                        formik.handleChange(event);
                                                        handleChange(event.currentTarget.value);
                                                    }}
                                                    onFocus={() => setIsShowValidationLabel(true)}
                                                />
                                            </Grid>
                                            {isShowValidationLabel && (
                                                <Grid item xs={12} data-testid="error-message">
                                                    <PasswordValidation errors={errors} theme={theme} />
                                                </Grid>
                                            )}
                                            {errorMessage && (
                                                <MessageBox message={errorMessage} theme={theme} variant="error" onClose={() => setErrorMessage(null)} />
                                            )}
                                        </Grid>
                                    </form>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                        {mode === "clone" && (
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{t("cloneRolesFromAnotherUser")}</Typography>
                                <Typography variant="subtitle1">{t("cloneRolesFromSimilarUser")}</Typography>
                            </Grid>
                        )}
                        {mode === "create" && (
                            <Grid item xs={12}>
                                <Typography variant="h6">{t("addRole")}</Typography>
                                <Typography variant="body1">{t("allocateRolesToUser")}</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <form id="clone-form" onSubmit={selectFormik.handleSubmit}>
                                <Grid container spacing={1} alignItems={formik.errors.role ? "center" : "flex-end"}>
                                    {mode === "clone" ? (
                                        <Grid item xs={12}>
                                            <AutocompleteCustom
                                                fullWidth
                                                getLabel={false}
                                                name="role"
                                                label={t("SelectUserToClone")}
                                                options={optionCloneUsers}
                                                theme={theme}
                                                onChange={(event, newValue) => {
                                                    setUserToClone(newValue);
                                                    selectFormik.handleChange(event);
                                                }}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={9}>
                                            <SelectCustom
                                                error={formik.errors.role as string}
                                                label={t("selectRole")}
                                                name="role"
                                                options={optionAddRoles}
                                                theme={theme}
                                                touched
                                                value={selectFormik.values.role}
                                                onBlur={selectFormik.handleBlur}
                                                onChange={(event) => {
                                                    selectFormik.handleChange(event);
                                                    setAddRoleIds(event.target.value);
                                                }}
                                            />
                                        </Grid>
                                    )}

                                    {mode === "create" && (
                                        <Grid item xs={3}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                                fullWidth
                                                endIcon={<Add />}
                                            >
                                                {t("add")}
                                            </Button>
                                        </Grid>
                                    )}
                                    {mode === "create" && addRole && (
                                        <Grid item xs={12}>
                                            <DescriptionColumns value={addRole.description} permissions={addRole.permissions} />
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {mode === "create" && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">{t("currentRoles")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={1} justify="flex-end">
                        <Grid item>
                            <Button component={Link} color="secondary" variant="text" to="/user-administration">
                                {t("cancel")}
                            </Button>
                        </Grid>
                        {mode === "clone" && (
                            <>
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            setMode("create");
                                        }}
                                    >
                                        {t("skip")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        form="clone-form"
                                        type="submit"
                                        disabled={!userToClone}
                                        onClick={() => {
                                            setMode("create");
                                            handleCloneUserRoles();
                                        }}
                                    >
                                        {t("cloneUser")}
                                    </Button>
                                </Grid>
                            </>
                        )}
                        {mode === "create" && (
                            <Grid item>
                                <Button data-testid="submit-button" color="primary" variant="contained" type="submit" form="user-form">
                                    {params.id ? t("updateUser") : t("createUser")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default UserAdministrationEditPage;
