import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Markdown} from '../Markdown';
import {ProductContext} from '../../common/context';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';
import { useDefaultTranslation } from '../../common/Translation';

// TODO - Its a shame that we needed to copy this into the codebase, but it was necessary in order to replace the rendering of the 'label' as markdown.
//  If there is a hook for this in the future then feel free to use that instead.

// Check to see if a schema specifies that a value must be true
function schemaRequiresTrueValue(schema) {
    // Check if const is a truthy value
    if (schema.const) {
        return true;
    }

    // Check if an enum has a single value of true
    if (schema.enum && schema.enum.length === 1 && schema.enum[0] === true) {
        return true;
    }

    // If anyOf has a single value, evaluate the subschema
    if (schema.anyOf && schema.anyOf.length === 1) {
        return schemaRequiresTrueValue(schema.anyOf[0]);
    }

    // If oneOf has a single value, evaluate the subschema
    if (schema.oneOf && schema.oneOf.length === 1) {
        return schemaRequiresTrueValue(schema.oneOf[0]);
    }

    // Evaluate each subschema in allOf, to see if one of them requires a true
    // value
    if (schema.allOf) {
        return schema.allOf.some(schemaRequiresTrueValue);
    }
}

function CheckboxWidget(props) {
    const {product} = useContext(ProductContext);
    const {
        amend,
        schema,
        id,
        value,
        disabled,
        readonly,
        label,
        autofocus,
        onBlur,
        onFocus,
        onChange,
    } = props;
    const {t} = useDefaultTranslation();
    // Because an unchecked checkbox will cause html5 validation to fail, only add
    // the "required" attribute if the field value must be "true", due to the
    // "const" or "enum" keywords
    const required = schemaRequiresTrueValue(schema);

    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const defaultValue = amend && !isDirty && props.options.hasOwnProperty('default') ? props.options.default : value

    return (
            <div className={`checkbox ${isValid ? '' : 'error'} ${disabled || readonly ? 'disabled' : ''}`}>
                {schema.description && (
                        <div style={{marginBottom: 10}}>
                            <Markdown value={schema.description} product={product}/>
                        </div>
                )}
                <label>
                    <input
                            type="checkbox"
                            id={id}
                            checked={typeof value === 'undefined' ? false : defaultValue}
                            required={required}
                            disabled={disabled || readonly}
                            autoFocus={autofocus}
                            onChange={event => {
                                resetRequiredMessage(event);
                                setIsDirty(true);
                                onChange(event.target.checked)
                                setIsValid(event.target.checked)
                            }}
                            onBlur={onBlur && (event => onBlur(id, event.target.checked))}
                            onFocus={onFocus && (event => onFocus(id, event.target.checked))}
                            onInvalid={required ? (event => {changeRequiredMessage(event, t); setIsValid(false)}) : null}
                    />
                    <span>{label && <Markdown value={label} product={product}/>}</span>
                </label>
            </div>
    );
}

CheckboxWidget.defaultProps = {
    autofocus: false,
};

if (process.env.NODE_ENV !== 'production') {
    CheckboxWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.bool,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
    };
}

export default CheckboxWidget;
