import React, { useState } from 'react';
import { FlatfileButton } from '@flatfile/react';
import { ImportApiClient } from '@blocksure/blocksure-core/dist/src/services/api-clients';
import licenceVal from '../licence-key';
import deleteIfBlank from '../delete-if-blank';
import nullRemover from '../remove-if-null';
// import enable from '../enable-button';
import flatfileFields from '../flatfile-fields.js';
import { namespacedLocalStorage } from '../helper';
import './index.css';
// import validation from '../validation';

let flatfileOutputDataArray = [];


const consistantAdditionalAttributes = { // attributes that are the same for each policy, these are appended to all policies
    'policyholder.appPath': '/stu',
    'policyholder.address.country': 'Indonesia',
    'student.address.country': 'Indonesia',
    'meta.dueDateOverride': '2021-05-25T10:00:00.000Z', // can be any past date
    userName: null

};

const saveImportData = async (flatfileOutputDataArray) => {
    const productApiClient = new ImportApiClient(namespacedLocalStorage);
    try {
        const res = await productApiClient.importData(flatfileOutputDataArray);
    } catch (error) {
        console.error(error);
    }
};

const onDataReturnFunc = async (results, currentUser) => {
    flatfileOutputDataArray = [];
    // adds attributes that are the same for all policies to the
    consistantAdditionalAttributes.userName = `${currentUser.userDetails.firstName}_${currentUser.userDetails.lastName}`;

    // null values must be removed
    results = await nullRemover.removeNulls(results);
    // adds output to an array
    for (let i = 0; i < results.validData.length; i++) {
        results.validData[i] = Object.assign(results.validData[i], consistantAdditionalAttributes);
        flatfileOutputDataArray.push(results.validData[i]);

    }
    // deletes empty attributes
    flatfileOutputDataArray = (deleteIfBlank(flatfileOutputDataArray));
    // enables button so that the requests can be sent

    await saveImportData(flatfileOutputDataArray);

    return 'Done!';
};

const ImportCsv = (props) => {
    const {currentUser, schema} = props;
    const [results, setResults] = useState();
    const fields = schema ? [schema] : [];

    return (
        <div>
            <FlatfileButton
                // mountUrl="https://portal-2.flatfile.io/" // used to override Flatfile URL for on-prem deployments
                licenseKey={licenceVal}
                customer={{ userId: currentUser.userDetails.id }}
                settings={{
                    type: 'Contact',
                    title: 'Import From CSV File',
                    fields: flatfileFields.fields,
                    // fields,
                    theme: {
                        buttons: {
                            primary: {
                                backgroundColor: '#2062c7'
                            }
                        }
                    },
                    devMode: true
                }}
                // onRecordInit={(record) => validation.validateRecords(record)} // returns correctly validated records
                // onRecordChange={(record) => validation.validateRecords(record)} // returns correctly validated records
                onData={async (results) => onDataReturnFunc(results, currentUser)}
                render={(importer, launch) => {
                    return <div className="importButton"><button disabled={!schema} onClick={launch}>Next</button></div>;
                }}
            />
        </div>
    );
};


export default ImportCsv;
