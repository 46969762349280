import React from 'react';
import './Progress.css';
import {useDefaultTranslation} from '../common/Translation';

const HiddenSteps = ['amend', 'quote', 'summary', 'password'];

const WIDTH = '63%';

const STYLES = {
    display: 'block',
    margin: '0 auto',
    wordBreak: 'keep-all'
};
const PROPERTY = 'property';
const LIST =  ['aboutYou', 'moreAbout', PROPERTY];

const Progress = ({
    steps,
    active
}) => {
    let seenActive = active === 'amend';
    // Don't render if there is just 1 step.
    if (steps.length < 2 || active === 'quote') return '';
    return (
            <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8 d-none d-sm-block">
                <ol className="progress-pips">
                    {steps.filter(it => !HiddenSteps.includes(it.name))
                            .map((step, i) => {
                                let isActive = step.name === active;
                                seenActive = seenActive || isActive;
                                return <ProgressPip key={i} {...{
                                    step,
                                    isActive,
                                    seenActive,
                                    i,
                                    steps
                                }} />;
                            })}
                </ol>
            </div>
    );
};

function outerClasses(isActive, seenActive, isLast) {
    const classes = [];
    if (isActive) classes.push('is-active');
    else if (!seenActive) classes.push('is-complete');
    if (isLast) classes.push('progress-pips__last');
    return classes.join(' ');
}

function beforeClasses(isActive, seenActive) {
    // We have to use the bootstrap styles so that we get matching styles for existing boostrap themes.
    const background = isActive ? 'btn-info' : !seenActive ? 'btn-primary' : 'pending';
    return `before ${background}`;
}

function afterClasses(isActive, seenActive) {
    const background = !seenActive ? 'btn-primary' : 'pending';
    return `after ${background}`;
}

const ProgressPip = ({
    step,
    isActive,
    seenActive,
    i,
    steps
}) => {
    const {i18n} = useDefaultTranslation();
    const isLast = i === steps.length - 1;
    const format = Intl.NumberFormat(i18n.language);
    const needStyle = i18n.language.includes('ja') && LIST.includes(step.name);
    const width = step.name === PROPERTY ? 'auto' : WIDTH;
    return (
            <li key={step.name} className={outerClasses(isActive, seenActive, isLast)} data-step={i + 1}>
                <div className={beforeClasses(isActive, seenActive)}>{format.format(i + 1)}</div>
                <span style={needStyle ? {
                    ...STYLES,
                    width,
                } : {}}>{step.title}</span>
                <div className={afterClasses(isActive, seenActive)}/>
            </li>
    );
};

export default Progress;
