import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Grid, Link as LinkButton, Typography } from "@material-ui/core";
import { BannerClientLogo, PageContainer } from "../components";

const PaymentGatewayPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageContainer title={t("paymentGateway")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("paymentGateway")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body1">{t("paymentGatewayBody")}</Typography>
                        </Grid>
                        <br />
                        <br />
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                <LinkButton component={Link} to="/support">
                                    {t("support")}
                                </LinkButton>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default PaymentGatewayPage;
