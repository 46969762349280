import * as React from "react";
import { useContext, useEffect, useMemo, useReducer } from "react";
import { BackButton, BannerClientLogo, PageContainer } from "../components";
import { FETCH_DONE, FETCH_ERROR, FETCH_START, initialState, OPEN_CANCEL_POLICY, reducer, SET_ACTIVE_TAB } from "./models/reducer";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    ClaimApiClient,
    PaymentApiClient,
    PolicyApiClient,
    PolicyholderApiClient,
    ProductApiClient,
} from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import GlobalContext from "../context/global-context";
import { PolicyContext } from "./models/context";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { EIdentityType, EPolicyStatus, IPolicy, IPolicyholder, IProduct, MessageBox, signInService, PolicyholderUtils, PolicyUtils, getUpcomingStatus } from "@surelync/common";
import PremiumSummary from "./components/PremiumSummary/PremiumSummary";
import AmountsDue from "./components/AmountsDue/AmountsDue";
import Claims from "./components/Claims/Claims";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CancelPolicyDialog from "./components/CancelPolicyDialog/CancelPolicyDialog";
import PolicyOverview from "../components/PolicyOverview/PolicyOverview";
import DocumentsOverview from "../components/DocumentsOverview/DocumentsOverview";
import { PathByStatus } from "../@Policies/tabs.model";
import Tabs from "../components/Tabs/Tabs";
import Payments from "./components/Payments/Payments";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: 0,
            padding: 0,
        },
        card: {
            display: "flex",
            flexDirection: "column",
        },
    })
);

const getPreviousPolicies = async (policyApiClient, id, load) => {
    const data = await policyApiClient.getSequences(id, load);
    const policy = getPolicy(getSequences(data));
    return policy
}

const getAllPreviousPolicies = async (policyApiClient, id, load, policies) => {
    const policy = await getPreviousPolicies(policyApiClient, id, load);
    policies = policies.concat(policy);
    return policy.renewal.previousPolicyId
        ? await getAllPreviousPolicies(policyApiClient, policy.renewal.previousPolicyId, load, policies)
        : policies
}

const getSequences = (responseSequences) :IPolicy[] => responseSequences.items;

const getPolicy = (sequences, sequenceId = null) => {
    return sequenceId
        ? sequences.find((it) => it.sequenceId === sequenceId)
        : sequences.find((it) => it.status === EPolicyStatus.Bound) || sequences[0];
}

const getPrefix = (appPath: string): string => appPath.startsWith("/sureapp/") ? "" : "/sureapp";

const PolicyDetailsPage: React.FC = () => {
    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, initialState);
    const { currentUser, locale, namespacedLocalStorage } = useContext(GlobalContext);
    const { id, sequenceId } = useParams<{ id: string; sequenceId: string }>();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [hasRenewal, setHasRenewal] = React.useState(false);
    const [prevSequenceId, setPrevSequenceId] = React.useState(null);
    const [sectionDisplayOrder, setSectionDisplayOrder] = React.useState<Array<string>>(null);
    const { activeTab, documents, error, fetching, policy, policyholder, product, selectedSection, sequences, universalPolicyholderSchema } = state;
    const location = useLocation<{ from: string; backBtnlabel: string; redirectTo: string }>();
    const history = useHistory();
    const theme = useTheme();
    const { identityType, party } = currentUser.authDetails;

    // fetch data request
    useEffect(() => {
        let isMount = true;
        const fetchData = async () => {
            const claimApiClient = new ClaimApiClient(namespacedLocalStorage);
            const paymentApiClient = new PaymentApiClient(namespacedLocalStorage);
            const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
            const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
            const productApiClient = new ProductApiClient(namespacedLocalStorage);

            try {
                dispatch({ type: FETCH_START });

                const load = [
                    "load.sections",
                    "load.product",
                    "load.sections.quote",
                    "load.transactions",
                    "load.meta",
                    "load.attachments",
                    "load.submission",
                    "load.breakdown",
                ];
                const responseSequences = await policyApiClient.getSequences(id, load);
                let sequences: IPolicy[] = getSequences(responseSequences);

                // SUR-133 do not work
                // currentPolicy = sequences.find(s => s.sequence === s.version)
                // Sequence with Quoted and Bound policies do not increment s.version
                const currentPolicy: IPolicy = getPolicy(sequences, sequenceId);

                // SUR-133 Do not display previous Quote Versions if there is a Version that has been Bound.
                if (currentPolicy.status === EPolicyStatus.Bound) {
                    sequences = sequences.filter((seq) => seq.status !== EPolicyStatus.Quoted);
                } else if (currentPolicy.status === EPolicyStatus.Quoted) {
                    sequences = [currentPolicy];
                }
                let renewedPolicies = [];
                if (currentPolicy.renewal.previousPolicyId) {
                    renewedPolicies = await getAllPreviousPolicies(policyApiClient, currentPolicy.renewal.previousPolicyId, load, renewedPolicies);
                }

                const appName = namespacedLocalStorage.getItem("app-name");
                const sequenceIds = sequences.map((s) => s.sequenceId);
                const claims = await claimApiClient.search({ sequenceIds });
                const product: IProduct = await productApiClient.getProduct(currentPolicy.productId);
                const policyholder: IPolicyholder = await policyholderApiClient.getPolicyholder(currentPolicy.policyholderId);
                const countryCode = policyholder?.shared?.schemaCountry;
                const universalPolicyholderSchema = await policyholderApiClient.getSchema(countryCode, policyholder?.type);
                const searchCriteria = Object.assign({
                    parties: party,
                    sequenceIds,
                    statuses: ["Paid", "Pending"],
                });
                const settlements = signInService.hasPermission(["SETTLEMENT_READ"]) ? await paymentApiClient.search(searchCriteria) : { items: [] };
                const documents = PolicyUtils.getDocuments(
                    { items: [...renewedPolicies, ...sequences], basepath: policyApiClient.basepath },
                    { items: claims.items, basepath: claimApiClient.basepath },
                    { items: [product], basepath: productApiClient.basepath },
                    appName,
                    locale
                );

                const sectionDisplayOrder = product.sectionDisplayOrder.filter((name) => currentPolicy.sections[name]);

                setSectionDisplayOrder(sectionDisplayOrder);

                // cleanup func
                if (!isMount) return;

                dispatch({
                    type: FETCH_DONE,
                    payload: {
                        claims: claims.items,
                        documents,
                        policy: currentPolicy,
                        policyholder,
                        product,
                        sequences,
                        settlements: settlements.items,
                        universalPolicyholderSchema,
                    },
                });
            } catch (error) {
                console.error(error);
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // open direct link and init back button
    useEffect(() => {
        if (!policy) {
            return;
        }

        const from = location?.state?.from;
        if (!from) {
            const path = (PathByStatus(policy.status) || "policies").toLowerCase();
            const state = { from: location.pathname, backBtnlabel: t(path), redirectTo: `/${path}` };
            history.replace({ pathname: location.pathname, state });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    useEffect(() => {
        let isMount = true;
        if (!policy) {
            return;
        }
        const getRenewalPolicy = async () => {
            const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
            try {
                const policyId = policy.id;
                const data = await policyApiClient.getRenewalPolicy([policyId], []);
                const previousPolicyIds = data?.items?.map((policy) => policy.renewal.previousPolicyId);
                const previousPolicy = data?.items?.find(policy => policyId === policy.renewal.previousPolicyId);
                const hasRenewal = previousPolicyIds.includes(policyId);
                // cleanup func
                if (!isMount) return;
                setHasRenewal(hasRenewal);
                setPrevSequenceId(previousPolicy?.sequenceId);
            } catch (error) {
                console.error(error);
                // cleanup func
                if (!isMount) return;
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }

        }
        getRenewalPolicy();
        return () => {
            isMount = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const isMultiSections = useMemo(() => {
        if (!policy) {
            return;
        }

        return Object.keys(policy.sections).length > 1;
    }, [policy]);

    const tabNames = useMemo(() => {
        if (!policy) {
            return [];
        }

        if (sectionDisplayOrder.length === 1) {
            return [t("overview")];
        }

        const tabs = [];

        if (isMultiSections) {
            tabs.push(t("overview"));
        }

        sectionDisplayOrder.forEach((name) => {
            tabs.push(product.sections[name].name);
        });

        return tabs;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy]);

    const isAmendButton = () => identityType === EIdentityType.Broker &&
        signInService.hasPermission(["POLICY_EDIT"]) && policy && policy.status === EPolicyStatus.Bound && policy.version === policy.sequence;

    // Cancel button should be visible for all cases as follows:
    // Status = Bound
    // AND Current date < Policy.expiryDate
    // AND exist permission to edit a policy
    // AND the party of which he/she is a member
    const isCancelButton = () => {
        if (!policy) {
            return false;
        }

        const isAuthorisedParties = product?.cancellation?.authorisedParties?.includes(party);
        if (policy.status !== EPolicyStatus.Bound || !signInService.hasPermission(["POLICY_EDIT"]) || !isAuthorisedParties) {
            return false;
        }

        return FormatUtils.now() <= FormatUtils.parseDate(policy.expiryDate);
    };

    const handleActions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getHandleParams = () => {
        const state = { from: location.pathname };
        let appPath = policy.meta.appPath;
        if (!appPath) {
            const s = sequences.find((s) => s.meta.appPath);
            appPath = s ? s.meta.appPath : "";
        }
        return {
            appPath,
            state
        }
    }

    const handleAmendPolicy = () => {
        const {appPath, state} = getHandleParams();
        const prefix = getPrefix(appPath);
        handleClose();
        history.push({ pathname: `${prefix}${appPath}/amend/${policy.sequenceId}/amend`, state });
    };

    const handleRenewPolicy = () => {
        const {appPath, state} = getHandleParams();
        const prefix = getPrefix(appPath);
        handleClose();
        history.push({ pathname: `${prefix}${appPath}/renewal/${prevSequenceId}`, state });
    };

    const handleCancelPolicy = () => {
        handleClose();
        dispatch({ type: OPEN_CANCEL_POLICY, payload: true });
    };

    const handleChangeTab = React.useCallback(
        (value) => {
            const sectionName = sectionDisplayOrder[value - (isMultiSections ? 1 : 0)];
            const selectedSection = value === 0 ? null : policy.sections[sectionName];
            dispatch({ type: SET_ACTIVE_TAB, payload: { activeTab: value, selectedSection } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [policy]
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderTitle = policy ? `${t("policy")} - ${t("id")} ${id}` : t("policy");

    const lastUpdated = policy ? FormatUtils.renderDate(policy.ts) : "";

    const fullName = useMemo(() => (policyholder ? PolicyholderUtils._renderFullName(policyholder) : ""), [policyholder]);

    const renderHeader = () => {
        if (!policy) {
            return null;
        }
        const header = policy.status === EPolicyStatus.Quoted ? t("quote") : t("policy");
        return `${header} - ${policy?.reportingRef}`;
    };

    const renderBanner = () => {
        if (!policy) {
            return null;
        }
        const upcoming = getUpcomingStatus(policy);

        if (upcoming) {
            const today = FormatUtils.today();
            const expiryDate = FormatUtils.parseDate(policy.expiryDate);
            const diff = expiryDate.diff(today, ["days"]);
            const days = Math.floor(diff.days);
            return (
                <Grid item xs={12} md={6} data-testid="renewal-message">
                    <MessageBox message={`${t("daysBeforeRenewal", {days})}. ${t("renewalDueSoon")}.`} theme={theme} variant="important" />
                </Grid>

            )
        }
        return null;
    }

    useEffect(() => {
        const key = "redirect";
        const path = namespacedLocalStorage.getItem(key);
        if (path) {
            namespacedLocalStorage.removeItem(key);
            history.push(path);
        }
    }, [history, namespacedLocalStorage]);

    return (
        <PolicyContext.Provider value={[state, dispatch]}>
            <PageContainer isBreadcrumb={true} fetching={fetching} title={renderTitle}>
                <Container maxWidth="lg" classes={{ root: classes.wrapper }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6} sm={8} md={5}>
                                    <Box mb={1}>
                                        <BackButton title={fullName} />
                                    </Box>
                                    <Typography variant="h5">{renderHeader()}</Typography>
                                    <Typography color="textPrimary" variant="body2">
                                        {`${t("id")} - ${id}`}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sm={4} md={2}>
                                    <Box textAlign={{ xs: "right", md: "center" }}>
                                        <BannerClientLogo />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={5}>
                                    <Hidden xsUp={!policy}>
                                        <Box pt={4} textAlign="right">
                                            <Typography variant="body2">
                                                {t("lastUpdated")}: {lastUpdated}
                                            </Typography>
                                        </Box>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                        {renderBanner()}
                        <Grid item xs={12}>
                            <Grid container justify="space-between" spacing={2}>
                                <Grid item style={{ maxWidth: "100%" }}>
                                    <Tabs names={tabNames} value={activeTab} onChange={handleChangeTab} />
                                </Grid>

                                <Hidden xsUp={!isCancelButton() && !isAmendButton()}>
                                    <Grid item>
                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Button aria-controls="actions-menu" aria-haspopup="true" variant="text" onClick={handleActions}>
                                                    {t("actions")} <ArrowDropDownIcon />
                                                </Button>
                                                <Menu
                                                    id="actions-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem disabled={!isAmendButton()} onClick={handleAmendPolicy}>
                                                        {t("amend")}
                                                    </MenuItem>
                                                    {
                                                        hasRenewal ? (
                                                            <MenuItem disabled={!isAmendButton()} onClick={handleRenewPolicy}>
                                                                {t("renew")}
                                                            </MenuItem>
                                                        ) : null
                                                    }
                                                    <MenuItem disabled={!isCancelButton()} onClick={handleCancelPolicy}>
                                                        {t("cancel")}
                                                    </MenuItem>
                                                    {/* <MenuItem>
                              {t("renistatePolicy")}
                            </MenuItem>
                          */}
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>

                        {error ? (
                            <Grid item xs={12} md={6}>
                                <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch({ type: FETCH_ERROR, payload: null })} />
                            </Grid>
                        ) : null}

                        <Hidden xsUp={!policy}>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="stretch" wrap="wrap">
                                    <Grid item xs={12} md={6} className={classes.card}>
                                        <PolicyOverview
                                            addressFieldOrder={universalPolicyholderSchema?.properties?.address["ui:order"]}
                                            isHideTitle={true}
                                            isMultiSections={isMultiSections}
                                            policy={policy}
                                            policyholder={policyholder}
                                            product={product}
                                            sectionName={selectedSection ? sectionDisplayOrder[activeTab - 1] : ""}
                                            sequences={sequences}
                                            showSumInsured={true}
                                            title={t("overview")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.card}>
                                        <DocumentsOverview
                                            documents={documents}
                                            policy={policy}
                                            policyholder={policyholder}
                                            title={t("policyDocuments")}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={identityType === EIdentityType.Insurer ? 6 : 3} className={classes.card}>
                                        {identityType === EIdentityType.Insurer ? (
                                            <PremiumSummary isSectionLevel={!!selectedSection} />
                                        ) : (
                                            <Payments isSectionLevel={!!selectedSection} />
                                        )}
                                    </Grid>

                                    <Hidden xsUp={identityType === EIdentityType.Insurer}>
                                        <Grid item xs={12} md={3} className={classes.card}>
                                            <AmountsDue isSectionLevel={!!selectedSection} />
                                        </Grid>
                                    </Hidden>

                                    <Grid item xs={12} md={6} className={classes.card}>
                                        <Claims />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </PageContainer>
            <CancelPolicyDialog />
        </PolicyContext.Provider>
    );
};

export default PolicyDetailsPage;
