import React from "react";
import { Link } from "react-router-dom";
import { i18n, PolicyUtils } from "@surelync/common";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import Typography from "@material-ui/core/Typography";

export const getHeaderColumnsI18 = (onRedirect: (url: string) => any) => [
    {
        Header: () => i18n.t("dateCreated"),
        accessor: "modifiedDate",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
    },
    {
        Header: () => i18n.t("filename"),
        accessor: "name",
        Cell: (row: CellInfo) => PolicyUtils._parseDocumentName(row.value, row.row.original.version),
    },
    {
        Header: () => i18n.t("attachedTo"),
        accessor: "externalRefLabel",
        Cell: (cell: CellInfo) => {
            return (
                <LinkButton component={Link} to={onRedirect(cell.row.original.externalRefPath)} variant="caption">
                    {cell.value}
                </LinkButton>
            );
        },
    },
    {
        Header: () => i18n.t("attachment"),
        accessor: "url",
        Cell: (cell: CellInfo) => {
            return (
                <Typography variant="body1">
                    <LinkButton target="_blank" rel="noopener" href={cell.value}>
                        {i18n.t("view")}
                    </LinkButton>
                </Typography>
            );
        },
    },
];
