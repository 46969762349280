import React, {useState} from 'react';
import {TradingGroupContext} from '../common/context';
import Form from 'react-jsonschema-form';
import {Link, useParams, useRouteMatch, withRouter} from 'react-router-dom';
import {Markdown} from '../schema/Markdown';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

const Schema = (iHaveReadAndUnderstoodThis) => ({
    type: 'object',
    required: ['agree'],
    properties: {
        agree: {
            type: 'boolean',
            title: iHaveReadAndUnderstoodThis,
            enum: [true]
        }
    }
});

export const ClaimTerms = withRouter(({history}) => {
    const {t} = useDefaultTranslation();
    const [agreed, setAgreed] = useState({agree: false});
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    const iHaveReadAndUnderstoodThis = t('iHaveReadAndUnderstoodThis', {defaultValue: 'I have read and understood this.'});
    const schema = Schema(iHaveReadAndUnderstoodThis);

    const onSubmit = () => history.push(`${newPath}/claim/date`);

    return (
            <TradingGroupContext.Consumer>{({formulator: {claim: {termsAndConditions = []} = {}} = {}} = {}) => (
                    <div className="screen container">
                        <div className="clearfix">&nbsp;</div>
                        <div className="section offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                            <ClaimTermsArray claimTerms={termsAndConditions}/>
                            <div className="rjsf offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                                <Form key="schema" formData={agreed} omitExtraData liveOmit schema={schema}
                                      onChange={({formData}) => setAgreed(formData)} onSubmit={onSubmit}>
                                    <button type="Submit" data-menukey="back"
                                            className="btn btn-lg btn-block btn-primary gap">{t('continue')}</button>
                                    <Link data-menukey="back" to={`${newPath}/claim`} className="btn btn-lg btn-block btn-secondary gap">{t('back')}</Link>
                                </Form>
                            </div>
                        </div>
                        <div className="clearfix">&nbsp;</div>
                    </div>
            )}</TradingGroupContext.Consumer>
    );
});

const ClaimTermsArray = ({claimTerms = []}) =>
        <Markdown key="claim-term" value={claimTerms.join('\n')} useParagraph={true}/>;
