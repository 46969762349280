import { EPolicyStatus } from "@surelync/common";

export enum ETabs {
    "quoted" = "quotes",
    "referred" = "referrals",
    "bound" = "policies",
    "dtq" = "dtqs",
    "cancelled" = "cancellations",
}

export const StatusByPath = {
    quotes: EPolicyStatus.Quoted,
    referrals: EPolicyStatus.Referral,
    policies: EPolicyStatus.Bound,
    dtqs: EPolicyStatus.Dtq,
    cancellations: EPolicyStatus.Cancelled,
};

export const PathByStatus = (status: string) => Object.keys(StatusByPath).find((key) => StatusByPath[key] === status);
