export default class NamespacedLocalStorage {
    constructor(localStorage, applicationName) {
        this.localStorage = localStorage;
        this.prefix = `com.blocksure.${applicationName}.`;
    }

    setItem(key, value) {
        this.localStorage.setItem(this.withNamespace(key), value);
    }

    getItem(key) {
        return this.localStorage.getItem(this.withNamespace(key));
    }

    removeItem(key) {
        return this.localStorage.removeItem(this.withNamespace(key));
    }

    clear() {
        this.localStorage.clear();
    }

    keys() {
        const keys = [];
        // Unfortunately, we have to iterate this way because this is the only official interface into the storage API
        for (let i = 0; i < this.localStorage.length; i++) {
            const key = this.localStorage.key(i);
            if (this.matchesNamespace(key)) keys.push(this.removeNamespace(key));
        }
        return keys;
    }

    matchesNamespace(key) {
        return key.indexOf(this.prefix) === 0;
    }

    withNamespace(key) {
        if (!key) throw new Error('key must not be null or empty.');
        return `${this.prefix}${key}`;
    }

    removeNamespace(key) {
        if (!key) throw new Error('key must not be null or empty.');
        return key.substr(this.prefix.length);
    }
}
