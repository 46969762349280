import * as React from "react";
import { useContext, useMemo, useReducer } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContactContext } from "../../../../models";
import { IPolicy, PolicyholderUtils, Preloader, checkUpcomingStatus, checkUpcomingExpireToToday, checkUpcomingExpireToInception, RecordsNotFound } from "@surelync/common";
import { initialState, reducer } from "./reducer";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import GlobalContext from "../../../../../context/global-context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        ellipsContent: {
            overflow: "hidden",
            maxWidth: "100%",
        },
    })
);

type IProps = Record<string, unknown>;

const UpcomingRenewals: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ fetching }] = useReducer(reducer, initialState);
    const { products } = useContext(GlobalContext);
    const [{ policies, policyholder, universalPolicyholderSchema }] = useContext(ContactContext);
    const { t } = useTranslation();
    const theme = useTheme();

    // if (policy has status=Bound)
    // && expiryDate is in 3 weeks or less
    // && ([expiryDate - inceptionDate ] > 4 weeks)) then
    // display in Upcoming Renewals
    const upcomingPolicies = useMemo(() => {
        const result = policies
            .filter((policy) => checkUpcomingStatus(policy))
            .filter((policy) => checkUpcomingExpireToToday(policy))
            .filter((policy) => checkUpcomingExpireToInception(policy));
        return FormatUtils.sortByProperty(result, "expiryDate");
    }, [policies]);

    const renderAddress = (policy: IPolicy) => {
        const address = policy && policy.submission && policy.submission.aboutYou;
        const fieldOrder = universalPolicyholderSchema?.properties?.address["ui:order"];
        return address ? PolicyholderUtils.renderAddress(policyholder, address, fieldOrder) : "";
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("upcomingRenewals")}
            </Typography>
            <Paper className={classes.root}>
                {fetching ? (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Preloader theme={theme} />
                        </Grid>
                    </Grid>
                ) : upcomingPolicies?.length && products ? (
                    <Grid container direction="column" spacing={3} classes={{ root: classes.content }}>
                        <Grid item xs>
                            {
                                // display 3 policies
                                upcomingPolicies.slice(0, 3).map((policy, index) => (
                                    <Grid container key={index} spacing={3} wrap="nowrap" data-testid="policy-item">
                                        <Grid item style={{ visibility: index === 0 ? "visible" : "hidden" }}>
                                            <Avatar>
                                                <Typography color="primary" variant="h6" data-testid="count">
                                                    {upcomingPolicies.length}
                                                </Typography>
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs classes={{ root: classes.ellipsContent }}>
                                            <Typography variant="h6" title={FormatUtils.renderDate(policy.expiryDate)} noWrap>
                                                {t("renewalDate")}: {FormatUtils.renderDate(policy.expiryDate)}
                                            </Typography>
                                            <Typography variant="body1" noWrap title={products[policy.productId]?.name}>
                                                {products[policy.productId]?.name}
                                            </Typography>
                                            <Typography variant="body1" noWrap title={renderAddress(policy)}>
                                                {renderAddress(policy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    );
};

export default UpcomingRenewals;
