import React from 'react';
import {Menu} from '../shared/Menu';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {Screen} from '../shared/Screen';
import {ClaimListContext, TradingGroupContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';
import {Markdown} from '../schema/Markdown';
import {convertToNewPath} from "../base";

const ClaimMenu = () => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <Screen>
                <ClaimListContext.Consumer>{({claims}) => (
                        <TradingGroupContext.Consumer>{({
                            formulator: {
                                mainMenu: {footer} = {},
                                claim: {termsAndConditions} = {}
                            } = {}
                        }) => {
                            const items = [];
                            items.push(<Link data-menukey="info" to={`${newPath}/claim/info`}
                                             className="btn btn-lg btn-block btn-primary gap">{t('beforeYouClaim')}</Link>);
                            items.push(<Link data-menukey="new" to={termsAndConditions ? `${newPath}/claim/terms` : `${newPath}/claim/date`}
                                             className="btn btn-lg btn-block btn-primary gap">{t('makeAClaim')}</Link>);
                            if (claims && claims.length > 0) {
                                items.push(<Link data-menukey="history" to={`${newPath}/claim/history`}
                                                 className="btn btn-lg btn-block btn-primary gap">{t('claimHistory')}</Link>);
                            }
                            items.push(<Link data-menukey="back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary gap">{t('back')}</Link>);
                            if (footer) {
                                items.push(<hr data-menukey="separator2"/>);
                                items.push(<label data-menukey="footer" className="alert alert-info gap col-xs-12 text-center"><Markdown
                                        value={footer}/></label>);
                            }
                            return (
                                    <div className="container">
                                        <Menu items={items}/>
                                    </div>
                            );
                        }}</TradingGroupContext.Consumer>
                )}</ClaimListContext.Consumer>
            </Screen>
    );
};

export default ClaimMenu;
