import React, { RefObject } from "react";
import { i18n } from "@surelync/common";
import { CellInfo } from "react-table";
import { Link } from "react-router-dom";
import LinkButton from "@material-ui/core/Link";
import { DescriptionColumns } from "../components";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Remove from "@material-ui/icons/Remove";

type UserFn = (id: string) => void;

export const getHeaderColumnsI18 = (editPermission: boolean, showAllRef: RefObject<boolean>, onRole: UserFn) => [
    {
        Header: () => i18n.t("name"),
        accessor: "name",
        Cell: (row: CellInfo) =>
            row.value === "-" || !editPermission ? (
                row.value
            ) : (
                <LinkButton component={Link} to={`/role-administration/${row.row.original.id}`} variant="body1">
                    {row.value}
                </LinkButton>
            ),
        size: "small",
    },
    {
        Header: () => i18n.t("description"),
        accessor: "description",
        Cell: (row: CellInfo) => <DescriptionColumns showAll={showAllRef.current} permissions={row.row.original.permissions} value={row.value} />,
        size: "large",
    },
    {
        Header: () => null,
        accessor: "removeButton",
        Cell: (row: CellInfo) => {
            return (
                <Box display="flex" justifyContent="flex-end">
                    {row.value && (
                        <Button color="primary" variant="text" endIcon={<Remove />} size="small" onClick={() => onRole(row.row.original.id)}>
                            {i18n.t("remove")}
                        </Button>
                    )}
                </Box>
            );
        },
        size: "small",
    },
];
