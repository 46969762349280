import React, { useContext } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography/Typography";
import { useTranslation } from "react-i18next";
import { ESortDirection } from "@surelync/common";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { HomeContext, SET_PAGINATION, SET_SORT_TYPE } from "../../models";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GlobalContext from "../../../context/global-context";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Hidden } from "@material-ui/core";
import "./style.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageSize: {
            color: theme.typography.body2.color,
            fontSize: theme.typography.body2.fontSize,
            height: "auto !important"
        },
    })
);

interface IProps {
    hideSort?: boolean;
}

export const Pagination: React.FC<IProps> = ({ hideSort }) => {
    const classes = useStyles();
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const [state, dispatch] = useContext(HomeContext);
    const { t } = useTranslation();

    const {
        pagination: { limit, offset, total },
        sort,
    } = state;

    const totalNumber = Math.ceil(total / limit);
    const thisNumber = offset + 1;

    const handleBefore = () => dispatch({ type: SET_PAGINATION, payload: { limit, total, offset: offset - 1 } });
    const handleNext = () => dispatch({ type: SET_PAGINATION, payload: { limit, total, offset: offset + 1 } });

    const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target;
        dispatch({ type: SET_PAGINATION, payload: { limit: value, total: 0, offset: 0 } });
        // save setting for user came back to this page
        namespacedLocalStorage.setItem("search-page-size", value.toString());
    };

    const handleSort = () => {
        const newValue = sort === ESortDirection.ASC ? ESortDirection.DESC : ESortDirection.ASC;
        dispatch({ type: SET_SORT_TYPE, payload: newValue });
        namespacedLocalStorage.setItem("search-sort", newValue);
    };

    return (
        <Grid container alignItems="center" spacing={2} data-testid="pagination">
            <Hidden xsUp={hideSort}>
                <Grid item>
                    <Button color="default" variant="text" data-testid="sort-btn" onClick={handleSort}>
                        <Box mr={1}>{t("lastUpdate")}</Box>
                        {sort === ESortDirection.DESC ? <ArrowDownwardIcon fontSize="small" /> : <ArrowUpwardIcon fontSize="small" />}
                    </Button>
                </Grid>
            </Hidden>

            <Hidden xsUp={limit >= total}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="body2">{t("pageNumberOutOfHowMany", { thisNumber, totalNumber })}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton disabled={thisNumber === 1} onClick={handleBefore}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton disabled={thisNumber === totalNumber} onClick={handleNext}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Select
                        labelId="select-page-size-label"
                        id="select-page-size"
                        data-testid="select-page-size"
                        value={limit}
                        classes={{ root: classes.pageSize }}
                        onChange={handlePageSizeChange}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Grid>
            </Hidden>
        </Grid>
    );
};
