import { IAttempts } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const setDataPaymentAttempts = (data: IAttempts[]) => {
    return data.map((item) => ({
        attemptedDate: FormatUtils.renderDateTime(item.date),
        message: item.message,
        paymentReference: item.reference ? item.reference : "-",
        status: item.status,
    }));
};
