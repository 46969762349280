import { i18n } from "@surelync/common";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("dateDue"),
        accessor: "dateDue",
    },
    {
        Header: () => i18n.t("dateCollected"),
        accessor: "dateCollected",
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
    },
    {
        Header: () => i18n.t("totalAmount"),
        accessor: "totalAmount",
    },
    {
        Header: () => i18n.t("txnCreationDate"),
        accessor: "transactionCreationDate",
    },
];
