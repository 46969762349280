import React from "react";
import { useField } from "formik";
import { InputCustom } from "@surelync/common";
import { useTheme } from "@material-ui/core/styles";

export const FTextField: React.FC<any> = ({ ...props }) => {
    const theme = useTheme();
    const [field, meta] = useField<unknown>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <InputCustom
            {...props}
            {...field}
            autoComplete="off"
            data-testid={props["data-testid"] || props.name}
            fullWidth
            theme={theme}
            helperText={errorText}
            error={errorText}
        />
    );
};
