import { i18n, IClaim, IPolicy, IPolicyholder, IProduct, PolicyholderUtils } from "@surelync/common";

export const setDataClaims = (
    claims: { items: IClaim[] },
    policyholders: { [key: string]: IPolicyholder },
    policies: { items: IPolicy[] },
    products: { [key: string]: IProduct }
) =>
    claims.items.map((item) => {
        const policy = policies.items.find((policyItem) => policyItem.id === item.policyId);
        const policyholder = policyholders[policy.policyholderId];
        const policyNo = policy.reportingRef;
        const product: IProduct = products[item.productId];
        const claimNo = item.reportingRef;
        return {
            policyholder: PolicyholderUtils._renderFullName(policyholder),
            policyNo,
            claimNo,
            status: i18n.t(item.status.toLowerCase()),
            category: item.category,
            dateOfLoss: item.dateOfIncident,
            dateReceived: item.creationDate,
            lossReserve: item.claimAmount,
            paidUpToDate: "", // always zero until backend provide a field with a claim payment value.
            totalIncurred: item.claimAmount, // will be the total of the Paid up to date and the Loss reserve.
            policyholderId: policyholder.id,
            currency: item.currency,
            id: item.id,
            policyId: policy.id,

            productName: product.name,
            subCategory: item.subCategory,
            creationDate: item.creationDate,
            claimFee: item.claimFee,
            lossDescription: item.lossDescription && item.lossDescription !== " " ? item.lossDescription : null,
        };
    });

// Additional columns would not be in the default column view
// These columns can be added by using Column Preferences

export const defaultColumns = [
    { id: "policyholder", isAdditional: false, header: ["policyholder"] },
    { id: "claimNo", isAdditional: false, header: ["claimNo"] },
    { id: "status", isAdditional: false, header: ["status"] },
    { id: "category", isAdditional: false, header: ["category"] },
    { id: "dateOfLoss", isAdditional: false, header: ["dateOfLoss"] },
    { id: "dateReceived", isAdditional: false, header: ["dateReceived"] },
    { id: "paidUpToDate", isAdditional: false, header: ["paidUpToDate"] },
    { id: "lossReserve", isAdditional: false, header: ["lossReserve"] },
    { id: "totalIncurred", isAdditional: false, header: ["totalIncurred"] },

    { id: "productName", isAdditional: true, header: ["productName"] },
    { id: "subCategory", isAdditional: true, header: ["subCategory"] },
    { id: "creationDate", isAdditional: true, header: ["creationDate"] },
    { id: "claimFee", isAdditional: true, header: ["claimFee"] },
    { id: "lossDescription", isAdditional: true, header: ["lossDescription"] },
];
