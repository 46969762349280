import * as React from "react";
import { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import GlobalContext from "../../context/global-context";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button, Divider, Hidden, IconButton, useTheme } from "@material-ui/core";
import { PolicyApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IAttachment, IPolicy, IPolicyholder, PolicyUtils, RecordsNotFound } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const MAX_COUNT = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

interface IProps {
    documents: IAttachment[];
    policy: IPolicy;
    policyholder: IPolicyholder;
    title: string;
}

const DocumentsOverview: React.FC<IProps> = ({ documents, policy, policyholder, title }) => {
    const classes = useStyles();
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const appName = namespacedLocalStorage.getItem("app-name");
    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const productApiClient = new ProductApiClient(namespacedLocalStorage);

    const lang = policyholder.shared.locale || policy.meta.language || "en";

    const handleViewAllDocuments = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderDocument = (document: IAttachment, index: number, count: number) => {
        const path = document.product ? productApiClient.basepath : policyApiClient.basepath;
        const id = document.product ? policy.productId : policy.id;
        const href = PolicyUtils.formatAttachmentHref(document, path, id, appName, lang);
        const name = PolicyUtils._parseDocumentName(document.name, document.version);

        return (
            <Grid container key={index} spacing={1} alignItems="center">
                <Grid item xs={2}>
                    <IconButton color="primary" target="_blank" rel="noopener" href={href}>
                        <GetAppIcon fontSize="large" />
                    </IconButton>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" noWrap title={name}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2">{FormatUtils.renderDate(document.modifiedDate)}</Typography>
                </Grid>
                <Hidden xsUp={index >= count - 1}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Hidden>
            </Grid>
        );
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {title}
            </Typography>
            <Paper className={classes.root}>
                {documents && documents.length ? (
                    <Grid container direction="column" spacing={1} classes={{ root: classes.content }}>
                        <Grid item xs>
                            {documents
                                .slice(0, MAX_COUNT)
                                .map((document: IAttachment, index: number, arr) => renderDocument(document, index, arr.length))}
                        </Grid>

                        <Hidden xsUp={documents.length <= MAX_COUNT}>
                            <Grid item>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Link href="" color="primary" variant="body2" onClick={handleViewAllDocuments}>
                                        {t("viewAllDocuments")}
                                    </Link>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <Dialog fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="documents-dialog" open={Boolean(anchorEl)}>
                <DialogTitle>
                    <Typography variant="h6">{t("policyDocuments")}</Typography>
                </DialogTitle>
                <DialogContent>
                    {documents.map((document: IAttachment, index: number, arr) => renderDocument(document, index, arr.length))}
                </DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={1}>
                        <Grid item>
                            <Button color="secondary" variant="contained" onClick={handleClose}>
                                {t("close")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DocumentsOverview;
