import React, {Component} from 'react';
import './TooltipStyle.css';

/* eslint-disable jsx-a11y/no-static-element-interactions */
export default class ToolTipComponent extends Component {

    constructor(props, context) {
        super(props, context);
        this.onClick = this.onClick.bind(this);
        this.state = {active: false};
    }

    onClick(e) {
        e.preventDefault();
        this.setState({active: !this.state.active});
        if (this.props.onClick) this.props.onClick();
    }

    render() {
        const {
            buttonText,
            children
        } = this.props;
        return (
            <span>
                {buttonText
                    ? <button aria-label={buttonText} type="button" className="button is-info is-outlined btn-link float-right btn-icon"
                        onClick={this.onClick}>{buttonText}</button>
                    : (
                        <button aria-label="info" className="button is-large is-white btn-link float-right btn-icon" onClick={this.onClick}>
                            <span className="icon">
                                <i className="fa fa-question-circle-o"/>
                            </span>
                        </button>
                    )}
                <div className={`modal modal-top ${this.state.active ? 'is-active' : ''}`}>
                    <div aria-label="close" className="modal-background" role="button" onClick={this.onClick}/>
                    <div className="modal-content" style={{maxWidth: 800}}>
                        <section className="modal-card-body">
                            {children}
                        </section>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={this.onClick}/>
                </div>
            </span>
        );
    }
}
