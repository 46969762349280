import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core/";
import { useTheme } from "@material-ui/core/styles";

import { PolicyApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { ITableData, TableContainer } from "@surelync/common";
import { setDataPolicyPayment } from "./helper";
import GlobalContext from "../../context/global-context";
import { getHeaderColumnsI18 } from "./columns.config";

const PolicyPaymentFlyout: React.FC = () => {
    const { t } = useTranslation();
    const { openPolicyPaymentFlyout, namespacedLocalStorage, setOpenPolicyPaymentFlyoutOpen } = useContext(GlobalContext);

    const [headerColumns, setHeaderColumns] = useState(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const theme = useTheme();

    const handleClose = () => {
        setOpenPolicyPaymentFlyoutOpen(false);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const policy = await policyApiClient.getPolicyBySequenceId(openPolicyPaymentFlyout.sequenceId);
            const headerColumnsI18 = getHeaderColumnsI18();
            const dataSettlementStatement = setDataPolicyPayment(policy.transactions);

            setData(dataSettlementStatement);
            setHeaderColumns(headerColumnsI18);
        } catch (error) {
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openPolicyPaymentFlyout.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("payments")}</DialogTitle>
            <DialogContent dividers>
                <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PolicyPaymentFlyout;
