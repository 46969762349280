import {Component} from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line  no-undef
        if (global.Sentry) this.Sentry = Sentry;
    }

    componentDidCatch(error, info) {
        console.error(error);
        console.info(info);
        this.Sentry ? Sentry.withScope(scope => {
            scope.setExtra('debug', false);
            Sentry.captureException(error);
        }) : console.log('Could not find Sentry configuration, surpressing error');
    }

    render = () => this.props.children;
}
