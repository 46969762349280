import React from 'react';
import {Menu} from '../shared/Menu';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {Screen} from '../shared/Screen';
import {TradingGroupContext} from '../common/context';
import {ProductLink} from '../product/ProductLink';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

const HomeNoAuth = () => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <Screen>
                <TradingGroupContext.Consumer>{({products}) => {
                    const items = [];
                    const productIds = Object.values(products || {});
                    productIds.forEach(it => {
                        items.push(<ProductLink data-menukey={it.id} product={it}/>);
                    });
                    if (productIds.length > 0) {
                        items.push(<hr data-menukey="separator"/>);
                    }
                    items.push(<Link data-menukey="login" to={`${newPath}/login`} className="btn btn-lg btn-block btn-secondary">{t('logIn')}</Link>);
                    return (
                            <div className="container">
                                <Menu items={items}/>
                            </div>
                    );
                }}</TradingGroupContext.Consumer>
            </Screen>
    );
};

export default HomeNoAuth;
