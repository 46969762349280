import * as React from "react";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { createStyles, lighten, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GlobalContext from "../../../context/global-context";
import { PolicyContext } from "../../models/context";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { OPEN_CANCEL_POLICY } from "../../models/reducer";
import { useFormik } from "formik";
import { PolicyApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import {
    DatePickerCustom,
    InputCustom,
    IPolicy,
    ITransaction,
    ITransationFile,
    LoadingBtn,
    localeService,
    MessageBox,
    PolicyUtils,
    SelectCustom
} from "@surelync/common";
import Box from "@material-ui/core/Box";
import WarningIcon from "@material-ui/icons/Warning";
import {
    initialState,
    POLICY_CANCEL_DONE,
    POLICY_CANCEL_FAILED,
    POLICY_CANCEL_START,
    POLICY_PROCEED_DONE,
    POLICY_PROCEED_FAILED,
    POLICY_PROCEED_START,
    reducer,
    RESET,
    SET_EARLIST_CANELLATION_DATE
} from "./reducer";
import { CancelType } from "./cancel.models";
import { Hidden } from "@material-ui/core";
import {  } from "../../../components";
import { CancellationFee } from "./components/CancellationFee";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { DateTime } from "luxon";

const MAX_LENGTH_DESCRIPTION = 1000;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningMessage: {
            background: lighten(theme.palette.primary.main, 0.85),
            padding: theme.spacing(2),
        },
    })
);

type IProps = Record<string, unknown>;

const CancelPolicyDialog: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ openCancelPolicy, policy, product }, dispatchPolycyContext] = useContext(PolicyContext);
    const { locale, namespacedLocalStorage } = useContext(GlobalContext);
    const [{ cancellationFee, earliestCancellationDate, error, pending, refundable, refundAmount }, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();
    const theme = useTheme();
    const [reasonsTranslated, setReasons] = useState<{ [key: string]: string }>();

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const mtaSequence = policy && policy.version > 0;

    useEffect(() => {
        if (!policy) {
            return;
        }
        if (mtaSequence) {
            formik.values.cancelType = "Custom";
        }
        const pendingTransactions = Object.values(policy.transactions).filter((transaction) => (transaction as ITransaction).status === "Pending");
        const earliestCancellationDate = pendingTransactions.length > 0 ? (pendingTransactions[0] as ITransaction).due : policy.expiryDate;
        dispatch({ type: SET_EARLIST_CANELLATION_DATE, payload: earliestCancellationDate });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy, openCancelPolicy]);

    useEffect(() => {
        let isMount = true;
        if (!policy || reasonsTranslated || !openCancelPolicy) {
            return;
        }

        const fetchReasons = async () => {
            const productApiClient = new ProductApiClient(namespacedLocalStorage);
            const attachment = product.hiddenAttachments
                ? product.hiddenAttachments.find((a) => a.name === `translation-${localeService.getLanguage()}.json`)
                : null;
            const translation: ITransationFile = attachment ? await productApiClient.getAttachment(product.id, attachment.location, true) : {};

            if (!isMount) {
                return;
            }

            if (translation.cancellation?.reasons) {
                setReasons(translation.cancellation.reasons);
            }
        };

        fetchReasons();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policy, reasonsTranslated, openCancelPolicy]);

    // return to the step 1
    const handleBack = () => {
        dispatch({ type: POLICY_CANCEL_DONE, payload: { refundable: false, cancellationFee: null, refundAmount: null } });
    };

    const handleClose = () => {
        formik.resetForm();
        dispatch({ type: RESET });
        dispatchPolycyContext({ type: OPEN_CANCEL_POLICY, payload: false });
    };

    const handlePayTransactionClick = async () => {
        dispatch({ type: POLICY_PROCEED_START });
        try {
            const { cancelType, cancelReason, description } = formik.values;
            const cancelDate = formik.values.cancelDate.toISO();
            const { transactions, status } = await policyApiClient.cancelPolicy(
                policy.paymentSource,
                policy.id,
                cancelDate,
                cancelType,
                cancelReason,
                description
            );
            const adjustmentTransaction = transactions.find(transaction => transaction.transactionType === 'Correction'
                    || transaction.transactionType === 'Refund');
            if (typeof adjustmentTransaction !== 'undefined') {
                const lastAttempt = adjustmentTransaction.attempts[adjustmentTransaction.attempts.length - 1];
                if (lastAttempt && lastAttempt.status.toUpperCase() !== 'SUCCESS')
                    dispatch({ type: POLICY_PROCEED_FAILED, payload: `${t("status")} ${lastAttempt.status}` });
            } else if (status !== 'Cancelled') dispatch({ type: POLICY_PROCEED_FAILED, payload: `${t("status")} ${status}` });
            else dispatch({ type: POLICY_PROCEED_DONE });

            handleClose();
            window.location.reload();
        } catch (e) {
            dispatch({ type: POLICY_PROCEED_FAILED, payload: e.message });
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: CancelType) => {
        if (value === "Inception") {
            formik.values.cancelDate = FormatUtils.parseDate(policy.inceptionDate).toUTC();
            formik.setFieldTouched("cancelDate", true);
        }
        formik.handleChange(event);
    };

    const formik = useFormik({
        initialValues: {
            cancelDate: null,
            cancelType: "", // 'Inception' | 'Custom',
            cancelReason: "",
            description: "",
            time: "00:00",
        },
        validate: ({ cancelDate, cancelReason }) => {
            const errors: any = {};

            if (!cancelDate) {
                errors.cancelDate = t("required");
            } else {
                const date = FormatUtils.parseDate(cancelDate).toUTC();
                const inceptionDate = FormatUtils.parseDate(policy.inceptionDate).toUTC();
                const expiryDate = FormatUtils.parseDate(policy.expiryDate).toUTC();
                const nextPaymentDate = FormatUtils.parseDate(earliestCancellationDate).toUTC();
                const invalidDate =
                    FormatUtils.compareDates(date, expiryDate, "days") > 0 || FormatUtils.compareDates(date, inceptionDate, "days") < 0
                        ? t("betweenInceptionAndExpiry", {
                            date: FormatUtils.parseDate(cancelDate).toUTC().toFormat("ff"),
                            inception: FormatUtils.parseDate(policy.inceptionDate).toUTC().toFormat("ff"),
                            expiry: FormatUtils.parseDate(policy.expiryDate).toUTC().toFormat("ff"),
                        })
                        : null;
                const latestDate =
                    date > nextPaymentDate ? t("cancellationDateBefore", { date: FormatUtils.renderDate(earliestCancellationDate) }) : null;
                const error = invalidDate || latestDate || null;
                if (error) {
                    errors.cancelDate = error;
                }
            }

            if (!cancelReason) {
                errors.cancelReason = t("required");
            }

            return errors;
        },
        onSubmit: async (values) => {
            const { cancelType, cancelReason, description } = values;
            const cancelDate = FormatUtils.parseDate(values.cancelDate).toUTC().toISO();

            try {
                dispatch({ type: POLICY_CANCEL_START });
                const quotes = await policyApiClient.cancelPolicyPreview(policy.id, cancelDate, cancelType, cancelReason, description, false);
                const latestQuote: IPolicy = quotes[quotes.length - 1];
                const refundTransaction = latestQuote.transactions.filter((transaction) => transaction.transactionType === "Refund");
                const refundAmount = refundTransaction.length !== 0 ? refundTransaction[0].totalAmount : 0;
                const adjustmentTransaction = latestQuote.transactions.filter(
                    (transaction) => transaction.transactionType === "Correction" && transaction.componentAmounts.administration > 0
                );
                const cancellationFee = adjustmentTransaction.length !== 0 ? adjustmentTransaction[0].componentAmounts.administration : 0;
                dispatch({ type: POLICY_CANCEL_DONE, payload: { cancellationFee, refundAmount } });
            } catch (error) {
                console.error(error);
                dispatch({ type: POLICY_CANCEL_FAILED, payload: generateErrorMessage(error) });
            }
        },
    });

    const handleDatePickerChange = (value: Date) => {
        const dateValue = FormatUtils.renderFormatDate(value, FormatUtils.SERVER_DATE_FORMAT);
        const date = DateTime.fromFormat(`${dateValue} 00:00 +0`, `${FormatUtils.SERVER_DATE_FORMAT} HH:mm Z`).toUTC();
        formik.setFieldValue("cancelType", "Custom");
        formik.setFieldValue("cancelDate", date);
    };

    const minDate = useMemo(() => {
        if (!policy) {
            return null;
        }
        const startDate = mtaSequence ? policy.effectiveFromDate : policy.inceptionDate;
        return FormatUtils.parseDate(startDate).toUTC();
    }, [mtaSequence, policy]);

    const coolingOffPeriod = useMemo(() => {
        if (!policy) {
            return null;
        }
        const startDate = mtaSequence ? policy.effectiveFromDate : policy.inceptionDate;
        const period = product.coolingOffPeriod && !mtaSequence ? FormatUtils.parseIsoDuration(product.coolingOffPeriod) : {};
        return FormatUtils.parseDate(startDate).toUTC().plus(period);
    }, [mtaSequence, policy, product?.coolingOffPeriod]);

    const maxDate = useMemo(() => {
        if (!policy || !openCancelPolicy) {
            return null;
        }

        const policyType = policy ? PolicyUtils.parseIsoDuration(policy.payFrequency) : "day";

        return FormatUtils.parseDate(earliestCancellationDate)
            .toUTC()
            .minus({ [policyType]: 1 })
            .toJSDate();
    }, [policy, earliestCancellationDate, openCancelPolicy]);

    const optionsReasons = useMemo(() => {
        if (!product) {
            return [];
        }

        return product.cancellation.reasons.map((item) => ({
            label: (reasonsTranslated && reasonsTranslated[item]) || item,
            value: item,
        }));
    }, [product, reasonsTranslated]);

    const parseCancelDate = () => {
        const value = FormatUtils.parseDate(formik.values.cancelDate).toUTC();
        return value.isValid ? value.toJSDate() : null;
    };

    const renderButtons = () => {
        if (!policy) {
            return null;
        }

        if (refundable) {
            return (
                <>
                    <Grid item>
                        <Button color="secondary" variant="contained" onClick={handleBack}>
                            {t("back")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <LoadingBtn
                            fetching={pending}
                            color="primary"
                            label={t("proceed")}
                            theme={theme}
                            variant="contained"
                            onClick={handlePayTransactionClick}
                        />
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid item>
                    <Button color="secondary" variant="contained" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                </Grid>
                <Grid item>
                    <LoadingBtn
                        fetching={pending}
                        color="primary"
                        label={t("cancelPolicy")}
                        theme={theme}
                        // type="submit"
                        variant="contained"
                        onClick={() => formik.submitForm()}
                    />
                </Grid>
            </>
        );
    };

    const formatDate = () => {
        if (formik.values.cancelDate) {
            return FormatUtils.parseDate(formik.values.cancelDate).toUTC().toFormat("DD");
        } else {
            return null;
        }
    };

    // render form only when Dialog is open
    const renderContent = () => {
        if (!policy) {
            return null;
        }

        return (
            <Grid container spacing={2}>
                <Hidden xsUp={refundable}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" disabled={pending || mtaSequence}>
                            <FormLabel component="legend">
                                {t("selectCancellationType")}{" "}
                                {mtaSequence ? (
                                    <Typography color="primary" component="span" variant="caption">
                                        ({t("noMTA")})
                                    </Typography>
                                ) : (
                                    ""
                                )}
                            </FormLabel>
                            <RadioGroup aria-label="cancelType" name="cancelType" value={formik.values.cancelType} onChange={handleTypeChange}>
                                <FormControlLabel
                                    value="Inception"
                                    control={<Radio color="primary" />}
                                    label={`${t("cancelFromTheInceptionDate")} (${minDate.toFormat("ff")})`}
                                />
                                <FormControlLabel value="Custom" control={<Radio color="primary" />} label={t("cancelFromASpecifiedDate")} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={6}>
                    <DatePickerCustom
                        error={formik.errors.cancelDate as string}
                        displayValue={formatDate()}
                        label={`${t("cancellationDate")}*`}
                        locale={locale}
                        minDate={coolingOffPeriod.toJSDate()}
                        maxDate={maxDate}
                        name="cancelDate"
                        readOnly={pending || refundable}
                        theme={theme}
                        touched={formik.touched.cancelDate as boolean}
                        value={formik.values.cancelDate ? parseCancelDate() : null}
                        onBlur={formik.handleBlur}
                        onChange={handleDatePickerChange}
                    />
                </Grid>

                <Hidden xsUp={refundable}>
                    <Grid item xs={12}>
                        <Box className={classes.warningMessage}>
                            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <WarningIcon color="primary" />
                                </Grid>
                                <Grid item>{t("cancellationWarning")}</Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <SelectCustom
                        error={formik.errors.cancelReason}
                        label={`${t("cancellationReason")}*`}
                        name="cancelReason"
                        options={optionsReasons}
                        readOnly={pending || refundable}
                        theme={theme}
                        touched={formik.touched.cancelReason}
                        value={formik.values.cancelReason}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputCustom
                        autoComplete="off"
                        helperText={refundable ? "" : `${formik.values.description.length}/${MAX_LENGTH_DESCRIPTION}`}
                        label={t("additionalDetailsOptional")}
                        name="description"
                        maxLength={1000}
                        multiline
                        minRows={4}
                        maxRows={4}
                        readOnly={pending || refundable}
                        theme={theme}
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
                {refundable ? (
                    <Grid item xs={12}>
                        <CancellationFee cancellationFee={cancellationFee} currency={policy.currency} premiumRefund={-refundAmount} />
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit} id="role-cancel-form">
            <Dialog
                disableEnforceFocus
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="cancelling-policy-dialog"
                open={openCancelPolicy}
            >
                <DialogTitle>
                    <Typography component="span" variant="h6">
                        {t("cancellingPolicy")} - {policy?.reportingRef}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2" paragraph>
                        {t("id")} - {policy?.id}
                    </Typography>
                    <Divider />
                </DialogTitle>
                <DialogContent>{renderContent()}</DialogContent>
                <DialogActions>
                    <Grid container justify="flex-end" spacing={1}>
                        <Hidden xsUp={!error}>
                            <Grid item xs={12}>
                                {<MessageBox message={error} theme={theme} variant="error" />}
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {renderButtons()}
                    </Grid>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default CancelPolicyDialog;
