import "regenerator-runtime/runtime";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { PolicyApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { IIssues, IProduct, IReferral, ISection, LoadingBtn } from "@surelync/common";
import Issues from "./Issues";
import GlobalContext from "../../context/global-context";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
    })
);

interface IProps {
    product: IProduct;
    referral: IReferral;
    resolved: boolean;
    onError: (error: string) => void;
    onResolved: (value: boolean) => void;
}

const Referrals: React.FC<IProps> = ({ referral, product, resolved, onError, onResolved }) => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [uploading, setUploading] = useState(false);
    const [endorsements, setEndorsements] = useState<any | null>(null);

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);

    const getEndorsements = (referral: IReferral) => {
        const endorsements = {};
        Object.entries(referral.sections).forEach(([sectionName, section]: [string, ISection]) => {
            const issues = {};
            Object.values(section.issues).forEach((issue: { name: string; endorsement: any }) => {
                issues[issue.name] = issue.endorsement?.name;
            });
            endorsements[sectionName] = issues;
        });
        return endorsements;
    };

    const createReferralData = (sectionName, issueName, value) => {
        if (!endorsements[sectionName]) endorsements[sectionName] = {};
        endorsements[sectionName][issueName] = value;

        setEndorsements(endorsements);
    };

    const saveEndorsements = async () => {
        setUploading(true);
        onError(null);

        try {
            const endorsed = await policyApiClient.endorsePolicy(referral.sequenceId, endorsements);
            const resolved = endorsed.status === "Quoted";
            const newEndorsements = getEndorsements(referral);

            if (!isMountRef.current) {
                return;
            }

            setEndorsements(newEndorsements);
            onResolved(resolved);
            // this.setState({referral: endorsed, pending: false, endorsements: this.getEndorsements(referral), resolved});
        } catch (error) {
            // this.setState({pending: false, error: e.message});
            onError(generateErrorMessage(error));
        }

        setUploading(false);
    };

    const sections = Object.entries(referral.sections);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        const currentEndrosement = getEndorsements(referral);
        setEndorsements(currentEndrosement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("referral")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container spacing={1} alignItems="center" justify="flex-end">
                    {referral &&
                        product &&
                        endorsements &&
                        sections.map(([sectionName, section], index) => {
                            const productSection = product.sections[sectionName];
                            return (
                                <React.Fragment key={index}>
                                    {section &&
                                        (section as ISection).issues &&
                                        Object.values((section as ISection).issues).map((issue: any, index) => (
                                            <Issues
                                                issue={issue}
                                                productSection={productSection}
                                                endorsements={endorsements}
                                                sectionName={sectionName}
                                                createReferralData={createReferralData}
                                                key={index}
                                            />
                                        ))}
                                    <Grid item>
                                        <LoadingBtn
                                            disabled={resolved}
                                            fetching={uploading}
                                            label={t("save")}
                                            theme={theme}
                                            onClick={saveEndorsements}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                </Grid>
            </Paper>
        </>
    );
};

export default Referrals;
