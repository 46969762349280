import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { useTheme } from "@material-ui/core/styles";
import { RoleApiClient, UserApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { EIdentityType, ITableData, MessageBox, signInService, TableContainer } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { setDataUsers } from "./helper";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, ConfirmDialog, PageContainer } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";

const permissions = ["USER_EDIT"];

const UserAdministrationPage: React.FC = () => {
    const { namespacedLocalStorage, currentUser } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const showAllRef = useRef<boolean>(false);
    const [error, setError] = useState<string>(null);
    const [editPermission, setEditPermission] = useState<boolean | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<string>(null);

    const userApiClient = new UserApiClient(namespacedLocalStorage);
    const roleApiClient = new RoleApiClient(namespacedLocalStorage);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const fetchData = async () => {
        if (!isMountRef.current) {
            return;
        }

        setLoading(true);

        const emailUserEnabled = currentUser.authDetails.identityType === EIdentityType.Blocksure;

        try {
            const { roles } = await roleApiClient.listRoles();
            const { users } = await userApiClient.listUsers();

            const dataPolicies = setDataUsers(roles, users, emailUserEnabled);

            if (!isMountRef.current) {
                return;
            }

            getColumns();
            setData(dataPolicies);
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }

            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    const getColumns = () => {
        const isMobile = window.innerWidth < theme.breakpoints.values.md;
        const headerColumnsI18 = getHeaderColumnsI18(editPermission, isMobile, showAllRef, setSelectedUserId);
        setHeaderColumns(headerColumnsI18);
    };

    const handleConfirm = (result: boolean) => {
        if (result) {
            deleteUser(selectedUserId);
        }
        setSelectedUserId(null);
    };

    const deleteUser = async (id: string) => {
        try {
            await userApiClient.deleteUser(id);
            await fetchData();
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }
            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
    };

    useEffect(() => {
        const editPermission = signInService.hasPermission(permissions);
        setEditPermission(editPermission);
    }, []);

    const handleShowAll = () => {
        showAllRef.current = !showAllRef.current;
        getColumns();
    };

    return (
        <PageContainer title={t("userAdministration")}>
            {headerColumns && <div data-testid="columns" />}
            {data && <div data-testid="data" />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("userAdministration")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Grid container spacing={3}>
                        {!error && (
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button color="primary" variant="text" onClick={handleShowAll}>
                                        {showAllRef.current ? t("hideAllDetails") : t("showAllDetails")}
                                    </Button>
                                    {editPermission && (
                                        <Button component={Link} color="primary" endIcon={<Add />} variant="text" to="/user-administration/create">
                                            {t("createUser")}
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        )}
                        {error ? (
                            <Grid item xs={12} md={8} lg={6}>
                                <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                            </Grid>
                        ) : null}
                        {editPermission === null ? null : (
                            <Grid item xs={12}>
                                <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmDialog open={!!selectedUserId} message={`${t("sure2DeleteUser")}?`} onClose={handleConfirm} />
        </PageContainer>
    );
};

export default UserAdministrationPage;
