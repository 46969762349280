import React from 'react';
import YesNoField from './YesNoField';
import {useDefaultTranslation} from '../../common/Translation';

/**
 * Renders the same as a normal object, but has a 'Yes to All' and 'No to all' that will toggle all other booleans in the object.
 */
const BulkToggleField = (props) => {
    const {t} = useDefaultTranslation();
    const yesNoFields = Object.entries(props.uiSchema)
            .filter(([_, it]) => it && it['ui:field'] === 'yesno');
    const allFalse = yesNoFields.filter(([name]) => props.formData[name] === false).length === yesNoFields.length;
    const allTrue = yesNoFields.filter(([name]) => props.formData[name] === true).length === yesNoFields.length;
    const {ObjectField} = props.registry.fields;
    return <div>
        <ObjectField {...props} />
        <div className="clearfix">
            <div className="form-group field field-boolean clearfix">
                <YesNoField
                        schema={{}}
                        disabled={props.disabled}
                        formData={allFalse ? false : allTrue ? true : null}
                        options={{
                            enumOptions: [{
                                label: t('noToAll'),
                                value: false
                            }, {
                                label: t('yesToAll'),
                                value: true
                            }]
                        }}
                        onChange={e => {
                            const updated = {...props.formData};
                            yesNoFields.forEach(([name, _]) => updated[name] = e);
                            props.onChange(updated);
                        }}
                />
            </div>
        </div>
    </div>;
};

export default BulkToggleField;
