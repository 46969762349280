import {Markdown} from './Markdown';
import ToolTipComponent from '@blocksure/blocksure-core/dist/src/components/ToolTipComponent';
import DescriptionField from './field/DescriptionField';
import React from 'react';
import { useDefaultTranslation } from '../common/Translation';
import { changeRequiredMessage } from './SchemaUtils';

const REQUIRED_FIELD_SYMBOL = '*';
export const DefaultTemplate = ({
    id,
    label,
    children,
    errors,
    rawHelp,
    description,
    hidden,
    required,
    displayLabel,
    classNames,
    uiSchema
}) => {
    const {t} = useDefaultTranslation();
    if (hidden) return <div className="hidden">{children}</div>;
    return (
            <div className={classNames}>
                {uiSchema['fm:hr'] && <hr/>}
                {(displayLabel || uiSchema['ui:title']) && label && (
                        <label className="col-form-label" htmlFor={id}>
                            <Markdown value={label + (required ? REQUIRED_FIELD_SYMBOL : '')}/>
                        </label>
                )}
                {uiSchema['fm:tooltip'] && (
                        <ToolTipComponent onClick={() => global.embedded && window.parent.scrollTo(0, 0)}>
                            <Markdown value={uiSchema['fm:tooltip']} useParagraph/>
                        </ToolTipComponent>
                )}
                {displayLabel && description ? <DescriptionField description={<Markdown value={description}/>}/> : null}
                {children.filter(Boolean).map(child => {
                    const props = child.props;
                    const { required } = props;
                    const newProps = {...props, onInvalid: required ? (event => changeRequiredMessage(event, t)) : null}
                  
                    return React.cloneElement(child, newProps)
                })}
                {errors}
                {rawHelp && <Markdown value={rawHelp}/>}
            </div>
    );
};
