import uniqBy from "lodash/uniqBy";
import { hiddenRoles, IRole, isNotHiddenPermissions, IUserDetails, TranslateUtils } from "@surelync/common";

export const setDataProfiles = (roles: IRole[], users: IUserDetails[]) => {
    const result = [];

    users
        .filter((user) => user.firstName && user.lastName)
        .forEach((user) => {
            const userRoles = roles.filter(
                (role) => user.roles.find((userRole) => userRole.id === role.id) && hiddenRoles.indexOf(role.category) === -1
            );

            userRoles.forEach((role, index) => {
                const permissions = role.permissions.filter(isNotHiddenPermissions);
                const translate = TranslateUtils.translateRole(role.name);
                result.push({
                    name: translate.name,
                    id: user.id,
                    description: translate.description || role.description,
                    permissions: uniqBy(permissions, (element) => element.id),
                });
            });
        });

    return result;
};
