import React, {useState} from 'react';
import AddressLookupService from '@blocksure/blocksure-core/dist/src/services/AddressLookupService';
import Autosuggest from 'react-autosuggest';
import PostcodeUtils from '@blocksure/blocksure-core/dist/src/utilities/PostcodeUtils';
import {useDefaultTranslation} from '../../../common/Translation';
import {valueOrEnvVariable} from '../../../shared/util';
import {addAutoFocus, changeRequiredMessage, resetRequiredMessage} from '../../SchemaUtils';
import { getItemForSort, getSortedKey, filterDoubles, sortAddressesNumerically } from './utils';
import '../../../styles/AutoSuggest.css';

const addressLookupService = new AddressLookupService();
const apiToken = valueOrEnvVariable('!!addressLookupApiKey!!', 'bVxa6gNkekmmPUz-mTkkYA10762');
const hiddenAutocompleteValues = ['subzone', 'country'];
const UK_KEY = 'UK';

const isUK = (country) => country === UK_KEY;

function getDefault(schema, it) {
    let propertyDef = schema.properties && schema.properties[it] && schema.properties[it];
    return (propertyDef && propertyDef.default) || undefined;
}

const AddressField = (props) => {
    const {
        name,
        schema,
        uiSchema,
        disabled,
        required,
        formData,
        errorSchema,
        onChange
    } = props;
    const country = uiSchema['fm:country'] || (uiSchema['ui:options'] && uiSchema['ui:options'].country) || UK_KEY;
    const {t} = useDefaultTranslation();
    const [suggestions, setSuggestions] = useState([]);

    const fields = uiSchema['ui:order'] || Object.keys(schema.properties);
    const tokens = fields.map(it => [it, formData[it]]);
    const selectedItem = tokens.find(([field, value]) => field !== 'postcode' && !!value && value !== getDefault(schema, field));

    const values = tokens.map(it => {
        const def = getDefault(schema, it[0]);
        if (def && it[1] === undefined) {
            return def;
        }
        return it[1];
    });

    const [fallback, setFallback] = useState();

    async function fetchAddresses({value: postcode}) {
        if (typeof postcode === 'object') return;
        postcode = addressLookupService.sanitisePostcode(country, postcode);
        setSuggestions([]);
        if (!PostcodeUtils.validate(postcode, country)) return;
        localStorage.removeItem(`com.blocksure.addressLookupCache`);
        const cache = JSON.parse(localStorage.getItem(`com.blocksure.addressLookupCacheV2`) || '{}');
        try {
            if (!cache[postcode]) cache[postcode] = await loadAddresses(country, postcode, apiToken);
            localStorage.setItem(`com.blocksure.addressLookupCacheV2`, JSON.stringify(cache));
            const sortedItem = getItemForSort(cache[postcode], fields);
            const sortedField = getSortedKey(sortedItem, fields);
            setSuggestions(sortAddressesNumerically(sortedField, cache[postcode]) || []);
        } catch (err) {
            console.error(err);
        }
    }

    function select(e, {suggestion}) {
        e.preventDefault();
        const postcode = formData.postcode.toUpperCase();
        console.info(schema.required);
        const suggestionsWithDefaults = {...suggestion}
        fields.forEach((it) => {
            if (suggestionsWithDefaults[it] === undefined) {
                const def = getDefault(schema, it);
                if (def) {
                    suggestionsWithDefaults[it] = getDefault(schema, it);
                }
            }
        })

        // If any of the required fields are null, we the user needs to enter manually.
        if (schema.required && schema.required.find(it => it !== 'postcode' && !suggestion[it])) toggleFallback(e, true);
        onChange({postcode: postcode, ...suggestionsWithDefaults});
    }

    function toggleFallback(e, fallback = true) {
        e.preventDefault();
        setFallback(fallback);
    }

    function clear(e) {
        e.preventDefault();
        setSuggestions([]);
        onChange(Object.keys(formData)
                .reduce((obj, it) => {
                    obj[it] = getDefault(schema, it);
                    return obj;
                }, {}));
    }

    const renderSuggestion = (postcode) => (item) => {
        if (typeof item === 'string') return <b className="text-center">{item}</b>;
        const isUKCountry = isUK(country);
        const values = isUKCountry ?
            fields.filter(field => !hiddenAutocompleteValues.includes(field)).map(it => item[it])
            : fields.map(it => item[it]).concat(postcode);
        return <span>{values
                .filter(x => !!x)
                .filter(filterDoubles)
                .join(', ')}</span>;
    };

    const onChangeHandler = (e, {newValue}) => {
        if (typeof newValue === 'string') onChange({postcode: newValue && newValue.toUpperCase()});
    };

    function getInputProps(postcode) {
        return {
            onChange: onChangeHandler,
            name,
            disabled,
            value: (postcode || '').toUpperCase(),
            placeholder: uiSchema['ui:placeholder'],
            className: 'form-control',
            id: name,
            // Prevents autoComplete (using autoComplete='off' does not). https://stackoverflow.com/a/46452284
            autoComplete: 'new-password',
            required,
            onBlur: (event) => (resetRequiredMessage(event)),
            onInvalid: required ? (event => changeRequiredMessage(event, t)) : null,
        };
    }

    function renderAutocomplete() {
        const {postcode} = formData || {};

        const errors = ((errorSchema.postcode && errorSchema.postcode.__errors) || [])
                .concat((errorSchema.zone && errorSchema.zone.__errors) || [])
                .concat((errorSchema.subzone && errorSchema.subzone.__errors) || [])
                .concat((errorSchema.line3 && errorSchema.line3.__errors) || [])
                .concat((errorSchema.line2 && errorSchema.line2.__errors) || [])
                .concat((errorSchema.line1 && errorSchema.line1.__errors) || []);
        return <div className="row">
            <div className="col-sm-9 col-xs-8">
                <Autosuggest
                        disabled={disabled}
                        required={required}
                        suggestions={suggestions}
                        getSuggestionValue={sug => sug}
                        onSuggestionsFetchRequested={fetchAddresses}
                        alwaysRenderSuggestions
                        renderSuggestion={renderSuggestion(postcode)}
                        onSuggestionSelected={select}
                        inputProps={getInputProps(postcode)}
                />
            </div>

            <div className="col-sm-3 col-xs-4">
                <button disabled={disabled} onClick={toggleFallback} className="btn btn-info btn-block">{t('edit')}</button>
            </div>
            <div>
                <ul className="error-detail bs-callout bs-callout-info">
                    {[...(new Set(errors))]
                            .filter(elem => !!elem)
                            .map((error, index) => <li className="text-danger" key={index}>{error}</li>)}
                </ul>
            </div>
        </div>;
    }

    const ObjectField = props.registry.fields.ObjectField;

    if (fallback) {
        const propsWithAutoFocus = {
            ...props,
            uiSchema: addAutoFocus(fields, props.uiSchema)
        };
        return (
                <div>
                    <ObjectField {...propsWithAutoFocus} onChange={onChange}/>
                    <div className="row">
                        <div className="offset-sm-6 col-sm-3 offset-xs-6 col-6">
                            <button disabled={disabled} onClick={clear}
                                className="btn btn-info btn-block">{t('clear')}</button>
                        </div>
                        <div className="col-sm-3 col-6">
                            <button disabled={disabled} onClick={e => toggleFallback(e, false)}
                                className="btn btn-info btn-block">{t('cancel')}</button>
                        </div>
                    </div>
                </div>
        );
    }
    return (
            <div className="form-group field field-string">
                <label className="col-form-label" htmlFor="root_contactPreference">{schema.title || name}{required ? '*' : ''}</label>
                {selectedItem ?
                        <RenderSelectedItem {...{
                            t,
                            disabled,
                            edit: toggleFallback,
                            values
                        }} /> :
                        renderAutocomplete()}
            </div>
    );
};

const RenderSelectedItem = ({
    t,
    disabled,
    edit,
    values
}) => {
    return (
            <div className="row">
                <div className="col-sm-9 col-xs-8">
                    <div className="form-control address-input">
                        {values.filter(x => !!x)
                                .filter(filterDoubles)
                                .join(', ')}
                    </div>
                </div>
                <div className="col-sm-3 col-xs-4">
                    <button type="button" disabled={disabled} onClick={edit} className="btn btn-info btn-block">{t('edit')}</button>
                </div>
            </div>
    );
};

async function loadAddresses(country, postcode, apiToken) {
    try {
        const newAddresses = JSON.parse(await addressLookupService.doFetch(country, postcode, apiToken));
        return addressLookupService.parse(country, newAddresses);
    } catch (e) {
        console.error(`Unable to load addresses for [${postcode}]`, e);
        return [];
    }
}

export default AddressField;
