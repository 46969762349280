import * as React from "react";
import { useContext } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { PolicyContext } from "../../models/context";
import LinkButton from "@material-ui/core/Link";
import { IClaim, RecordsNotFound } from "@surelync/common";
import { Link, useLocation } from "react-router-dom";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
        scrollable: {
            overflow: "hidden",
            overflowY: "auto",
        },
    })
);

type IProps = Record<string, unknown>;

const Claims: React.FC<IProps> = () => {
    const classes = useStyles();
    const [{ claims }] = useContext(PolicyContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const location = useLocation();
    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname } };
    };

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("claims")}
            </Typography>
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={1} classes={{ root: classes.content }}>
                    <Grid item xs classes={{ root: classes.scrollable }}>
                        {claims && claims.length ? (
                            claims.map((claim: IClaim, index: number) => (
                                <Grid container spacing={1} key={index}>
                                    <Grid item xs={3}>
                                        <LinkButton
                                            data-testid="claim-link"
                                            component={Link}
                                            to={handleRedirect(`/claims/${claim.id}`)}
                                            variant="body1"
                                        >
                                            {claim.reportingRef}
                                        </LinkButton>
                                        <Typography variant="body2">{FormatUtils.renderDate(claim.dateOfIncident)}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                            {t("status")}:{" "}
                                            <Typography component="span" variant="body2">
                                                {claim.status}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1">
                                            {t("category")}:{" "}
                                            <Typography component="span" variant="body2">
                                                {claim.category}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{claim.lossDescription}</Typography>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Grid container justify="center" alignItems="center" style={{ height: "90%" }}>
                                <Grid item>
                                    <RecordsNotFound theme={theme} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Claims;
