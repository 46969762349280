import { IAction } from "@surelync/common";

export const ADD_ENQUIRY_DONE = "ADD_ENQUIRY_DONE";
export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";
export const OPEN_NEW_DIALOG = "OPEN_NEW_DIALOG";

export const initialState = {
    enquiries: null,
    error: "",
    fetching: false,
    newEnquiry: null,
    open: false,
};

export function reducer(state: typeof initialState, action: IAction) {
    switch (action.type) {
        case ADD_ENQUIRY_DONE:
            return { ...initialState, newEnquiry: action.payload };
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false, enquiries: action.payload };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        case OPEN_NEW_DIALOG:
            return { ...state, open: action.payload };
        default:
            return state;
    }
}
