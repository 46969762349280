import React, {useContext, useState} from 'react';
import {Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {ClaimSchemaContext, CurrentStepContext, PendingContext, SubmissionContext} from '../common/context';
import Schema from '../schema/Schema';
import {validate} from '../schema/Validation';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

export const ClaimStep = ({children}) => {
    const [error, setError] = useState(false);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {
        currentStep,
        nextStep
    } = useContext(CurrentStepContext);
    const {
        updateStepSubmission,
        stepSubmission
    } = useContext(SubmissionContext);
    const {
        stepSchema,
        stepUiSchema
    } = useContext(ClaimSchemaContext);

    const onChange = ({formData}) => {
        if (Object.keys(formData).length === 0) return;
        updateStepSubmission(formData);
    };

    const onSubmit = () => {
        pendingAction(async () => {
            setError(null);
            try {
                if (nextStep) nextStep();
            } catch (e) {
                console.error('Error submitting form', e);
                setError(generateErrorMessage(e));
            }
        });
    };

    return (
            <div className="section rjsf offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                {error && <Error>{error}</Error>}
                {/* This key property is essential. Otherwise, when we transition to the next step, the submission for the current step gets reset to the blank values of the next step.*/}
                <Schema key={currentStep} schema={stepSchema} formData={stepSubmission || {}} uiSchema={stepUiSchema} onSubmit={onSubmit}
                        pending={pending} onChange={onChange} validate={validate(stepUiSchema)}>
                    {children}
                </Schema>
            </div>
    );
};
