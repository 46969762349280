import { CurrencyUtils, i18n, localeService } from "@surelync/common";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const getHeaderColumnsI18 = () => [
    {
        Header: () => i18n.t("policyId"),
        accessor: "reportingRef",
        Cell: (row: CellInfo) => row.value,
        size: "medium",
    },
    {
        Header: () => i18n.t("transactionReference"),
        accessor: "transactionId",
        Cell: (row: CellInfo) => row.value,
        size: "medium",
    },
    {
        Header: () => i18n.t("dateCollected"),
        accessor: "paid",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => i18n.t("dateSettled"),
        accessor: "dateSettled",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => i18n.t("dateAvailable"),
        accessor: "available",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => row.value,
        size: "small",
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        Cell: (row: CellInfo) => row.value,
        size: "small",
    },
    {
        Header: () => i18n.t("amountCollected"),
        accessor: "grossAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        Header: () => i18n.t("paymentFee"),
        accessor: "paymentFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
    {
        Header: () => i18n.t("netAmount"),
        accessor: "netAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: "small",
    },
];
