import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import { PolicyApiClient, PolicyholderApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { useStyles } from "./styles";
import { BackButton, BannerClientLogo, PageContainer } from "../components";
import Overview from "./Overview";
import Referrals from "./Referrals";
import GlobalContext from "../context/global-context";
import { MessageBox } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { useTheme } from "@material-ui/core";

const ReferralPage: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const params = useParams<{ id: string }>();
    const [referral, setReferral] = useState<any>();
    const [product, setProduct] = useState<any>();
    const [policyholder, setPolicyholder] = useState<any>();
    const [resolved, setResolved] = useState<boolean>();
    const [fetching, setFetching] = useState<boolean>();
    const [error, setError] = useState<string>(null);

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
    const productApiClient = new ProductApiClient(namespacedLocalStorage);

    const fetchData = async () => {
        let isMount = true;
        setFetching(true);
        setError(null);
        try {
            const referral = await policyApiClient.getPolicyBySequenceId(params.id);
            const product = await productApiClient.getProduct(referral.productId);
            const policyholder = await policyholderApiClient.getPolicyholder(referral.policyholderId);
            const resolved = referral.status === "Quoted";

            if (!isMount) {
                return;
            }

            setReferral(referral);
            setProduct(product);
            setPolicyholder(policyholder);
            setResolved(resolved);
        } catch (error) {
            if (isMount) {
                setError(generateErrorMessage(error));
            }
        }
        if (isMount) {
            setFetching(false);
        }
        return () => {
            isMount = false;
        };
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const lastUpdated = referral ? FormatUtils.renderDate(referral.ts) : "";

    const sections: any = referral && Object.entries(referral.sections);

    return (
        <PageContainer fetching={fetching} title={t("referral")} isBreadcrumb={true}>
            <Container maxWidth="md" classes={{ root: classes.wrapper }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={6} sm={8} md={5}>
                                <Box mb={1}>
                                    <BackButton />
                                </Box>
                                <Typography variant="h5">{t("referral")}</Typography>
                                <Typography color="textPrimary" variant="body2">
                                    {`${t("id")} - ${params.id}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={4} md={2}>
                                <Box textAlign={{ xs: "right", md: "center" }}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} data-testid="last-updated">
                                <Hidden xsUp={!referral}>
                                    <Box textAlign="right">
                                        <Typography variant="body2">
                                            {t("lastUpdated")}: {lastUpdated}
                                        </Typography>
                                    </Box>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} data-testid="message-box">
                        {resolved ? <MessageBox message={t("endorsementshaveBeenApplied")} theme={theme} variant="important" /> : null}
                        {error ? <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} /> : null}
                    </Grid>

                    <Hidden xsUp={!referral || !policyholder || !product}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="stretch" wrap="wrap">
                                <Grid item xs={12} md={8} className={classes.card}>
                                    <Overview referral={referral} product={product} policyholder={policyholder} />
                                </Grid>
                                {sections && sections[0][1].issues && Object.values(sections[0][1].issues)[0] && (
                                    <Grid item xs={12} md={10} className={classes.card}>
                                        <Referrals
                                            referral={referral}
                                            product={product}
                                            resolved={resolved}
                                            onError={setError}
                                            onResolved={setResolved}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </PageContainer>
    );
};

export default ReferralPage;
