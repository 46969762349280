import React from 'react';
import './Menu.css';

// Shows items one after another, but optionally allows you to pass some of the items again in the 'breakout' list which will cause
// them to display beside the 'menu' on larger screens.
// Each item should have a unique 'data-menukey' prop.
export const Menu = ({
    items,
    breakout = [],
    className,
    fullWidth = false,
}) => (
        <div className={`row menu${fullWidth ? ' menuFullWidth' : ''}`}>
            <div className={`${breakout.length === 0 ? `${className} col-md-8 offset-md-2` : 'col-6'}`}>
                {items.map(item => {
                    if (breakout.indexOf(item) > -1) {
                        return <div key={item.props['data-menukey']} className="d-sm-block d-md-none">{item}</div>;
                    } else {
                        return <div key={item.props['data-menukey']}>{item}</div>;
                    }
                })}
            </div>
            <div className="col-6">
                {breakout.map(item => {
                    return <div key={item.props['data-menukey']} className="d-sm-none d-md-block">{item}</div>;
                })}
            </div>
        </div>
);
