import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Link as LinkButton, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { BannerClientLogo, PageContainer } from "../components";
import buildJson from "../build.json";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { AssetsService } from "@surelync/common";

const getYear = () => {
    const defaultDate = FormatUtils.parseDate("2021-01-01");
    const today = FormatUtils.today();
    return FormatUtils.renderFormatDate(defaultDate < today ? today : defaultDate, "yyyy");
};

const About: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const envJSON = (window as any).env;

    return (
        <PageContainer title={t("aboutSurelync")}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box textAlign="center" pb={2}>
                        <BannerClientLogo />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="h5">
                        {t("aboutSurelync")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        {t("utiliseBlockchainTech")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <img className={classes.logo} src={`${AssetsService.basePath()}images/logo-blue.png`} alt="surelync logo" />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        <LinkButton
                            target="_blank"
                            href="https://www.blocksure.com/?utm_source=surelync&utm_medium=navigation&utm_campaign=surelync_about_screen"
                        >
                            www.blocksure.com
                        </LinkButton>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center" gutterBottom data-testid="version">
                        {t("version")}: {buildJson.version}
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        {t("commit")}: {buildJson.commit}
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        {t("buildDate")}: {FormatUtils.renderFormatDate(buildJson.buildTime, "FFF") || "-"}
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        {t("environment")}: {envJSON?.env}:{envJSON?.location}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center" gutterBottom>
                        © 2017 - {getYear()} &nbsp;Blocksure Limited
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        {t("blocksureRegisteredCompanyDetails")}
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        {t("blocksureRegisteredTrademark")}
                    </Typography>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default About;
