import { IAction } from "@surelync/common";

export const SAVE_BLOB_DONE = "SAVE_BLOB_DONE";
export const SAVE_BLOB_FAILED = "SAVE_BLOB_FAILED";
export const SAVE_BLOB_START = "SAVE_BLOB_START";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_CROPPER = "SET_CROPPER";
export const SET_IMAGE = "SET_IMAGE";

export interface ClientLogoState {
    cropper: any;
    editable: boolean;
    image: string;
    uploading: boolean;
}

export const initialState: ClientLogoState = {
    cropper: null,
    editable: false, // user having permission BRAND_EDIT
    image: null,
    uploading: false,
};

export function reducer(state: ClientLogoState, action: IAction): ClientLogoState {
    switch (action.type) {
        case SAVE_BLOB_START:
            return { ...state, uploading: true };
        case SAVE_BLOB_DONE:
            return { ...state, uploading: false, image: null };
        case SAVE_BLOB_FAILED:
            return { ...state, uploading: false };
        case SET_EDIT_MODE:
            return { ...state, editable: action.payload };
        case SET_IMAGE:
            return { ...state, image: action.payload };
        case SET_CROPPER:
            return { ...state, cropper: action.payload };

        default:
            return state;
    }
}
