import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { TableContainer, ITableData } from "@surelync/common";
import { setDataClaims } from "./helper";
import { ContactContext } from "../../models";
import { getHeaderColumnsI18 } from "./columns.config";

const TabClaims: React.FC = () => {
    const theme = useTheme();
    const [{ claims, policies, policyholder }] = useContext(ContactContext);

    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [total, setTotal] = useState<number | null>(null);

    const location = useLocation();
    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname } };
    };

    const fetchData = (rowsPerPage: number, page: number) => {
        const headerColumnsI18 = getHeaderColumnsI18(handleRedirect);
        const offset = page * rowsPerPage;
        const dataPolicies = setDataClaims(claims.slice(offset, offset + rowsPerPage), { [policyholder.id]: policyholder }, policies);

        setData(dataPolicies);
        setHeaderColumns(headerColumnsI18);
        setTotal(claims.length);
    };

    return <TableContainer theme={theme} columns={headerColumns} data={data} total={total} fetchData={fetchData} />;
};

export default TabClaims;
