import * as React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LinkButton from "@material-ui/core/Link";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IBackButtonProps {
    title?: string; // can be Policyholder full name
}

export const BackButton: React.FC<IBackButtonProps> = ({ title }) => {
    const location = useLocation<{ from: string; backBtnlabel: string; redirectTo: string }>();
    const { t } = useTranslation();

    const renderBackButton = () => {
        const backBtnlabel = location?.state?.backBtnlabel;

        if (backBtnlabel) {
            return backBtnlabel;
        } else if (location?.state?.from.startsWith("/contacts/")) {
            return `${t("backTo")} ${title}`;
        } else if (location?.pathname.startsWith("/referral/")) {
            return t("referrals");
        } else if (location?.pathname.startsWith("/claims/")) {
            return t("claims");
        } else if (location?.pathname.startsWith("/statement-details/")) {
            return t("drawdowns");
        } else {
            return t("back");
        }
    };

    const redirectTo = () => {
        // console.log('redirectTo', location?.state?.redirectTo, location?.state?.from)
        const redirectTo = location?.state?.redirectTo || location?.state?.from;

        // open direct link
        if (redirectTo) {
            return redirectTo;
        } else if (location.pathname.includes("/policies")) {
            return "/policies";
        } else if (location.pathname.includes("/referral")) {
            return "/referrals";
        } else if (location.pathname.includes("/claims")) {
            return "/claims";
        } else if (location.pathname.includes("/contacts")) {
            return "/contacts";
        } else if (location.pathname.includes("/statement-details/")) {
            return "/statements";
        } else {
            return "";
        }
    };

    return (
        <LinkButton component={Link} to={redirectTo()} color="primary" variant="body2">
            <ArrowBackIosIcon fontSize="small" style={{ verticalAlign: "middle" }} />
            {renderBackButton()}
        </LinkButton>
    );
};
