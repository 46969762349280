import React, {useContext} from 'react';
import {PolicyListContext} from '../common/context';
import {Menu} from '../shared/Menu';
import {Screen} from '../shared/Screen';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const AmendMenu = () => {
    const {t} = useDefaultTranslation();
    const {policies} = useContext(PolicyListContext);
    const items = [];
    const { path } = useRouteMatch();
    const { id } = useParams();
    const newPath = convertToNewPath(path, id);

    items.push(<Link data-menukey="email" to={`${newPath}/email`} className="btn btn-lg btn-block btn-primary gap">{t('amendEmailAddress')}</Link>);

    const finalSequencePolicies = policies && policies.filter(it => it.sequence === it.version);
    if (finalSequencePolicies && finalSequencePolicies.length > 0) {
        const to = finalSequencePolicies && finalSequencePolicies.length > 1 ? `${newPath}/amend/policy` : `${newPath}/amend/${finalSequencePolicies[0].sequenceId}`;
        items.push(<Link data-menukey="policy" to={to} className="btn btn-lg btn-block btn-primary gap">{t('amendPolicyDetails')}</Link>);
    }
    
    items.push(<hr data-menukey="separator"/>);
    items.push(<Link data-menukey="Back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary">{t('back')}</Link>);
    return (
            <Screen>
                <Menu items={items}/>
            </Screen>
    );
};
