import React from 'react';
import {PolicyListContext} from '../common/context';
import {Menu} from '../shared/Menu';
import {Screen} from '../shared/Screen';
import {PolicyList} from '../shared/PolicyList';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import Loading from '../widgets/Loading';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

export const PolicyMenu = ({match}) => {
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    return (
            <Screen>
                <PolicyListContext.Consumer>{({policies}) => {
                    const items = [];
                    if (policies) {
                        if (policies.length > 0) {
                            const to = match.path.includes('amend') ? '/amend/' : '/policy/';
                            if (policies.length > 1) {
                                items.push(<h4 data-menukey="outstanding-title">{t('latestPolicy')}:</h4>);
                                items.push(<PolicyList data-menukey="policies" policies={[policies[0]]} linkFunc={it => `${newPath}${to}${it.sequenceId}`}/>);
                                items.push(<><br/><h4 data-menukey="outstanding-title">{t('otherPolicies')}:</h4></>);
                                items.push(<PolicyList data-menukey="policies" policies={[...policies.slice(1)]} linkFunc={it => `${newPath}${to}${it.sequenceId}`}/>);
                            } else {
                                items.push(<PolicyList data-menukey="policies" policies={policies} linkFunc={it => `${newPath}${to}${it.sequenceId}`}/>);
                            }
                        } else {
                            items.push(<h2 data-menukey="warning" className="text-center"><label
                                    className="badge badge-warning">{t('youHaveNoPolicies')}</label></h2>);
                        }
                    } else {
                        items.push(<div data-menukey="loading" className="text-center"><Loading/></div>);
                    }
                    items.push(<hr data-menukey="separator"/>);
                    items.push(<Link data-menukey="Back" to={`${newPath}/`} className="btn btn-lg btn-block btn-secondary">{t('back')}</Link>);
                    return <Menu items={items}/>;
                }}</PolicyListContext.Consumer>
            </Screen>
    );
};
