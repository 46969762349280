import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {Screen} from '../shared/Screen';
import {AuthContext, PendingContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {convertToNewPath} from "../base";

export const AmendEmail = () => (
        <Screen>
            <AuthContext.Consumer>{({
                auth,
                policyholder
            }) => (
                    <AmendEmailScreen auth={auth} policyholder={policyholder}/>
            )}</AuthContext.Consumer>
        </Screen>
);

const AmendEmailScreen = ({
    auth,
    policyholder
}) => {
    const history = useHistory();
    const {t} = useDefaultTranslation();
    const {userExists} = useContext(AuthContext);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const [error, setError] = useState(false);
    const policyholderId = policyholder && policyholder.id;
    const [updatedEmail, setEmail] = useState(sessionStorage.getItem('com.blocksure.updatedEmail'));

    const onChange = (updatedEmail) => {
        setError(null);
        sessionStorage.setItem('com.blocksure.updatedEmail', updatedEmail);
        setEmail(updatedEmail);
    };

    const saveChanges = async () => {
        pendingAction(async () => {
            try {
                const exists = await userExists(updatedEmail);
                if (exists) setError(t('emailExists'));
                else history.push(`${newPath}/email/${policyholderId}`);
            } catch (e) {
                console.error(`Invalid email [${updatedEmail}]].`, e);
                setError(generateErrorMessage(e, t('invalidEmail')));
            }
        });
    };

    const resetChange = () => {
        sessionStorage.removeItem('com.blocksure.updatedEmail');
    };

    return (
            <div className="menu">
                <div className="offset-md-2 col-md-8">
                    {error && <Error>{error}</Error>}
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <label data-menukey="Existing" htmlFor="existing">{t('existingEmail')}</label>
                        </div>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <input data-menukey="Email" className="form-control" type="email" id="existing" name="existing" disabled
                                   value={auth.username}/>
                        </div>
                    </div>
                    <div className="row gap">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <label data-menukey="Label" htmlFor="email">{t('enterEmail')}</label>
                        </div>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <input data-menukey="Input" className="form-control" type="email" id="email" name="email"
                                   onChange={e => onChange(e.target.value)} value={updatedEmail ? updatedEmail : ''}/>
                        </div>
                    </div>
                    <hr data-menukey="separator"/>
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <Button data-menukey="Save" className="btn btn-primary gap float-right" disabled={!updatedEmail} pending={pending}
                                    onClick={saveChanges}>{t('save')}</Button>
                            <Link data-menukey="Cancel" role="button" className="btn btn-primary gap float-right" to={`${newPath}/`} disabled={pending}
                                  onClick={resetChange}>{t('cancel')}</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
};
