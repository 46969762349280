import "regenerator-runtime/runtime";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { ContactContext, EDIT_CONTACT_MODE, FETCH_ERROR } from "../../models";
import { PolicyholderApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { FETCH_DONE } from "../../models/action";
import { Form } from "../../../components";
import {
    EPolicyStatus,
    IProduct,
    MessageBox,
    policyholderSchema,
    PolicyholderUtils,
    validatePhoneNumberFn
} from "@surelync/common";
import InputValidation from "@blocksure/blocksure-core/dist/src/utilities/InputValidation";
import { IFormData } from "./request.model";
import GlobalContext from "../../../context/global-context";
import { Box, useTheme } from "@material-ui/core";

type IProps = Record<string, unknown>;

const TabIdentityAndAddress: React.FC<IProps> = () => {
    const { namespacedLocalStorage, products } = useContext(GlobalContext);
    const [{ policyholder, policies, universalPolicyholderSchema }, dispatch] = useContext(ContactContext);
    const [formData, setFormData] = useState<IFormData>(null);
    const [countryCode, setCountryCode] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [universalSchema, setUniversalSchema] = useState(null);
    const [universalUISchema, setUniversalUISchema] = useState(null);
    const { t } = useTranslation();
    const theme = useTheme();
    const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
    const productApiClient = new ProductApiClient(namespacedLocalStorage);

    const policy = policies[0];
    const product = policy ? products[policy.productId] : ({} as IProduct);
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const attachmentScheme = product.hiddenAttachments ? product.hiddenAttachments.find((a) => a.name === "submissionSchema.json") : null;
                const submissionSchema = attachmentScheme ? await productApiClient.getAttachment(product.id, attachmentScheme.location, true) : {};
                const { properties } = submissionSchema;
                const countryCode =
                    properties?.yourDetails?.["fm:country"] ||
                    properties?.moreAbout?.["fm:country"] ||
                    properties?.aboutYou?.["fm:country"] ||
                    policyholder.shared.schemaCountry;

                if (!isMountRef.current) {
                    return;
                }

                const { schema, uiSchema } = policyholderSchema(universalPolicyholderSchema, countryCode);

                setCountryCode(countryCode);
                setUniversalSchema(schema);
                setUniversalUISchema(uiSchema);
            } catch (error) {
                if (!isMountRef.current) {
                    return;
                }

                console.error(error);
                dispatch({ type: FETCH_ERROR, payload: generateErrorMessage(error) });
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!universalSchema) {
            return;
        }
        initForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universalSchema, policyholder.version]);

    const initForm = () => {
        const keys = Object.keys(universalSchema.properties);
        const formData = {} as IFormData;
        keys.forEach((key) => (formData[key] = { ...policyholder.shared[key] }));

        if (!isMountRef.current) {
            return;
        }
        
        formData.contact.primaryPhoneNumber = PolicyholderUtils._renderPhoneNumber(formData.contact.primaryPhoneNumber);
        
        setFormData(formData);
    };

    const validate = useCallback(
        (formData: IFormData, errors) => {
            const keys = Object.keys(formData);
            keys.forEach((key) => {
                const requires: string[] = universalSchema.properties[key].required;
                requires.forEach((field) => {
                    if (!formData[key][field]) {
                        errors[key][field].addError(t("required"));
                    }
                });
            });

            if (formData.contact.primaryPhoneNumber !== undefined && !errors.contact.primaryPhoneNumber.__errors.length) {
                const validateFn = validatePhoneNumberFn.default;
                if (!validateFn(formData.contact.primaryPhoneNumber)) {
                    errors.contact.primaryPhoneNumber.addError(t("invalidPhone"));
                }
            }

            if (
                formData.contact.primaryEmailAddress !== undefined &&
                !InputValidation.isValidEmail(formData.contact.primaryEmailAddress) &&
                !errors.contact.primaryEmailAddress.__errors.length
            ) {
                errors.contact.primaryEmailAddress.addError(t("invalidEmail"));
            }

            return errors;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [countryCode, universalSchema]
    );

    const onSubmit = useCallback(
        async ({ formData }) => {
            if (isSubmitting || !isMountRef.current) {
                return;
            }

            const params = {
                ...policyholder,
                primaryEmailAddress: formData.contact.primaryEmailAddress,
                primaryPhoneNumber: formData.contact.primaryPhoneNumber,
                shared: {
                    ...policyholder.shared,
                    ...formData,
                },
            };

            setFormData(formData);
            dispatch({ type: FETCH_ERROR, payload: null });
            setSubmitting(true);

            try {
                const response = await policyholderApiClient.updatePolicyholder(policyholder.id, params);

                if (!isMountRef.current) {
                    return;
                }

                dispatch({ type: FETCH_DONE, payload: { policyholder: response } });
                dispatch({ type: EDIT_CONTACT_MODE, payload: false });
            } catch (err) {
                if (!isMountRef.current) {
                    return;
                }

                dispatch({ type: FETCH_ERROR, payload: err.message || err });
            }

            setSubmitting(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSubmitting]
    );

    const isEditable = useMemo(() => {
        if (!policies || !policies.length) {
            return false;
        }
        const quote = policies.find((p) => p.status === EPolicyStatus.Quoted);
        const bound = policies.find((p) => p.status === EPolicyStatus.Bound);
        return quote && !bound;
    }, [policies]);

    const renderInstruction = (
        <Box py={5}>
            <MessageBox message={t("personalUpdate")} theme={theme} variant="important" />
        </Box>
    );

    if (!universalSchema) {
        return null;
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6} lg={5} xl={4}>
                <Form
                    {...(isEditable
                        ? universalUISchema
                        : {
                            uiSchema: { ...universalUISchema, "ui:readonly": true },
                            children: renderInstruction,
                        })}
                    data-testid="form"
                    schema={universalSchema}
                    formData={formData}
                    disabled={isSubmitting}
                    validate={validate}
                    onSubmit={onSubmit}
                />
            </Grid>
        </Grid>
    );
};

export default TabIdentityAndAddress;
