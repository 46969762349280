import { IAuditEvent } from "@surelync/common";

export const prepareData = (data: IAuditEvent[]) => {
    return data.map((item) => ({
        dateCreated: item.ts,
        email: item.email,
        eventType: item.eventType,
        description: item.detail,
        ip: item.ip,
        id: item.id,
        userId: item.userId,
    }));
};
