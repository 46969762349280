import React from "react";
import { Link } from "react-router-dom";
import { CurrencyUtils, i18n, localeService } from "@surelync/common";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import "./styles.css";

export const getHeaderColumnsI18 = (isMobile: boolean, onRedirect: (url: string) => any, isQuote: boolean) => [
    {
        Header: () => (isQuote ? i18n.t("quoteNo") : i18n.t("policyNo")),
        accessor: "policyNoAndQuoteNo",
        Cell: (row: CellInfo) => {
            return (
                <>
                    <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.id}`)} variant="caption">
                        {isQuote ? row.row.original.quoteNo : row.row.original.policyNo}
                    </LinkButton>
                </>
            );
        },
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: isQuote ? [i18n.t("quoteNo")] : [i18n.t("policyNo")],
            values: isQuote ? ["quoteNo"] : ["policyNo"],
        },
    },
    {
        Header: () => i18n.t("email"),
        accessor: "email",
        size: isMobile ? "medium" : "large",
        toCSV: {
            headers: [i18n.t("email")],
        },
    },
    {
        Header: () => i18n.t("policyholder"),
        accessor: "policyholder",
        Cell: (row: CellInfo) => {
            return (
                <LinkButton component={Link} to={onRedirect(`/contacts/${row.row.original.policyholderId}`)} variant="body1">
                    {row.value}
                </LinkButton>
            );
        },
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("policyholder")],
        },
    },
    {
        Header: () => i18n.t("policies"),
        accessor: "policies",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("policies")],
        },
    },
    {
        Header: () => i18n.t("effective"),
        accessor: "effective",
        size: "small",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        toCSV: {
            headers: [i18n.t("effective")],
            format: (row) => FormatUtils.renderDate(row["effective"]),
        },
    },
    {
        Header: () => i18n.t("renew"),
        accessor: "renew",
        size: "small",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => {
            const value = FormatUtils.renderDate(row.value);
            if (row.row.original.upcoming) {
                return (
                    <div className="upcoming-col" title={i18n.t("renewalDueSoon")}>
                        {value}
                    </div>
                );
            }
            return value;
        },
        toCSV: {
            headers: [i18n.t("renew")],
            format: (row) => FormatUtils.renderDate(row["renew"]),
        },
    },
    {
        Header: () => i18n.t("product"),
        accessor: "product",
        size: "small",
        toCSV: {
            headers: [i18n.t("product")],
        },
    },
    {
        Header: () => i18n.t("mga"),
        accessor: "mga",
        size: "small",
        toCSV: {
            headers: [i18n.t("mga")],
        },
    },
    {
        Header: () => i18n.t("insurer"),
        accessor: "insurer",
        size: isMobile ? "small" : "medium",
        Cell: (row: CellInfo) =>
            row.row.original.isMultiSections ? (
                <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.id}`)} variant="body1">
                    {i18n.t("multiSection")}
                </LinkButton>
            ) : (
                row.value
            ),
        toCSV: {
            headers: [i18n.t("insurer")],
            format: (row) => (row.isMultiSections ? i18n.t("multiSection") : row["insurer"]),
        },
    },
    {
        Header: () => i18n.t("agent"),
        accessor: "agent",
        size: "small",
        toCSV: {
            headers: [i18n.t("agent")],
        },
    },
    {
        Header: () => i18n.t("grossBrokerage"),
        accessor: "grossBrokerage",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("grossBrokerage")],
            format: (row) => CurrencyUtils._renderCurrency(row["grossBrokerage"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("mgaCommission"),
        accessor: "mgaCommission",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("mgaCommission")],
            format: (row) => CurrencyUtils._renderCurrency(row["mgaCommission"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("totalDeductions"),
        accessor: "totalDeductions",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("totalDeductions")],
            format: (row) => CurrencyUtils._renderCurrency(row["totalDeductions"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("premium"),
        accessor: "premium",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("premium")],
            format: (row) => CurrencyUtils._renderCurrency(row["premium"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("accountBalance"),
        accessor: "accountBalance",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("accountBalance")],
            format: (row) => CurrencyUtils._renderCurrency(row["accountBalance"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("lastUpdate"),
        accessor: "lastUpdate",
        size: isMobile ? "small" : "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDate(row.value),
        toCSV: {
            headers: [i18n.t("lastUpdate")],
            format: (row) => FormatUtils.renderDate(row["lastUpdate"]),
        },
    },

    // additional columns
    {
        Header: () => i18n.t("effectiveFromDate"),
        accessor: "effectiveFromDate",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        toCSV: {
            headers: [i18n.t("effectiveFromDate")],
            format: (row) => FormatUtils.renderDateTime(row["effectiveFromDate"]),
        },
    },
    {
        Header: () => i18n.t("effectiveToDate"),
        accessor: "effectiveToDate",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        toCSV: {
            headers: [i18n.t("effectiveToDate")],
            format: (row) => FormatUtils.renderDateTime(row["effectiveToDate"]),
        },
    },
    {
        Header: () => i18n.t("issueDate"),
        accessor: "issueDate",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        toCSV: {
            headers: [i18n.t("issueDate")],
            format: (row) => FormatUtils.renderDateTime(row["issueDate"]),
        },
    },
    {
        Header: () => i18n.t("policyType"),
        accessor: "policyType",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("policyType")],
        },
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        size: "small",
        toCSV: {
            headers: [i18n.t("currency")],
        },
    },
    {
        Header: () => i18n.t("valueInsured"),
        accessor: "valueInsured",
        sortType: "basic",
        Cell: (row: CellInfo) =>
            row.row.original.isMultiSections ? (
                <LinkButton component={Link} to={onRedirect(`/policies/${row.row.original.id}`)} variant="body1">
                    {i18n.t("multiSection")}
                </LinkButton>
            ) : (
                CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency)
            ),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("valueInsured")],
            format: (row) => CurrencyUtils._renderCurrency(row["valueInsured"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("expiry"),
        accessor: "expiry",
        size: isMobile ? "small" : "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
        toCSV: {
            headers: [i18n.t("expiry")],
            format: (row) => FormatUtils.renderDateTime(row["expiry"]),
        },
    },
    {
        Header: () => i18n.t("ipt"),
        accessor: "ipt",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("ipt")],
            format: (row) => CurrencyUtils._renderCurrency(row["ipt"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("totalPayableByPolicyholder"),
        accessor: "totalPayableByPolicyholder",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        size: isMobile ? "medium" : "large",
        toCSV: {
            headers: [i18n.t("totalPayableByPolicyholder")],
            format: (row) => CurrencyUtils._renderCurrency(row["totalPayableByPolicyholder"], localeService.locale, row.currency),
        },
    },

    // BROKER ONLY
    {
        Header: () => `${i18n.t("brokerage")} %`,
        accessor: "percentAmount",
        sortType: "basic",
        Cell: (row: CellInfo) => row.value,
        toCSV: {
            headers: [`${i18n.t("brokerage")} %`],
        },
    },
    {
        Header: () => i18n.t("brokerage"),
        accessor: "brokerage",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("brokerage")],
            format: (row) => CurrencyUtils._renderCurrency(row["brokerage"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("brokerPlatformFee"),
        accessor: "brokerPlatformFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("brokerPlatformFee")],
            format: (row) => CurrencyUtils._renderCurrency(row["brokerPlatformFee"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("adminFee"),
        accessor: "adminFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("adminFee")],
            format: (row) => CurrencyUtils._renderCurrency(row["adminFee"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("adminPlatform"),
        accessor: "adminPlatform",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("adminPlatform")],
            format: (row) => CurrencyUtils._renderCurrency(row["adminPlatform"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("brokerageLessBroker"),
        accessor: "brokerageLessBroker",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("brokerageLessBroker")],
            format: (row) => CurrencyUtils._renderCurrency(row["brokerageLessBroker"], localeService.locale, row.currency),
        },
    },

    // INSURER ONLY
    {
        Header: () => `${i18n.t("totalDeductions")} %`,
        accessor: "totalDeductionsPercent",
        sortType: "basic",
        Cell: (row: CellInfo) => `${row.value}%`,
        toCSV: {
            headers: [`${i18n.t("totalDeductions")} %`],
        },
    },
    {
        Header: () => i18n.t("totalDeductions"),
        accessor: "totalDeductions",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("totalDeductions")],
            format: (row) => CurrencyUtils._renderCurrency(row["totalDeductions"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("netPremium"),
        accessor: "netPremium",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("netPremium")],
            format: (row) => CurrencyUtils._renderCurrency(row["netPremium"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("insurerPlatformFee"),
        accessor: "insurerPlatformFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("insurerPlatformFee")],
            format: (row) => CurrencyUtils._renderCurrency(row["insurerPlatformFee"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("netPremiumLessFeePlusIPT"),
        accessor: "netPremiumLessFeePlusIPT",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("netPremiumLessFeePlusIPT")],
            format: (row) => CurrencyUtils._renderCurrency(row["netPremiumLessFeePlusIPT"], localeService.locale, row.currency),
        },
    },

    // MGA ONLY
    {
        Header: () => `${i18n.t("mgaCommission")} %`,
        accessor: "mgaCommissionPercent",
        sortType: "basic",
        Cell: (row: CellInfo) => row.value,
        toCSV: {
            headers: [`${i18n.t("mgaCommission")} %`],
        },
    },
    {
        Header: () => i18n.t("mgaCommission"),
        accessor: "mgaCommission",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("mgaCommission")],
            format: (row) => CurrencyUtils._renderCurrency(row["mgaCommission"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("mgaLessPlatform"),
        accessor: "mgaLessPlatform",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("mgaLessPlatform")],
            format: (row) => CurrencyUtils._renderCurrency(row["mgaLessPlatform"], localeService.locale, row.currency),
        },
    },
    {
        Header: () => i18n.t("mgaPlatformFee"),
        accessor: "mgaPlatformFee",
        sortType: "basic",
        Cell: (row: CellInfo) => CurrencyUtils._renderCurrency(row.value, localeService.locale, row.row.original.currency),
        toCSV: {
            headers: [i18n.t("mgaPlatformFee")],
            format: (row) => CurrencyUtils._renderCurrency(row["mgaPlatformFee"], localeService.locale, row.currency),
        },
    },
];
