import { CurrencyUtils, IPolicy, localeService } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const setDataPolicyPayment = (data: IPolicy[]) => {
    return data.map((item) => ({
        dateDue: FormatUtils.renderDateTime(item.due),
        dateCollected: item.paid ? FormatUtils.renderDateTime(item.paid) : "-",
        status: item.status,
        currency: item.currency,
        totalAmount: CurrencyUtils._renderCurrency(item.totalAmount, localeService.locale, item.currency),
        transactionCreationDate: FormatUtils.renderDateTime(item.creation),
    }));
};
