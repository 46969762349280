export function translate(t, schema) {
    translateProperties(t, schema.definitions, schema, schema.properties);
    return schema;
}

function mergeDef(defs, property) {
    if (!property.$ref) return property;
    let def = defs[property.$ref.replace(/^#\/definitions\//, '')];
    let merged = {
        ...def, ...{
            ...property,
            $ref: undefined
        }
    };
    return mergeDef(defs, merged);
}

function translateProperties(t, defs, schema, properties) {
    if (schema['fm:validation']) schema['fm:validation'].forEach(validation => {
        validation.message = translateOrDefault(schema, validation.message, t, `validation.${validation.name}`);
    });
    Object.keys(properties)
            .forEach(key => {
                let property = properties[key];
                if (property && typeof property !== 'object') return;
                const merged = mergeDef(defs, property);
                translatePropertyDef(t, defs, merged, property);
            });
}

function translatePropertyDef(t, defs, merged, property) {
    const enumNames = merged['enumNames'] || merged['enum'];
    if (enumNames) property['enumNames'] = enumNames.map((_, i) => translateOrDefault(property, enumNames[i], t, `enumNames.${i}`));
    setIfNotUndefined(merged, property, translateOrDefault(property, merged.title, t, 'title'), 'title');
    setIfNotUndefined(merged, property, translateOrDefault(property, merged.description, t, 'description'), 'description');
    setIfNotUndefined(merged, property, translateOrDefault(property, property['ui:help'], t, 'help'), 'ui:help');
    setIfNotUndefined(merged, property, translateOrDefault(property, property['ui:placeholder'], t, 'placeholder'), 'ui:placeholder');
    setIfNotUndefined(merged, property, translateOrDefault(property, property['ui:tooltip'], t, 'tooltip'), 'fm:tooltip');
    translateObjectProp(merged, t, defs, property);

    if (merged.dependencies) {
        Object.entries(merged.dependencies)
                .forEach(([property, {oneOf}]) => {
                    if (oneOf) oneOf.forEach(it => translateObjectProp(it, t, defs, it));
                });
    }
}

function translateObjectProp(merged, t, defs, property) {
    if (merged.properties) translateProperties(t, defs, property, merged.properties);
    if (merged.items && merged.items.properties) translateProperties(t, defs, merged.items, merged.items.properties);
}

function setIfNotUndefined(propertyIncludingDefs, property, value, key) {
    // If the property doesn't already have this value, we bail.
    if (!propertyIncludingDefs[key]) return;
    if (!Object.is(value, undefined)) {
        property[key] = value;
    }
}

function translateOrDefault(schema, defaultValue, t, key) {
    let result = schema[`ui:${key}`] || schema[`fm:${key}`] || defaultValue;
    if (schema && schema['fm:path']) {
        let k = `${schema['fm:path']}.tx-${key}`;
        // Indexers with brackets (array[2]) don't work in i18n, but using them as a property seem to be fine (array.2) .
        k = k.replace(/\[([0-9+])]/, '.$1');
        result = t(k, {defaultValue: result});
        if (result === k) result = undefined;
    }
    return result;
}
