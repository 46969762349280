export enum ESearchType {
    Global = "Global",
    All = "All",
    Individual = "Individual",
    Company = "Company",
    Quote = "Quote",
    Policy = "Policy",
    Claim = "Claim",
    Referral = "Referral",
    Decline = "Decline",
    Cancellation = "Cancellation",
}
