import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import { ClaimApiClient, PolicyApiClient, PolicyholderApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { generateCsvData, ITableData, MessageBox, TableContainer, TableOptions } from "@surelync/common";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { defaultColumns, setDataClaims } from "./helper";
import { useStyles } from "./styles";
import GlobalContext from "../context/global-context";
import { BannerClientLogo, PageContainer } from "../components";
import CustomiseFlyout from "../components/flyouts/CustomiseFlyout";
import { getHeaderColumnsI18 } from "./columns.config";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import Box from "@material-ui/core/Box";

const tableName = "claim";

const ClaimsPage: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const csvLinkEl = useRef(null);
    const { displayedTableColumns, namespacedLocalStorage, products, setOpenCustomiseFlyout, setDisplayedTableColumns } = useContext(GlobalContext);

    const [globalSearch, setGlobalSearch] = useState<string | null>(null);
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [total, setTotal] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);
    const [blobLoading, setBlobLoading] = useState<boolean>(true);
    const [csvData, setCsvData] = useState<any>([]);

    const claimApiClient = new ClaimApiClient(namespacedLocalStorage);
    const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);

    const location = useLocation();
    const isMountRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountRef.current = false;
        };
    }, []);

    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: location.pathname, backBtnlabel: t("claims") } };
    };

    const handleDownloadCSV = () => {
        if (!data) {
            return;
        }

        const { sortIndexes } = data as any;
        const sortedData = sortIndexes ? sortIndexes.map((i) => data[i]) : data;

        setCsvData(generateCsvData(headerColumns, sortedData));

        setTimeout(() => csvLinkEl.current?.link.click(), 100);
    };

    const isMobile = window.innerWidth < theme.breakpoints.values.md;

    const getColumns = () => {
        const allColumns = getHeaderColumnsI18(isMobile, handleRedirect);

        let resultAfterFiltering = [];

        if (displayedTableColumns) {
            displayedTableColumns.forEach((column) => {
                const isDisplayed = allColumns.find((disabledColumn) => disabledColumn.accessor === column.id);
                if (isDisplayed) {
                    resultAfterFiltering.push(isDisplayed);
                }
            });
        } else {
            resultAfterFiltering = allColumns;
        }

        return resultAfterFiltering;
    };

    const fetchBlob = async () => {
        if (!isMountRef.current) {
            return;
        }

        setBlobLoading(true);
        const result = [];

        try {
            const blobs = await blobApiClient.retrievedTag(`${tableName}-column-preferences`);

            blobs[tableName].forEach((blob) => {
                const key = Object.keys(blob)[0];
                const column = defaultColumns.find((defaultColumn) => defaultColumn.id === key);
                if (column) {
                    result.push(column);
                }
            });

            if (!isMountRef.current) {
                return;
            }

            setDisplayedTableColumns(result);
            setBlobLoading(false);
        } catch (error) {
            setDisplayedTableColumns(defaultColumns.filter((c) => !c.isAdditional));
            setBlobLoading(false);
        }
    };

    const fetchData = async (rowsPerPage: number, page: number, isRefresh: boolean) => {
        const load = ["load.product", "load.breakdown"];

        if (!isMountRef.current) {
            return;
        }

        if (!isRefresh) {
            setLoading(true);
            setError(null);
            setData(null);
        }

        try {
            const headerColumnsI18 = getColumns();
            const claims = await claimApiClient.search({}, rowsPerPage, page);

            // Empty response
            if (!claims.items.length) {
                setData([]);
                setHeaderColumns(headerColumnsI18);
                setTotal(0);
                setLoading(false);
                return;
            }

            const policyIds = claims.items.map((item) => item.policyId);
            const policies = await policyApiClient.search({ policyIds: Array.from(new Set(policyIds)) }, 1000, 0, load);
            const policyholderIds = policies.items.map((item) => item.policyholderId);
            const policyholders = await policyholderApiClient.search({
                policyholderIds: Array.from(new Set(policyholderIds)),
            });

            const dataClaims = setDataClaims(claims, policyholders, policies, products);

            if (!isMountRef.current) {
                return;
            }

            setData(dataClaims);
            setTotal(claims.total);

            if (!isRefresh) {
                setHeaderColumns(headerColumnsI18);
            }
        } catch (error) {
            if (!isMountRef.current) {
                return;
            }
            setError(generateErrorMessage(error));
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchBlob();
        return () => {
            if (!isMountRef.current) {
                return;
            }
            setDisplayedTableColumns([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filename = useMemo(() => `${t("claims")} - ${FormatUtils.now("DD")}.csv`, []);

    return (
        <PageContainer title={t("claims")}>
            {defaultColumns && <div data-testid="blob" />}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={6} sm={8} md={5}>
                            <Typography variant="h5">{t("claims")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.endStyle}>
                    <TableOptions
                        theme={theme}
                        searchLabel={t("filterThisPage")}
                        searchChange={setGlobalSearch}
                        downloadLabel={t("download")}
                        downloadClick={handleDownloadCSV}
                        customiseLabel={t("customise")}
                        customiseClick={() => setOpenCustomiseFlyout(true)}
                    />
                </Grid>
                <Grid item xs={12}>
                    {error ? (
                        <Grid item xs={12} md={8} lg={6} xl={4}>
                            <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                        </Grid>
                    ) : null}
                    <TableContainer
                        theme={theme}
                        columns={headerColumns}
                        data={data}
                        globalSearch={globalSearch}
                        loading={loading}
                        blobLoading={blobLoading}
                        total={total}
                        displayedTableColumns={displayedTableColumns}
                        fetchData={fetchData}
                        refreshInterval={15000}
                        withBlob
                    />
                </Grid>
            </Grid>
            <CustomiseFlyout tableName={tableName} defaultColumns={defaultColumns} />
            <CSVLink ref={csvLinkEl} data={csvData} filename={filename} />
        </PageContainer>
    );
};

export default ClaimsPage;
