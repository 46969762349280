import React from 'react';
import ToolTipComponent from '@blocksure/blocksure-core/dist/src/components/ToolTipComponent';
import Form from 'react-jsonschema-form';
import './Schema.css';
import YesNoField from './field/YesNoField';
import DatePickerWidget from './widget/DatePickerWidget';
import TitleField from './field/TitleField';
import DescriptionField from './field/DescriptionField';
import {Markdown} from './Markdown';
import CheckboxWidget from './widget/CheckboxWidget';
import AddressField from './field/AddressField/AddressField';
import PhoneNumberField from './field/PhoneNumberField/PhoneNumberField';
import NotEmptyStringField from './field/NotEmptyStringField';
import BulkToggleField from './field/BulkToggleField';
import AltDateWidget from './widget/AltDateWidget';
import {DefaultTemplate} from './DefaultTemplate';
import {AltDateTimeWidget} from './widget/AltDateTimeWidget';
import {ImageUpload} from './widget/ImageUploadWidget';
import MoneyWidget from './widget/MoneyWidget';
import {useDefaultTranslation} from '../common/Translation';
import TextWidget from './widget/TextWidget';
import EmailWidget from './widget/EmailWidget';

let localAmend = false;

const Schema = ({
    amend,
    children,
    schema,
    uiSchema,
    formData,
    onChange,
    onSubmit,
    pending,
    validate
}) => {
    const {t} = useDefaultTranslation();
    localAmend = amend;
    return (
        <Form amend={amend}
              widgets={widgets}
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              key="schema"
              omitExtraData
              liveOmit
              showErrorList={false}
              FieldTemplate={DefaultTemplate}
              fields={fields}
              disabled={pending}
              onChange={onChange}
              validate={validate}
              transformErrors={transformErrors(t)}
              onSubmit={onSubmit}>
            {children}
        </Form>
);
}

export const widgets = {
    infoWidget: props => <ToolTipComponent onClick={() => global.embedded && window.parent.scrollTo(0, 0)}>{props.schema.tooltip}</ToolTipComponent>,
    CheckboxWidget: props => <CheckboxWidget amend={localAmend} {...props} />,
    TextWidget: TextWidget,
    EmailWidget: EmailWidget,
    'date-picker': DatePickerWidget,
    // This is a fork of the AltDateWidget that ships with rjsf. We can remove if they merge and release this PR: https://github.com/rjsf-team/react-jsonschema-form/pull/1681
    'alt-date2': AltDateWidget,
    'alt-datetime2': AltDateTimeWidget,
    'files': ImageUpload,
    'money-widget': MoneyWidget
};

const fields = {
    TitleField: props => <TitleField {...props} title={<Markdown value={props.title}/>}/>,
    DescriptionField: props => <DescriptionField {...props} description={<Markdown value={props.description}/>}/>,
    addressField: AddressField,
    notEmptyStringField: NotEmptyStringField,
    phoneNumberField: PhoneNumberField,
    yesno: YesNoField,
    bulkToggle: BulkToggleField
};

const transformErrors = t => errors => {
    return errors.map(error => {
      if (error.name === "required") {
        error.message = t("requiredProperty");
      } else if (error.name === "format") {
        error.message = t("fillOutThisField");
      }
      return error;
    });
  }

export default Schema;
