import { IAction } from "@surelync/common";

export const FETCH_START = "FETCH_START";
export const FETCH_DONE = "FETCH_DONE";
export const FETCH_ERROR = "FETCH_ERROR";

type IInitialState = {
    fetching: boolean;
    error: string;
};

export const initialState = {
    fetching: false,
    error: "",
};

export function reducer(state: IInitialState, action: IAction) {
    switch (action.type) {
        case FETCH_START:
            return { ...state, fetching: true };
        case FETCH_DONE:
            return { ...state, fetching: false };
        case FETCH_ERROR:
            return { ...state, fetching: false, error: action.payload };
        default:
            return state;
    }
}
