import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { CurrencyUtils, localeService } from "@surelync/common";

interface IProps {
    cancellationFee: number;
    currency: string;
    premiumRefund: number;
}

export const CancellationFee: React.FC<IProps> = ({ cancellationFee, currency, premiumRefund }) => {
    const { t } = useTranslation();

    const total = premiumRefund - cancellationFee;
    const totalText = total >= 0 ? t("refundAmount") : t("netAmount");

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
                <Typography align="right" variant="body1">
                    {t("premiumRefund")}:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6">{CurrencyUtils._renderCurrency(premiumRefund, localeService.locale, currency)}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography align="right" variant="body1">
                    {t("cancellationFee")}:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6">{CurrencyUtils._renderCurrency(cancellationFee, localeService.locale, currency)}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography align="right" variant="body1">
                    {totalText}:
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6">{CurrencyUtils._renderCurrency(total, localeService.locale, currency)}</Typography>
            </Grid>
        </Grid>
    );
};
