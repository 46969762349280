/* eslint-disable camelcase */
export default class AddressLookupService {
    constructor(namespacedLocalStorage, fetch) {
        this.namespacedLocalStorage = namespacedLocalStorage;
        this.fetch = fetch;
    }

    async requestAddresses(country = 'UK', postcode, apiKey) {
        const sanitisedPostcode = this.sanitisePostcode(country, postcode);
        const addresses = await this.tryCacheOr(sanitisedPostcode, () => this.doFetch(country, sanitisedPostcode, apiKey));
        return this.parse(country, addresses);
    }

    sanitisePostcode(country = 'UK', postcode = '') {
        if (country === 'JP') return postcode.replace(/-/g, '').toLowerCase();
        return postcode.replace(/ /g, '').toLowerCase();
    }

    doFetch(country = 'UK', postcode, apiKey) {
        if (country === 'JP') return this.getAddressLookupJapan(postcode);

        return this.getAddressLookup(postcode, apiKey);
    }

    async getAddressLookup(postCode, apiKey) {
        const url = `https://api.getAddress.io/find/${postCode}?api-key=${apiKey}&expand=true`;
        const response = await (this.fetch || fetch)(url);
        if (response.ok) return response.text();
        throw new Error('Unable to load addresses');
    }

    async getAddressLookupJapan(postCode) {
        const apiKey = '!!addressLookupJapanApiKey!!';
        const url = `https://apis.postcode-jp.com/api/v4/postcodes/${postCode}?fields=town,city,postcode,prefCode,pref&apiKey=${apiKey}`;
        if (apiKey.indexOf('!!') === 0) {
            console.warn('Postcode-jp ApiKey not found.');
        }
        const response = await (this.fetch || fetch)(url);
        if (response.ok) {
            const data = await response.json();
            const address = data[0];
            if (!address) return '{"addresses": []}';
            return JSON.stringify({
                addresses: [{
                    line_3: address.town,
                    town_or_city: address.city,
                    postcode: address.postcode,
                    county: address.pref
                }]
            });
        }
        throw new Error('Unable to load addresses');
    }

    async tryCacheOr(postCode, func) {
        let addresses = this.namespacedLocalStorage.getItem(postCode);
        if (!addresses) {
            addresses = await func();
            this.namespacedLocalStorage.setItem(postCode, addresses);
        }
        return JSON.parse(addresses);
    }

    parse(countryISOCode, { addresses }) {
        return addresses.map(address => {
            const {
                line_1, line_2, line_3, line_4, locality, town_or_city, county, country, building_name,
                building_number, sub_building_name, sub_building_number, thoroughfare
            } = address;

            let line1;
            let line2;

            if (countryISOCode === 'JP') {
                // TODO Japan mapping
            } else {
                const addressNumber = (building_number === ''
                    || building_number === 'undefined' || building_number === undefined) ? sub_building_number : building_number;
                const addressStreet = (thoroughfare === ''
                    || thoroughfare === 'undefined' || thoroughfare === undefined) ? building_name + sub_building_name : thoroughfare;
                const address = `${addressNumber} ${addressStreet}`;
                const lines = [line_1, line_2];
                line2 = (line_1 || line_2)
                    ? lines.filter(Boolean).join(', ').trim() : (address); // Street Name & Number / Building Name
                line1 = line_1 !== line2 ? line_1 : undefined;
            }
            console.debug(`address = ${JSON.stringify({
                line1,
                line2,
                line3: line_3,
                line4: line_4,
                locality,
                subzone: town_or_city,
                zone: county,
                country
            })}`);

            return { line1, line2, line3: line_3, line4: line_4, locality, subzone: town_or_city, zone: county, country };
        });
    }
}
