import InputValidation from "@blocksure/blocksure-core/dist/src/utilities/InputValidation";
import { i18n } from "@surelync/common";

export const validate = (id: string, roles: string[]) => (values) => {
    const errors: any = {};

    if (!values.email) {
        errors.email = i18n.t("fillField");
    } else if (!InputValidation.isValidEmail(values.email)) {
        errors.email = i18n.t("invalidEmail");
    }

    if (!values.firstName) {
        errors.firstName = i18n.t("fillField");
    }

    if (!values.lastName) {
        errors.lastName = i18n.t("fillField");
    }

    if (!id && !values.password) {
        errors.password = i18n.t("fillField");
    }

    if (!id && !roles.length) {
        errors.role = i18n.t("allocateRolesToUser");
    }

    return errors;
};
