import React, { useEffect, useContext } from "react";
import { PageContainer } from "../components";
import CSVImporterApp from "@blocksure/csv-importer";
import Grid from "@material-ui/core/Grid";
import "./styles.css";
import GlobalContext from "../context/global-context";


const envJSON = (window as any).env;

const CsvImport: React.FC = () => {
    const { locale, currentUser } = useContext(GlobalContext);

    /*
    FIXME @surelync/main Error: application '@surelync/main' died in status LOADING_SOURCE_CODE: Module parse failed
    If @blocksure/sureapp/dist/app.css include Bootstrap

    Maybe this is solution: https://medium.com/@harith.sankalpa/how-to-load-css-or-sass-styles-into-single-spa-framework-using-style-loader-7d5937fc1c53
  */
    useEffect(() => {
        const head = document.head;
        const linkBootstrap = document.createElement("link");
        const linkFontawesome = document.createElement("link");

        linkBootstrap.type = "text/css";
        linkBootstrap.rel = "stylesheet";
        linkBootstrap.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
        linkBootstrap.integrity = "sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l";
        linkBootstrap.crossOrigin = "anonymous";

        linkFontawesome.type = "text/css";
        linkFontawesome.rel = "stylesheet";
        linkFontawesome.href = "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
        linkFontawesome.integrity = "sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p";
        linkFontawesome.crossOrigin = "anonymous";

        head.appendChild(linkBootstrap);
        head.appendChild(linkFontawesome);

        return () => {
            try {
                head.removeChild(linkBootstrap);
                head.removeChild(linkFontawesome);
            } catch (error) {
                console.warn(error);
            }
        };
    }, []);

    return (
        <>
            <PageContainer title="title">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CSVImporterApp surelyncProps={{ appName: "Surelync", env: envJSON, currentUser, locale }} />
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );
};

export default CsvImport;
