import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "@surelync/common";
import { CellInfo } from "react-table";
import LinkButton from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

interface IProps {
    isPolicyholderReadPermission: boolean;
    onOpenPayment: (id: string) => any;
    onOpenRetryPayment: (transactionId: string) => any;
    onOpenPolicyPayment: (sequenceId: string) => any;
}

export const getHeaderColumnsI18 = (props: IProps) => [
    {
        Header: () => i18n.t("policyId"),
        accessor: "reportingRef",
        size: "extraMedium",
    },
    {
        Header: () => i18n.t("policyIssueDate"),
        accessor: "policyIssueDate",
        size: "medium",
    },
    {
        Header: () => i18n.t("policyholder"),
        accessor: "policyholder",
        Cell: (row: CellInfo) => {
            return (
                <>
                    {row.value === "-" || !props.isPolicyholderReadPermission ? (
                        <div>{row.value}</div>
                    ) : (
                        <LinkButton component={Link} to={`/contacts/${row.value}`} variant="body1">
                            {row.value}
                        </LinkButton>
                    )}
                </>
            );
        },
        size: "extraMedium",
    },
    {
        Header: () => i18n.t("dateDueFromPolicyholder"),
        accessor: "dateDueFromPolicyholder",
        size: "medium",
    },
    {
        Header: () => i18n.t("status"),
        accessor: "status",
        Cell: (row: CellInfo) => row.value,
        size: "small",
    },
    {
        Header: () => i18n.t("failureReason"),
        accessor: "failureReason",
        size: "large",
    },
    {
        Header: () => i18n.t("collectedAttemptDetails"),
        accessor: "collectedAttemptDetails",
        Cell: (row: CellInfo) => {
            return (
                <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                        props.onOpenPayment(row.row.original.transactionReference);
                    }}
                >
                    {i18n.t("expand")}
                </Button>
            );
        },
        size: "small",
    },
    {
        Header: () => i18n.t("retryCollection"),
        accessor: "retryCollection",
        Cell: (row: CellInfo) => {
            return (
                <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                        props.onOpenRetryPayment(row.row.original.transactionReference);
                    }}
                >
                    {i18n.t("retry")}
                </Button>
            );
        },
        size: "small",
    },
    {
        Header: () => i18n.t("currency"),
        accessor: "currency",
        size: "small",
    },
    {
        Header: () => i18n.t("amountPayableByPolicyholder"),
        accessor: "amountPayableByPolicyholder",
        size: "extraMedium",
    },
    {
        Header: () => i18n.t("transactionReference"),
        accessor: "transactionReference",
        size: "medium",
    },
    {
        Header: () => i18n.t("policyPaymentSchedule"),
        accessor: "policyPaymentSchedule",
        Cell: (row: CellInfo) => {
            return (
                <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                        props.onOpenPolicyPayment(row.row.original.sequenceId);
                    }}
                >
                    {i18n.t("viewDetails")}
                </Button>
            );
        },
        size: "extraMedium",
    },
    {
        Header: () => i18n.t("inception"),
        accessor: "inception",
        size: "medium",
    },
    {
        Header: () => i18n.t("expiry"),
        accessor: "expiry",
        size: "medium",
    },
    {
        Header: () => i18n.t("policyStatus"),
        accessor: "policyStatus",
        size: "small",
    },
    {
        Header: () => i18n.t("cancelPolicy"),
        accessor: "cancelPolicy",
        size: "small",
    },
];
