const getPathTokens = () => window.location.pathname.split("/").filter(it => it !== "");

export const SureAppName = getPathTokens()[0] === 'surelync' ? 'global' : 'sureapp-user';

export function getBase() {
    let pathTokens = getPathTokens();
    const isSurelync = pathTokens[0] === 'surelync';

    if (isSurelync) {
        pathTokens.shift(0);
    }

    return pathTokens[0] === 'sureapp' ? `/${pathTokens[0]}/${pathTokens[1]}` : `/sureapp/${pathTokens[0]}`;
}

export function getProductBase() {
    let pathTokens = getPathTokens();
    const isSurelync = pathTokens[0] === 'surelync';

    if (isSurelync) {
        pathTokens.shift(0);
    }
    
    return pathTokens[0] === 'sureapp' ? pathTokens[1] : pathTokens[0]
}

export function convertToNewPath(path, id) {
    let newPath = path.replace(":id", id);
    if(newPath.startsWith("/")) {
        newPath = newPath.slice(1);
    }
        
    if(newPath.endsWith("/")) {
        newPath = newPath.slice(0, -1);
    }
    
    const pathArray = newPath.split("/")
    return "/"+pathArray[0];
}

export const productBase = getProductBase()

export const base = getBase();
