import React, {useEffect, useState} from 'react';
import Loading from '../widgets/Loading';

// The sole purpose of this screen is to render as blank for 1 second, then if it is still mounted after 1 second, it will display a loading spinner.
export const LoadingScreen = () => {
    const [takingALongTime, setTakingALongTime] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setTakingALongTime(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
            <div className="screen text-center">
                {takingALongTime && <Loading/>}
            </div>
    );
};
