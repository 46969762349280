import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        margin: 0,
        padding: 0,
    },
    card: {
        display: "flex",
        flexDirection: "column",
    },
}));
