import AltDateWidget from './AltDateWidget';
import React from 'react';

export let AltDateTimeWidget = (props) =>
        <AltDateWidget {...props} time/>;

AltDateTimeWidget.defaultProps = {
    time: true,
    disabled: false,
    readonly: false,
    autofocus: false,
    options: {
        yearsRange: [1900, new Date().getFullYear() + 2],
    },
};
