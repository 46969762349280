import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { useStyles } from "./styles";
import { IPermission, TranslateUtils } from "@surelync/common";

interface IProps {
    showAll?: boolean;
    permissions: IPermission[];
    value: string;
    alwaysShow?: boolean;
}

const DescriptionColumns: React.FC<IProps> = ({ showAll, alwaysShow, permissions, value }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(alwaysShow);

    useEffect(() => {
        if (showAll) {
            setOpen(showAll);
        }
    }, [showAll]);

    return (
        <div>
            <div>{value}</div>
            <div className={classes.listContentWrapper}>
                <List className={classes.listMain} disablePadding={!open}>
                    {open &&
                        permissions.map((permission) => (
                            <ListItem key={permission.id} disableGutters dense className={classes.permissions}>
                                <ListItemIcon className={classes.listIcon}>
                                    <FiberManualRecord fontSize="small" className={classes.listIconSVG} />
                                </ListItemIcon>
                                <ListItemText primary={TranslateUtils.translateAltValue(permission.name, permission.description)} />
                            </ListItem>
                        ))}
                </List>
            </div>

            {!alwaysShow && (
                <Button color="primary" variant="text" size="small" onClick={() => setOpen(!open)} className={classes.descriptionBtn}>
                    {open ? t("hideDetails") : t("showDetails")}
                </Button>
            )}
        </div>
    );
};

export default DescriptionColumns;
