import licence from './licence-key.js';

/*  this should be the productID of the most up to date version of the student product
 * @type {string}
 */
const stuProductID = 'prd_stu76NVYYtYESPmzS2iGS';
/**
 * Billing Entity ID for invoice emails beings end to joe.green@blocksure.com (university bursar)
 * @type {string}
 */
const billingEntityId = 'bil_3B62kFFsgnHGQKisk5wxbn';
/**
 * when set to true, as test functionality, a random number will be appended to the end of the email so that the same policyholder can be used multiple time
 * @type {boolean}
 */
const testMode = false;
const intermediaryServerURL = 'http://localhost:8000';
/**
 * max image width in pixels
 * @type {number}
 */
const imageSize = 400;
const mainAPI = 'import/api/v1/';
const bulkAPIEndpoint = `${mainAPI}import`;
const imageAPIEndpoint = `${mainAPI}image`;

const appPath = '/stu';
/**
 * replaced beneficiary with student
 * @type {string[]}
 */
const listOfStdFields = [
    // list of fields that are used, an * is used at the end for a manual field, they will be deleted from the actual field,
    // a ! indicates it is unique, if both it should be in the order !*
    'policyholder.primaryEmailAddress!*', 'policyholder.firstName', 'policyholder.lastName*', 'policyholder.dateOfBirth*',
    'policyholder.ktpNumber!', 'policyholder.religion', 'policyholder.occupation',
    'policyholder.familyId', 'policyholder.primaryPhoneNumber',
    'policyholder.placeOfBirth', 'policyholder.address.line1*', 'policyholder.address.line2*',
    'policyholder.address.zone*', 'policyholder.address.subzone*', 'policyholder.address.postcode',
    'policyholderSpouse.firstName', 'policyholderSpouse.lastName', 'policyholderSpouse.dateOfBirth',
    'student.contactPreference', 'student.homeTelephoneNumber', 'student.mobileTelephoneNumber', 'student.studentEmailAddress',
    'student.personalEmailAddress', 'student.address.line1', 'student.address.line2', 'student.address.zone', 'student.address.subzone',
    'student.address.postcode', 'student.firstName', 'student.lastName', 'student.dateOfBirth', 'student.ktpNumber', 'contact.homeTelephoneNumber',
    'meta.billingEntityName'
];

const listOfEnumeratedFields = [ // Enumerated fields are entered as such, note if an invalid enum is used then it will import as null
    {
        label: 'policyholder.title',
        key: 'policyholder.title',
        type: 'select',
        options: [
            {value: 'Mr', label: 'Mr'},
            {value: 'Miss', label: 'Miss'},
            {value: 'Mrs', label: 'Mrs'},
            {value: 'Dr.', label: 'Dr.'}
        ]
    },
    {
        label: 'student.sex',
        key: 'student.sex',
        type: 'select',
        options: [
            {value: 'Male', label: 'Male'},
            {value: 'Female', label: 'Female'}
        ]
    },
    {
        label: 'student.title',
        key: 'student.title',
        type: 'select',
        options: [
            {value: 'Mr', label: 'Mr'},
            {value: 'Miss', label: 'Miss'},
            {value: 'Mrs', label: 'Mrs'},
            {value: 'Dr.', label: 'Dr.'}
        ]
    },
    {
        label: 'policyholderSpouse.title',
        key: 'policyholderSpouse.title',
        type: 'select',
        options: [
            {value: 'Mr', label: 'Mr'},
            {value: 'Miss', label: 'Miss'},
            {value: 'Mrs', label: 'Mrs'},
            {value: 'Dr.', label: 'Dr.'}
        ]
    },
    {
        label: 'policyholder.sex',
        key: 'policyholder.sex',
        type: 'select',
        options: [
            {value: 'Male', label: 'Male'},
            {value: 'Female', label: 'Female'}
        ]
    },
    {
        label: 'policyholderSpouse.sex',
        key: 'policyholderSpouse.sex',
        type: 'select',
        options: [
            {value: 'Male', label: 'Male'},
            {value: 'Female', label: 'Female'}
        ]
    },
    {
        label: 'policyholder.civilStatus',
        key: 'policyholder.civilStatus',
        type: 'select',
        options: [
            {value: 'Single', label: 'Single'},
            {value: 'Married', label: 'Married'},
            {value: 'Divorced', label: 'Divorced'},
            {value: 'Widowed', label: 'Widowed'},
            {value: 'Married Not Recorded', label: 'Married Not Recorded'}
        ]
    },
    {
        label: 'policyholder.smokingStatus',
        key: 'policyholder.smokingStatus',
        type: 'select',
        options: [
            {value: 'Non-Smoker', label: 'Non-Smoker'},
            {value: 'Smoker', label: 'Smoker'}
        ]
    },
    {
        label: 'policyholder.contactPreference',
        key: 'policyholder.contactPreference',
        type: 'select',
        options: [
            {value: 'email', label: 'email'},
            {value: 'phone', label: 'phone'},
            {value: 'post', label: 'post'},
            {value: 'text-phone', label: 'text-phone'}
        ]
    },
    {
        label: 'policyholder.relationshipToBeneficiary',
        key: 'policyholder.relationshipToBeneficiary',
        type: 'select',
        options: [
            {value: 'Father', label: 'Father'},
            {value: 'Mother', label: 'Mother'},
            {value: 'Grandparent', label: 'Grandparent'},
            {value: 'Guardian', label: 'Guardian'},
            {value: 'Other', label: 'Other'}
        ]
    }
];

/**
 * a list of some pre-made Billing Entity IDs are in https://docs.google.com/document/d/1QL0IE3-WVC4zdpZ3sIvv5s8nZnqUWZtTz0c4l1Ansa8/edit#
 */
export default {
    stuProductID,
    billingEntityId,
    imageAPIEndpoint,
    licence,
    testMode,
    intermediaryServerURL,
    imageSize,
    bulkAPIEndpoint,
    appPath,
    listOfStdFields,
    listOfEnumeratedFields
};
