import React from 'react';
import {useDefaultTranslation} from './Translation';

export const Tabs = ({
    tabs,
    activeTab,
    onTabClick
}) => {
    const {t} = useDefaultTranslation();
    return <div className="gap btn-group" style={{margin: 'auto'}}>{tabs.map((id) => {
        return <Tab key={id} id={id} onClick={onTabClick} active={id === activeTab}>{t(id)}</Tab>;
    })}
    </div>;
};


const Tab = ({
    id,
    children,
    active,
    onClick
}) => (
        <button className={'key btn ' + (active ? 'btn-primary' : 'btn-secondary')} onClick={() => onClick(id)}>{children}</button>
);
