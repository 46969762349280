import * as React from "react";
import { useContext, useMemo, useReducer } from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import LinkButton from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { ContactContext, SET_TAB } from "../../../../models";
import GlobalContext from "../../../../../context/global-context";
import { initialState, reducer } from "./reducer";
import { Line } from "../../../../../components";
import { CurrencyUtils, localeService, Preloader, IPolicy, RecordsNotFound } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { ETab } from "../../../../models/tabs.model";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            flex: "1 0 auto",
            padding: theme.spacing(3),
        },
        content: {
            flex: "1 0 auto",
            minHeight: 200,
        },
    })
);

type IProps = Record<string, unknown>;

const RecentPolicies: React.FC<IProps> = () => {
    const [{ fetching }] = useReducer(reducer, initialState);
    const { products } = useContext(GlobalContext);
    const [{ policies }, dispatch] = useContext(ContactContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const recentPolicies = useMemo(() => {
        if (!policies) {
            return [];
        }
        const cached = new Map<string, boolean>();
        const result: IPolicy[] = [];
        const sortedPolicies: IPolicy[] = FormatUtils.sortByProperty([...policies], "effectiveFromDate").reverse();
        sortedPolicies.forEach((it) => {
            if (!cached.has(it.id)) {
                cached.set(it.id, true);
                result.push(it);
            }
        });
        return result.slice(0, 2);
    }, [policies]);

    const renderDates = (policy: IPolicy) =>
        `${FormatUtils.renderDate(policy.effectiveFromDate)} - ${FormatUtils.renderDate(policy.effectiveToDate)}`;
    const renderPremium = (policy: IPolicy) =>
        CurrencyUtils._renderCurrency(policy?.grossPremium?.cumulative, localeService.locale, policy?.currency);
    const renderProductName = (productId: string) => products[productId]?.name;

    return (
        <>
            <Typography gutterBottom variant="body1">
                {t("recentPolicies")}
            </Typography>
            <Paper className={classes.root}>
                {fetching ? (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <Preloader theme={theme} />
                        </Grid>
                    </Grid>
                ) : recentPolicies.length ? (
                    <Grid container direction="column" spacing={3} classes={{ root: classes.content }}>
                        {recentPolicies.map((sequence) => (
                            <Grid item xs key={sequence.sequenceId}>
                                <LinkButton component={Link} to={`/policies/${sequence.id}`} variant="body1">
                                    {sequence.reportingRef} - {renderProductName(sequence.productId)}
                                </LinkButton>
                                <Line divider label={t("policyDates")} value={renderDates(sequence)} />
                                <Line label={t("policyPremium")} value={renderPremium(sequence)} />
                            </Grid>
                        ))}
                        <Grid item>
                            <Box display="flex" justifyContent="flex-end">
                                <LinkButton
                                    href="#"
                                    color="primary"
                                    variant="body2"
                                    onClick={() => dispatch({ type: SET_TAB, payload: ETab.Policies })}
                                >
                                    {t("viewAll")}
                                </LinkButton>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justify="center" alignItems="center" classes={{ root: classes.content }}>
                        <Grid item>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    );
};

export default RecentPolicies;
