import React, {useContext} from 'react';
import {Link, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import './Header.css';
import {AuthContext, ProductContext, TradingGroupContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';
import {convertToNewPath} from "../base";

const Header = () => {
    const location = useLocation();
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {t} = useDefaultTranslation();
    const {
        auth,
        signOut,
        surelyncProps
    } = useContext(AuthContext);
    const {
        logo,
        formulator = {}
    } = useContext(TradingGroupContext);
    const tokenExists = auth && auth.username !== 'anonymous';
    const showSignOut = !surelyncProps && tokenExists && location.pathname !== `${newPath}/`;

    return (
            <ProductContext.Consumer>{({logo: productLogo = logo} = {}) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-10 col-sm-10 col-md-10 text-right">
                                {showSignOut &&
                                <button className="header-sign-out btn btn-link" onClick={signOut}>{t('signOut')}</button>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-xs-2 col-xs-8 offset-sm-3 col-sm-8 offset-md-2 col-md-8 text-center">
                                <Link to={`${newPath}/`} style={{textDecoration: 'none'}}>
                                    <h1>
                                        {/* Show logo if available. */}
                                        {productLogo && <img className="product-logo" src={productLogo} alt="logo"/>}
                                        {/* Show a text header if defined. */}
                                        {formulator.headerText ? <span>&nbsp;&nbsp;{formulator.headerText}</span> : ''}
                                    </h1>
                                </Link>
                            </div>
                        </div>
                    </div>
            )}</ProductContext.Consumer>
    );
};

export default Header;
