import products from '../products';
import {ProductApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import {SureAppName} from '../base';

const productApiClient = new ProductApiClient(SureAppName);

export const loadAttachments = async (product, attachments) => {
    // The 'productData' simply allows for fast debugging/development of products locally.
    const productData = products[product.id];

    const getAttachmentLocation = (name) => {
        const attachments = [
            ...product.dynamicDocumentTemplates,
            ...product.hiddenAttachments,
            ...product.staticAttachments
        ];
        if (!attachments.length) return null;
        const matching = attachments.filter(it => it.name === name);
        return matching.length > 0 ? matching[0].location : null;
    };

    const getAttachmentData = async name => {
        const location = getAttachmentLocation(name);
        return location && productApiClient.getAttachment(product.id, location, true);
    };

    const getAttachmentUrl = async name => {
        const location = getAttachmentLocation(name);
        return location && productApiClient.getAttachmentUrl(product.id, location, SureAppName);
    };

    const results = {};

    const promises = Object.entries(attachments)
            .map(async ([key, {
                name,
                urlOnly
            }]) => {
                const value = (productData && productData[key]) || (urlOnly ? await getAttachmentUrl(name) : await getAttachmentData(name));
                return [key, value];
            });
    // Wait for all in parallel.
    (await Promise.all(promises)).forEach(([key, data]) => {
        results[key] = data;
    });
    return results;
};
