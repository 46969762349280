import React from 'react';
import {getPrice, SinglePrice} from '../quote/QuoteArray';
import {Instalments} from '../shared/Instalments';
import {useDefaultTranslation} from '../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';

export const YourQuote = ({
    product,
    quote,
}) => {
    const {t} = useDefaultTranslation();
    
    return (
            <div>
                <div className="text-center">
                    <h1>{t('renewal')}</h1>
                </div>
                {<div className="text-center gap">
                    {t('yourRenewalWillBeEffectiveFromTo', {
                        renewalFrom: FormatUtils.renderDate(quote.effectiveFromDate),
                        renewalTo: FormatUtils.renderDate(quote.effectiveToDate)
                    })}
                </div>}
                <div className="text-center gap">
                    If you wish to change your policy cover then please contact us.
                </div>
                <div className="col-md-12 text-center">
                    <SinglePrice {...getPrice(quote)} product={product}/>
                </div>

                <div className="col-md-12 text-center gap">
                    <div className="message-body">
                        <Instalments transactions={quote && quote.transactions}/>
                    </div>
                </div>
            </div>
    );
};
