import React, {useContext, useState} from 'react';
import {useParams, useRouteMatch, withRouter} from 'react-router-dom';
import Form from 'react-jsonschema-form';
import {useDefaultTranslation} from '../common/Translation';
import {widgets} from '../schema/Schema';
import './ClaimDate.css';
import {DateTime} from 'luxon';
import {PolicyListContext} from '../common/context';
import {PolicyList} from '../shared/PolicyList';
import Loading from '../widgets/Loading';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import {convertToNewPath} from "../base";

const Schema = (whenDidYourIncidentHappen) => ({
    type: 'object',
    required: ['date'],
    properties: {
        date: {
            type: 'string',
            title: whenDidYourIncidentHappen
        }
    }
});

const UiSchema = {
    date: {
        'ui:widget': 'alt-datetime2',
        'ui:time': true,
        'ui:minute': false,
        'ui:mustBePast': true,
        'ui:yearsRange': [new Date().getFullYear(), -100]
    },
    datetime: {
        'ui:widget': 'alt-datetime2',
        'ui:time': true,
        'ui:minute': false,
        'ui:yearsRange': [new Date().getFullYear(), -100]
    }
};

export const ClaimDate = withRouter(({history}) => {
    const {t} = useDefaultTranslation();
    const {policies} = useContext(PolicyListContext);
    const [error, setError] = useState(undefined);
    const [form, setForm] = useState({
        date: FormatUtils.today()
                .startOf('hour')
                .toISO()
    });

    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    const iHaveReadAndUnderstoodThis = t('whenDidYourIncidentHappen', {defaultValue: 'When did your incident happen?'});
    const schema = Schema(iHaveReadAndUnderstoodThis);

    const validate = (formData, errors) => {
        const endOfHour = FormatUtils.today()
                .startOf('hour');
        if (FormatUtils.parseDate(formData.date) > endOfHour)
            errors.date.addError('Date of incident cannot be in the future.');
        return errors;
    };

    const onChange = ({formData}) => {
        if (Object.keys(formData).length === 0) return;
        const endOfHour = FormatUtils.today()
                .startOf('hour');
        if (FormatUtils.parseDate(formData.date) > endOfHour) {
            setError('Choose another date.');
        } else {
            setError(null);
        }
        setForm(formData);
    };

    const effectivePolicies = policies && policies.filter(policy => {
        const date = FormatUtils.parseDate(form.date);
        return FormatUtils.parseDate(policy.effectiveFromDate)
                        .toUTC()
                        .startOf('hour') <= date &&
                FormatUtils.parseDate(policy.effectiveToDate)
                        .toUTC()
                        .endOf('hour') > date;
    });

    let linkFunc = it => {
        const incidentDate = DateTime.max(FormatUtils.parseDate(form.date), FormatUtils.parseDate(it.effectiveFromDate)
                .toUTC())
                .toISO();
        return ({
            pathname: `${newPath}/claim/${it.sequenceId}/new`,
            state: {incidentDate}
        });
    };

    return (
            <div className="screen container">
                <div className="clearfix">&nbsp;</div>
                <div className="section offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                    <Form
                            widgets={widgets}
                            key="schema"
                            formData={form}
                            omitExtraData
                            liveOmit
                            liveValidate
                            schema={schema}
                            uiSchema={UiSchema}
                            onChange={onChange}
                            showErrorList={false}
                            validate={validate}
                    >
                        <label className="col-form-label" htmlFor="root_date">{t('choosePolicyForClaim')}</label>
                        {effectivePolicies ?
                                effectivePolicies.length > 0 && !error ?
                                        <PolicyList showPrice={false} policies={effectivePolicies} linkFunc={linkFunc}/> :
                                        <h3 className="text-center"><label className="badge badge-lg badge-danger">{t('noneApplicable')}</label>
                                        </h3> :
                                <Loading inline/>}
                        <hr/>
                        <button data-menukey="back" onClick={history.goBack} className="btn btn-lg btn-block btn-primary gap">{t('back')}</button>
                    </Form>
                </div>
                <div className="clearfix">&nbsp;</div>
            </div>
    );
});

