import React from 'react';
import {CurrentStepProvider} from '../step/CurrentStepProvider';
import {PolicyScreen} from '../shared/Screen';
import {SubmissionProvider} from '../submission/SubmissionProvider';
import {ClaimDebugView} from '../debug/ClaimDebugView';
import {FLOW_CLAIM, StepWidget} from '../step/StepWidget';
import {MetadataProvider} from '../submission/MetadataProvider';
import {ClaimSchemaProvider} from './ClaimSchemaProvider';
import {ClaimProvider} from './ClaimProvider';

export const ClaimFlow = ({
    children,
    match,
    location
}) => {
    if (location.state && location.state.incidentDate) {
        sessionStorage.setItem(`com.blocksure.incidentDate`, location.state && location.state.incidentDate);
    }

    const screens = (
            <CurrentStepProvider flow={FLOW_CLAIM}>
                <MetadataProvider>
                    <SubmissionProvider type="claim">
                        <ClaimSchemaProvider>
                            <ClaimProvider>
                                <ClaimDebugView/>
                                <div className="clearfix">
                                    {children}
                                    <StepWidget flow={FLOW_CLAIM}/>
                                </div>
                            </ClaimProvider>
                        </ClaimSchemaProvider>
                    </SubmissionProvider>
                </MetadataProvider>
            </CurrentStepProvider>
    );

    return (
            <PolicyScreen sequenceId={match.params.sequenceId}>
                {screens}
            </PolicyScreen>
    );
};
